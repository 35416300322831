import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  save (p_uuid, uuid, data) {
    var d_uuid = 'new';
    if (uuid) {
      d_uuid = uuid;
    }
    let url = API_BASE_URL + 'patient/' + p_uuid + '/wisdom_data/' + d_uuid;
    return Vue.http.put(url, data);
  },
  save_wdm (p_uuid, wdmr_uuid, data) {
    if (!wdmr_uuid) {
      wdmr_uuid = 'new';
    }
    return Vue.http.put(API_BASE_URL + 'patient/' + p_uuid + '/save_wdm/' + wdmr_uuid, data);
  },
  save_multi_wdmr_list (p_uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + p_uuid + '/save_multi_wdmr_list', data);
  },
  save_patient_hims_protocol_inspection (p_uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + p_uuid + '/save_patient_hims_protocol_inspection', data);
  },
  search (data) {
    return Vue.http.post(API_BASE_URL + 'patient/' + data.patient_id + '/wisdom_data/list');
  },
  get_document_list (patient_id) {
    return Vue.http.get(API_BASE_URL + 'patient/' + patient_id + '/get_document_list');
  },
  prepare_hims_protocol_inspection_data (patient_id, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + patient_id + '/prepare_hims_protocol_inspection_data', data);
  },
  getPatientRecords (data) {
    return Vue.http.post(API_BASE_URL + 'patient/getPatientRecords', data);
  },
  get_patient_wdmr (data) {
    return Vue.http.post(API_BASE_URL + 'patient/get_patient_wdmr', data);
  },
  approve_wdm11 (data) {
    return Vue.http.post(API_BASE_URL + 'patient/approve_wdm11', data);
  },
  add_new_clone_wdm11 (data) {
    return Vue.http.post(API_BASE_URL + 'patient/add_new_clone_wdm11', data);
  },
  get_wdmr10_childs (data) {
    return Vue.http.post(API_BASE_URL + 'patient/get_wdmr10_childs', data);
  },
  get_last_records_related_with_this_schema (data) {
    return Vue.http.post(API_BASE_URL + 'patient/get_last_records_related_with_this_schema', data);
  },
  get_patient_wdmr11_or_wdmr12 (data) {
    return Vue.http.post(API_BASE_URL + 'patient/get_patient_wdmr11_or_wdmr12', data);
  },
  get_wdmr_and_its_wdm (data) {
    return Vue.http.post(API_BASE_URL + 'get_wdmr_and_its_wdm', data);
  },
  get (puid, uuid = null) {
    let url = uuid !== null ? API_BASE_URL + 'patient/' + puid + '/wisdom_data/' + uuid : API_BASE_URL + 'patient/' + puid + '/wisdom_data/list';
    return Vue.http.get(url);
  },
  delete (p_uuid, uuid, data_type) {
    return Vue.http.delete(API_BASE_URL + 'patient/' + p_uuid + '/wisdom_data/' + uuid + '/delete/' + data_type);
  },
  add_new_cycles_to_wdmr10 (data) {
    return Vue.http.post(API_BASE_URL + 'patient/add_new_cycles_to_wdmr10', data);
  }
};
