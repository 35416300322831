import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  save_phrase_data (data) {
    return Vue.http.post(API_BASE_URL + 'phrase/save_phrase_data', data);
  },
  get_phrase_list (query) {
    return Vue.http.get(API_BASE_URL + 'phrase/get_phrase_list?' + query);
  },
  phrase_operation (data) {
    return Vue.http.post(API_BASE_URL + 'phrase/phrase_operation', data);
  },
  phrase_column_option_match (data) {
    return Vue.http.post(API_BASE_URL + 'phrase/phrase_column_option_match', data);
  },
  word_analyze_in_column (data) {
    return Vue.http.post(API_BASE_URL + 'phrase/word_analyze_in_column', data);
  },
  add_or_edit_columns_by_including_the_words (data) {
    return Vue.http.post(API_BASE_URL + 'addoredit/columns/by_inclouding_the_words', data);
  }
};
