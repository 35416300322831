import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  save (data) {
    if (!data.id) {
      data.id = 'new';
    }
    let url = API_BASE_URL + 'hospital_save/';
    return Vue.http.put(url, data);
  },
  get (query) {
    return Vue.http.get(API_BASE_URL + 'hospital_settings/?' + query);
  },
  get_hospital_group () {
    return Vue.http.get(API_BASE_URL + 'hospital_group/');
  },
  get_all_hospitals_list () {
    return Vue.http.get(API_BASE_URL + 'get_all_hospitals_list/');
  },
  get_hospital_wtl_as_select (data) {
    return Vue.http.post(API_BASE_URL + 'get_hospital_wtl_as_select', data);
  },
  add_new_hospital_id_to_group (data) {
    return Vue.http.post(API_BASE_URL + 'hospital/add_new_hospital_id_to_group', data);
  },
  add_new_hospital_group (data) {
    return Vue.http.post(API_BASE_URL + 'hospital/add_new_hospital_group', data);
  },
  group_save (data) {
    var u_uuid = 'new';
    if (data.id) {
      u_uuid = data.id;
    }
    let url = API_BASE_URL + 'hospital_group_save/' + u_uuid;
    return Vue.http.put(url, data);
  }
};
