<template>
  <div>
    <div class="card-body compilestr-background" :style="mode === 'edit' ? 'margin-left: 30px; padding: 3px;' : 'padding: 3px;'">
      <div class="row collapse show" :id="acordion_index" v-if="mode === 'edit'">
        <div class="col-sm-12" style="text-align: right;">
          <b-button @click="AddToCompileStr(compile_str_array, '(')" style="margin-right: 7px;">(</b-button>
          <b-button @click="AddToCompileStr(compile_str_array, ')')" style="margin-right: 7px;">)</b-button>
          <b-button @click="AddToCompileStr(compile_str_array, 'and')" style="margin-right: 7px;">{{ $t('wdm16.3969') }}</b-button>
          <b-button @click="AddToCompileStr(compile_str_array, 'or')" style="margin-right: 7px;">{{ $t('wdm16.4254') }}</b-button>
          <b-button @click="BackInCompileStr(compile_str_array)" style="margin-right: 7px;">{{ $t('wdm16.3889') }}</b-button>
          <b-button @click="ClearCompileStr(compile_str_array)" style="margin-right: 7px;">{{ $t('wdm16.2781') }}</b-button>
        </div>
      </div>
      <div class="row" style="margin-left: 0px; padding: 3px; text-align: right;">
        <div class="col-sm-1" v-if="mode === 'edit'" data-toggle="collapse" data-parent="#accordion_query" :href="'#' + acordion_index" aria-expanded="true">
          <!-- <button type="button" class="btn btn-primary fa fa-edit"></button> -->
        </div>
        <div class="col-sm-11">
          <template v-for="(k, k_ind) in compile_str_array">
            <span v-if="k === ')' || k === '('" class="compilestr-parenthesis"> {{ k }} </span>
            <span v-else-if="k === 'and'" class="compilestr-operation-and"> {{ k }} </span>
            <span v-else-if="k === 'or'" class="compilestr-operation-or"> {{ k }} </span>
            <span v-else class="compilestr-caption"> {{ (k + 1) }}. {{ show_caption }} </span>
          </template>
          <br>
          <span v-if="CountSameItemsInArray(compile_str_array, '(') !== CountSameItemsInArray(compile_str_array, ')')" style="color: red;"> {{ $t('wdm16.11236') }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { RuleEngine } from '@/services/public/functions';

export default {
  name: 'RuleCompileStr',
  mounted () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  computed: {
    ...mapGetters({
      lang: 'lang'
    })
  },
  props: {
    acordion_index: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: true
    },
    show_caption: {
      type: String,
      required: true
    },
    compile_str_array: {
      type: Array,
      required: true
    }
  },
  data () {
    return {};
  },
  created () {},
  methods: {
    ClearCompileStr: function (compile_str_array) {
      for (let i = compile_str_array.length - 1; i > -1; i--) {
        compile_str_array.splice(i, 1);
      }
    },
    BackInCompileStr: function (compile_str_array) {
      if (compile_str_array.length) {
        compile_str_array.splice(compile_str_array.length - 1, 1);
      }
    },
    AddToCompileStr: function (compile_str_array, key) {
      if (key === '(') {
        if (compile_str_array.length === 0 || ['and', 'or', '('].indexOf(compile_str_array[compile_str_array.length - 1]) !== -1) {
          compile_str_array.push(key);
        } else {
          alert(this.$t('wdm16.12691'));
        }
      } else if (key === ')') {
        if (compile_str_array.length > 0 && ['and', 'or', '('].indexOf(compile_str_array[compile_str_array.length - 1]) === -1) {
          if (compile_str_array[compile_str_array.length - 1] === ')') {
            if (this.CountSameItemsInArray(compile_str_array, ')') !== this.CountSameItemsInArray(compile_str_array, '(')) {
              compile_str_array.push(key);
            } else {
              alert(this.$t('wdm16.12691'));
            }
          } else {
            compile_str_array.push(key);
          }
        } else {
          alert(this.$t('wdm16.12691'));
        }
      } else if (key === 'and' || key === 'or') {
        if (compile_str_array.length > 0 && ['and', 'or', '('].indexOf(compile_str_array[compile_str_array.length - 1]) === -1) {
          compile_str_array.push(key);
          if (compile_str_array[compile_str_array.length - 2] === ')') {
            compile_str_array.push('(');
          }
        } else {
          alert(this.$t('wdm16.12691'));
        }
      }
    },
    CountSameItemsInArray: function (arr, val) {
      let count = 0;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === val) {
          count++;
        }
      }
      return count;
    },
    field_tree_calculate: function () {
      RuleEngine.field_tree_calculate(this.query_list);
    },
    delete_field_in_query: function (field_index, query) {
      query.fields.splice(field_index, 1);
      RuleEngine.field_tree_calculate(this.query_list);
    },
    calculate_field_style (field_data) {
      if (field_data.significant === 'true') {
        return 'margin: 0px; margin-top: 2px;';
      } else {
        return 'background-color: #f1aeae; margin: 0px; margin-top: 2px;';
      }
    },
    add_key_compile_str: function (type, key, query_ind) {
      let list_count = 0;
      let compile_str_array = [];
      if (type === 'query') {
        this.compile_str = [];
        compile_str_array = this.compile_str;
        list_count = this.query_list.length;
      } else if (type === 'field') {
        this.query_list[query_ind].compile_str_array = [];
        compile_str_array = this.query_list[query_ind].compile_str_array;
        list_count = this.query_list[query_ind].fields.length;
      }
      compile_str_array.push('(');
      for (let i = 0; i < list_count; i++) {
        compile_str_array.push(i);
        if (list_count - 1 !== i) { compile_str_array.push(key); }
      }
      compile_str_array.push(')');
    },
    AddThisQueryToOrder: function (q_ind) {
      if (this.query_order.length === 0) {
        this.query_order.push({ queries: [q_ind], day: 0, minute: 0, dif_type: 'none', time_relation: 'none' });
      } else {
        if (this.query_order[this.query_order.length - 1].queries.length === 1) {
          this.query_order[this.query_order.length - 1].queries.push(q_ind);
        } else {
          this.query_order.push({ queries: [q_ind], day: 0, minute: 0, dif_type: 'none', time_relation: 'none' });
        }
      }
    },
    ExcludeThisQuery: function (query_ind) {
      this.query_list.splice(query_ind, 1);
    },
    add_to_model: function (compile_str, q_ind) {
      if (compile_str.length === 0) {
        compile_str.push('(');
        compile_str.push(q_ind);
      } else {
        if (['(', 'or', 'and'].indexOf(compile_str[compile_str.length - 1]) !== -1) {
          compile_str.push(q_ind);
        } else {
          alert(this.$t('wdm16.12691'));
        }
      }
    }

  },
  watch: {},
  components: {}
};

</script>

<style type="text/css">
.query-param {
  border-left: dotted 1px #20a8d8;
  border-bottom: dotted 1px #20a8d8;
  padding-left: 15px;
}

.compilestr-parenthesis {
  font-size: 20px;
  color: #f20714;
  font-family: Georgia, serif;
}

.compilestr-operation-and {
  font-size: 15px;
  color: #1dff00;
  font-family: Georgia, serif;
  margin: 5px;
}
.compilestr-operation-or {
  font-size: 15px;
  color: #ffbb00;
  font-family: Georgia, serif;
  margin: 5px;
}

.compilestr-caption {
  color: #39b2d5;
}

.compilestr-background {
  background-color: white;
}

</style>

