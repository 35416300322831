<template>
  <div>
    <template v-for="(pr1, pr1_ind) in option_group_data.param_order">
      <template v-if="calculate_if(record_data, pr1, option_group_data.parameters[pr1], option_group_data, '', anatomy, record_data)">
        <wisdom-param :test_mode="test_mode" :option_data="option_data" :wdm_name="option_data[data_type]['name']" :settings_mode="settings_mode" :external_patient_data="external_patient_data" :anatomy="anatomy" :data="record_data" :option_param_mother="option_group_data" :param="pr1" :level="'first'" :data_type="data_type" :rule_engine="rule_engine" :query_list_type="query_list_type" :polestar="polestar" :query_list="query_list" :column_list="column_list" :param_change="param_change" :option_loc="calculate_option_loc(param_group, pr1, pr1, '', '', '', '', '1', '', '', '', '')" :stage_data="stage_data" :key="'param_order_wisdom-param-component-' + pr1_ind + '-' + pr1" :p_wdmrData="main_record_data"></wisdom-param>
        <template v-if="settings_mode">
          <b-row style="margin: 2px; border-bottom: solid 1px black;">
            <b-col cols="2"> <span style="color: red;">{{ $t('wdm16.4037') }} =></span> </b-col>
            <b-col cols="10">
              <b-badge :id="'my_favorite_' + pr1" :variant="f_paramFavoriteControl(pr1) ? 'success' : 'danger'" @click="f_addDeleteFavoriteParam(pr1)" style="cursor: pointer;"> F </b-badge>
              <b-popover :target="'my_favorite_' + pr1" triggers="hover focus">
                <template slot="title">{{ $t('wdm16.11448') }}</template>
                {{ $t('wdm16.11449') }}
              </b-popover>
            </b-col>
          </b-row>
        </template>
        <template v-if="option_group_data.parameters[pr1].type === 'object_list_options'">
          <template v-if="option_group_data.parameters[pr1].param_order && option_group_data.parameters[pr1].param_order.length > 0">
            <template v-for="(k, k_ind, k_order) in record_data[pr1]">
              <b-card class="mb-1" no-body header-tag="header" footer-tag="footer" :key="'record_data_pr1_card-' + k_ind + k_order + '-' + pr1" style="margin-left: 30px;">
                <b-card-header header-bg-variant="secondary" class="p-1" v-b-toggle="pr1 + k_ind">
                  <b-row style="cursor: pointer;">
                    <b-col cols="10">
                      <strong style="color: red;">{{ k.name.label }}</strong>
                    </b-col>
                    <b-col cols="2">
                      <b-button size="sm" variant="danger" @click="$delete(record_data[pr1], k_ind); param_change_func(); $forceUpdate();">
                        <li class="fa fa-trash"></li>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card-header>
                <b-collapse :id="pr1 + k_ind" v-if="k.detail" :visible="true">
                  <template v-for="(pr2, pr2_ind) in option_group_data.parameters[pr1].param_order">
                    <template v-if="calculate_if(k.detail, pr2, option_group_data.parameters[pr1].parameters[pr2], option_group_data.parameters[pr1], k_ind, anatomy, record_data, k.detail)">
                      <wisdom-param :test_mode="test_mode" :option_data="option_data" :wdm_name="option_data[data_type]['name']" :settings_mode="settings_mode" :external_patient_data="external_patient_data" :anatomy="anatomy" :data="k.detail" :option_param_mother="option_group_data.parameters[pr1]" :param="pr2" :level="'second'" :data_type="data_type" :rule_engine="rule_engine" :query_list_type="query_list_type" :polestar="polestar" :query_list="query_list" :column_list="column_list" :param_change="param_change" :option_loc="calculate_option_loc(param_group, pr2, pr1, pr2, '', 'detail', '', '2', k.name, '', '', '')" :stage_data="stage_data" :key="'pr1_param_order_wisdom-param-component-' + pr2_ind" :p_wdmrData="main_record_data"></wisdom-param>
                      <template v-if="option_group_data.parameters[pr1].parameters[pr2].type === 'object_list_options'">
                        <template v-if="option_group_data.parameters[pr1].parameters[pr2].param_order && option_group_data.parameters[pr1].parameters[pr2].param_order.length > 0">
                          <b-card v-for="(k2, k2_ind, k2_order) in k.detail[pr2]" class="mb-1" no-body header-tag="header" footer-tag="footer" :key="'k_detail_pr2_div-' + k2_order + '-' + pr2">
                            <b-card-header header-bg-variant="secondary" class="p-1" v-b-toggle="pr1 + pr2 + k2_ind">
                              <b-row style="cursor: pointer;">
                                <b-col cols="10">
                                  <strong style="color: red;">{{ k2.name.label }}</strong>
                                </b-col>
                                <b-col cols="2">
                                  <b-button size="sm" variant="danger" @click="$delete(k.detail[pr2], k2_ind); param_change_func(); $forceUpdate();">
                                    <li class="fa fa-trash"></li>
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-card-header>
                            <b-collapse :id="pr1 + pr2 + k2_ind" v-if="k2.detail" :visible="true">
                              <template v-for="(pr3, pr3_ind) in option_group_data.parameters[pr1].parameters[pr2].param_order">
                                <template v-if="calculate_if(k2.detail, pr3, option_group_data.parameters[pr1].parameters[pr2].parameters[pr3], option_group_data.parameters[pr1].parameters[pr2], k2_ind, anatomy, record_data, k.detail, k2.detail)">
                                  <wisdom-param :test_mode="test_mode" :option_data="option_data" :wdm_name="option_data[data_type]['name']" :settings_mode="settings_mode" :external_patient_data="external_patient_data" :anatomy="anatomy" :data="k2.detail" :option_param_mother="option_group_data.parameters[pr1].parameters[pr2]" :param="pr3" :level="'third'" :data_type="data_type" :rule_engine="rule_engine" :query_list_type="query_list_type" :polestar="polestar" :query_list="query_list" :column_list="column_list" :param_change="param_change" :option_loc="calculate_option_loc(param_group, pr3, pr1, pr2, pr3, 'detail', 'detail', '3', k.name, k2.name, '', '')" :stage_data="stage_data" :key="'pr1_pr2_param_order_wisdom-param-component-' + pr3_ind" :p_wdmrData="main_record_data"></wisdom-param>
                                </template>
                              </template>
                            </b-collapse>
                          </b-card>
                        </template>
                        <template v-else>
                          <template v-for="(k2, k2_ind, k2_order) in k.detail[pr2]">
                            <b-row style="padding: 3px;">
                              <b-col cols="4">
                              </b-col>
                              <b-col cols="8">
                                <b-button size="lg" variant="danger" @click="$delete(k.detail[pr2], k2_ind); param_change_func(); $forceUpdate();">
                                  <li class="fa fa-trash"></li>
                                </b-button>
                                {{ k2.name.label }}
                              </b-col>
                            </b-row>
                          </template>
                        </template>
                      </template>
                      <template v-if="option_group_data.parameters[pr1].parameters[pr2].type === 'list_parameter' && k.detail[pr2] && k.detail[pr2].list">
                        <b-card v-for="(ls, ls_ind) in k.detail[pr2].list" class="mb-1" no-body header-tag="header" footer-tag="footer" :key="'k_detail_pr2_list_card-' + ls_ind + '-' + pr2">
                          <b-card-header header-bg-variant="secondary" class="p-1" v-b-toggle="pr1 + pr2 + ls_ind">
                            <b-row style="cursor: pointer;">
                              <b-col cols="10">
                                <strong style="color: red;">{{ ls_ind + 1 }} - {{ option_group_data.parameters[pr1].parameters[pr2].name.label }}</strong>
                              </b-col>
                              <b-col cols="2">
                                <template v-if="option_group_data.parameters[pr1].parameters[pr2].disabled === undefined || (option_group_data.parameters[pr1].parameters[pr2].disabled !== undefined && option_group_data.parameters[pr1].parameters[pr2].disabled !== 1)">
                                  <b-button size="sm" variant="danger" @click="k.detail[pr2].list.splice(ls_ind, 1); param_change_func(); $forceUpdate()">
                                    <li class="fa fa-trash"></li>
                                  </b-button>
                                  <b-button size="sm" variant="primary" @click="k.detail[pr2].list.push(JSON.parse(JSON.stringify(k.detail[pr2].list[ls_ind]))); param_change_func(); $forceUpdate();">
                                    <li class="fa fa-copy"></li>
                                  </b-button>
                                </template>
                              </b-col>
                            </b-row>
                          </b-card-header>
                          <b-collapse :id="pr1 + pr2 + ls_ind" :visible="true">
                            <template v-for="(pr3, pr3_ind) in option_group_data.parameters[pr1].parameters[pr2].param_order">
                              <template v-if="calculate_if(ls, pr3, option_group_data.parameters[pr1].parameters[pr2].parameters[pr3], option_group_data.parameters[pr1].parameters[pr2], '', anatomy, record_data, k.detail, ls)">
                                <wisdom-param :test_mode="test_mode" :option_data="option_data" :wdm_name="option_data[data_type]['name']" :settings_mode="settings_mode" :external_patient_data="external_patient_data" :anatomy="anatomy" :data="ls" :option_param_mother="option_group_data.parameters[pr1].parameters[pr2]" :param="pr3" :level="'third'" :data_type="data_type" :rule_engine="rule_engine" :query_list_type="query_list_type" :polestar="polestar" :query_list="query_list" :column_list="column_list" :param_change="param_change" :option_loc="calculate_option_loc(param_group, pr3, pr1, pr2, pr3, 'detail', 'list', '3', k.name, '', '', 'this')" :stage_data="stage_data" :key="'pr1_pr2_param_order_wisdom-param-component-2-' + pr3_ind + '-' + pr3" :p_wdmrData="main_record_data"></wisdom-param>
                                <template v-if="option_group_data.parameters[pr1].parameters[pr2].parameters[pr3].type === 'object_list_options'">
                                  <div v-for="(k3, k3_ind, k3_order) in ls[pr3]" style="margin-left: 50px;" :key="'ls_pr3_div-' + k3_order + '-' + pr3">
                                    <b-row style="padding: 3px;">
                                      <b-col cols="4">
                                      </b-col>
                                      <b-col cols="8">
                                        <b-button size="lg" variant="danger" @click="$delete(ls[pr3], k3_ind); param_change_func(); $forceUpdate();">
                                          <li class="fa fa-trash"></li>
                                        </b-button>
                                        {{ k3.name.label }}
                                      </b-col>
                                    </b-row>
                                  </div>
                                </template>
                              </template>
                            </template>
                          </b-collapse>
                        </b-card>
                      </template>
                    </template>
                  </template>
                </b-collapse>
              </b-card>
            </template>
          </template>
          <template v-else>
            <template v-for="(k, k_ind, k_order) in record_data[pr1]">
              <b-row style="padding: 3px;">
                <b-col cols="4">
                </b-col>
                <b-col cols="8">
                  <b-button size="lg" variant="danger" @click="$delete(record_data[pr1], k_ind); param_change_func(); $forceUpdate();">
                    <li class="fa fa-trash"></li>
                  </b-button>
                  {{ k.name.label }}
                </b-col>
              </b-row>
            </template>
          </template>
        </template>
        <template v-if="option_group_data.parameters[pr1].type === 'list_parameter' && record_data[pr1] && record_data[pr1].list">
          <b-card v-for="(ls, ls_ind) in record_data[pr1].list" class="mb-1" no-body header-tag="header" footer-tag="footer" :key="'record_data_pr1_list_card-' + pr1 + '_' + ls_ind" style="margin-left: 30px;">
            <b-card-header header-bg-variant="success" class="p-1" v-b-toggle="pr1 + ls_ind">
              <b-row style="cursor: pointer;">
                <b-col cols="10">
                  <strong>{{ ls_ind + 1 }} - {{ option_group_data.parameters[pr1].name.label.toLocaleUpperCase('TR') }}</strong>
                </b-col>
                <b-col cols="2">
                  <template v-if="option_group_data.parameters[pr1].disabled === undefined || (option_group_data.parameters[pr1].disabled !== undefined && option_group_data.parameters[pr1].disabled !== 1)">
                    <b-button size="sm" variant="danger" @click="record_data[pr1].list.splice(ls_ind, 1); param_change_func(); $forceUpdate()">
                      <li class="fa fa-trash"></li>
                    </b-button>
                    <b-button size="sm" variant="primary" @click="record_data[pr1].list.push(JSON.parse(JSON.stringify(record_data[pr1].list[ls_ind]))); param_change_func(); $forceUpdate();">
                      <li class="fa fa-copy"></li>
                    </b-button>
                  </template>
                </b-col>
              </b-row>
            </b-card-header>
            <b-collapse :id="pr1 + ls_ind" :visible="true">
              <template v-for="(pr2, pr2_ind) in option_group_data.parameters[pr1].param_order">
                <template v-if="calculate_if(ls, pr2, option_group_data.parameters[pr1].parameters[pr2], option_group_data.parameters[pr1], '', anatomy, record_data, ls)">
                  <wisdom-param :test_mode="test_mode" :option_data="option_data" :wdm_name="option_data[data_type]['name']" :settings_mode="settings_mode" :external_patient_data="external_patient_data" :anatomy="anatomy" :data="ls" :option_param_mother="option_group_data.parameters[pr1]" :param="pr2" :level="'second'" :data_type="data_type" :rule_engine="rule_engine" :query_list_type="query_list_type" :polestar="polestar" :query_list="query_list" :column_list="column_list" :param_change="param_change" :option_loc="calculate_option_loc(param_group, pr2, pr1, pr2, '', 'list', '', '2', '', '', 'this', '')" :stage_data="stage_data" :key="'param_order-pr2-wisdom-param-component2-' + pr2_ind" :p_wdmrData="main_record_data"></wisdom-param>
                  <template v-if="option_group_data.parameters[pr1].parameters[pr2].type === 'object_list_options'">
                    <template v-if="option_group_data.parameters[pr1].parameters[pr2].param_order && option_group_data.parameters[pr1].parameters[pr2].param_order.length > 0">
                      <b-card v-for="(k, k_ind, k_order) in ls[pr2]" no-body class="mb-1" header-tag="header" footer-tag="footer" :key="'ls_pr2_card-' + k_order + '-' + pr2" style="margin-left: 30px;">
                        <b-card-header header-bg-variant="secondary" class="p-1" v-b-toggle="pr1 + ls_ind + k_ind">
                          <b-row style="cursor: pointer;">
                            <b-col cols="10">
                              <strong>{{ k.name.label }}</strong>
                            </b-col>
                            <b-col cols="2">
                              <b-button size="sm" variant="danger" @click="$delete(ls[pr2], k_ind); param_change_func(); $forceUpdate();">
                                <li class="fa fa-trash"></li>
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-card-header>
                        <b-collapse :id="pr1 + ls_ind + k_ind" v-if="k.detail" :visible="true">
                          <template v-for="(pr3, pr3_ind) in option_group_data.parameters[pr1].parameters[pr2].param_order">
                            <template v-if="calculate_if(k.detail, pr3, option_group_data.parameters[pr1].parameters[pr2].parameters[pr3], option_group_data.parameters[pr1].parameters[pr2], k_ind, anatomy, record_data, ls, k.detail)">
                              <wisdom-param :test_mode="test_mode" :option_data="option_data" :wdm_name="option_data[data_type]['name']" :settings_mode="settings_mode" :external_patient_data="external_patient_data" :anatomy="anatomy" :data="k.detail" :option_param_mother="option_group_data.parameters[pr1].parameters[pr2]" :param="pr3" :level="'third'" :data_type="data_type" :rule_engine="rule_engine" :query_list_type="query_list_type" :polestar="polestar" :query_list="query_list" :column_list="column_list" :param_change="param_change" :option_loc="calculate_option_loc(param_group, pr3, pr1, pr2, pr3, 'list', 'detail', '3', '', k.name, 'this', '')" :stage_data="stage_data" :key="'param_order-pr3-wisdom-param-component3-' + pr3_ind" :p_wdmrData="main_record_data"></wisdom-param>
                            </template>
                          </template>
                        </b-collapse>
                      </b-card>
                    </template>
                    <template v-else>
                      <template v-for="(k, k_ind, k_order) in ls[pr2]">
                        <b-row style="padding: 3px;">
                          <b-col cols="4">
                          </b-col>
                          <b-col cols="8">
                            <b-button size="lg" variant="danger" @click="$delete(ls[pr2], k_ind); param_change_func(); $forceUpdate();">
                              <li class="fa fa-trash"></li>
                            </b-button>
                            {{ k.name.label }}
                          </b-col>
                        </b-row>
                      </template>
                    </template>
                  </template>
                  <template v-if="option_group_data.parameters[pr1].parameters[pr2].type === 'list_parameter' && ls[pr2] && ls[pr2].list">
                    <b-card v-for="(ls2, ls2_ind) in ls[pr2].list" no-body class="mb-1" header-tag="header" footer-tag="footer" :key="'ls_pr2_list_card-' + ls2_ind + '-' + pr2">
                      <b-card-header header-bg-variant="secondary" class="p-1" v-b-toggle="pr1 + pr2 + ls_ind + ls2_ind">
                        <b-row style="cursor: pointer;">
                          <b-col cols="10">
                            <strong style="color: red;">{{ ls2_ind + 1 }} - {{ option_group_data.parameters[pr1].parameters[pr2].name.label }}</strong>
                          </b-col>
                          <b-col cols="2">
                            <template v-if="option_group_data.parameters[pr1].parameters[pr2].disabled === undefined || (option_group_data.parameters[pr1].parameters[pr2].disabled !== undefined && option_group_data.parameters[pr1].parameters[pr2].disabled !== 1)">
                              <b-button size="sm" variant="danger" @click="ls[pr2].list.splice(ls2_ind, 1); param_change_func(); $forceUpdate()">
                                <li class="fa fa-trash"></li>
                              </b-button>
                              <b-button size="sm" variant="primary" @click="ls[pr2].list.push(JSON.parse(JSON.stringify(ls[pr2].list[ls2_ind]))); param_change_func(); $forceUpdate();">
                                <li class="fa fa-copy"></li>
                              </b-button>
                            </template>
                          </b-col>
                        </b-row>
                      </b-card-header>
                      <b-collapse :id="pr1 + pr2 + ls_ind + ls2_ind" :visible="true">
                        <template v-for="(pr3, pr3_ind) in option_group_data.parameters[pr1].parameters[pr2].param_order">
                          <template v-if="calculate_if(ls2, pr3, option_group_data.parameters[pr1].parameters[pr2].parameters[pr3], option_group_data.parameters[pr1].parameters[pr2], '', anatomy, record_data, ls, ls2)">
                            <wisdom-param :test_mode="test_mode" :option_data="option_data" :wdm_name="option_data[data_type]['name']" :settings_mode="settings_mode" :external_patient_data="external_patient_data" :anatomy="anatomy" :data="ls2" :option_param_mother="option_group_data.parameters[pr1].parameters[pr2]" :param="pr3" :level="'third'" :data_type="data_type" :rule_engine="rule_engine" :query_list_type="query_list_type" :polestar="polestar" :query_list="query_list" :column_list="column_list" :param_change="param_change" :option_loc="calculate_option_loc(param_group, pr3, pr1, pr2, pr3, 'list', 'list', '3', '', '', 'this', 'this')" :stage_data="stage_data" :key="'pr1_pr2_param_order_wisdom_param_component-' + pr3_ind" :p_wdmrData="main_record_data"></wisdom-param>
                            <template v-if="option_group_data.parameters[pr1].parameters[pr2].parameters[pr3].type === 'object_list_options'">
                              <div v-for="(k3, k3_ind, k3_order) in ls2[pr3]" style="margin-left: 50px;" :key="'ls2_pr3_div-' + k3_order + '-' + pr3">
                                <b-row>
                                  <b-col cols="10" style="background-color: #abf1d1; padding: 6px; cursor: pointer;">
                                    <strong style="color: red;">{{ k3.name.label }}</strong>
                                  </b-col>
                                  <b-col cols="2">
                                    <b-button size="sm" variant="danger" @click="$delete(ls2[pr3], k3_ind); param_change_func(); $forceUpdate();"><i class="fa fa-trash"></i></b-button>
                                  </b-col>
                                </b-row>
                              </div>
                            </template>
                          </template>
                        </template>
                      </b-collapse>
                    </b-card>
                  </template>
                </template>
              </template>
            </b-collapse>
          </b-card>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  default as WisdomParam
} from "./WisdomParam";
import { ClsUserSettings } from '@/services/public/user';

export default {
  name: "WisdomParamCycle",
  props: {
    settings_mode: {
      type: Boolean,
      required: false
    },
    parameter_list: {
      type: Array,
      required: false
    },
    use_parameter_list: {
      type: Boolean,
      required: false
    },
    favorite_show_mode: {
      type: Boolean
    },
    user_favorite_parameter_list: {
      type: Object,
      required: false
    },
    option_group_data: {
      type: Object,
      required: true
    },
    main_record_data: {
      type: Object,
      required: false
    },
    record_data: {
      type: Object,
      required: false
    },
    option_data: {
      type: Object,
      required: true,
      default: {}
    },
    anatomy: {
      type: Object,
      required: false
    },
    anatomy_use: {
      type: String,
      required: true
    },
    selected_anatomy_parent: {
      type: Object,
      required: false
    },
    param_group: {
      type: String,
      required: true
    },
    data_type: {
      type: String,
      required: true
    },
    query_list_type: {
      type: String,
      required: false
    },
    rule_engine: {
      type: Boolean,
      required: false,
      default: false
    },
    polestar: {
      type: Boolean,
      required: false,
    },
    query_list: {
      type: Array,
      required: false,
      default: []
    },
    column_list: {
      type: Array,
      required: false
    },
    param_change: {
      type: Object,
      required: true
    },
    stage_data: {
      type: Object,
      required: false
    },
    external_patient_data: {
      type: Object,
      required: false
    },
    test_mode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created () {
    if (this.external_patient_data) {
      this.patient = JSON.parse(JSON.stringify(this.external_patient_data))
    } else {
      this.patient = JSON.parse(JSON.stringify(this.patient_tmp))
    }
  },
  mounted () {},
  computed: mapGetters({
    lang: "lang",
    patient_tmp: "patient_selected"
  }),
  data () {
    return {
      patient: {}
    };
  },
  methods: {
    param_change_favorite_show_mode: function () {
      if (this.param_change.favorite_show_mode) {
        this.param_change.favorite_show_mode = 0;
      } else {
        this.param_change.favorite_show_mode = 1;
      }
    },
    param_change_func: function () {
      if (this.param_change.id) {
        this.param_change.id = 0;
      } else {
        this.param_change.id = 1;
      }
    },
    f_paramFavoriteControl: function (param_value) {
      try {
        if (this.user_favorite_parameter_list['param_group_list'][this.param_group]['parameters'][param_value]) {
          return true;
        } else {
          return false;
        }
      } catch {

        return false;
      }
    },
    f_addDeleteFavoriteParam: function (param_value) {
      let user_data = JSON.parse(localStorage.getItem('user'));
      let status = 'add';
      try {
        // Param value varsa siliyor. catch olmuyor.
        // Yoksa da ilgili lokasyonda problem yoksa catch olmuyor.
        // status durumuna göre devam ediyoruz
        if (user_data.settings.wdmr_record.settings.favorite_show_mode.settings[this.data_type]['param_group_list'][this.param_group]['parameters'][param_value]) {
          this.$delete(user_data.settings.wdmr_record.settings.favorite_show_mode.settings[this.data_type]['param_group_list'][this.param_group]['parameters'], param_value);
          status = 'delete';
        }
      } catch {}
      if (status === 'add') {
        ClsUserSettings.free_structure(user_data);
        if (!user_data.settings.wdmr_record.settings.favorite_show_mode.settings[this.data_type]) {
          user_data.settings.wdmr_record.settings.favorite_show_mode.settings[this.data_type] = {
            'name': this.option_data[this.data_type]['name'],
            'param_group_list': {}
          }
        }
        if (!user_data.settings.wdmr_record.settings.favorite_show_mode.settings[this.data_type]['param_group_list'][this.param_group]) {
          user_data.settings.wdmr_record.settings.favorite_show_mode.settings[this.data_type]['param_group_list'][this.param_group] = {
            'name': this.option_data[this.data_type]['param_group']['parameters'][this.param_group],
            'parameters': {}
          }
        }
        if (!user_data.settings.wdmr_record.settings.favorite_show_mode.settings[this.data_type]['param_group_list'][this.param_group]['parameters'][param_value]) {
          user_data.settings.wdmr_record.settings.favorite_show_mode.settings[this.data_type]['param_group_list'][this.param_group]['parameters'][param_value] = {
            'name': this.option_data[this.data_type][this.param_group]['parameters'][param_value]['name']
          }
        }
      }
      localStorage.user = JSON.stringify(user_data);
      this.param_change_favorite_show_mode();
    },
    calculate_option_loc: function (
      pg,
      param,
      pr1,
      pr2,
      pr3,
      pr2_in,
      pr3_in,
      level,
      detail_pr1,
      detail_pr2,
      list_use_1,
      list_use_2
    ) {
      let option_loc_data = {
        pg: "",
        pr1: "",
        pr2: "",
        pr3: "",
        pr2_in: pr2_in,
        pr3_in: pr3_in,
        level: level,
        param: "",
        detail_pr1: detail_pr1,
        detail_pr2: detail_pr2,
        list_use_1: list_use_1,
        list_use_2: list_use_2,
        anatomy_use: "any",
        anatomy: this.anatomy,
        anatomy_parent: this.selected_anatomy_parent
      };
      option_loc_data.pg = this.option_data[
        this.data_type
      ].param_group.parameters[pg];
      let param1_data = {};
      let param2_data = {};
      let param3_data = {};
      if (level === "1") {
        param1_data = this.option_data[this.data_type][pg].parameters[pr1];
        option_loc_data.pr1 = param1_data.name;
        option_loc_data.param = option_loc_data.pr1;
      } else if (level === "2") {
        param1_data = this.option_data[this.data_type][pg].parameters[pr1];
        param2_data = this.option_data[this.data_type][pg].parameters[pr1]
          .parameters[pr2];
        option_loc_data.pr1 = param1_data.name;
        option_loc_data.pr2 = param2_data.name;
        option_loc_data.param = option_loc_data.pr2;
      } else if (level === "3") {
        param1_data = this.option_data[this.data_type][pg].parameters[pr1];
        param2_data = this.option_data[this.data_type][pg].parameters[pr1]
          .parameters[pr2];
        param3_data = this.option_data[this.data_type][pg].parameters[pr1]
          .parameters[pr2].parameters[pr3];
        option_loc_data.pr1 = param1_data.name;
        option_loc_data.pr2 = param2_data.name;
        option_loc_data.pr3 = param3_data.name;
        option_loc_data.param = option_loc_data.pr3;
      }
      if (pg !== "anatomy") {
        this.$delete(option_loc_data, "anatomy_use");
        this.$delete(option_loc_data, "anatomy");
        this.$delete(option_loc_data, "anatomy_parent");
      }
      return option_loc_data;
    },
    calculate_if_operation_analyze: function () {

    },
    calculate_if: function (recording_data_region, param_ind, recording_param_data, location_parameters, mother = "", anatomy_region, level1_record_data = {}, level2_record_data = {}, level3_record_data = {}) {
      /*
      console.log('recording_data_region', recording_data_region);
      console.log('param_ind', param_ind);
      console.log('recording_param_data', recording_param_data);
      console.log('location_parameters', location_parameters);
      console.log('mother', mother);
      console.log('anatomy_region', anatomy_region);
      */
      /* rule enginede ve polestarda kolon seçimi için kullanıldığından tüm parametreleri gösteriyoruz.
      if (this.rule_engine || this.polestar) {
        return true;
      }
      */
      // özel bir parametrenin sadece gösterilmesini istyorsak bu parametre listesinden yönetiyoruz.
      if (!recording_data_region) {
        return false;
      }
      if (this.favorite_show_mode) {
        try {
          if (!this.user_favorite_parameter_list['param_group_list'][this.param_group]['parameters'][param_ind]) {
            return false;
          }
        } catch {
          return false;
        }
      }
      // favorite_show_mode false ise diğer analizlere ayrıca devam ediyoruz.
      if (this.parameter_list && this.parameter_list.length > 0 && this.use_parameter_list && this.parameter_list.indexOf(param_ind) === -1) {
        return false;
      }
      let ret = false;
      if (recording_param_data.show === "no") {
        return ret;
      }
      if (recording_param_data.if) {
        // friend parameters control
        if (recording_param_data.if.friend && Object.keys(recording_param_data.if.friend).length > 0) {
          let friend_data = recording_param_data.if.friend;
          for (let i in friend_data) {
            // aynı veri kayıt grubunda ve level'inde ise
            if (friend_data[i].parent_param === "yes") {
              // parametre seçili değilse ya da parametre seçili olup val değeri doldurulmadıysa
              if (!recording_data_region[i] || (recording_data_region[i] && !recording_data_region[i]['val'])) {
                if (recording_data_region[param_ind]) {
                  this.$delete(recording_data_region, param_ind);
                }
                return false;
              } else { // parametrede val değeri dolu
                for (let k in friend_data[i].operation) {
                  if (k === "or_options") {
                    if (typeof recording_data_region[i]['val'] === 'object') {
                      if (friend_data[i].operation[k].indexOf(recording_data_region[i]['val'].value) === -1) {
                        if (recording_data_region[param_ind]) {
                          this.$delete(recording_data_region, param_ind);
                        }
                        return false;
                      } else {
                        ret = true;
                      }
                    }
                  }
                }
              }
            } else { // başka veri kayıt grubunda ise.
              if (this.param_group === friend_data[i].param_group) {
                // friend parametresi 3. level bir parametre ise
                if (friend_data[i].param3) {
                  if (!level3_record_data[friend_data[i].param3] || (level3_record_data[friend_data[i].param3] && !level3_record_data[friend_data[i].param3]['val'])) {
                    if (recording_data_region[param_ind]) {
                      this.$delete(recording_data_region, param_ind);
                    }
                    return false;
                  } else {
                    for (let k in friend_data[i].operation) {
                      if (k === "or_options") {
                        if (typeof level3_record_data[friend_data[i].param3]['val'] === 'object') {
                          if (friend_data[i].operation[k].indexOf(level3_record_data[friend_data[i].param3]['val'].value) === -1) {
                            if (recording_data_region[param_ind]) {
                              this.$delete(recording_data_region, param_ind);
                            }
                            return false;
                          } else {
                            ret = true;
                          }
                        }
                      }
                    }
                  }
                } else if (friend_data[i].param2) {
                  // friend parametresi 2. level bir parametre ise
                  if (!level2_record_data[friend_data[i].param2] || (level2_record_data[friend_data[i].param2] && !level2_record_data[friend_data[i].param2]['val'])) {
                    if (recording_data_region[param_ind]) {
                      this.$delete(recording_data_region, param_ind);
                    }
                    return false;
                  } else {
                    for (let k in friend_data[i].operation) {
                      if (k === "or_options") {
                        if (typeof level2_record_data[friend_data[i].param2]['val'] === 'object') {
                          if (friend_data[i].operation[k].indexOf(level2_record_data[friend_data[i].param2]['val']) === -1) {
                            if (recording_data_region[param_ind]) {
                              this.$delete(recording_data_region, param_ind);
                            }
                            return false;
                          } else {
                            ret = true;
                          }
                        }
                      }
                    }
                  }
                } else if (friend_data[i].param) {
                  if (!level1_record_data[friend_data[i].param] || (level1_record_data[friend_data[i].param] && !level1_record_data[friend_data[i].param]['val'])) {
                    if (recording_data_region[param_ind]) {
                      this.$delete(recording_data_region, param_ind);
                    }
                    return false;
                  } else {
                    for (let k in friend_data[i].operation) {
                      if (k === "or_options") {
                        if (typeof level1_record_data[friend_data[i].param]['val'] === 'object') {
                          if (friend_data[i].operation[k].indexOf(level1_record_data[friend_data[i].param]['val'].value) === -1) {
                            if (recording_data_region[param_ind]) {
                              this.$delete(recording_data_region, param_ind);
                            }
                            return false;
                          } else {
                            ret = true;
                          }
                        }
                      }
                    }
                  }
                }
              } else {
                // farklı grupta ise friend oluşturma sırasında anatomi datasından bir sorgu oluşturulamadığından yok.
                // yani general gibi bir diğer ek anatomi alt bölgesi içermeyen bir gruptan birinci düzeydeki değer sadece sorgulanabiliyor.
                if (!this.main_record_data[friend_data[i].param_group]) {
                  if (recording_data_region[param_ind]) {
                    this.$delete(recording_data_region, param_ind);
                  }
                  return false;
                } else {
                  if (!this.main_record_data[friend_data[i].param_group][friend_data[i].param] || (this.main_record_data[friend_data[i].param_group][friend_data[i].param]) && !this.main_record_data[friend_data[i].param_group][friend_data[i].param]['val']) {
                    if (recording_data_region[param_ind]) {
                      this.$delete(recording_data_region, param_ind);
                    }
                    return false;
                  } else {
                    for (let k in friend_data[i].operation) {
                      if (k === "or_options") {
                        if (typeof this.main_record_data[friend_data[i].param_group][friend_data[i].param]['val'] === 'object') {
                          if (friend_data[i].operation[k].indexOf(this.main_record_data[friend_data[i].param_group][friend_data[i].param]['val'].value) === -1) {
                            if (recording_data_region[param_ind]) {
                              this.$delete(recording_data_region, param_ind);
                            }
                            return false;
                          } else {
                            ret = true;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } else {
          ret = true;
        }
        // mother control
        if (recording_param_data.if.mother && Object.keys(recording_param_data.if.mother).length > 0 && mother) {
          if (recording_param_data.if.mother[mother] === undefined) {
            if (recording_data_region[param_ind]) {
              this.$delete(recording_data_region, param_ind);
            }
            return false;
          } else {
            ret = true;
          }
        } else {
          ret = true;
        }
        // patient control
        if (recording_param_data.if.patient && recording_param_data.if.patient.length > 0) {
          let det = false;
          for (let i in recording_param_data.if.patient) {
            if (recording_param_data.if.patient[i].type === 'sex') {
              if (recording_param_data.if.patient[i].value === 'm' && this.patient.sex === '0') {
                det = true;
                break;
              } else if (recording_param_data.if.patient[i].value === 'f' && this.patient.sex !== '0') {
                det = true;
                break;
              }
            }
          }
          if (det) {
            ret = true;
          } else {
            if (recording_data_region[param_ind]) {
              this.$delete(recording_data_region, param_ind);
            }
            return false;
          }
        } else {
          ret = true;
        }
        // mother anatomy control
        if (recording_param_data.if.mother_anatomy && recording_param_data.if.mother_anatomy.length > 0) {
          let det = false;
          for (let i in recording_param_data.if.mother_anatomy) {
            if (recording_param_data.if.mother_anatomy[i].value === anatomy_region.value) {
              det = true;
              break;
            }
          }
          if (det) {
            ret = true;
          } else {
            if (recording_data_region[param_ind]) {
              this.$delete(recording_data_region, param_ind);
            }
            return false;
          }
        } else {
          ret = true;
        }
        // ana_parent control
        if (recording_param_data.if.ana_parent && recording_param_data.if.ana_parent.length > 0) {
          let det = false;
          for (let i in recording_param_data.if.ana_parent) {
            if (
              anatomy_region.parent.indexOf(
                recording_param_data.if.ana_parent[i].value
              ) !== -1
            ) {
              det = true;
              break;
            }
          }
          if (det) {
            ret = true;
          } else {
            if (recording_data_region[param_ind]) {
              this.$delete(recording_data_region, param_ind);
            }
            return false;
          }
        } else {
          ret = true;
        }
        //
      } else {
        ret = true;
      }
      if (!ret) {
        if (recording_data_region[param_ind]) {
          this.$delete(recording_data_region, param_ind);
        }
      }
      return ret;
    }
  },
  watch: {
    "param_change.id": function (id) {
      // console.log('changed in wisdom param cycle, id => ', this.param_change.id);
      this.$forceUpdate();
    }
  },
  components: {
    WisdomParam
  }
};

</script>

<style>


</style>

