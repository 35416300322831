var frequency = [
{'label': '1x1', 'value': '1x1'},
{'label': '2x1', 'value': '2x1'},
{'label': '3x1', 'value': '3x1'},
{'label': '4x1', 'value': '4x1'},
{'label': '5x1', 'value': '5x1'},
{'label': '6x1', 'value': '6x1'},
{'label': '7x1', 'value': '7x1'},
{'label': '8x1', 'value': '8x1'},
{'label': '2x2', 'value': '2x2'},
{'label': '2x3', 'value': '2x3'}];

export { frequency as options };
