var countries = [ { 'label': 'Turkey', 'translation': { 'tr': 'Türkiye' }, 'value': 'TR' },
{ 'label': 'Andorra', 'translation': { 'tr': 'Andorra' }, 'value': 'AD' },
{ 'label': 'United arab emirates', 'translation': { 'tr': 'Birleşik Arap Emirlikleri' }, 'value': 'AE' },
{ 'label': 'Afghanistan', 'translation': { 'tr': 'Afganistan' }, 'value': 'AF' },
{ 'label': 'Antigua and barbuda', 'translation': { 'tr': 'Antigua ve Barbuda' }, 'value': 'AG' },
{ 'label': 'Anguilla', 'translation': { 'tr': 'Anguilla' }, 'value': 'AI' },
{ 'label': 'Albania', 'translation': { 'tr': 'Arnavutluk' }, 'value': 'AL' },
{ 'label': 'Armenia', 'translation': { 'tr': 'Ermenistan' }, 'value': 'AM' },
{ 'label': 'Angola', 'translation': { 'tr': 'Angola' }, 'value': 'AO' },
{ 'label': 'Antarktika', 'translation': { 'tr': 'Antarktika' }, 'value': 'AQ' },
{ 'label': 'Argentina', 'translation': { 'tr': 'Arjantin' }, 'value': 'AR' },
{ 'label': 'American samoa', 'translation': { 'tr': 'Amerikan Samoası' }, 'value': 'AS' },
{ 'label': 'Austria', 'translation': { 'tr': 'Avusturya' }, 'value': 'AT' },
{ 'label': 'Australia', 'translation': { 'tr': 'Avustralya' }, 'value': 'AU' },
{ 'label': 'Aruba', 'translation': { 'tr': 'Aruba' }, 'value': 'AW' },
{ 'label': 'Azerbaijan', 'translation': { 'tr': 'Azerbaycan' }, 'value': 'AZ' },
{ 'label': 'Bosnia and herzegovina', 'translation': { 'tr': 'Bosna-Hersek' }, 'value': 'BA' },
{ 'label': 'Barbados', 'translation': { 'tr': 'Barbados' }, 'value': 'BB' },
{ 'label': 'Bangladesh', 'translation': { 'tr': 'Bangladeş' }, 'value': 'BD' },
{ 'label': 'Belgium', 'translation': { 'tr': 'Belçika' }, 'value': 'BE' },
{ 'label': 'Burkina faso', 'translation': { 'tr': 'Burkina Faso' }, 'value': 'BF' },
{ 'label': 'Bulgaria', 'translation': { 'tr': 'Bulgaristan' }, 'value': 'BG' },
{ 'label': 'Bahrain', 'translation': { 'tr': 'Bahreyn' }, 'value': 'BH' },
{ 'label': 'Burundi', 'translation': { 'tr': 'Burundi' }, 'value': 'BI' },
{ 'label': 'Benin', 'translation': { 'tr': 'Benin' }, 'value': 'BJ' },
{ 'label': 'St barts', 'translation': { 'tr': 'Saint Barthelemy' }, 'value': 'BL' },
{ 'label': 'Bermuda', 'translation': { 'tr': 'Bermuda' }, 'value': 'BM' },
{ 'label': 'Brunei', 'translation': { 'tr': 'Brunei' }, 'value': 'BN' },
{ 'label': 'Bolivia', 'translation': { 'tr': 'Bolivya' }, 'value': 'BO' },
{ 'label': 'Brazil', 'translation': { 'tr': 'Brezilya' }, 'value': 'BR' },
{ 'label': 'Bahamas', 'translation': { 'tr': 'Bahamalar' }, 'value': 'BS' },
{ 'label': 'Bhutan', 'translation': { 'tr': 'Bhutan' }, 'value': 'BT' },
{ 'label': 'Bouvet island', 'translation': { 'tr': 'Bouvet Adası' }, 'value': 'BV' },
{ 'label': 'Botswana', 'translation': { 'tr': 'Botsvana' }, 'value': 'BW' },
{ 'label': 'Belarus', 'translation': { 'tr': 'Beyaz Rusya' }, 'value': 'BY' },
{ 'label': 'Belize', 'translation': { 'tr': 'Belize' }, 'value': 'BZ' },
{ 'label': 'Canada', 'translation': { 'tr': 'Kanada' }, 'value': 'CA' },
{ 'label': 'Cocos island', 'translation': { 'tr': 'Cocos (Keyling) Adaları' }, 'value': 'CC' },
{ 'label': 'Democratic republic of congo', 'translation': { 'tr': 'Demokratik Kongo Cumhuriyeti' }, 'value': 'CD' },
{ 'label': 'Central african republic', 'translation': { 'tr': 'Orta Afrika Cumhuriyeti' }, 'value': 'CF' },
{ 'label': 'Republic of the congo', 'translation': { 'tr': 'Kongo Cumhuriyeti' }, 'value': 'CG' },
{ 'label': 'Switzerland', 'translation': { 'tr': 'İsviçre' }, 'value': 'CH' },
{ 'label': 'Ivory coast', 'translation': { 'tr': 'Fildişi Sahili' }, 'value': 'CI' },
{ 'label': 'Cook islands', 'translation': { 'tr': 'Cook Adaları' }, 'value': 'CK' },
{ 'label': 'Chile', 'translation': { 'tr': 'Şili' }, 'value': 'CL' },
{ 'label': 'Cameroon', 'translation': { 'tr': 'Kamerun' }, 'value': 'CM' },
{ 'label': 'China', 'translation': { 'tr': 'Çin' }, 'value': 'CN' },
{ 'label': 'Colombia', 'translation': { 'tr': 'Kolombiya' }, 'value': 'CO' },
{ 'label': 'Costa rica', 'translation': { 'tr': 'Kosta Rika' }, 'value': 'CR' },
{ 'label': 'Cuba', 'translation': { 'tr': 'Küba' }, 'value': 'CU' },
{ 'label': 'Cape verde', 'translation': { 'tr': 'Yeşil Burun Adaları' }, 'value': 'CV' },
{ 'label': 'Curacao', 'translation': { 'tr': 'Curaçao' }, 'value': 'CW' },
{ 'label': 'Cyprus', 'translation': { 'tr': 'Kıbrıs' }, 'value': 'CY' },
{ 'label': 'Czech republic', 'translation': { 'tr': 'Çek Cumhuriyeti' }, 'value': 'CZ' },
{ 'label': 'Germany', 'translation': { 'tr': 'Almanya' }, 'value': 'DE' },
{ 'label': 'Djibouti', 'translation': { 'tr': 'Cibuti' }, 'value': 'DJ' },
{ 'label': 'Denmark', 'translation': { 'tr': 'Danimarka' }, 'value': 'DK' },
{ 'label': 'Dominica', 'translation': { 'tr': 'Dominika' }, 'value': 'DM' },
{ 'label': 'Dominican republic', 'translation': { 'tr': 'Dominik Cumhuriyeti' }, 'value': 'DO' },
{ 'label': 'Algeria', 'translation': { 'tr': 'Cezayir' }, 'value': 'DZ' },
{ 'label': 'Ecuador', 'translation': { 'tr': 'Ekvador' }, 'value': 'EC' },
{ 'label': 'Estonia', 'translation': { 'tr': 'Estonya' }, 'value': 'EE' },
{ 'label': 'Egypt', 'translation': { 'tr': 'Mısır' }, 'value': 'EG' },
{ 'label': 'Sahrawi arab democratic republic', 'translation': { 'tr': 'Batı Sahra' }, 'value': 'EH' },
{ 'label': 'Eritrea', 'translation': { 'tr': 'Eritre' }, 'value': 'ER' },
{ 'label': 'Spain', 'translation': { 'tr': 'İspanya' }, 'value': 'ES' },
{ 'label': 'Ethiopia', 'translation': { 'tr': 'Etiyopya' }, 'value': 'ET' },
{ 'label': 'Finland', 'translation': { 'tr': 'Finlandiya' }, 'value': 'FI' },
{ 'label': 'Fiji', 'translation': { 'tr': 'Fiji' }, 'value': 'FJ' },
{ 'label': 'Falkland islands', 'translation': { 'tr': 'Falkland Adaları (Islas Malvinas)' }, 'value': 'FK' },
{ 'label': 'Micronesia', 'translation': { 'tr': 'Mikronezya Federal Devletleri' }, 'value': 'FM' },
{ 'label': 'Faroe islands', 'translation': { 'tr': 'Faroe Adaları' }, 'value': 'FO' },
{ 'label': 'France', 'translation': { 'tr': 'Fransa' }, 'value': 'FR' },
{ 'label': 'Gabon', 'translation': { 'tr': 'Gabon' }, 'value': 'GA' },
{ 'label': 'United kingdom', 'translation': { 'tr': 'Birleşik Krallık' }, 'value': 'GB' },
{ 'label': 'Grenada', 'translation': { 'tr': 'Grenada' }, 'value': 'GD' },
{ 'label': 'Georgia', 'translation': { 'tr': 'Gürcistan' }, 'value': 'GE' },
{ 'label': 'Guernsey', 'translation': { 'tr': 'Guernsey' }, 'value': 'GG' },
{ 'label': 'Ghana', 'translation': { 'tr': 'Gana' }, 'value': 'GH' },
{ 'label': 'Gibraltar', 'translation': { 'tr': 'Cebelitarık' }, 'value': 'GI' },
{ 'label': 'Greenland', 'translation': { 'tr': 'Grönland' }, 'value': 'GL' },
{ 'label': 'Gambia', 'translation': { 'tr': 'Gambiya' }, 'value': 'GM' },
{ 'label': 'Guinea', 'translation': { 'tr': 'Gine' }, 'value': 'GN' },
{ 'label': 'Equatorial guinea', 'translation': { 'tr': 'Ekvator Ginesi' }, 'value': 'GQ' },
{ 'label': 'Greece', 'translation': { 'tr': 'Yunanistan' }, 'value': 'GR' },
{ 'label': 'Guatemala', 'translation': { 'tr': 'Guatemala' }, 'value': 'GT' },
{ 'label': 'Guam', 'translation': { 'tr': 'Guam' }, 'value': 'GU' },
{ 'label': 'Guinea bissau', 'translation': { 'tr': 'Gine-Bissau' }, 'value': 'GW' },
{ 'label': 'Guyana', 'translation': { 'tr': 'Guyana' }, 'value': 'GY' },
{ 'label': 'Hong kong', 'translation': { 'tr': 'Hong Kong' }, 'value': 'HK' },
{ 'label': 'Honduras', 'translation': { 'tr': 'Honduras' }, 'value': 'HN' },
{ 'label': 'Croatia', 'translation': { 'tr': 'Hırvatistan' }, 'value': 'HR' },
{ 'label': 'Haiti', 'translation': { 'tr': 'Haiti' }, 'value': 'HT' },
{ 'label': 'Hungary', 'translation': { 'tr': 'Macaristan' }, 'value': 'HU' },
{ 'label': 'Indonesia', 'translation': { 'tr': 'Endonezya' }, 'value': 'ID' },
{ 'label': 'Ireland', 'translation': { 'tr': 'İrlanda' }, 'value': 'IE' },
{ 'label': 'Israel', 'translation': { 'tr': 'İsrail' }, 'value': 'IL' },
{ 'label': 'Isle of man', 'translation': { 'tr': 'Man Adası' }, 'value': 'IM' },
{ 'label': 'India', 'translation': { 'tr': 'Hindistan' }, 'value': 'IN' },
{ 'label': 'British indian ocean territory', 'translation': { 'tr': 'Britanya Hint Okyanusu Toprakları' }, 'value': 'IO' },
{ 'label': 'Iraq', 'translation': { 'tr': 'Irak' }, 'value': 'IQ' },
{ 'label': 'Iran', 'translation': { 'tr': 'İran' }, 'value': 'IR' },
{ 'label': 'Iceland', 'translation': { 'tr': 'İzlanda' }, 'value': 'IS' },
{ 'label': 'Italy', 'translation': { 'tr': 'İtalya' }, 'value': 'IT' },
{ 'label': 'Jersey', 'translation': { 'tr': 'Jersey' }, 'value': 'JE' },
{ 'label': 'Jamaica', 'translation': { 'tr': 'Jamaika' }, 'value': 'JM' },
{ 'label': 'Jordan', 'translation': { 'tr': 'Ürdün' }, 'value': 'JO' },
{ 'label': 'Japan', 'translation': { 'tr': 'Japonya' }, 'value': 'JP' },
{ 'label': 'Kenya', 'translation': { 'tr': 'Kenya' }, 'value': 'KE' },
{ 'label': 'Kyrgyzstan', 'translation': { 'tr': 'Kırgızistan' }, 'value': 'KG' },
{ 'label': 'Cambodia', 'translation': { 'tr': 'Kamboçya' }, 'value': 'KH' },
{ 'label': 'Kiribati', 'translation': { 'tr': 'Kiribati' }, 'value': 'KI' },
{ 'label': 'Comoros', 'translation': { 'tr': 'Komorlar' }, 'value': 'KM' },
{ 'label': 'Saint kitts and nevis', 'translation': { 'tr': 'Saint Kitts ve Nevis' }, 'value': 'KN' },
{ 'label': 'North korea', 'translation': { 'tr': 'Kuzey Kore' }, 'value': 'KP' },
{ 'label': 'South korea', 'translation': { 'tr': 'Güney Kore' }, 'value': 'KR' },
{ 'label': 'Kwait', 'translation': { 'tr': 'Kuveyt' }, 'value': 'KW' },
{ 'label': 'Cayman islands', 'translation': { 'tr': 'Cayman Adaları' }, 'value': 'KY' },
{ 'label': 'Kazakhstan', 'translation': { 'tr': 'Kazakistan' }, 'value': 'KZ' },
{ 'label': 'Laos', 'translation': { 'tr': 'Laos' }, 'value': 'LA' },
{ 'label': 'Lebanon', 'translation': { 'tr': 'Lübnan' }, 'value': 'LB' },
{ 'label': 'St lucia', 'translation': { 'tr': 'Saint Lucia' }, 'value': 'LC' },
{ 'label': 'Liechtenstein', 'translation': { 'tr': 'Lihtenştayn' }, 'value': 'LI' },
{ 'label': 'Sri lanka', 'translation': { 'tr': 'Sri Lanka' }, 'value': 'LK' },
{ 'label': 'Liberia', 'translation': { 'tr': 'Liberya' }, 'value': 'LR' },
{ 'label': 'Lesotho', 'translation': { 'tr': 'Lesotho' }, 'value': 'LS' },
{ 'label': 'Lithuania', 'translation': { 'tr': 'Litvanya' }, 'value': 'LT' },
{ 'label': 'Luxembourg', 'translation': { 'tr': 'Lüksemburg' }, 'value': 'LU' },
{ 'label': 'Latvia', 'translation': { 'tr': 'Letonya' }, 'value': 'LV' },
{ 'label': 'Libya', 'translation': { 'tr': 'Libya' }, 'value': 'LY' },
{ 'label': 'Morocco', 'translation': { 'tr': 'Fas' }, 'value': 'MA' },
{ 'label': 'Monaco', 'translation': { 'tr': 'Monako' }, 'value': 'MC' },
{ 'label': 'Moldova', 'translation': { 'tr': 'Moldova' }, 'value': 'MD' },
{ 'label': 'Montenegro', 'translation': { 'tr': 'Karadağ' }, 'value': 'ME' },
{ 'label': 'Madagascar', 'translation': { 'tr': 'Madagaskar' }, 'value': 'MG' },
{ 'label': 'Marshall island', 'translation': { 'tr': 'Marshall Adaları' }, 'value': 'MH' },
{ 'label': 'Republic of macedonia', 'translation': { 'tr': 'Makedonya' }, 'value': 'MK' },
{ 'label': 'Mali', 'translation': { 'tr': 'Mali' }, 'value': 'ML' },
{ 'label': 'Myanmar', 'translation': { 'tr': 'Burma' }, 'value': 'MM' },
{ 'label': 'Mongolia', 'translation': { 'tr': 'Moğolistan' }, 'value': 'MN' },
{ 'label': 'Macao', 'translation': { 'tr': 'Makao' }, 'value': 'MO' },
{ 'label': 'Northern marianas islands', 'translation': { 'tr': 'Kuzey Mariana Adaları' }, 'value': 'MP' },
{ 'label': 'Martinique', 'translation': { 'tr': 'Martinique' }, 'value': 'MQ' },
{ 'label': 'Mauritania', 'translation': { 'tr': 'Moritanya' }, 'value': 'MR' },
{ 'label': 'Montserrat', 'translation': { 'tr': 'Montserrat' }, 'value': 'MS' },
{ 'label': 'Malta', 'translation': { 'tr': 'Malta' }, 'value': 'MT' },
{ 'label': 'Mauritius', 'translation': { 'tr': 'Mauritius' }, 'value': 'MU' },
{ 'label': 'Maldives', 'translation': { 'tr': 'Maldivler' }, 'value': 'MV' },
{ 'label': 'Malawi', 'translation': { 'tr': 'Malavi' }, 'value': 'MW' },
{ 'label': 'Mexico', 'translation': { 'tr': 'Meksika' }, 'value': 'MX' },
{ 'label': 'Malasya', 'translation': { 'tr': 'Malezya' }, 'value': 'MY' },
{ 'label': 'Mozambique', 'translation': { 'tr': 'Mozambik' }, 'value': 'MZ' },
{ 'label': 'Namibia', 'translation': { 'tr': 'Namibya' }, 'value': 'NA' },
{ 'label': 'New caledonia', 'translation': { 'tr': 'Yeni Kaledonya' }, 'value': 'NC' },
{ 'label': 'Niger', 'translation': { 'tr': 'Nijer' }, 'value': 'NE' },
{ 'label': 'Norfolk island', 'translation': { 'tr': 'Norfolk Adası' }, 'value': 'NF' },
{ 'label': 'Nigeria', 'translation': { 'tr': 'Nijerya' }, 'value': 'NG' },
{ 'label': 'Nicaragua', 'translation': { 'tr': 'Nikaragua' }, 'value': 'NI' },
{ 'label': 'Netherlands', 'translation': { 'tr': 'Hollanda' }, 'value': 'NL' },
{ 'label': 'Norway', 'translation': { 'tr': 'Norveç' }, 'value': 'NO' },
{ 'label': 'Nepal', 'translation': { 'tr': 'Nepal' }, 'value': 'NP' },
{ 'label': 'Nauru', 'translation': { 'tr': 'Nauru' }, 'value': 'NR' },
{ 'label': 'Niue', 'translation': { 'tr': 'Niue' }, 'value': 'NU' },
{ 'label': 'New zealand', 'translation': { 'tr': 'Yeni Zelanda' }, 'value': 'NZ' },
{ 'label': 'Oman', 'translation': { 'tr': 'Umman' }, 'value': 'OM' },
{ 'label': 'Panama', 'translation': { 'tr': 'Panama' }, 'value': 'PA' },
{ 'label': 'Peru', 'translation': { 'tr': 'Peru' }, 'value': 'PE' },
{ 'label': 'French polynesia', 'translation': { 'tr': 'Fransız Polinezyası' }, 'value': 'PF' },
{ 'label': 'Papua new guinea', 'translation': { 'tr': 'Papua Yeni Gine' }, 'value': 'PG' },
{ 'label': 'Philippines', 'translation': { 'tr': 'Filipinler' }, 'value': 'PH' },
{ 'label': 'Pakistan', 'translation': { 'tr': 'Pakistan' }, 'value': 'PK' },
{ 'label': 'Poland', 'translation': { 'tr': 'Polonya' }, 'value': 'PL' },
{ 'label': 'Pitcairn islands', 'translation': { 'tr': 'Pitcairn Adaları' }, 'value': 'PN' },
{ 'label': 'Puerto rico', 'translation': { 'tr': 'Porto Riko' }, 'value': 'PR' },
{ 'label': 'Palestine', 'translation': { 'tr': 'Filistin' }, 'value': 'PS' },
{ 'label': 'Portugal', 'translation': { 'tr': 'Portekiz' }, 'value': 'PT' },
{ 'label': 'Palau', 'translation': { 'tr': 'Palau' }, 'value': 'PW' },
{ 'label': 'Paraguay', 'translation': { 'tr': 'Paraguay' }, 'value': 'PY' },
{ 'label': 'Qatar', 'translation': { 'tr': 'Katar' }, 'value': 'QA' },
{ 'label': 'Romania', 'translation': { 'tr': 'Romanya' }, 'value': 'RO' },
{ 'label': 'Serbia', 'translation': { 'tr': 'Sırbistan' }, 'value': 'RS' },
{ 'label': 'Russia', 'translation': { 'tr': 'Rusya' }, 'value': 'RU' },
{ 'label': 'Rwanda', 'translation': { 'tr': 'Ruanda' }, 'value': 'RW' },
{ 'label': 'Saudi arabia', 'translation': { 'tr': 'Suudi Arabistan' }, 'value': 'SA' },
{ 'label': 'Solomon islands', 'translation': { 'tr': 'Solomon Adaları' }, 'value': 'SB' },
{ 'label': 'Seychelles', 'translation': { 'tr': 'Seyşeller' }, 'value': 'SC' },
{ 'label': 'Sudan', 'translation': { 'tr': 'Sudan' }, 'value': 'SD' },
{ 'label': 'Sweden', 'translation': { 'tr': 'İsveç' }, 'value': 'SE' },
{ 'label': 'Singapore', 'translation': { 'tr': 'Singapur' }, 'value': 'SG' },
{ 'label': 'Slovenia', 'translation': { 'tr': 'Slovenya' }, 'value': 'SI' },
{ 'label': 'Slovakia', 'translation': { 'tr': 'Slovakya' }, 'value': 'SK' },
{ 'label': 'Sierra leone', 'translation': { 'tr': 'Sierra Leone' }, 'value': 'SL' },
{ 'label': 'San marino', 'translation': { 'tr': 'San Marino' }, 'value': 'SM' },
{ 'label': 'Senegal', 'translation': { 'tr': 'Senegal' }, 'value': 'SN' },
{ 'label': 'Somalia', 'translation': { 'tr': 'Somali' }, 'value': 'SO' },
{ 'label': 'Suriname', 'translation': { 'tr': 'Surinam' }, 'value': 'SR' },
{ 'label': 'South sudan', 'translation': { 'tr': 'Güney Sudan' }, 'value': 'SS' },
{ 'label': 'Sao tome and prince', 'translation': { 'tr': 'Sao Tome ve Principe' }, 'value': 'ST' },
{ 'label': 'El salvador', 'translation': { 'tr': 'El Salvador' }, 'value': 'SV' },
{ 'label': 'Sint maarten', 'translation': { 'tr': 'Saint Martin' }, 'value': 'SX' },
{ 'label': 'Syria', 'translation': { 'tr': 'Suriye' }, 'value': 'SY' },
{ 'label': 'Swaziland', 'translation': { 'tr': 'Svaziland' }, 'value': 'SZ' },
{ 'label': 'Turks and caicos', 'translation': { 'tr': 'Turks ve Caicos Adaları' }, 'value': 'TC' },
{ 'label': 'Chad', 'translation': { 'tr': 'Çad' }, 'value': 'TD' },
{ 'label': 'Togo', 'translation': { 'tr': 'Togo' }, 'value': 'TG' },
{ 'label': 'Thailand', 'translation': { 'tr': 'Tayland' }, 'value': 'TH' },
{ 'label': 'Tajikistan', 'translation': { 'tr': 'Tacikistan' }, 'value': 'TJ' },
{ 'label': 'Tokelau', 'translation': { 'tr': 'Tokelau' }, 'value': 'TK' },
{ 'label': 'East timor', 'translation': { 'tr': 'Doğu Timor' }, 'value': 'TL' },
{ 'label': 'Turkmenistan', 'translation': { 'tr': 'Türkmenistan' }, 'value': 'TM' },
{ 'label': 'Tunisia', 'translation': { 'tr': 'Tunus' }, 'value': 'TN' },
{ 'label': 'Tonga', 'translation': { 'tr': 'Tonga' }, 'value': 'TO' },
{ 'label': 'Trinidad and tobago', 'translation': { 'tr': 'Trinidad ve Tobago' }, 'value': 'TT' },
{ 'label': 'Tuvalu', 'translation': { 'tr': 'Tuvalu' }, 'value': 'TV' },
{ 'label': 'Taiwan', 'translation': { 'tr': 'Tayvan' }, 'value': 'TW' },
{ 'label': 'Tanzania', 'translation': { 'tr': 'Tanzanya' }, 'value': 'TZ' },
{ 'label': 'Ukraine', 'translation': { 'tr': 'Ukrayna' }, 'value': 'UA' },
{ 'label': 'Uganda', 'translation': { 'tr': 'Uganda' }, 'value': 'UG' },
{ 'label': 'United nations', 'translation': { 'tr': 'Birleşmiş Milletler' }, 'value': 'UN' },
{ 'label': 'United states of america', 'translation': { 'tr': 'Amerika Birleşik Devletleri' }, 'value': 'US' },
{ 'label': 'Uruguay', 'translation': { 'tr': 'Uruguay' }, 'value': 'UY' },
{ 'label': 'Uzbekistn', 'translation': { 'tr': 'Özbekistan' }, 'value': 'UZ' },
{ 'label': 'Vatican city', 'translation': { 'tr': 'Vatikan' }, 'value': 'VA' },
{ 'label': 'St vincent and the grenadines', 'translation': { 'tr': 'Saint Vincent ve Grenadinler' }, 'value': 'VC' },
{ 'label': 'Venezuela', 'translation': { 'tr': 'Venezuela' }, 'value': 'VE' },
{ 'label': 'British virgin islands', 'translation': { 'tr': 'Britanya Virjin Adaları' }, 'value': 'VG' },
{ 'label': 'Virgin islands', 'translation': { 'tr': 'Virjin Adaları' }, 'value': 'VI' },
{ 'label': 'Vietnam', 'translation': { 'tr': 'Vietnam' }, 'value': 'VN' },
{ 'label': 'Vanuatu', 'translation': { 'tr': 'Vanuatu' }, 'value': 'VU' },
{ 'label': 'Samoa', 'translation': { 'tr': 'Samoa' }, 'value': 'WS' },
{ 'label': 'Kosovo', 'translation': { 'tr': 'Kosova' }, 'value': 'XK' },
{ 'label': 'Yemen', 'translation': { 'tr': 'Yemen' }, 'value': 'YE' },
{ 'label': 'South africa', 'translation': { 'tr': 'Güney Afrika' }, 'value': 'ZA' },
{ 'label': 'Zambia', 'translation': { 'tr': 'Zambiya' }, 'value': 'ZM' },
{ 'label': 'Zimbabwe', 'translation': { 'tr': 'Zimbabve' }, 'value': 'ZW' }];

export { countries as options };
