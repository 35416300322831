<template>
  <div v-if="patient_data">
    <b-row>
      <b-col sm="12" :lg="d_showMode === 'left_right' ? 6 : 12">
        <b-card class="card-accent-success" no-body style="margin-bottom: 3px;">
          <b-card-header header-bg-variant="primary" class="p-1">
            <span v-if="patient_data.last_bmi_data !== 'none'"> {{ $t('wdm16.3774') }}</span>
            <span v-else>( Henüz VYA hesabı için gerekli olan kilo boy kaydı yok. Lütfen giriş yapınız. )</span>
            <b-button size="sm" class="pull-right" @click="f_newWdmWithParameterList('wdm1')" variant="success"><i class="icon-plus"> Yeni kilo & boy</i></b-button>
          </b-card-header>
          <b-row>
            <b-col cols="12">
              <b-row>
                <b-col cols="12">
                  <span v-if="patient_data.last_bmi_data !== 'none'">Tarih: {{ DateTimeFormatWithDayDif(patient_data.last_bmi_data.date) }}</span>
                </b-col>
              </b-row>
              <template v-if="patient_data.last_bmi_data !== 'none'">
                <b-row>
                  <b-col sm="4" md="4">
                    {{ $t('wdm16.3904') }} :
                    <span v-if="show_kg"> {{patient_data.last_bmi_data.weight}} {{ $t('wdm16.3999') }} </span>
                    <span v-else> {{ Math.round(patient_data.last_bmi_data.weight / 0.45359237) }} {{ $t('wdm16.3971') }} </span>
                  </b-col>
                  <b-col sm="4" md="4">
                    {{ $t('wdm16.4200') }} :
                    <span v-if="show_cm"> {{patient_data.last_bmi_data.length}} {{ $t('wdm16.4076') }} </span>
                    <span v-else> {{ (patient_data.last_bmi_data.length / 2.54).toFixed(1) }} {{ $t('wdm16.4129') }} </span>
                  </b-col>
                  <b-col sm="4" md="4">
                    VYA :
                    <span> {{ patient_data.last_bmi_data.bsa }}  m² </span>
                  </b-col>
                </b-row>
                <b-row @click="max_vya_is_2 ? max_vya_is_2 = false : max_vya_is_2 = true; bsa_calculate();" style="cursor: pointer;">
                  <b-col cols="12">
                    <i>Maksimum vücut yüzey alanı 2 olabilir.</i>
                    <input v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_max-bsa-control') !== -1" type="checkbox" v-model="max_vya_is_2" name="max_vya_is_2">
                  </b-col>
                </b-row>
              </template>
            </b-col>
          </b-row>
          <small class="text-muted">
          </small>
        </b-card>
      </b-col>
      <b-col sm="12" :lg="d_showMode === 'left_right' ? 6 : 12">
        <b-card class="card-accent-success" no-body style="margin-bottom: 3px;">
          <b-card-header header-bg-variant="primary" class="p-1">
            <span v-if="d_lastCreaData && patient_data.last_bmi_data !== 'none'"> {{ $t('wdm16.4218') }} </span>
            <span v-else>( Henüz GFR hesabı için gerekli olan {{ !d_lastCreaData ? 'kreatinin' : '' }} {{ patient_data.last_bmi_data === 'none' ? 'kilo boy' : '' }} kaydı yok. Lütfen giriş yapınız. )</span>
            <b-button size="sm" class="pull-right" @click="d_onlyCrea = true; f_newWdmWithParameterList('laboratory')" variant="success"><i class="icon-plus"> yeni krea</i></b-button>
            <b-button size="sm" class="pull-right" @click="d_onlyCrea = false; f_newWdmWithParameterList('laboratory')" variant="secondary"><i class="icon-plus"> yeni lab</i></b-button>
          </b-card-header>
          <div>
            <b-row v-if="d_lastCreaData && d_lastCreaData.gfr && d_lastCreaData.gfr !== 'none'">
              <b-col cols="12">
                <b-row>
                  <b-col cols="12">
                    <span v-if="d_lastCreaData">Tarih: {{ DateTimeFormatWithDayDif(d_lastCreaData.date) }}</span>
                  </b-col>
                </b-row>
                <template v-if="patient_data.last_bmi_data !== 'none'">
                  <b-row>
                    <b-col sm="4" md="4">
                      {{ $t('wdm16.3884') }} :
                      <template v-if="d_lastCreaData && d_lastCreaData.val !== undefined">
                        <span v-if="show_mgdl"> {{ d_lastCreaData.val }} </span>
                        <span v-else> {{ (d_lastCreaData.val * 88.4).toFixed(1) }} </span>
                        <span v-if='show_mgdl' @click="show_mgdl = false" style="cursor: pointer;">mg/dl<i class="fa fa-hand-o-up"></i></span>
                        <span v-else @click="show_mgdl = true" style="cursor: pointer;">umol/L<i class="fa fa-hand-o-up"></i>;</span>
                      </template>
                    </b-col>
                    <b-col sm="8" md="8">
                      GFR (
                      <template v-if="d_lastCreaData && d_lastCreaData.selected_gfr_formule && d_lastCreaData.selected_gfr_formule === 'cockcroft_gault'">
                        <span style="cursor: pointer;" v-if="patient_data.color !== 0" @click="d_lastCreaData.selected_gfr_formule = 'mdrd'; gfr_calculate()">COCKCROFT-GAULT <i class="fa fa-hand-o-up"></i></span>
                        <span v-else-if="patient_data.color === 0">COCKCROFT-GAULT <i class="fa fa-hand-o-up"></i></span>
                      </template>
                      <span style="cursor: pointer;" v-if="d_lastCreaData && d_lastCreaData.selected_gfr_formule === 'mdrd'" @click="d_lastCreaData.selected_gfr_formule = 'cockcroft_gault'; gfr_calculate()">MDRD <i class="fa fa-hand-o-up"></i></span> ) : {{ d_lastCreaData.gfr }} {{ $t('wdm16.3869') }}
                    </b-col>
                  </b-row>
                  <b-row v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_gfr-type-control') !== -1">
                    <b-col cols="4" @click="d_lastCreaData.selected_weight_for_gfr = 'actual_weight_gfr'; gfr_calculate()" style="cursor: pointer;">
                      <input type="radio" value="actual_weight_gfr" v-model="d_lastCreaData.selected_weight_for_gfr" id="actual_weight"> {{ $t('wdm16.3896') }} {{patient_data.last_bmi_data.weight}} kg
                    </b-col>
                    <b-col cols="4" @click="d_lastCreaData.selected_weight_for_gfr = 'adjusted_weight_gfr'; gfr_calculate()" style="cursor: pointer;">
                      <input type="radio" value="adjusted_weight_gfr" v-model="d_lastCreaData.selected_weight_for_gfr" id="adjusted_weight"> {{ $t('wdm16.4063') }} {{patient_data.last_bmi_data.adjusted_weight}} kg
                    </b-col>
                    <b-col cols="4" @click="d_lastCreaData.selected_weight_for_gfr = 'ideal_weight_gfr'; gfr_calculate()" style="cursor: pointer;">
                      <input type="radio" value="ideal_weight_gfr" v-model="d_lastCreaData.selected_weight_for_gfr" id="ideal_weight"> {{ $t('wdm16.4143') }} {{patient_data.last_bmi_data.ideal_weight}} kg
                    </b-col>
                  </b-row>
                  <b-row v-else>
                    <b-col cols="12">
                      <div class="col-sm-4">
                        <input type="radio" disabled value="actual_weight_gfr" v-model="d_lastCreaData.selected_weight_for_gfr" id="actual_weight"> {{ $t('wdm16.3896') }} {{patient_data.last_bmi_data.weight}} kg
                      </div>
                      <div class="col-sm-4">
                        <input type="radio" disabled value="adjusted_weight_gfr" v-model="d_lastCreaData.selected_weight_for_gfr" id="adjusted_weight"> {{ $t('wdm16.4063') }} {{patient_data.last_bmi_data.adjusted_weight}} kg
                      </div>
                      <div class="col-sm-4">
                        <input type="radio" disabled value="ideal_weight_gfr" v-model="d_lastCreaData.selected_weight_for_gfr" id="ideal_weight"> {{ $t('wdm16.4143') }} {{patient_data.last_bmi_data.ideal_weight}} kg
                      </div>
                    </b-col>
                  </b-row>
                </template>
              </b-col>
            </b-row>
            <div class="card" style="background-color: rgb(185, 240, 197); margin-bottom: 0px;"></div>
          </div>
          <small class="text-muted"></small>
        </b-card>
      </b-col>
    </b-row>
    <modal v-if="show_wisdom_data_modal" @close="show_wisdom_data_modal = false" :width="'750'">
      <h3 slot="header"> {{ option_data[data_type].name.label }} </h3>
      <div slot="body">
        <template v-if="data_type === 'laboratory'">
          <template v-if="d_onlyCrea">
            <wisdom-data-modal ref="wisdom_data_modal" :data="wisdom_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]" :parameter_list="parameter_list" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
          </template>
          <template v-else>
            <wisdom-data-modal ref="wisdom_data_modal" :data="wisdom_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
          </template>
        </template>
        <template v-else>
          <wisdom-data-modal ref="wisdom_data_modal" :data="wisdom_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
        </template>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveWdm()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="show_wisdom_data_modal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import GlobalService from '@/services/global';
import { ClsWdmrForever } from "@/services/public/wdm";
import WisdomDataService from '@/services/wisdom_data';
import {
  default as Modal
} from '@/components/widgets/Modal';
import { mapGetters } from 'vuex';
// import BmiService from '@/router/physicalexam_bmi';
// import LaboratoryService from '@/router/laboratory';
import { DateFunctions } from '@/services/public/date_functions';
import moment from 'moment';
// import OptionService from '@/services/option';

export default {
  name: 'BmiGfrWidget',
  computed: {
    ...mapGetters({
      lang: 'lang',
      device: 'device',
      patient: 'patient'
    })
  },
  components: {
    Modal,
    WisdomDataModal: () => import('@/components/widgets/WisdomDataModal')
  },
  props: {
    patient_data: {
      type: Object,
      required: true
    },
    bmi_gfr_change: {
      type: Object,
      required: true
    },
    patients_chemo_list: {
      type: Boolean,
      required: false
    },
    p_showMode: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      d_selectedAnatomySchemaList: [],
      d_showMode: 'left_right',
      parameter_list: ['2'],
      view_mode: 'macro',
      max_vya_is_2: true,
      show_evidence: false,
      show_cm: true,
      show_mgdl: true,
      show_kg: true,
      option_data: {},
      wisdom_data: {},
      show_wisdom_data_modal: false,
      d_onlyCrea: false,
      d_lastCreaData: {}
    };
  },
  created: function () {
    if (this.p_showMode !== undefined) {
      this.d_showMode = this.p_showMode;
    }
    this.user = JSON.parse(localStorage.getItem('user'));
    this.d_lastCreaData = this.f_calculateLastCreaDate();
    this.bsa_calculate();
    if (this.patients_chemo_list) {
      this.view_mode = 'micro';
    }
  },
  mounted () {
    this.$options.sockets.onmessage = function (message) {
      let socket_data = JSON.parse(message.data);
      // console.log('add edit', socket_data);
      if (socket_data.type && socket_data.patient_id && this.patient_data.id === socket_data.patient_id) {
        if (['last_bmi_update'].indexOf(socket_data.type) !== -1) {
          this.patient_data.last_bmi_data = socket_data.data;
          this.bsa_calculate();
          let weight = 'Kaydı yok';
          let length = 'Kaydı yok';
          if (this.patient_data.last_bmi_data !== 'none') { weight = this.patient_data.last_bmi_data.weight; }
          if (this.patient_data.last_bmi_data !== 'none') { length = this.patient_data.last_bmi_data.length; }
          let snotify_body = this.patient_data.firstname + ' ' + this.patient_data.lastname + '>> ' + ' kilo boyu güncellendi. ' + 'Kilo: ' + weight + ' & ' + 'Boy: ' + length;
          this.$snotify.warning(snotify_body, 'Kilo & Boy Güncellemesi', {
            titleMaxLength: 60,
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true
          });
        }
        if (['patient_last_laboratory'].indexOf(socket_data.type) !== -1) {
          this.patient_data.last_laboratory = socket_data.data;
          this.d_lastCreaData = this.f_calculateLastCreaDate();
          this.gfr_calculate();
        }
      }
    };
  },
  methods: {
    f_calculateLastCreaDate: function () {
      if (this.patient_data.last_laboratory['2'] && !this.patient_data.last_laboratory['Crea']) {
        return this.patient_data.last_laboratory['2'];
      }
      if (!this.patient_data.last_laboratory['2'] && this.patient_data.last_laboratory['Crea']) {
        return this.patient_data.last_laboratory['Crea'];
      }
      if (this.patient_data.last_laboratory['2'] && this.patient_data.last_laboratory['Crea']) {
        if (this.patient_data.last_laboratory['2']['date'] > this.patient_data.last_laboratory['Crea']['date']) {
          return this.patient_data.last_laboratory['2'];
        } else {
          return this.patient_data.last_laboratory['Crea'];
        }
      }
    },
    f_saveWdm: function () {
      if (this.option_data[this.wisdom_data.type].date_ability && this.option_data[this.wisdom_data.type].date_ability === 'yes') {
        if (!this.wisdom_data.date) {
          let modal_data = { 'type': 'alert', 'text': "Lütfen tarih giriniz", 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }
      }
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['require']);
      let wdmr_name = return_data.name;
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      // Bir wdmr kaydı yapabilmek için en az bir parametre dolu olması gerekiyor.
      if (!only_one_param_is_filled) {
        let modal_data = { 'type': 'alert', 'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      // wdmr kaydı sırasında tüm wdm parametreleri için require kontrolü yapıyor. İlgili parametrede val değeri oluştuysa zaten oraya girilmiş oluyor. İçine girilipte (wisdom-param componenti) dolu olmayan tüm parametreleri kontrol ediyoruz. Örneğin er ihd testini ekledi. Fakat pozitif / negatif değerini doldurmadıysa geçemiyor.
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' * ' + wdmr_require_alerts[r].label;
        }
        let modal_data = { 'type': 'alert', 'text': require_txt, 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['name', 'delete']);
      wdmr_name = return_data.name;
      this.wisdom_data['label'] = wdmr_name;
      wdmr_require_alerts = return_data.wdmr_require_alerts;
      only_one_param_is_filled = return_data.only_one_param_is_filled;
      this.show_wisdom_data_modal = false;
      let d_uuid = this.wisdom_data.id ? this.wisdom_data.id : '';
      let data = {
        'webservice': {},
        'data': this.wisdom_data
      };
      // this.$refs.wisdom_data_modal.calculate_name();
      WisdomDataService.save_wdm(this.patient_data.id, d_uuid, data).then(resp => {
        // console.log(resp.data);
        let txt = '';
        if (resp.data.status === "success") {
          txt = 'Kayıt işlemi başarılı';
          if (resp.data.result.type === 'wdm1') {
            if (!this.patient_data.last_bmi_data || (this.patient_data.last_bmi_data && !this.patient_data.last_bmi_data.date) || (this.patient_data.last_bmi_data && this.patient_data.last_bmi_data.date && resp.data.result['date'] > this.patient_data.last_bmi_data.date)) {
              this.patient_data.last_bmi_data = {
                'date': resp.data.result['date'],
                'id': resp.data.result['id'],
                'created_by': resp.data.result['created_by'],
                'created_at': resp.data.result['created_at'],
                'weight': resp.data.result['data']['general']['weight']['val'],
                'weight_kg': resp.data.result['data']['general']['weight_kg']['val'],
                'weight_lbs': resp.data.result['data']['general']['weight_lbs']['val'],
                'length': resp.data.result['data']['general']['length']['val'],
                'length_cm': resp.data.result['data']['general']['length_cm']['val'],
                'length_inch': resp.data.result['data']['general']['length_inch']['val'],
                'adjusted_weight': resp.data.result['data']['general']['adjusted_weight']['val'],
                'ideal_weight': resp.data.result['data']['general']['ideal_weight']['val'],
                'bodymassindex': resp.data.result['data']['general']['bmi']['val'],
                'bsa': resp.data.result['data']['general']['bsa']['val']
              };
            }
          }
        } else {
          txt = 'error: ' + resp.data.message;
        }
        let modal_data = { 'type': 'alert', 'text': txt, 'centered': true, 'title': 'Laboratuar kayıt işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      });
    },
    f_newWdmWithParameterList: function (data_type) {
      this.data_type = data_type;
      // predata hazırlanma sebebi bir değişkene eşittir diğer değişken dendiğinde computed property değişiyor. Fakat datanın içerisindeki sadece bir bilgi değişince sayfada re render olmuyor. dolayısıyla önce predata hazırlayıp. asıl datamıza yani wisdom data ya eşitliyoruz aşağıda.
      let pre_data = { 'data': {}, 'date': '', 'type': this.data_type, 'reference_wdm': {} };
      let wdm_version = JSON.parse(localStorage.getItem('wdm_version'));
      let doc_key = '';
      let doc_version = '';
      let bucket = '';
      if (wdm_version.hospital.last[data_type]) {
        doc_key = wdm_version.hospital.last[data_type].key;
        doc_version = wdm_version.hospital.last[data_type].version;
        bucket = 'hospital';
      }
      if (wdm_version.wisdom.last[data_type]) {
        doc_key = wdm_version.wisdom.last[data_type].key;
        doc_version = wdm_version.wisdom.last[data_type].version;
        bucket = 'option';
      }
      if (wdm_version.user.last[data_type]) {
        doc_key = wdm_version.user.last[data_type].key;
        doc_version = wdm_version.user.last[data_type].version;
        bucket = 'hospital';
      }
      GlobalService.get_one_cb_document({ 'key': doc_key, 'bucket': bucket })
        .then(resp => {
          if (resp.data.status === 'success') {
            let option_data = resp.data.result;
            this.option_data = {};
            this.option_data[data_type] = option_data;
            for (let i in option_data.param_group.param_order) {
              pre_data['data'][option_data.param_group.param_order[i]] = {};
            }
            pre_data['reference_wdm']['version'] = doc_version;
            pre_data['reference_wdm']['owner_type'] = option_data['owner_type'];
            pre_data['reference_wdm']['key'] = doc_key;
            this.wisdom_data = pre_data;
            this.show_wisdom_data_modal = true;
          } else {
            alert('error ', resp.data.message);
          }
        });
    },
    data_changed: function () {
      if (this.bmi_gfr_change.status) {
        this.bmi_gfr_change.status = false;
      } else {
        this.bmi_gfr_change.status = true;
      }
    },
    DateTimeFormatWithDayDif: function (date) {
      let day_dif = DateFunctions.date_dif_today(date);
      if (day_dif === 0) {
        return moment(date).format('DD/MM/YYYY') + ' (' + this.$t('wdm16.6218') + ')';
      } else if (day_dif === 1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' ' + this.$t('wdm16.6207') + ')';
      } else if (day_dif > 1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' ' + this.$t('wdm16.6227') + ')';
      } else if (day_dif === -1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' ' + this.$t('wdm16.6208') + ')';
      } else if (day_dif < -1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' ' + this.$t('wdm16.6215') + ')';
      }
      return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
    },
    bsa_calculate: function () {
      if (this.patient_data.last_bmi_data !== 'none') {
        let bsa = 0;
        if (!this.patient_data.last_bmi_data.selected_weight_for_bsa) {
          this.patient_data.last_bmi_data.selected_weight_for_bsa = 'actual_weight';
        }
        if (this.patient_data.last_bmi_data.selected_weight_for_bsa === 'ideal_weight') {
          bsa = 0.007184 * Math.pow(this.patient_data.last_bmi_data.length, 0.725) * Math.pow(this.patient_data.last_bmi_data.ideal_weight, 0.425);
        } else if (this.patient_data.last_bmi_data.selected_weight_for_bsa === 'adjusted_weight') {
          bsa = 0.007184 * Math.pow(this.patient_data.last_bmi_data.length, 0.725) * Math.pow(this.patient_data.last_bmi_data.adjusted_weight, 0.425);
        } else {
          bsa = 0.007184 * Math.pow(this.patient_data.last_bmi_data.length, 0.725) * Math.pow(this.patient_data.last_bmi_data.weight, 0.425);
        }
        if (this.max_vya_is_2 && bsa > 2) {
          this.patient_data.last_bmi_data.bsa = 2;
        } else {
          this.patient_data.last_bmi_data.bsa = parseFloat(bsa).toFixed(2);
        }
      }
      this.gfr_calculate();
    },
    gfr_calculate: function () {
      this.$forceUpdate();
      if (this.patient_data.last_bmi_data !== 'none') {
        if (this.d_lastCreaData) {
          if (!this.d_lastCreaData.selected_gfr_formule) {
            let bmi = parseFloat(this.patient_data.last_bmi_data.bodymassindex);
            if (bmi < 18.5) {
              this.d_lastCreaData.selected_weight_for_gfr = 'actual_weight_gfr';
            } else if (bmi > 25) {
              this.d_lastCreaData.selected_weight_for_gfr = 'adjusted_weight_gfr';
            } else {
              this.d_lastCreaData.selected_weight_for_gfr = 'ideal_weight_gfr';
            }
            this.d_lastCreaData.selected_gfr_formule = 'cockcroft_gault';
          }
          let gfr = 0;
          let a = parseFloat(this.patient_data.last_bmi_data.weight);
          if (this.d_lastCreaData.selected_gfr_formule === 'cockcroft_gault') {
            if (this.d_lastCreaData.selected_weight_for_gfr === 'adjusted_weight_gfr') {
              a = parseFloat(this.patient_data.last_bmi_data.adjusted_weight);
            } else if (this.d_lastCreaData.selected_weight_for_gfr === 'ideal_weight_gfr') {
              a = parseFloat(this.patient_data.last_bmi_data.ideal_weight);
            }
            if (this.patient_data.sex === '0') {
              let res = (((140 - this.patient_data.age) * a) / (72 * parseFloat(this.d_lastCreaData.val)));
              gfr = res.toFixed(0);
            } else { // if female
              let res = (((140 - this.patient_data.age) * a) / (72 * parseFloat(this.d_lastCreaData.val))) * 0.85;
              gfr = res.toFixed(0);
            }
          } else { // mdrd formülü GFR = 186 × Serum Cr-1.154 × age-0 .203 × 1.212 (if patient is black) × 0.742 (if female)
            if (this.patient_data.color === 1) {
              if (this.patient_data.sex === '0') { // '0' erkek
                gfr = 186 * Math.pow(parseFloat(this.d_lastCreaData.val), -1.154) * Math.pow(this.patient_data.age, -0.203);
              } else { // 1 kadın ise
                gfr = 186 * Math.pow(parseFloat(this.d_lastCreaData.val), -1.154) * Math.pow(this.patient_data.age, -0.203) * 0.742;
              }
            } else if (this.patient_data.color === 2) { // if race = black
              if (this.patient_data.sex === '0') { // 0 erkek
                gfr = 186 * Math.pow(parseFloat(this.d_lastCreaData.val), -1.154) * Math.pow(this.patient_data.age, -0.203) * 1.212;
              } else { // 1 kadın ise
                gfr = 186 * Math.pow(parseFloat(this.d_lastCreaData.val), -1.154) * Math.pow(this.patient_data.age, -0.203) * 0.742 * 1.212;
              }
            }
          }
          this.d_lastCreaData.gfr = parseFloat(gfr).toFixed(0);
        }
      } else if (this.patient_data.last_bmi_data === 'none') {
        if (this.d_lastCreaData) {
          this.d_lastCreaData.gfr = 'none';
        }
      }
      this.data_changed();
    }
  },
  watch: {}
};

</script>

<style type="text/css">


</style>

