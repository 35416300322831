import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  save (data) {
    var r_uuid = 'new';
    if (data.id) {
      r_uuid = data.id;
    }
    let url = API_BASE_URL + 'rule/' + r_uuid;
    return Vue.http.put(url, data);
  },
  search (rule_id) {
    var r_uuid = 'all';
    if (rule_id) {
      r_uuid = rule_id;
    }
    return Vue.http.get(API_BASE_URL + 'rule/list/' + r_uuid);
  },
  get (r_uuid, uuid) {
    return Vue.http.get(API_BASE_URL + 'patient/' + r_uuid + '/laboratory/' + uuid);
  },
  rule_engine_test (data) {
    return Vue.http.post(API_BASE_URL + 'rule/rule_engine_test', data);
  },
  publish_decision_making_package (data) {
    return Vue.http.post(API_BASE_URL + 'rule/publish_decision_making_package', data);
  },
  analyze_wdmr_list (data) {
    return Vue.http.post(API_BASE_URL + 'rule/analyze_wdmr_list', data);
  },
  analyze_wdm_parameters_with_text (data) {
    return Vue.http.post(API_BASE_URL + 'rule/analyze_wdm_parameters_with_text', data);
  },
  create_text_of_rule_algoritm (data) {
    return Vue.http.post(API_BASE_URL + 'rule/create_text_of_rule_algoritm', data);
  },
  get_dmp_rule_algorithm_list (data) {
    return Vue.http.post(API_BASE_URL + 'rule/get_dmp_rule_algorithm_list', data);
  },
  delete (r_uuid, rule_type) {
    return Vue.http.delete(API_BASE_URL + 'rule/' + r_uuid + '/delete/' + rule_type);
  }
};
