var city = [
{ 'value': '01', 'label': 'Adana' },
{ 'value': '02', 'label': 'Adıyaman' },
{ 'value': '03', 'label': 'Afyonkarahisar' },
{ 'value': '04', 'label': 'Ağrı' },
{ 'value': '68', 'label': 'Aksaray' },
{ 'value': '05', 'label': 'Amasya' },
{ 'value': '06', 'label': 'Ankara' },
{ 'value': '07', 'label': 'Antalya' },
{ 'value': '75', 'label': 'Ardahan' },
{ 'value': '08', 'label': 'Artvin' },
{ 'value': '09', 'label': 'Aydın' },
{ 'value': '10', 'label': 'Balıkesir' },
{ 'value': '74', 'label': 'Bartın' },
{ 'value': '72', 'label': 'Batman' },
{ 'value': '69', 'label': 'Bayburt' },
{ 'value': '11', 'label': 'Bilecik' },
{ 'value': '12', 'label': 'Bingöl' },
{ 'value': '13', 'label': 'Bitlis' },
{ 'value': '14', 'label': 'Bolu' },
{ 'value': '15', 'label': 'Burdur' },
{ 'value': '16', 'label': 'Bursa' },
{ 'value': '17', 'label': 'Çanakkale' },
{ 'value': '18', 'label': 'Çankırı' },
{ 'value': '19', 'label': 'Çorum' },
{ 'value': '20', 'label': 'Denizli' },
{ 'value': '21', 'label': 'Diyarbakır' },
{ 'value': '81', 'label': 'Düzce' },
{ 'value': '22', 'label': 'Edirne' },
{ 'value': '23', 'label': 'Elazığ' },
{ 'value': '24', 'label': 'Erzincan' },
{ 'value': '25', 'label': 'Erzurum' },
{ 'value': '26', 'label': 'Eskişehir' },
{ 'value': '27', 'label': 'Gaziantep' },
{ 'value': '28', 'label': 'Giresun' },
{ 'value': '29', 'label': 'Gümüşhane' },
{ 'value': '30', 'label': 'Hakkari' },
{ 'value': '31', 'label': 'Hatay' },
{ 'value': '76', 'label': 'Iğdır' },
{ 'value': '32', 'label': 'Isparta' },
{ 'value': '34', 'label': 'İstanbul' },
{ 'value': '35', 'label': 'İzmir' },
{ 'value': '46', 'label': 'Kahramanmaraş' },
{ 'value': '78', 'label': 'Karabük' },
{ 'value': '70', 'label': 'Karaman' },
{ 'value': '36', 'label': 'Kars' },
{ 'value': '37', 'label': 'Kastamonu' },
{ 'value': '38', 'label': 'Kayseri' },
{ 'value': '71', 'label': 'Kırıkkale' },
{ 'value': '39', 'label': 'Kırklareli' },
{ 'value': '40', 'label': 'Kırşehir' },
{ 'value': '79', 'label': 'Kilis' },
{ 'value': '41', 'label': 'Kocaeli' },
{ 'value': '42', 'label': 'Konya' },
{ 'value': '43', 'label': 'Kütahya' },
{ 'value': '44', 'label': 'Malatya' },
{ 'value': '45', 'label': 'Manisa' },
{ 'value': '47', 'label': 'Mardin' },
{ 'value': '33', 'label': 'Mersin' },
{ 'value': '48', 'label': 'Muğla' },
{ 'value': '49', 'label': 'Muş' },
{ 'value': '50', 'label': 'Nevşehir' },
{ 'value': '51', 'label': 'Niğde' },
{ 'value': '52', 'label': 'Ordu' },
{ 'value': '80', 'label': 'Osmaniye' },
{ 'value': '53', 'label': 'Rize' },
{ 'value': '54', 'label': 'Sakarya' },
{ 'value': '55', 'label': 'Samsun' },
{ 'value': '56', 'label': 'Siirt' },
{ 'value': '57', 'label': 'Sinop' },
{ 'value': '58', 'label': 'Sivas' },
{ 'value': '63', 'label': 'Şanlıurfa' },
{ 'value': '73', 'label': 'Şırnak' },
{ 'value': '59', 'label': 'Tekirdağ' },
{ 'value': '60', 'label': 'Tokat' },
{ 'value': '61', 'label': 'Trabzon' },
{ 'value': '62', 'label': 'Tunceli' },
{ 'value': '64', 'label': 'Uşak' },
{ 'value': '65', 'label': 'Van' },
{ 'value': '77', 'label': 'Yalova' },
{ 'value': '66', 'label': 'Yozgat' },
{ 'value': '67', 'label': 'Zonguldak' }];

export { city as options };
