var favorite_diagnosis =
  [
    { 'label': 'J12 - Viral pnömoni, başka yerde sınıflanmamış', 'translation': {'tr': 'J12 - Viral pnömoni, başka yerde sınıflanmamış'}, 'value': 'J12'},
    { 'label': 'C00 - Dudak malign neoplazmları', 'translation': { 'tr': 'C00 - Dudak malign neoplazmları' }, 'value': 'C00' },
    { 'label': 'C00.0 - Üst dudak malign neoplazmı, dış bölge ', 'translation': { 'tr': 'C00.0 - Üst dudak malign neoplazmı, dış bölge ' }, 'value': 'C00.0' },
    { 'label': 'C00.1 - Alt dudak malign neoplazmı, dış bölge ', 'translation': { 'tr': 'C00.1 - Alt dudak malign neoplazmı, dış bölge ' }, 'value': 'C00.1' },
    { 'label': 'C00.2 - Dudak malign neoplazmı, dış bölge, dudak tanımlanmamış', 'translation': { 'tr': 'C00.2 - Dudak malign neoplazmı, dış bölge, dudak tanımlanmamış' }, 'value': 'C00.2' },
    { 'label': 'C00.3 - Üst dudak malign neoplazmı, iç yüz ', 'translation': { 'tr': 'C00.3 - Üst dudak malign neoplazmı, iç yüz ' }, 'value': 'C00.3' },
    { 'label': 'C00.4 - Alt dudak malign neoplazmı, iç yüz ', 'translation': { 'tr': 'C00.4 - Alt dudak malign neoplazmı, iç yüz ' }, 'value': 'C00.4' },
    { 'label': 'C00.5 - Dudak iç yüz malign neoplazmı, dudak tanımlanmamış', 'translation': { 'tr': 'C00.5 - Dudak iç yüz malign neoplazmı, dudak tanımlanmamış' }, 'value': 'C00.5' },
    { 'label': 'C00.6 - Dudak komissüru malign neoplazmı', 'translation': { 'tr': 'C00.6 - Dudak komissüru malign neoplazmı' }, 'value': 'C00.6' },
    { 'label': 'C00.8 - Dudak overlapping lezyonu', 'translation': { 'tr': 'C00.8 - Dudak overlapping lezyonu' }, 'value': 'C00.8' },
    { 'label': 'C00.9 - Dudak malign neoplazmı, dudak tanımlanmamış', 'translation': { 'tr': 'C00.9 - Dudak malign neoplazmı, dudak tanımlanmamış' }, 'value': 'C00.9' },
    { 'label': 'C01 - Dil kökü malign neoplazmları', 'translation': { 'tr': 'C01 - Dil kökü malign neoplazmları' }, 'value': 'C01' },
    { 'label': 'C02 - Dil malign neoplazmları, diğer ve tanımlanmamış kısımlarının', 'translation': { 'tr': 'C02 - Dil malign neoplazmları, diğer ve tanımlanmamış kısımlarının' }, 'value': 'C02' },
    { 'label': 'C02.0 - Dil arka yüzeyi malign neoplazmı', 'translation': { 'tr': 'C02.0 - Dil arka yüzeyi malign neoplazmı' }, 'value': 'C02.0' },
    { 'label': 'C02.1 - Dil kenarları malign neoplazmı', 'translation': { 'tr': 'C02.1 - Dil kenarları malign neoplazmı' }, 'value': 'C02.1' },
    { 'label': 'C02.2 - Dil ön yüzü malign neoplazmı', 'translation': { 'tr': 'C02.2 - Dil ön yüzü malign neoplazmı' }, 'value': 'C02.2' },
    { 'label': 'C02.3 - Dil 2/3 önü malign neoplazmı, tanımlanmamış kısım', 'translation': { 'tr': 'C02.3 - Dil 2/3 önü malign neoplazmı, tanımlanmamış kısım' }, 'value': 'C02.3' },
    { 'label': 'C02.4 - Lingual tonsil malign neoplazmı', 'translation': { 'tr': 'C02.4 - Lingual tonsil malign neoplazmı' }, 'value': 'C02.4' },
    { 'label': 'C02.8 - Dilin overlapping lezyonu', 'translation': { 'tr': 'C02.8 - Dilin overlapping lezyonu' }, 'value': 'C02.8' },
    { 'label': 'C02.9 - Dil malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C02.9 - Dil malign neoplazmı, tanımlanmamış' }, 'value': 'C02.9' },
    { 'label': 'C03 - Diş eti malign neoplazmları', 'translation': { 'tr': 'C03 - Diş eti malign neoplazmları' }, 'value': 'C03' },
    { 'label': 'C03.0 - Üst diş eti malign neoplazmı ', 'translation': { 'tr': 'C03.0 - Üst diş eti malign neoplazmı ' }, 'value': 'C03.0' },
    { 'label': 'C03.1 - Alt diş eti malign neoplazmı', 'translation': { 'tr': 'C03.1 - Alt diş eti malign neoplazmı' }, 'value': 'C03.1' },
    { 'label': 'C03.9 - Diş eti malign neoplazmı, yer tanımlanmamış', 'translation': { 'tr': 'C03.9 - Diş eti malign neoplazmı, yer tanımlanmamış' }, 'value': 'C03.9' },
    { 'label': 'C04 - Ağız tabanı malign neoplazmları', 'translation': { 'tr': 'C04 - Ağız tabanı malign neoplazmları' }, 'value': 'C04' },
    { 'label': 'C04.0 - Ağzın ön tabanı malign neoplazmı', 'translation': { 'tr': 'C04.0 - Ağzın ön tabanı malign neoplazmı' }, 'value': 'C04.0' },
    { 'label': 'C04.1 - Ağız yan duvarı malign neoplazmı', 'translation': { 'tr': 'C04.1 - Ağız yan duvarı malign neoplazmı' }, 'value': 'C04.1' },
    { 'label': 'C04.8 - Ağız tabanı overlapping lezyonu', 'translation': { 'tr': 'C04.8 - Ağız tabanı overlapping lezyonu' }, 'value': 'C04.8' },
    { 'label': 'C04.9 - Ağız tabanı malign neoplazmı, yer tanımlanmamış', 'translation': { 'tr': 'C04.9 - Ağız tabanı malign neoplazmı, yer tanımlanmamış' }, 'value': 'C04.9' },
    { 'label': 'C05 - Damak malign neoplazmları', 'translation': { 'tr': 'C05 - Damak malign neoplazmları' }, 'value': 'C05' },
    { 'label': 'C05.0 - Sert damak malign neoplazmı', 'translation': { 'tr': 'C05.0 - Sert damak malign neoplazmı' }, 'value': 'C05.0' },
    { 'label': 'C05.1 - Yumuşak damak malign neoplazmı', 'translation': { 'tr': 'C05.1 - Yumuşak damak malign neoplazmı' }, 'value': 'C05.1' },
    { 'label': 'C05.2 - Uvula malign neoplazmı', 'translation': { 'tr': 'C05.2 - Uvula malign neoplazmı' }, 'value': 'C05.2' },
    { 'label': 'C05.8 - Damak overlapping lezyonu', 'translation': { 'tr': 'C05.8 - Damak overlapping lezyonu' }, 'value': 'C05.8' },
    { 'label': 'C05.9 - Damak malign neoplazmı, yer tanımlanmamış', 'translation': { 'tr': 'C05.9 - Damak malign neoplazmı, yer tanımlanmamış' }, 'value': 'C05.9' },
    { 'label': 'C06 - Ağzın malign neoplazmları, diğer ve tanımlanmamış kısımlarının', 'translation': { 'tr': 'C06 - Ağzın malign neoplazmları, diğer ve tanımlanmamış kısımlarının' }, 'value': 'C06' },
    { 'label': 'C06.0 - Yanak mukozası malign neoplazmı', 'translation': { 'tr': 'C06.0 - Yanak mukozası malign neoplazmı' }, 'value': 'C06.0' },
    { 'label': 'C06.1 - Ağız vestibülü malign neoplazmı', 'translation': { 'tr': 'C06.1 - Ağız vestibülü malign neoplazmı' }, 'value': 'C06.1' },
    { 'label': 'C06.2 - Retromolar bölge malign neoplazmı ', 'translation': { 'tr': 'C06.2 - Retromolar bölge malign neoplazmı ' }, 'value': 'C06.2' },
    { 'label': 'C06.8 - Ağız diğer ve tanımlanmamış kısımlarının overlapping lezyonu', 'translation': { 'tr': 'C06.8 - Ağız diğer ve tanımlanmamış kısımlarının overlapping lezyonu' }, 'value': 'C06.8' },
    { 'label': 'C06.9 - Ağız malign neoplazmı, yer tanımlanmamış', 'translation': { 'tr': 'C06.9 - Ağız malign neoplazmı, yer tanımlanmamış' }, 'value': 'C06.9' },
    { 'label': 'C07 - Parotis bezi malign neoplazmı', 'translation': { 'tr': 'C07 - Parotis bezi malign neoplazmı' }, 'value': 'C07' },
    { 'label': 'C08 - Majör tükrük bezlerinin malign neoplazmları, diğer ve tanımlanmamış', 'translation': { 'tr': 'C08 - Majör tükrük bezlerinin malign neoplazmları, diğer ve tanımlanmamış' }, 'value': 'C08' },
    { 'label': 'C08.0 - Submandibüler bez malign neoplazmı', 'translation': { 'tr': 'C08.0 - Submandibüler bez malign neoplazmı' }, 'value': 'C08.0' },
    { 'label': 'C08.1 - Sublingual bez malign neoplazmı', 'translation': { 'tr': 'C08.1 - Sublingual bez malign neoplazmı' }, 'value': 'C08.1' },
    { 'label': 'C08.8 - Majör tükrük bezler overlapping lezyonu', 'translation': { 'tr': 'C08.8 - Majör tükrük bezler overlapping lezyonu' }, 'value': 'C08.8' },
    { 'label': 'C08.9 - Majör tükrük bezi malign neoplazmı, yer tanımlanmamış', 'translation': { 'tr': 'C08.9 - Majör tükrük bezi malign neoplazmı, yer tanımlanmamış' }, 'value': 'C08.9' },
    { 'label': 'C09 - Tonsil malign neoplazmı', 'translation': { 'tr': 'C09 - Tonsil malign neoplazmı' }, 'value': 'C09' },
    { 'label': 'C09.0 - Tonsiller fossada malign neoplazm', 'translation': { 'tr': 'C09.0 - Tonsiller fossada malign neoplazm' }, 'value': 'C09.0' },
    { 'label': 'C09.1 - Tonsiller plika (ön) (arka) malign neoplazmı', 'translation': { 'tr': 'C09.1 - Tonsiller plika (ön) (arka) malign neoplazmı' }, 'value': 'C09.1' },
    { 'label': 'C09.8 - Tonsil overlapping lezyonu', 'translation': { 'tr': 'C09.8 - Tonsil overlapping lezyonu' }, 'value': 'C09.8' },
    { 'label': 'C09.9 - Tonsil malign neoplazmı, yer tanımlanmamış', 'translation': { 'tr': 'C09.9 - Tonsil malign neoplazmı, yer tanımlanmamış' }, 'value': 'C09.9' },
    { 'label': 'C10 - Orofarinks malign neoplazmı', 'translation': { 'tr': 'C10 - Orofarinks malign neoplazmı' }, 'value': 'C10' },
    { 'label': 'C10.0 - Vallekula malign neoplazmı', 'translation': { 'tr': 'C10.0 - Vallekula malign neoplazmı' }, 'value': 'C10.0' },
    { 'label': 'C10.1 - Epiglot ön yüzünde malign neoplazm', 'translation': { 'tr': 'C10.1 - Epiglot ön yüzünde malign neoplazm' }, 'value': 'C10.1' },
    { 'label': 'C10.2 - Orofarinks yan duvarında malign neoplazm', 'translation': { 'tr': 'C10.2 - Orofarinks yan duvarında malign neoplazm' }, 'value': 'C10.2' },
    { 'label': 'C10.3 - Orofarinks arka duvarında malign neoplazm ', 'translation': { 'tr': 'C10.3 - Orofarinks arka duvarında malign neoplazm ' }, 'value': 'C10.3' },
    { 'label': 'C10.4 - Brankial yarık malign neoplazmı', 'translation': { 'tr': 'C10.4 - Brankial yarık malign neoplazmı' }, 'value': 'C10.4' },
    { 'label': 'C10.8 - Orofarinks overlapping lezyonu', 'translation': { 'tr': 'C10.8 - Orofarinks overlapping lezyonu' }, 'value': 'C10.8' },
    { 'label': 'C10.9 - Orofarinks malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C10.9 - Orofarinks malign neoplazmı, tanımlanmamış' }, 'value': 'C10.9' },
    { 'label': 'C11 - Nazofarinks malign neoplazmı', 'translation': { 'tr': 'C11 - Nazofarinks malign neoplazmı' }, 'value': 'C11' },
    { 'label': 'C11.0 - Nazofarinks üst duvarında malign neoplazm', 'translation': { 'tr': 'C11.0 - Nazofarinks üst duvarında malign neoplazm' }, 'value': 'C11.0' },
    { 'label': 'C11.1 - Nazofarinks arka duvarında malign neoplazm', 'translation': { 'tr': 'C11.1 - Nazofarinks arka duvarında malign neoplazm' }, 'value': 'C11.1' },
    { 'label': 'C11.2 - Nazofarinks yan duvarında malign neoplazm', 'translation': { 'tr': 'C11.2 - Nazofarinks yan duvarında malign neoplazm' }, 'value': 'C11.2' },
    { 'label': 'C11.3 - Nazofarinks ön duvarında malign neoplazm', 'translation': { 'tr': 'C11.3 - Nazofarinks ön duvarında malign neoplazm' }, 'value': 'C11.3' },
    { 'label': 'C11.8 - Nazofarinks overlapping lezyonu', 'translation': { 'tr': 'C11.8 - Nazofarinks overlapping lezyonu' }, 'value': 'C11.8' },
    { 'label': 'C11.9 - Nazofarinks malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C11.9 - Nazofarinks malign neoplazmı, tanımlanmamış' }, 'value': 'C11.9' },
    { 'label': 'C12 - Priform sinüsün malign neoplazmı', 'translation': { 'tr': 'C12 - Priform sinüsün malign neoplazmı' }, 'value': 'C12' },
    { 'label': 'C13 - Hipofarinks malign neoplazmı', 'translation': { 'tr': 'C13 - Hipofarinks malign neoplazmı' }, 'value': 'C13' },
    { 'label': 'C13.0 - Postkrikoid bölgede malign neoplazm', 'translation': { 'tr': 'C13.0 - Postkrikoid bölgede malign neoplazm' }, 'value': 'C13.0' },
    { 'label': 'C13.1 - Ariepiglottik katlantı, hipofaringeal yüzde malign neoplazm', 'translation': { 'tr': 'C13.1 - Ariepiglottik katlantı, hipofaringeal yüzde malign neoplazm' }, 'value': 'C13.1' },
    { 'label': 'C13.2 - Hipofarinks arka duvarında malign neoplazm', 'translation': { 'tr': 'C13.2 - Hipofarinks arka duvarında malign neoplazm' }, 'value': 'C13.2' },
    { 'label': 'C13.8 - Hipofarinks overlapping lezyonu', 'translation': { 'tr': 'C13.8 - Hipofarinks overlapping lezyonu' }, 'value': 'C13.8' },
    { 'label': 'C13.9 - Hipofarinks malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C13.9 - Hipofarinks malign neoplazmı, tanımlanmamış' }, 'value': 'C13.9' },
    { 'label': 'C14 - Dudak, oral kavite ve farinksin malign neoplazmları, diğer ve', 'translation': { 'tr': 'C14 - Dudak, oral kavite ve farinksin malign neoplazmları, diğer ve' }, 'value': 'C14' },
    { 'label': 'C14.0 - Farinks malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C14.0 - Farinks malign neoplazmı, tanımlanmamış' }, 'value': 'C14.0' },
    { 'label': 'C14.1 - Laringofarinks malign neoplazmı', 'translation': { 'tr': 'C14.1 - Laringofarinks malign neoplazmı' }, 'value': 'C14.1' },
    { 'label': 'C14.2 - Waldeyer halkası malign neoplazmı', 'translation': { 'tr': 'C14.2 - Waldeyer halkası malign neoplazmı' }, 'value': 'C14.2' },
    { 'label': 'C14.8 - Dudak oral kavite ve farinksin overlapping lezyonu', 'translation': { 'tr': 'C14.8 - Dudak oral kavite ve farinksin overlapping lezyonu' }, 'value': 'C14.8' },
    { 'label': 'C15 - Özefagus malign neoplazmı', 'translation': { 'tr': 'C15 - Özefagus malign neoplazmı' }, 'value': 'C15' },
    { 'label': 'C15.0 - Özefagus malign neoplazmı, servikal kısmı', 'translation': { 'tr': 'C15.0 - Özefagus malign neoplazmı, servikal kısmı' }, 'value': 'C15.0' },
    { 'label': 'C15.1 - Özefagus malign neoplazmı, torasik kısmı', 'translation': { 'tr': 'C15.1 - Özefagus malign neoplazmı, torasik kısmı' }, 'value': 'C15.1' },
    { 'label': 'C15.2 - Özefagus malign neoplazmı, abdominal kısmı', 'translation': { 'tr': 'C15.2 - Özefagus malign neoplazmı, abdominal kısmı' }, 'value': 'C15.2' },
    { 'label': 'C15.3 - Özefagus malign neoplazmı, 1/3 üst kısmı', 'translation': { 'tr': 'C15.3 - Özefagus malign neoplazmı, 1/3 üst kısmı' }, 'value': 'C15.3' },
    { 'label': 'C15.4 - Özefagus malign neoplazmı, 1/3 orta kısmı', 'translation': { 'tr': 'C15.4 - Özefagus malign neoplazmı, 1/3 orta kısmı' }, 'value': 'C15.4' },
    { 'label': 'C15.5 - Özefagus malign neoplazmı, 1/3 alt kısmı', 'translation': { 'tr': 'C15.5 - Özefagus malign neoplazmı, 1/3 alt kısmı' }, 'value': 'C15.5' },
    { 'label': 'C15.8 - Özefagus overlapping lezyonu', 'translation': { 'tr': 'C15.8 - Özefagus overlapping lezyonu' }, 'value': 'C15.8' },
    { 'label': 'C15.9 - Özefagus malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C15.9 - Özefagus malign neoplazmı, tanımlanmamış' }, 'value': 'C15.9' },
    { 'label': 'C16 - Mide malign neoplazmı', 'translation': { 'tr': 'C16 - Mide malign neoplazmı' }, 'value': 'C16' },
    { 'label': 'C16.0 - Kardiada malign neoplazm', 'translation': { 'tr': 'C16.0 - Kardiada malign neoplazm' }, 'value': 'C16.0' },
    { 'label': 'C16.1 - Mide fundusunda malign neoplazm', 'translation': { 'tr': 'C16.1 - Mide fundusunda malign neoplazm' }, 'value': 'C16.1' },
    { 'label': 'C16.2 - Mide korpusunda malign neoplazm', 'translation': { 'tr': 'C16.2 - Mide korpusunda malign neoplazm' }, 'value': 'C16.2' },
    { 'label': 'C16.3 - Pilorik antrumda malign neoplazm', 'translation': { 'tr': 'C16.3 - Pilorik antrumda malign neoplazm' }, 'value': 'C16.3' },
    { 'label': 'C16.4 - Pilor malign neoplazmı', 'translation': { 'tr': 'C16.4 - Pilor malign neoplazmı' }, 'value': 'C16.4' },
    { 'label': 'C16.5 - Mide küçük kurvaturunda malign neoplazm, tanımlanmamış', 'translation': { 'tr': 'C16.5 - Mide küçük kurvaturunda malign neoplazm, tanımlanmamış' }, 'value': 'C16.5' },
    { 'label': 'C16.6 - Mide büyük kurvaturunda malign neoplazm, tanımlanmamış', 'translation': { 'tr': 'C16.6 - Mide büyük kurvaturunda malign neoplazm, tanımlanmamış' }, 'value': 'C16.6' },
    { 'label': 'C16.8 - Mide overlapping lezyonu', 'translation': { 'tr': 'C16.8 - Mide overlapping lezyonu' }, 'value': 'C16.8' },
    { 'label': 'C16.9 - Mide malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C16.9 - Mide malign neoplazmı, tanımlanmamış' }, 'value': 'C16.9' },
    { 'label': 'C17 - İnce bağırsak malign neoplazmı', 'translation': { 'tr': 'C17 - İnce bağırsak malign neoplazmı' }, 'value': 'C17' },
    { 'label': 'C17.0 - Duodenum malign neoplazmı', 'translation': { 'tr': 'C17.0 - Duodenum malign neoplazmı' }, 'value': 'C17.0' },
    { 'label': 'C17.1 - Jejunum malign neoplazmı', 'translation': { 'tr': 'C17.1 - Jejunum malign neoplazmı' }, 'value': 'C17.1' },
    { 'label': 'C17.2 - İleum malign neoplazmı', 'translation': { 'tr': 'C17.2 - İleum malign neoplazmı' }, 'value': 'C17.2' },
    { 'label': 'C17.3 - Meckel divertikülünde malign neoplazm', 'translation': { 'tr': 'C17.3 - Meckel divertikülünde malign neoplazm' }, 'value': 'C17.3' },
    { 'label': 'C17.8 - İnce barsak overlapping lezyonu', 'translation': { 'tr': 'C17.8 - İnce barsak overlapping lezyonu' }, 'value': 'C17.8' },
    { 'label': 'C17.9 - İnce barsak malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C17.9 - İnce barsak malign neoplazmı, tanımlanmamış' }, 'value': 'C17.9' },
    { 'label': 'C18 - Kolon malign neoplazmı', 'translation': { 'tr': 'C18 - Kolon malign neoplazmı' }, 'value': 'C18' },
    { 'label': 'C18.0 - Çekumda malign neoplazm', 'translation': { 'tr': 'C18.0 - Çekumda malign neoplazm' }, 'value': 'C18.0' },
    { 'label': 'C18.1 - Appendiksde malign neoplazm', 'translation': { 'tr': 'C18.1 - Appendiksde malign neoplazm' }, 'value': 'C18.1' },
    { 'label': 'C18.2 - Çıkan kolonda malign neoplazm', 'translation': { 'tr': 'C18.2 - Çıkan kolonda malign neoplazm' }, 'value': 'C18.2' },
    { 'label': 'C18.3 - Kolon hepatik fleksürde malign neoplazm', 'translation': { 'tr': 'C18.3 - Kolon hepatik fleksürde malign neoplazm' }, 'value': 'C18.3' },
    { 'label': 'C18.4 - Transvers kolonda malign neoplazm', 'translation': { 'tr': 'C18.4 - Transvers kolonda malign neoplazm' }, 'value': 'C18.4' },
    { 'label': 'C18.5 - Kolon splenik fleksürde malign neoplazm', 'translation': { 'tr': 'C18.5 - Kolon splenik fleksürde malign neoplazm' }, 'value': 'C18.5' },
    { 'label': 'C18.6 - İnen kolonda malign neoplazm', 'translation': { 'tr': 'C18.6 - İnen kolonda malign neoplazm' }, 'value': 'C18.6' },
    { 'label': 'C18.7 - Sigmoid kolonda malign neoplazm', 'translation': { 'tr': 'C18.7 - Sigmoid kolonda malign neoplazm' }, 'value': 'C18.7' },
    { 'label': 'C18.8 - Kolon overlapping lezyonu', 'translation': { 'tr': 'C18.8 - Kolon overlapping lezyonu' }, 'value': 'C18.8' },
    { 'label': 'C18.9 - Kolon malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C18.9 - Kolon malign neoplazmı, tanımlanmamış' }, 'value': 'C18.9' },
    { 'label': 'C19 - Rektosigmoid bileşim malign neoplazmı', 'translation': { 'tr': 'C19 - Rektosigmoid bileşim malign neoplazmı' }, 'value': 'C19' },
    { 'label': 'C20 - Rektum malign neoplazmı', 'translation': { 'tr': 'C20 - Rektum malign neoplazmı' }, 'value': 'C20' },
    { 'label': 'C21 - Anüs ve anal kanal malign neoplazmı', 'translation': { 'tr': 'C21 - Anüs ve anal kanal malign neoplazmı' }, 'value': 'C21' },
    { 'label': 'C21.0 - Anüs malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C21.0 - Anüs malign neoplazmı, tanımlanmamış' }, 'value': 'C21.0' },
    { 'label': 'C21.1 - Anal kanal malign neoplazmı', 'translation': { 'tr': 'C21.1 - Anal kanal malign neoplazmı' }, 'value': 'C21.1' },
    { 'label': 'C21.2 - Kloakojenik bölge malign neoplazmı', 'translation': { 'tr': 'C21.2 - Kloakojenik bölge malign neoplazmı' }, 'value': 'C21.2' },
    { 'label': 'C21.8 - Rektum, anüs ve anal kanalın overlapping lezyonu', 'translation': { 'tr': 'C21.8 - Rektum, anüs ve anal kanalın overlapping lezyonu' }, 'value': 'C21.8' },
    { 'label': 'C22 - Karaciğer ve intrahepatik safra yolları malign neoplazmı', 'translation': { 'tr': 'C22 - Karaciğer ve intrahepatik safra yolları malign neoplazmı' }, 'value': 'C22' },
    { 'label': 'C22.0 - Hepatoselüler karsinom', 'translation': { 'tr': 'C22.0 - Hepatoselüler karsinom' }, 'value': 'C22.0' },
    { 'label': 'C22.1 - İntrehepatik safra yolu karsinomu', 'translation': { 'tr': 'C22.1 - İntrehepatik safra yolu karsinomu' }, 'value': 'C22.1' },
    { 'label': 'C22.2 - Hepatoblastom', 'translation': { 'tr': 'C22.2 - Hepatoblastom' }, 'value': 'C22.2' },
    { 'label': 'C22.3 - Karaciğerin anjiosarkomu', 'translation': { 'tr': 'C22.3 - Karaciğerin anjiosarkomu' }, 'value': 'C22.3' },
    { 'label': 'C22.4 - Karaciğer sarkomu, diğer', 'translation': { 'tr': 'C22.4 - Karaciğer sarkomu, diğer' }, 'value': 'C22.4' },
    { 'label': 'C22.7 - Karaciğerin diğer tanımlanmış karsinomları', 'translation': { 'tr': 'C22.7 - Karaciğerin diğer tanımlanmış karsinomları' }, 'value': 'C22.7' },
    { 'label': 'C22.9 - Karaciğer malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C22.9 - Karaciğer malign neoplazmı, tanımlanmamış' }, 'value': 'C22.9' },
    { 'label': 'C23 - Safra kesesi malign neoplazmı', 'translation': { 'tr': 'C23 - Safra kesesi malign neoplazmı' }, 'value': 'C23' },
    { 'label': 'C24 - Safra kanalı malign neoplazmı, diğer ve tanımlanmamış bölgelerin', 'translation': { 'tr': 'C24 - Safra kanalı malign neoplazmı, diğer ve tanımlanmamış bölgelerin' }, 'value': 'C24' },
    { 'label': 'C24.0 - Ekstrahepatik safra yolu malign neoplazmı', 'translation': { 'tr': 'C24.0 - Ekstrahepatik safra yolu malign neoplazmı' }, 'value': 'C24.0' },
    { 'label': 'C24.1 - Ampulla vateride malign neoplazm', 'translation': { 'tr': 'C24.1 - Ampulla vateride malign neoplazm' }, 'value': 'C24.1' },
    { 'label': 'C24.8 - Safra yollarının overlapping lezyonu', 'translation': { 'tr': 'C24.8 - Safra yollarının overlapping lezyonu' }, 'value': 'C24.8' },
    { 'label': 'C24.9 - Safra yolu malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C24.9 - Safra yolu malign neoplazmı, tanımlanmamış' }, 'value': 'C24.9' },
    { 'label': 'C25 - Pankreas malign neoplazmı', 'translation': { 'tr': 'C25 - Pankreas malign neoplazmı' }, 'value': 'C25' },
    { 'label': 'C25.0 - Pankreas başı malign neoplazmı', 'translation': { 'tr': 'C25.0 - Pankreas başı malign neoplazmı' }, 'value': 'C25.0' },
    { 'label': 'C25.1 - Pankreas gövdesi malign neoplazmı', 'translation': { 'tr': 'C25.1 - Pankreas gövdesi malign neoplazmı' }, 'value': 'C25.1' },
    { 'label': 'C25.2 - Pankreas kuyruğu malign neoplazmı', 'translation': { 'tr': 'C25.2 - Pankreas kuyruğu malign neoplazmı' }, 'value': 'C25.2' },
    { 'label': 'C25.3 - Pankreatik kanal malign neoplazmı', 'translation': { 'tr': 'C25.3 - Pankreatik kanal malign neoplazmı' }, 'value': 'C25.3' },
    { 'label': 'C25.4 - Endokrin pankreas malign neoplazmı', 'translation': { 'tr': 'C25.4 - Endokrin pankreas malign neoplazmı' }, 'value': 'C25.4' },
    { 'label': 'C25.7 - Pankreasın diğer kısımlarının malign neoplazmı', 'translation': { 'tr': 'C25.7 - Pankreasın diğer kısımlarının malign neoplazmı' }, 'value': 'C25.7' },
    { 'label': 'C25.8 - Pankreas overlapping lezyonu', 'translation': { 'tr': 'C25.8 - Pankreas overlapping lezyonu' }, 'value': 'C25.8' },
    { 'label': 'C25.9 - Pankreas malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C25.9 - Pankreas malign neoplazmı, tanımlanmamış' }, 'value': 'C25.9' },
    { 'label': 'C26 - Sindirim organları malign neoplazmı, diğer ve sınırları belirsiz bölgelerin', 'translation': { 'tr': 'C26 - Sindirim organları malign neoplazmı, diğer ve sınırları belirsiz bölgelerin' }, 'value': 'C26' },
    { 'label': 'C26.0 - Barsak kanalı malign neoplazmı, tanımlanmamış kısım', 'translation': { 'tr': 'C26.0 - Barsak kanalı malign neoplazmı, tanımlanmamış kısım' }, 'value': 'C26.0' },
    { 'label': 'C26.1 - Dalak malign neoplazmı', 'translation': { 'tr': 'C26.1 - Dalak malign neoplazmı' }, 'value': 'C26.1' },
    { 'label': 'C26.8 - Sindirim sisteminin overlapping lezyonu', 'translation': { 'tr': 'C26.8 - Sindirim sisteminin overlapping lezyonu' }, 'value': 'C26.8' },
    { 'label': 'C26.9 - Sindirim sistemi içinde sınırları belirsiz yerlerin malign neoplazmı', 'translation': { 'tr': 'C26.9 - Sindirim sistemi içinde sınırları belirsiz yerlerin malign neoplazmı' }, 'value': 'C26.9' },
    { 'label': 'C30 - Burun boşluğu ve ortakulak malign neoplazmı', 'translation': { 'tr': 'C30 - Burun boşluğu ve ortakulak malign neoplazmı' }, 'value': 'C30' },
    { 'label': 'C30.0 - Burun boşluğunda malign neoplazm', 'translation': { 'tr': 'C30.0 - Burun boşluğunda malign neoplazm' }, 'value': 'C30.0' },
    { 'label': 'C30.1 - Ortakulakta malign neoplazm', 'translation': { 'tr': 'C30.1 - Ortakulakta malign neoplazm' }, 'value': 'C30.1' },
    { 'label': 'C31 - Aksesuar sinüslerin malign neoplazmı', 'translation': { 'tr': 'C31 - Aksesuar sinüslerin malign neoplazmı' }, 'value': 'C31' },
    { 'label': 'C31.0 - Maksiller sinüs malign neoplazmı', 'translation': { 'tr': 'C31.0 - Maksiller sinüs malign neoplazmı' }, 'value': 'C31.0' },
    { 'label': 'C31.1 - Etmoid sinüs malign neoplazmı', 'translation': { 'tr': 'C31.1 - Etmoid sinüs malign neoplazmı' }, 'value': 'C31.1' },
    { 'label': 'C31.2 - Frontal sinüs malign neoplazmı', 'translation': { 'tr': 'C31.2 - Frontal sinüs malign neoplazmı' }, 'value': 'C31.2' },
    { 'label': 'C31.3 - Sfenoid sinüs malign neoplazmı', 'translation': { 'tr': 'C31.3 - Sfenoid sinüs malign neoplazmı' }, 'value': 'C31.3' },
    { 'label': 'C31.8 - Aksesuar sinüs overlapping lezyonu', 'translation': { 'tr': 'C31.8 - Aksesuar sinüs overlapping lezyonu' }, 'value': 'C31.8' },
    { 'label': 'C31.9 - Aksesuar sinüs malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C31.9 - Aksesuar sinüs malign neoplazmı, tanımlanmamış' }, 'value': 'C31.9' },
    { 'label': 'C32 - Larinks malign neoplazmı', 'translation': { 'tr': 'C32 - Larinks malign neoplazmı' }, 'value': 'C32' },
    { 'label': 'C32.0 - Glottis malign neoplazmı', 'translation': { 'tr': 'C32.0 - Glottis malign neoplazmı' }, 'value': 'C32.0' },
    { 'label': 'C32.1 - Supraglottis malign neoplazmı', 'translation': { 'tr': 'C32.1 - Supraglottis malign neoplazmı' }, 'value': 'C32.1' },
    { 'label': 'C32.2 - Subglottis malign neoplazmı', 'translation': { 'tr': 'C32.2 - Subglottis malign neoplazmı' }, 'value': 'C32.2' },
    { 'label': 'C32.3 - Larinks kıkırdağı malign neoplazmı', 'translation': { 'tr': 'C32.3 - Larinks kıkırdağı malign neoplazmı' }, 'value': 'C32.3' },
    { 'label': 'C32.8 - Larinksin overlapping lezyonu', 'translation': { 'tr': 'C32.8 - Larinksin overlapping lezyonu' }, 'value': 'C32.8' },
    { 'label': 'C32.9 - Larinks malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C32.9 - Larinks malign neoplazmı, tanımlanmamış' }, 'value': 'C32.9' },
    { 'label': 'C33 - Trakea malign neoplazmı', 'translation': { 'tr': 'C33 - Trakea malign neoplazmı' }, 'value': 'C33' },
    { 'label': 'C34 - Bronş ve akciğer malign neoplazmı', 'translation': { 'tr': 'C34 - Bronş ve akciğer malign neoplazmı' }, 'value': 'C34' },
    { 'label': 'C34.0 - Ana bronş malign neoplazmı', 'translation': { 'tr': 'C34.0 - Ana bronş malign neoplazmı' }, 'value': 'C34.0' },
    { 'label': 'C34.1 - Bronş veya akciğer malign neoplazmı, üst lob', 'translation': { 'tr': 'C34.1 - Bronş veya akciğer malign neoplazmı, üst lob' }, 'value': 'C34.1' },
    { 'label': 'C34.2 - Bronş veya akciğer malign neoplazmı, orta lob', 'translation': { 'tr': 'C34.2 - Bronş veya akciğer malign neoplazmı, orta lob' }, 'value': 'C34.2' },
    { 'label': 'C34.3 - Bronş veya akciğer malign neoplazmı, alt lob', 'translation': { 'tr': 'C34.3 - Bronş veya akciğer malign neoplazmı, alt lob' }, 'value': 'C34.3' },
    { 'label': 'C34.8 - Bronş ve akciğer overlapping lezyonu', 'translation': { 'tr': 'C34.8 - Bronş ve akciğer overlapping lezyonu' }, 'value': 'C34.8' },
    { 'label': 'C34.9 - Bronş veya akciğer malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C34.9 - Bronş veya akciğer malign neoplazmı, tanımlanmamış' }, 'value': 'C34.9' },
    { 'label': 'C37 - Timus malign neoplazmı', 'translation': { 'tr': 'C37 - Timus malign neoplazmı' }, 'value': 'C37' },
    { 'label': 'C38 - Kalp, mediasten ve plevra malign neoplazmı', 'translation': { 'tr': 'C38 - Kalp, mediasten ve plevra malign neoplazmı' }, 'value': 'C38' },
    { 'label': 'C38.0 - Kalp malign neoplazmı', 'translation': { 'tr': 'C38.0 - Kalp malign neoplazmı' }, 'value': 'C38.0' },
    { 'label': 'C38.1 - Ön mediasten malign neoplazmı', 'translation': { 'tr': 'C38.1 - Ön mediasten malign neoplazmı' }, 'value': 'C38.1' },
    { 'label': 'C38.2 - Arka mediasten malign neoplazmı', 'translation': { 'tr': 'C38.2 - Arka mediasten malign neoplazmı' }, 'value': 'C38.2' },
    { 'label': 'C38.3 - Mediasten malign neoplazmı, tanımlanmamış kısım', 'translation': { 'tr': 'C38.3 - Mediasten malign neoplazmı, tanımlanmamış kısım' }, 'value': 'C38.3' },
    { 'label': 'C38.4 - Plevra malign neoplazmı', 'translation': { 'tr': 'C38.4 - Plevra malign neoplazmı' }, 'value': 'C38.4' },
    { 'label': 'C38.8 - Kalp, mediasten ve plevra overlapping lezyonu', 'translation': { 'tr': 'C38.8 - Kalp, mediasten ve plevra overlapping lezyonu' }, 'value': 'C38.8' },
    { 'label': 'C39 - Solunum sistemi ve toraks-içi organların malign neoplazmı,', 'translation': { 'tr': 'C39 - Solunum sistemi ve toraks-içi organların malign neoplazmı,' }, 'value': 'C39' },
    { 'label': 'C39.0 - Üst solunum yolu malign neoplazmı, tanımlanmamış kısım', 'translation': { 'tr': 'C39.0 - Üst solunum yolu malign neoplazmı, tanımlanmamış kısım' }, 'value': 'C39.0' },
    { 'label': 'C39.8 - Solunum organları ve intratorasik organların overlapping lezyonu', 'translation': { 'tr': 'C39.8 - Solunum organları ve intratorasik organların overlapping lezyonu' }, 'value': 'C39.8' },
    { 'label': 'C39.9 - Solunum sisteminde eksik tanımlanmış yerlerin malign neoplazmı', 'translation': { 'tr': 'C39.9 - Solunum sisteminde eksik tanımlanmış yerlerin malign neoplazmı' }, 'value': 'C39.9' },
    { 'label': 'C40 - Ekstremite kemik ve eklem kıkırdağının malign neoplazmı', 'translation': { 'tr': 'C40 - Ekstremite kemik ve eklem kıkırdağının malign neoplazmı' }, 'value': 'C40' },
    { 'label': 'C40.0 - Üst ekstremite uzun kemikleri ve skapula malign neoplazmı', 'translation': { 'tr': 'C40.0 - Üst ekstremite uzun kemikleri ve skapula malign neoplazmı' }, 'value': 'C40.0' },
    { 'label': 'C40.1 - Üst eksremitenin kısa kemiklerinin malign neoplazmı', 'translation': { 'tr': 'C40.1 - Üst eksremitenin kısa kemiklerinin malign neoplazmı' }, 'value': 'C40.1' },
    { 'label': 'C40.2 - Alt ekstremite uzun kemiklerinin malign neoplazmı', 'translation': { 'tr': 'C40.2 - Alt ekstremite uzun kemiklerinin malign neoplazmı' }, 'value': 'C40.2' },
    { 'label': 'C40.3 - Alt ekstremite kısa kemiklerinin malign neoplazmı', 'translation': { 'tr': 'C40.3 - Alt ekstremite kısa kemiklerinin malign neoplazmı' }, 'value': 'C40.3' },
    { 'label': 'C40.8 - Ekstremite kemik ve eklem kıkırdağı overlopping lezyonları', 'translation': { 'tr': 'C40.8 - Ekstremite kemik ve eklem kıkırdağı overlopping lezyonları' }, 'value': 'C40.8' },
    { 'label': 'C40.9 - Ekstremite kemik ve eklem kıkırdağı malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C40.9 - Ekstremite kemik ve eklem kıkırdağı malign neoplazmı, tanımlanmamış' }, 'value': 'C40.9' },
    { 'label': 'C41 - Kemik ve eklem kıkırdağının malign neoplazmı, diğer ve tanımlanmamış ', 'translation': { 'tr': 'C41 - Kemik ve eklem kıkırdağının malign neoplazmı, diğer ve tanımlanmamış ' }, 'value': 'C41' },
    { 'label': 'C41.0 - Kafa ve yüz kemikleri malign neoplazmı', 'translation': { 'tr': 'C41.0 - Kafa ve yüz kemikleri malign neoplazmı' }, 'value': 'C41.0' },
    { 'label': 'C41.1 - Mandibula malign neoplazmı', 'translation': { 'tr': 'C41.1 - Mandibula malign neoplazmı' }, 'value': 'C41.1' },
    { 'label': 'C41.2 - Vertebral kolon malign neoplazmı', 'translation': { 'tr': 'C41.2 - Vertebral kolon malign neoplazmı' }, 'value': 'C41.2' },
    { 'label': 'C41.3 - Kaburgalar, sternum ve klavikula malign neoplazmı', 'translation': { 'tr': 'C41.3 - Kaburgalar, sternum ve klavikula malign neoplazmı' }, 'value': 'C41.3' },
    { 'label': 'C41.4 - Pelvik kemikleri, sakrum ve koksiks malign neoplazmı', 'translation': { 'tr': 'C41.4 - Pelvik kemikleri, sakrum ve koksiks malign neoplazmı' }, 'value': 'C41.4' },
    { 'label': 'C41.8 - Kemik ve eklem kıkırdağının overlapping lezyonu', 'translation': { 'tr': 'C41.8 - Kemik ve eklem kıkırdağının overlapping lezyonu' }, 'value': 'C41.8' },
    { 'label': 'C41.9 - Kemik ve eklem kıkırdakları malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C41.9 - Kemik ve eklem kıkırdakları malign neoplazmı, tanımlanmamış' }, 'value': 'C41.9' },
    { 'label': 'C43 - Deri malign melanomu', 'translation': { 'tr': 'C43 - Deri malign melanomu' }, 'value': 'C43' },
    { 'label': 'C43.0 - Malign melanom, dudakta', 'translation': { 'tr': 'C43.0 - Malign melanom, dudakta' }, 'value': 'C43.0' },
    { 'label': 'C43.1 - Malign melanom, kantus dahil göz kapağında', 'translation': { 'tr': 'C43.1 - Malign melanom, kantus dahil göz kapağında' }, 'value': 'C43.1' },
    { 'label': 'C43.2 - Malign melanom, kulak ve dış kulak yolunda', 'translation': { 'tr': 'C43.2 - Malign melanom, kulak ve dış kulak yolunda' }, 'value': 'C43.2' },
    { 'label': 'C43.3 - Malign melanom, yüz diğer ve tanımlanmamış kısımlarının ', 'translation': { 'tr': 'C43.3 - Malign melanom, yüz diğer ve tanımlanmamış kısımlarının ' }, 'value': 'C43.3' },
    { 'label': 'C43.4 - Malign melanom, kafa derisi ve boyunda', 'translation': { 'tr': 'C43.4 - Malign melanom, kafa derisi ve boyunda' }, 'value': 'C43.4' },
    { 'label': 'C43.5 - Malign melanom, gövdede', 'translation': { 'tr': 'C43.5 - Malign melanom, gövdede' }, 'value': 'C43.5' },
    { 'label': 'C43.6 - Malign melanom, omuz dahil üst ekstremitede', 'translation': { 'tr': 'C43.6 - Malign melanom, omuz dahil üst ekstremitede' }, 'value': 'C43.6' },
    { 'label': 'C43.7 - Malign melanom, kalça dahil alt ekstremitede', 'translation': { 'tr': 'C43.7 - Malign melanom, kalça dahil alt ekstremitede' }, 'value': 'C43.7' },
    { 'label': 'C43.8 - Deri overlapping malign melanomu', 'translation': { 'tr': 'C43.8 - Deri overlapping malign melanomu' }, 'value': 'C43.8' },
    { 'label': 'C43.9 - Deri malign melanomu, tanımlanmamış', 'translation': { 'tr': 'C43.9 - Deri malign melanomu, tanımlanmamış' }, 'value': 'C43.9' },
    { 'label': 'C44 - Derinin diğer malign neoplazmları', 'translation': { 'tr': 'C44 - Derinin diğer malign neoplazmları' }, 'value': 'C44' },
    { 'label': 'C44.0 - Dudak derisi malign neoplazmı', 'translation': { 'tr': 'C44.0 - Dudak derisi malign neoplazmı' }, 'value': 'C44.0' },
    { 'label': 'C44.1 - Göz kapağı derisi malign neoplazmı, kantus dahil', 'translation': { 'tr': 'C44.1 - Göz kapağı derisi malign neoplazmı, kantus dahil' }, 'value': 'C44.1' },
    { 'label': 'C44.2 - Kulak ve kanal derisi malign neoplazmı', 'translation': { 'tr': 'C44.2 - Kulak ve kanal derisi malign neoplazmı' }, 'value': 'C44.2' },
    { 'label': 'C44.3 - Yüz derisi malign neoplazmı, diğer ve tanımlanmamış kısımlarının', 'translation': { 'tr': 'C44.3 - Yüz derisi malign neoplazmı, diğer ve tanımlanmamış kısımlarının' }, 'value': 'C44.3' },
    { 'label': 'C44.4 - Kafa ve boyun derisi malign neoplazmı', 'translation': { 'tr': 'C44.4 - Kafa ve boyun derisi malign neoplazmı' }, 'value': 'C44.4' },
    { 'label': 'C44.5 - Gövde derisi malign neoplazmı', 'translation': { 'tr': 'C44.5 - Gövde derisi malign neoplazmı' }, 'value': 'C44.5' },
    { 'label': 'C44.6 - Üst ekstremite derisi malign neoplazmı, omuz dahil', 'translation': { 'tr': 'C44.6 - Üst ekstremite derisi malign neoplazmı, omuz dahil' }, 'value': 'C44.6' },
    { 'label': 'C44.7 - Alt ekstremite derisi malign neoplazmı, kalça dahil', 'translation': { 'tr': 'C44.7 - Alt ekstremite derisi malign neoplazmı, kalça dahil' }, 'value': 'C44.7' },
    { 'label': 'C44.8 - Deri overlapping lezyonu', 'translation': { 'tr': 'C44.8 - Deri overlapping lezyonu' }, 'value': 'C44.8' },
    { 'label': 'C44.9 - Deri malign neoplazmı malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C44.9 - Deri malign neoplazmı malign neoplazmı, tanımlanmamış' }, 'value': 'C44.9' },
    { 'label': 'C45 - Mezotelyoma', 'translation': { 'tr': 'C45 - Mezotelyoma' }, 'value': 'C45' },
    { 'label': 'C45.0 - Plevra mezotelyoması', 'translation': { 'tr': 'C45.0 - Plevra mezotelyoması' }, 'value': 'C45.0' },
    { 'label': 'C45.1 - Periton mezotelyoması', 'translation': { 'tr': 'C45.1 - Periton mezotelyoması' }, 'value': 'C45.1' },
    { 'label': 'C45.2 - Perikard mezotelyoması', 'translation': { 'tr': 'C45.2 - Perikard mezotelyoması' }, 'value': 'C45.2' },
    { 'label': 'C45.7 - Mezotelyoma, diğer yerlerin', 'translation': { 'tr': 'C45.7 - Mezotelyoma, diğer yerlerin' }, 'value': 'C45.7' },
    { 'label': 'C45.9 - Mezotelyoma, tanımlanmamış', 'translation': { 'tr': 'C45.9 - Mezotelyoma, tanımlanmamış' }, 'value': 'C45.9' },
    { 'label': 'C46 - Kaposi sarkomu', 'translation': { 'tr': 'C46 - Kaposi sarkomu' }, 'value': 'C46' },
    { 'label': 'C46.0 - Kaposi sarkomu, deri', 'translation': { 'tr': 'C46.0 - Kaposi sarkomu, deri' }, 'value': 'C46.0' },
    { 'label': 'C46.1 - Kaposi sarkomu, yumuşak doku', 'translation': { 'tr': 'C46.1 - Kaposi sarkomu, yumuşak doku' }, 'value': 'C46.1' },
    { 'label': 'C46.2 - Kaposi sarkomu, damak', 'translation': { 'tr': 'C46.2 - Kaposi sarkomu, damak' }, 'value': 'C46.2' },
    { 'label': 'C46.3 - Kaposi sarkomu, lenf nodları', 'translation': { 'tr': 'C46.3 - Kaposi sarkomu, lenf nodları' }, 'value': 'C46.3' },
    { 'label': 'C46.7 - Kaposi sarkomu, diğer yerlerin', 'translation': { 'tr': 'C46.7 - Kaposi sarkomu, diğer yerlerin' }, 'value': 'C46.7' },
    { 'label': 'C46.8 - Kaposi sarkomu, birden fazla organın', 'translation': { 'tr': 'C46.8 - Kaposi sarkomu, birden fazla organın' }, 'value': 'C46.8' },
    { 'label': 'C46.9 - Kaposi sarkomu, tanımlanmamış', 'translation': { 'tr': 'C46.9 - Kaposi sarkomu, tanımlanmamış' }, 'value': 'C46.9' },
    { 'label': 'C47 - Periferik sinirler ve otonom sinir sistemi malign neoplazmı', 'translation': { 'tr': 'C47 - Periferik sinirler ve otonom sinir sistemi malign neoplazmı' }, 'value': 'C47' },
    { 'label': 'C47.0 - Periferik sinirlerin malign neoplazmı, baş, yüz ve boyunda', 'translation': { 'tr': 'C47.0 - Periferik sinirlerin malign neoplazmı, baş, yüz ve boyunda' }, 'value': 'C47.0' },
    { 'label': 'C47.1 - Üst ekstremite periferik sinirleri malign neoplazmı, omuz dahil', 'translation': { 'tr': 'C47.1 - Üst ekstremite periferik sinirleri malign neoplazmı, omuz dahil' }, 'value': 'C47.1' },
    { 'label': 'C47.2 - Alt ekstremite periferik sinirleri malign neoplazmı, kalça dahil', 'translation': { 'tr': 'C47.2 - Alt ekstremite periferik sinirleri malign neoplazmı, kalça dahil' }, 'value': 'C47.2' },
    { 'label': 'C47.3 - Toraks periferik sinirleri malign neoplazmı', 'translation': { 'tr': 'C47.3 - Toraks periferik sinirleri malign neoplazmı' }, 'value': 'C47.3' },
    { 'label': 'C47.4 - Karnın periferik sinirleri malign neoplazmı', 'translation': { 'tr': 'C47.4 - Karnın periferik sinirleri malign neoplazmı' }, 'value': 'C47.4' },
    { 'label': 'C47.5 - Pelvis periferik sinirleri malign neoplazmı', 'translation': { 'tr': 'C47.5 - Pelvis periferik sinirleri malign neoplazmı' }, 'value': 'C47.5' },
    { 'label': 'C47.6 - Gövde periferik sinirleri malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C47.6 - Gövde periferik sinirleri malign neoplazmı, tanımlanmamış' }, 'value': 'C47.6' },
    { 'label': 'C47.8 - Periferik sinirlerin ve otonom sinir sistemi overlapping lezyonu', 'translation': { 'tr': 'C47.8 - Periferik sinirlerin ve otonom sinir sistemi overlapping lezyonu' }, 'value': 'C47.8' },
    { 'label': 'C47.9 - Periferik sinirler ve otonom sinir sistemi malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C47.9 - Periferik sinirler ve otonom sinir sistemi malign neoplazmı, tanımlanmamış' }, 'value': 'C47.9' },
    { 'label': 'C48 - Retroperiton ve periton malign neoplazmları', 'translation': { 'tr': 'C48 - Retroperiton ve periton malign neoplazmları' }, 'value': 'C48' },
    { 'label': 'C48.0 - Retroperiton malign neoplazmı', 'translation': { 'tr': 'C48.0 - Retroperiton malign neoplazmı' }, 'value': 'C48.0' },
    { 'label': 'C48.1 - Peritonun tanımlanmış kısımları malign neoplazmı', 'translation': { 'tr': 'C48.1 - Peritonun tanımlanmış kısımları malign neoplazmı' }, 'value': 'C48.1' },
    { 'label': 'C48.2 - Periton malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C48.2 - Periton malign neoplazmı, tanımlanmamış' }, 'value': 'C48.2' },
    { 'label': 'C48.8 - Retroperiton ve periton overlapping lezyonları', 'translation': { 'tr': 'C48.8 - Retroperiton ve periton overlapping lezyonları' }, 'value': 'C48.8' },
    { 'label': 'C49 - Bağ dokusu ve yumuşak doku diğer malign neoplazmı', 'translation': { 'tr': 'C49 - Bağ dokusu ve yumuşak doku diğer malign neoplazmı' }, 'value': 'C49' },
    { 'label': 'C49.0 - Baş, yüz ve boyun bağ ve yumuşak dokusu', 'translation': { 'tr': 'C49.0 - Baş, yüz ve boyun bağ ve yumuşak dokusu' }, 'value': 'C49.0' },
    { 'label': 'C49.1 - Üst ekstremite bağ ve yumuşak dokusu malign neoplazmı, omuz dahil', 'translation': { 'tr': 'C49.1 - Üst ekstremite bağ ve yumuşak dokusu malign neoplazmı, omuz dahil' }, 'value': 'C49.1' },
    { 'label': 'C49.2 - Alt ekstremite bağ ve yumuşak dokusu malign neoplazmı, kalça dahil', 'translation': { 'tr': 'C49.2 - Alt ekstremite bağ ve yumuşak dokusu malign neoplazmı, kalça dahil' }, 'value': 'C49.2' },
    { 'label': 'C49.3 - Toraks bağ ve yumuşak dokusu malign neoplazmı', 'translation': { 'tr': 'C49.3 - Toraks bağ ve yumuşak dokusu malign neoplazmı' }, 'value': 'C49.3' },
    { 'label': 'C49.4 - Karın bağ ve yumuşak dokusu malign neoplazmı', 'translation': { 'tr': 'C49.4 - Karın bağ ve yumuşak dokusu malign neoplazmı' }, 'value': 'C49.4' },
    { 'label': 'C49.5 - Pelvis bağ ve yumuşak dokusu malign neoplazmı', 'translation': { 'tr': 'C49.5 - Pelvis bağ ve yumuşak dokusu malign neoplazmı' }, 'value': 'C49.5' },
    { 'label': 'C49.6 - Gövde bağ ve yumuşak dokusu, tanımlanmamış', 'translation': { 'tr': 'C49.6 - Gövde bağ ve yumuşak dokusu, tanımlanmamış' }, 'value': 'C49.6' },
    { 'label': 'C49.8 - Bağ dokusu ve yumuşak doku overlapping lezyonu', 'translation': { 'tr': 'C49.8 - Bağ dokusu ve yumuşak doku overlapping lezyonu' }, 'value': 'C49.8' },
    { 'label': 'C49.9 - Bağ ve yumuşak doku, tanımlanmamış', 'translation': { 'tr': 'C49.9 - Bağ ve yumuşak doku, tanımlanmamış' }, 'value': 'C49.9' },
    { 'label': 'C50 - Meme malign neoplazmı', 'translation': { 'tr': 'C50 - Meme malign neoplazmı' }, 'value': 'C50' },
    { 'label': 'C50.0 - Meme ucu ve areola malign neoplazmı', 'translation': { 'tr': 'C50.0 - Meme ucu ve areola malign neoplazmı' }, 'value': 'C50.0' },
    { 'label': 'C50.1 - Meme merkezi kısmı malign neoplazmı', 'translation': { 'tr': 'C50.1 - Meme merkezi kısmı malign neoplazmı' }, 'value': 'C50.1' },
    { 'label': 'C50.2 - Meme üst iç kadranı malign neoplazmı', 'translation': { 'tr': 'C50.2 - Meme üst iç kadranı malign neoplazmı' }, 'value': 'C50.2' },
    { 'label': 'C50.3 - Meme alt iç kadranı malign neoplazmı', 'translation': { 'tr': 'C50.3 - Meme alt iç kadranı malign neoplazmı' }, 'value': 'C50.3' },
    { 'label': 'C50.4 - Meme üst dış kadranı malign neoplazmı', 'translation': { 'tr': 'C50.4 - Meme üst dış kadranı malign neoplazmı' }, 'value': 'C50.4' },
    { 'label': 'C50.5 - Meme alt dış kadranı malign neoplazmı', 'translation': { 'tr': 'C50.5 - Meme alt dış kadranı malign neoplazmı' }, 'value': 'C50.5' },
    { 'label': 'C50.6 - Meme aksiller kuyruğu malign neoplazmı', 'translation': { 'tr': 'C50.6 - Meme aksiller kuyruğu malign neoplazmı' }, 'value': 'C50.6' },
    { 'label': 'C50.8 - Meme overlapping lezyonu', 'translation': { 'tr': 'C50.8 - Meme overlapping lezyonu' }, 'value': 'C50.8' },
    { 'label': 'C50.9 - Meme malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C50.9 - Meme malign neoplazmı, tanımlanmamış' }, 'value': 'C50.9' },
    { 'label': 'C51 - Vulva malign neoplazmı', 'translation': { 'tr': 'C51 - Vulva malign neoplazmı' }, 'value': 'C51' },
    { 'label': 'C51.0 - Labium majus malign neoplazmı', 'translation': { 'tr': 'C51.0 - Labium majus malign neoplazmı' }, 'value': 'C51.0' },
    { 'label': 'C51.1 - Labium minüs malign neoplazmı', 'translation': { 'tr': 'C51.1 - Labium minüs malign neoplazmı' }, 'value': 'C51.1' },
    { 'label': 'C51.2 - Klitoris malign neoplazmı', 'translation': { 'tr': 'C51.2 - Klitoris malign neoplazmı' }, 'value': 'C51.2' },
    { 'label': 'C51.8 - Vulva overlapping lezyonu', 'translation': { 'tr': 'C51.8 - Vulva overlapping lezyonu' }, 'value': 'C51.8' },
    { 'label': 'C51.9 - Vulva malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C51.9 - Vulva malign neoplazmı, tanımlanmamış' }, 'value': 'C51.9' },
    { 'label': 'C52 - Vajina malign neoplazmı', 'translation': { 'tr': 'C52 - Vajina malign neoplazmı' }, 'value': 'C52' },
    { 'label': 'C53 - Serviks uteri malign neoplazmı', 'translation': { 'tr': 'C53 - Serviks uteri malign neoplazmı' }, 'value': 'C53' },
    { 'label': 'C53.0 - Endoserviks malign neoplazmı', 'translation': { 'tr': 'C53.0 - Endoserviks malign neoplazmı' }, 'value': 'C53.0' },
    { 'label': 'C53.1 - Ekzoserviks malign neoplazmı', 'translation': { 'tr': 'C53.1 - Ekzoserviks malign neoplazmı' }, 'value': 'C53.1' },
    { 'label': 'C53.8 - Serviks uteri overlapping lezyonu', 'translation': { 'tr': 'C53.8 - Serviks uteri overlapping lezyonu' }, 'value': 'C53.8' },
    { 'label': 'C53.9 - Serviks uteri malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C53.9 - Serviks uteri malign neoplazmı, tanımlanmamış' }, 'value': 'C53.9' },
    { 'label': 'C54 - Korpus uteri malign neoplazmı', 'translation': { 'tr': 'C54 - Korpus uteri malign neoplazmı' }, 'value': 'C54' },
    { 'label': 'C54.0 - İstmus uteri malign neoplazmı', 'translation': { 'tr': 'C54.0 - İstmus uteri malign neoplazmı' }, 'value': 'C54.0' },
    { 'label': 'C54.1 - Endometrium malign neoplazmı', 'translation': { 'tr': 'C54.1 - Endometrium malign neoplazmı' }, 'value': 'C54.1' },
    { 'label': 'C54.2 - Miyometrium malign neoplazmı', 'translation': { 'tr': 'C54.2 - Miyometrium malign neoplazmı' }, 'value': 'C54.2' },
    { 'label': 'C54.3 - Fundus uteri malign neoplazmı', 'translation': { 'tr': 'C54.3 - Fundus uteri malign neoplazmı' }, 'value': 'C54.3' },
    { 'label': 'C54.8 - Korpus uteri overlapping lezyonu', 'translation': { 'tr': 'C54.8 - Korpus uteri overlapping lezyonu' }, 'value': 'C54.8' },
    { 'label': 'C54.9 - Korpus uteri malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C54.9 - Korpus uteri malign neoplazmı, tanımlanmamış' }, 'value': 'C54.9' },
    { 'label': 'C55 - Uterus malign neoplazmı, tanımlanmamış kısım', 'translation': { 'tr': 'C55 - Uterus malign neoplazmı, tanımlanmamış kısım' }, 'value': 'C55' },
    { 'label': 'C56 - Over malign neoplazmı', 'translation': { 'tr': 'C56 - Over malign neoplazmı' }, 'value': 'C56' },
    { 'label': 'C57 - Kadın genital organları malign neoplazmı, diğer ve tanımlanmamış', 'translation': { 'tr': 'C57 - Kadın genital organları malign neoplazmı, diğer ve tanımlanmamış' }, 'value': 'C57' },
    { 'label': 'C57.0 - Fallop tüpü malign neoplazmı', 'translation': { 'tr': 'C57.0 - Fallop tüpü malign neoplazmı' }, 'value': 'C57.0' },
    { 'label': 'C57.1 - Geniş ligament malign neoplazmı', 'translation': { 'tr': 'C57.1 - Geniş ligament malign neoplazmı' }, 'value': 'C57.1' },
    { 'label': 'C57.2 - Yuvarlak ligament malign neoplazmı', 'translation': { 'tr': 'C57.2 - Yuvarlak ligament malign neoplazmı' }, 'value': 'C57.2' },
    { 'label': 'C57.3 - Parametrium malign neoplazmı', 'translation': { 'tr': 'C57.3 - Parametrium malign neoplazmı' }, 'value': 'C57.3' },
    { 'label': 'C57.4 - Uterus adneksleri malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C57.4 - Uterus adneksleri malign neoplazmı, tanımlanmamış' }, 'value': 'C57.4' },
    { 'label': 'C57.7 - Kadın genital organları malign neoplazmı, diğer tanımlanmış', 'translation': { 'tr': 'C57.7 - Kadın genital organları malign neoplazmı, diğer tanımlanmış' }, 'value': 'C57.7' },
    { 'label': 'C57.8 - Kadın genital organlarının overlapping lezyonu', 'translation': { 'tr': 'C57.8 - Kadın genital organlarının overlapping lezyonu' }, 'value': 'C57.8' },
    { 'label': 'C57.9 - Kadın genital organı malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C57.9 - Kadın genital organı malign neoplazmı, tanımlanmamış' }, 'value': 'C57.9' },
    { 'label': 'C58 - Plasenta malign neoplazmı', 'translation': { 'tr': 'C58 - Plasenta malign neoplazmı' }, 'value': 'C58' },
    { 'label': 'C60 - Penis malign neoplazmı', 'translation': { 'tr': 'C60 - Penis malign neoplazmı' }, 'value': 'C60' },
    { 'label': 'C60.0 - Sünnet derisi malign neoplazmı', 'translation': { 'tr': 'C60.0 - Sünnet derisi malign neoplazmı' }, 'value': 'C60.0' },
    { 'label': 'C60.1 - Glans penis malign neoplazmı', 'translation': { 'tr': 'C60.1 - Glans penis malign neoplazmı' }, 'value': 'C60.1' },
    { 'label': 'C60.2 - Penis gövdesi malign neoplazmı', 'translation': { 'tr': 'C60.2 - Penis gövdesi malign neoplazmı' }, 'value': 'C60.2' },
    { 'label': 'C60.8 - Penis overlapping lezyonu', 'translation': { 'tr': 'C60.8 - Penis overlapping lezyonu' }, 'value': 'C60.8' },
    { 'label': 'C60.9 - Penis malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C60.9 - Penis malign neoplazmı, tanımlanmamış' }, 'value': 'C60.9' },
    { 'label': 'C61 - Prostat malign neoplazmı', 'translation': { 'tr': 'C61 - Prostat malign neoplazmı' }, 'value': 'C61' },
    { 'label': 'C62 - Testis malign neoplazmı', 'translation': { 'tr': 'C62 - Testis malign neoplazmı' }, 'value': 'C62' },
    { 'label': 'C62.0 - İnmemiş testis malign neoplazmı', 'translation': { 'tr': 'C62.0 - İnmemiş testis malign neoplazmı' }, 'value': 'C62.0' },
    { 'label': 'C62.1 - İnmiş testis malign neoplazmı', 'translation': { 'tr': 'C62.1 - İnmiş testis malign neoplazmı' }, 'value': 'C62.1' },
    { 'label': 'C62.9 - Testis malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C62.9 - Testis malign neoplazmı, tanımlanmamış' }, 'value': 'C62.9' },
    { 'label': 'C63 - Erkek genital organlarının malign neoplazmı, diğer ve tanımlanmamış', 'translation': { 'tr': 'C63 - Erkek genital organlarının malign neoplazmı, diğer ve tanımlanmamış' }, 'value': 'C63' },
    { 'label': 'C63.0 - Epididimis malign neoplazmı', 'translation': { 'tr': 'C63.0 - Epididimis malign neoplazmı' }, 'value': 'C63.0' },
    { 'label': 'C63.1 - Spermatik kord malign neoplazmı', 'translation': { 'tr': 'C63.1 - Spermatik kord malign neoplazmı' }, 'value': 'C63.1' },
    { 'label': 'C63.2 - Skrotum malign neoplazmı', 'translation': { 'tr': 'C63.2 - Skrotum malign neoplazmı' }, 'value': 'C63.2' },
    { 'label': 'C63.7 - Erkek genital organları malign neoplazmı, diğer tanımlanmış', 'translation': { 'tr': 'C63.7 - Erkek genital organları malign neoplazmı, diğer tanımlanmış' }, 'value': 'C63.7' },
    { 'label': 'C63.8 - Erkek genital organlarının overlapping lezyonu', 'translation': { 'tr': 'C63.8 - Erkek genital organlarının overlapping lezyonu' }, 'value': 'C63.8' },
    { 'label': 'C63.9 - Erkek genital organı malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C63.9 - Erkek genital organı malign neoplazmı, tanımlanmamış' }, 'value': 'C63.9' },
    { 'label': 'C64 - Böbrek malign neoplazmı, renal pelvis hariç', 'translation': { 'tr': 'C64 - Böbrek malign neoplazmı, renal pelvis hariç' }, 'value': 'C64' },
    { 'label': 'C65 - Renal pelvis malign neoplazmı', 'translation': { 'tr': 'C65 - Renal pelvis malign neoplazmı' }, 'value': 'C65' },
    { 'label': 'C66 - Üreter malign neoplazmı', 'translation': { 'tr': 'C66 - Üreter malign neoplazmı' }, 'value': 'C66' },
    { 'label': 'C67 - Mesane malign neoplazmı', 'translation': { 'tr': 'C67 - Mesane malign neoplazmı' }, 'value': 'C67' },
    { 'label': 'C67.0 - Mesane trigonu malign neoplazmı', 'translation': { 'tr': 'C67.0 - Mesane trigonu malign neoplazmı' }, 'value': 'C67.0' },
    { 'label': 'C67.1 - Mesane kubbesi malign neoplazmı', 'translation': { 'tr': 'C67.1 - Mesane kubbesi malign neoplazmı' }, 'value': 'C67.1' },
    { 'label': 'C67.2 - Mesane yan duvarı malign neoplazmı', 'translation': { 'tr': 'C67.2 - Mesane yan duvarı malign neoplazmı' }, 'value': 'C67.2' },
    { 'label': 'C67.3 - Mesane ön duvarı malign neoplazmı', 'translation': { 'tr': 'C67.3 - Mesane ön duvarı malign neoplazmı' }, 'value': 'C67.3' },
    { 'label': 'C67.4 - Mesane arka duvarı malign neoplazmı', 'translation': { 'tr': 'C67.4 - Mesane arka duvarı malign neoplazmı' }, 'value': 'C67.4' },
    { 'label': 'C67.5 - Mesane boynu malign neoplazmı', 'translation': { 'tr': 'C67.5 - Mesane boynu malign neoplazmı' }, 'value': 'C67.5' },
    { 'label': 'C67.6 - Üreterik orifis malign neoplazmı', 'translation': { 'tr': 'C67.6 - Üreterik orifis malign neoplazmı' }, 'value': 'C67.6' },
    { 'label': 'C67.7 - Urakus malign neoplazmı', 'translation': { 'tr': 'C67.7 - Urakus malign neoplazmı' }, 'value': 'C67.7' },
    { 'label': 'C67.8 - Mesane overlapping lezyonu', 'translation': { 'tr': 'C67.8 - Mesane overlapping lezyonu' }, 'value': 'C67.8' },
    { 'label': 'C67.9 - Mesane malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C67.9 - Mesane malign neoplazmı, tanımlanmamış' }, 'value': 'C67.9' },
    { 'label': 'C68 - İdrar yollarının malign neoplazmı, diğer ve tanımlanmamış', 'translation': { 'tr': 'C68 - İdrar yollarının malign neoplazmı, diğer ve tanımlanmamış' }, 'value': 'C68' },
    { 'label': 'C68.0 - Üretra malign neoplazmı', 'translation': { 'tr': 'C68.0 - Üretra malign neoplazmı' }, 'value': 'C68.0' },
    { 'label': 'C68.1 - Paraüretral bez malign neoplazmı', 'translation': { 'tr': 'C68.1 - Paraüretral bez malign neoplazmı' }, 'value': 'C68.1' },
    { 'label': 'C68.8 - Üriner organların overlapping lezyonu', 'translation': { 'tr': 'C68.8 - Üriner organların overlapping lezyonu' }, 'value': 'C68.8' },
    { 'label': 'C68.9 - Üriner organ malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C68.9 - Üriner organ malign neoplazmı, tanımlanmamış' }, 'value': 'C68.9' },
    { 'label': 'C69 - Göz ve adneks malign neoplazmı', 'translation': { 'tr': 'C69 - Göz ve adneks malign neoplazmı' }, 'value': 'C69' },
    { 'label': 'C69.0 - Konjonktiva malign neoplazmı', 'translation': { 'tr': 'C69.0 - Konjonktiva malign neoplazmı' }, 'value': 'C69.0' },
    { 'label': 'C69.1 - Kornea malign neoplazmı', 'translation': { 'tr': 'C69.1 - Kornea malign neoplazmı' }, 'value': 'C69.1' },
    { 'label': 'C69.2 - Retina malign neoplazmı', 'translation': { 'tr': 'C69.2 - Retina malign neoplazmı' }, 'value': 'C69.2' },
    { 'label': 'C69.3 - Koroid malign neoplazmı', 'translation': { 'tr': 'C69.3 - Koroid malign neoplazmı' }, 'value': 'C69.3' },
    { 'label': 'C69.4 - Siliyer cisim malign neoplazmı', 'translation': { 'tr': 'C69.4 - Siliyer cisim malign neoplazmı' }, 'value': 'C69.4' },
    { 'label': 'C69.5 - Lakrimal bez ve kanal malign neoplazmı', 'translation': { 'tr': 'C69.5 - Lakrimal bez ve kanal malign neoplazmı' }, 'value': 'C69.5' },
    { 'label': 'C69.6 - Orbita malign neoplazmı', 'translation': { 'tr': 'C69.6 - Orbita malign neoplazmı' }, 'value': 'C69.6' },
    { 'label': 'C69.8 - Göz ve adneks overlapping lezyonu', 'translation': { 'tr': 'C69.8 - Göz ve adneks overlapping lezyonu' }, 'value': 'C69.8' },
    { 'label': 'C69.9 - Göz malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C69.9 - Göz malign neoplazmı, tanımlanmamış' }, 'value': 'C69.9' },
    { 'label': 'C70 - Meninks malign neoplazmı', 'translation': { 'tr': 'C70 - Meninks malign neoplazmı' }, 'value': 'C70' },
    { 'label': 'C70.0 - Serebral meninksler malign neoplazmı', 'translation': { 'tr': 'C70.0 - Serebral meninksler malign neoplazmı' }, 'value': 'C70.0' },
    { 'label': 'C70.1 - Spinal meninksler malign neoplazmı', 'translation': { 'tr': 'C70.1 - Spinal meninksler malign neoplazmı' }, 'value': 'C70.1' },
    { 'label': 'C70.9 - Meninkslerin malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C70.9 - Meninkslerin malign neoplazmı, tanımlanmamış' }, 'value': 'C70.9' },
    { 'label': 'C71 - Beyin malign neoplazmı', 'translation': { 'tr': 'C71 - Beyin malign neoplazmı' }, 'value': 'C71' },
    { 'label': 'C71.0 - Beyin malign neoplazmı, loblar ve ventriküller hariç', 'translation': { 'tr': 'C71.0 - Beyin malign neoplazmı, loblar ve ventriküller hariç' }, 'value': 'C71.0' },
    { 'label': 'C71.1 - Frontal lob malign neoplazmı', 'translation': { 'tr': 'C71.1 - Frontal lob malign neoplazmı' }, 'value': 'C71.1' },
    { 'label': 'C71.2 - Temporal lob malign neoplazmı', 'translation': { 'tr': 'C71.2 - Temporal lob malign neoplazmı' }, 'value': 'C71.2' },
    { 'label': 'C71.3 - Paryetal lob malign neoplazmı', 'translation': { 'tr': 'C71.3 - Paryetal lob malign neoplazmı' }, 'value': 'C71.3' },
    { 'label': 'C71.4 - Oksipital lob malign neoplazmı', 'translation': { 'tr': 'C71.4 - Oksipital lob malign neoplazmı' }, 'value': 'C71.4' },
    { 'label': 'C71.5 - Serebral ventrikül malign neoplazmı', 'translation': { 'tr': 'C71.5 - Serebral ventrikül malign neoplazmı' }, 'value': 'C71.5' },
    { 'label': 'C71.6 - Beyincik malign neoplazmı', 'translation': { 'tr': 'C71.6 - Beyincik malign neoplazmı' }, 'value': 'C71.6' },
    { 'label': 'C71.7 - Beyin sapı malign neoplazmı', 'translation': { 'tr': 'C71.7 - Beyin sapı malign neoplazmı' }, 'value': 'C71.7' },
    { 'label': 'C71.8 - Beyin overlapping lezyonu', 'translation': { 'tr': 'C71.8 - Beyin overlapping lezyonu' }, 'value': 'C71.8' },
    { 'label': 'C71.9 - Beyin malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C71.9 - Beyin malign neoplazmı, tanımlanmamış' }, 'value': 'C71.9' },
    { 'label': 'C72 - Spinal kord, kranial sinirler ve merkezi sinir sisteminin diğer', 'translation': { 'tr': 'C72 - Spinal kord, kranial sinirler ve merkezi sinir sisteminin diğer' }, 'value': 'C72' },
    { 'label': 'C72.0 - Spinal kord malign neoplazmı', 'translation': { 'tr': 'C72.0 - Spinal kord malign neoplazmı' }, 'value': 'C72.0' },
    { 'label': 'C72.1 - Kauda equina malign neoplazmı', 'translation': { 'tr': 'C72.1 - Kauda equina malign neoplazmı' }, 'value': 'C72.1' },
    { 'label': 'C72.2 - Olfaktor sinir malign neoplazmı', 'translation': { 'tr': 'C72.2 - Olfaktor sinir malign neoplazmı' }, 'value': 'C72.2' },
    { 'label': 'C72.3 - Optik sinir malign neoplazmı', 'translation': { 'tr': 'C72.3 - Optik sinir malign neoplazmı' }, 'value': 'C72.3' },
    { 'label': 'C72.4 - Akustik sinir malign neoplazmı', 'translation': { 'tr': 'C72.4 - Akustik sinir malign neoplazmı' }, 'value': 'C72.4' },
    { 'label': 'C72.5 - Kranial sinirlerin malign neoplazmı, diğer ve tanımlanmamış', 'translation': { 'tr': 'C72.5 - Kranial sinirlerin malign neoplazmı, diğer ve tanımlanmamış' }, 'value': 'C72.5' },
    { 'label': 'C72.8 - Beynin ve merkezi sinir sisteminin overlapping lezyonu, diğer kısımlarının', 'translation': { 'tr': 'C72.8 - Beynin ve merkezi sinir sisteminin overlapping lezyonu, diğer kısımlarının' }, 'value': 'C72.8' },
    { 'label': 'C72.9 - Merkezi sinir sistemi malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C72.9 - Merkezi sinir sistemi malign neoplazmı, tanımlanmamış' }, 'value': 'C72.9' },
    { 'label': 'C73 - Tiroid bez malign neoplazmı', 'translation': { 'tr': 'C73 - Tiroid bez malign neoplazmı' }, 'value': 'C73' },
    { 'label': 'C74 - Adrenal bez malign neoplazmı', 'translation': { 'tr': 'C74 - Adrenal bez malign neoplazmı' }, 'value': 'C74' },
    { 'label': 'C74.0 - Adrenal bez korteksi malign neoplazmı', 'translation': { 'tr': 'C74.0 - Adrenal bez korteksi malign neoplazmı' }, 'value': 'C74.0' },
    { 'label': 'C74.1 - Adrenal bez medüllası malign neoplazmı', 'translation': { 'tr': 'C74.1 - Adrenal bez medüllası malign neoplazmı' }, 'value': 'C74.1' },
    { 'label': 'C74.9 - Adrenal bez malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C74.9 - Adrenal bez malign neoplazmı, tanımlanmamış' }, 'value': 'C74.9' },
    { 'label': 'C75 - Endokrin bez ve ilgili yapıların diğer malign neoplazmı', 'translation': { 'tr': 'C75 - Endokrin bez ve ilgili yapıların diğer malign neoplazmı' }, 'value': 'C75' },
    { 'label': 'C75.0 - Paratiroid bez malign neoplazmı', 'translation': { 'tr': 'C75.0 - Paratiroid bez malign neoplazmı' }, 'value': 'C75.0' },
    { 'label': 'C75.1 - Hipofiz bezi malign neoplazmı', 'translation': { 'tr': 'C75.1 - Hipofiz bezi malign neoplazmı' }, 'value': 'C75.1' },
    { 'label': 'C75.2 - Kraniofaringeal dukt malign neoplazmı', 'translation': { 'tr': 'C75.2 - Kraniofaringeal dukt malign neoplazmı' }, 'value': 'C75.2' },
    { 'label': 'C75.3 - Pineal bez malign neoplazmı', 'translation': { 'tr': 'C75.3 - Pineal bez malign neoplazmı' }, 'value': 'C75.3' },
    { 'label': 'C75.4 - Karotid cisim malign neoplazmı', 'translation': { 'tr': 'C75.4 - Karotid cisim malign neoplazmı' }, 'value': 'C75.4' },
    { 'label': 'C75.5 - Aortik cisim ve diğer paraganlionlar malign neoplazmı', 'translation': { 'tr': 'C75.5 - Aortik cisim ve diğer paraganlionlar malign neoplazmı' }, 'value': 'C75.5' },
    { 'label': 'C75.8 - Birden fazla bez tutulumlu malign neoplazm, tanımlanmamış', 'translation': { 'tr': 'C75.8 - Birden fazla bez tutulumlu malign neoplazm, tanımlanmamış' }, 'value': 'C75.8' },
    { 'label': 'C75.9 - Endokrin bez malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C75.9 - Endokrin bez malign neoplazmı, tanımlanmamış' }, 'value': 'C75.9' },
    { 'label': 'C76 - Malign neoplazm, diğer ve sınırları belirsiz yerlerin', 'translation': { 'tr': 'C76 - Malign neoplazm, diğer ve sınırları belirsiz yerlerin' }, 'value': 'C76' },
    { 'label': 'C76.0 - Baş, yüz ve boyun malign neoplazmı', 'translation': { 'tr': 'C76.0 - Baş, yüz ve boyun malign neoplazmı' }, 'value': 'C76.0' },
    { 'label': 'C76.1 - Toraks malign neoplazmı', 'translation': { 'tr': 'C76.1 - Toraks malign neoplazmı' }, 'value': 'C76.1' },
    { 'label': 'C76.2 - Karın malign neoplazmı', 'translation': { 'tr': 'C76.2 - Karın malign neoplazmı' }, 'value': 'C76.2' },
    { 'label': 'C76.3 - Pelvis malign neoplazmı', 'translation': { 'tr': 'C76.3 - Pelvis malign neoplazmı' }, 'value': 'C76.3' },
    { 'label': 'C76.4 - Üst ekstremite malign neoplazmı', 'translation': { 'tr': 'C76.4 - Üst ekstremite malign neoplazmı' }, 'value': 'C76.4' },
    { 'label': 'C76.5 - Alt ekstremite malign neoplazmı', 'translation': { 'tr': 'C76.5 - Alt ekstremite malign neoplazmı' }, 'value': 'C76.5' },
    { 'label': 'C76.7 - Sınırları belirsiz yerlerin malign neoplazmı, diğer', 'translation': { 'tr': 'C76.7 - Sınırları belirsiz yerlerin malign neoplazmı, diğer' }, 'value': 'C76.7' },
    { 'label': 'C76.8 - Overlapping lezyonu diğer ve sınırları belirsiz yerlerin', 'translation': { 'tr': 'C76.8 - Overlapping lezyonu diğer ve sınırları belirsiz yerlerin' }, 'value': 'C76.8' },
    { 'label': 'C77 - Lenf nodlarının malign neoplazmı, sekonder ve tanımlanmamış', 'translation': { 'tr': 'C77 - Lenf nodlarının malign neoplazmı, sekonder ve tanımlanmamış' }, 'value': 'C77' },
    { 'label': 'C77.0 - Baş, yüz ve boyun lenf nodları sekonder malign neoplazmı', 'translation': { 'tr': 'C77.0 - Baş, yüz ve boyun lenf nodları sekonder malign neoplazmı' }, 'value': 'C77.0' },
    { 'label': 'C77.1 - Toraks-içi lenf nodları sekonder malign neoplazmı', 'translation': { 'tr': 'C77.1 - Toraks-içi lenf nodları sekonder malign neoplazmı' }, 'value': 'C77.1' },
    { 'label': 'C77.2 - Karın-içi lenf nodları sekonder malign neoplazmı', 'translation': { 'tr': 'C77.2 - Karın-içi lenf nodları sekonder malign neoplazmı' }, 'value': 'C77.2' },
    { 'label': 'C77.3 - Aksiller ve kol lenf nodları sekonder malign neoplazmı', 'translation': { 'tr': 'C77.3 - Aksiller ve kol lenf nodları sekonder malign neoplazmı' }, 'value': 'C77.3' },
    { 'label': 'C77.4 - İnguinal ve bacak lenf nodları sekonder malign neoplazmı', 'translation': { 'tr': 'C77.4 - İnguinal ve bacak lenf nodları sekonder malign neoplazmı' }, 'value': 'C77.4' },
    { 'label': 'C77.5 - İntrapelvik lenf nodları sekonder malign neoplazmı', 'translation': { 'tr': 'C77.5 - İntrapelvik lenf nodları sekonder malign neoplazmı' }, 'value': 'C77.5' },
    { 'label': 'C77.8 - Birden fazla organların lenf nodlarının sekonder malign neoplazmı', 'translation': { 'tr': 'C77.8 - Birden fazla organların lenf nodlarının sekonder malign neoplazmı' }, 'value': 'C77.8' },
    { 'label': 'C77.9 - Lenf nodu sekonder malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C77.9 - Lenf nodu sekonder malign neoplazmı, tanımlanmamış' }, 'value': 'C77.9' },
    { 'label': 'C78 - Solunum ve sindirim organlarının sekonder malign neoplazmı', 'translation': { 'tr': 'C78 - Solunum ve sindirim organlarının sekonder malign neoplazmı' }, 'value': 'C78' },
    { 'label': 'C78.0 - Akciğer sekonder malign neoplazmı', 'translation': { 'tr': 'C78.0 - Akciğer sekonder malign neoplazmı' }, 'value': 'C78.0' },
    { 'label': 'C78.1 - Mediasten sekonder malign neoplazmı', 'translation': { 'tr': 'C78.1 - Mediasten sekonder malign neoplazmı' }, 'value': 'C78.1' },
    { 'label': 'C78.2 - Plevra sekonder malign neoplazmı', 'translation': { 'tr': 'C78.2 - Plevra sekonder malign neoplazmı' }, 'value': 'C78.2' },
    { 'label': 'C78.3 - Solunum organlarının sekonder malign neoplazmı, diğer ve tanımlanmamış', 'translation': { 'tr': 'C78.3 - Solunum organlarının sekonder malign neoplazmı, diğer ve tanımlanmamış' }, 'value': 'C78.3' },
    { 'label': 'C78.4 - İnce bağırsak sekonder malign neoplazmı', 'translation': { 'tr': 'C78.4 - İnce bağırsak sekonder malign neoplazmı' }, 'value': 'C78.4' },
    { 'label': 'C78.5 - Kalın bağırsak ve rektum sekonder malign neoplazmı', 'translation': { 'tr': 'C78.5 - Kalın bağırsak ve rektum sekonder malign neoplazmı' }, 'value': 'C78.5' },
    { 'label': 'C78.6 - Retroperiton ve periton sekonder malign neoplazmı', 'translation': { 'tr': 'C78.6 - Retroperiton ve periton sekonder malign neoplazmı' }, 'value': 'C78.6' },
    { 'label': 'C78.7 - Karaciğer sekonder malign neoplazmı', 'translation': { 'tr': 'C78.7 - Karaciğer sekonder malign neoplazmı' }, 'value': 'C78.7' },
    { 'label': 'C78.8 - Sindirim organlarının sekonder malign neoplazmı, diğer ve tanımlanmamış', 'translation': { 'tr': 'C78.8 - Sindirim organlarının sekonder malign neoplazmı, diğer ve tanımlanmamış' }, 'value': 'C78.8' },
    { 'label': 'C79 - Sekonder malign neoplazmı, diğer yerlerin', 'translation': { 'tr': 'C79 - Sekonder malign neoplazmı, diğer yerlerin' }, 'value': 'C79' },
    { 'label': 'C79.0 - Böbrek ve renal pelvis sekonder malign neoplazmı', 'translation': { 'tr': 'C79.0 - Böbrek ve renal pelvis sekonder malign neoplazmı' }, 'value': 'C79.0' },
    { 'label': 'C79.1 - Mesane ve diğer ve tanımlanmamış üriner organların sekonder malign neoplazmı', 'translation': { 'tr': 'C79.1 - Mesane ve diğer ve tanımlanmamış üriner organların sekonder malign neoplazmı' }, 'value': 'C79.1' },
    { 'label': 'C79.2 - Deri sekonder malign neoplazmı', 'translation': { 'tr': 'C79.2 - Deri sekonder malign neoplazmı' }, 'value': 'C79.2' },
    { 'label': 'C79.3 - Beyin ve serebral meninkslerin sekonder malign neoplazmı', 'translation': { 'tr': 'C79.3 - Beyin ve serebral meninkslerin sekonder malign neoplazmı' }, 'value': 'C79.3' },
    { 'label': 'C79.4 - Sinir sistemi diğer ve tanımlanmamış bölümlerinin sekonder malign neoplazmı', 'translation': { 'tr': 'C79.4 - Sinir sistemi diğer ve tanımlanmamış bölümlerinin sekonder malign neoplazmı' }, 'value': 'C79.4' },
    { 'label': 'C79.5 - Kemik ve kemik iliği sekonder malign neoplazmı', 'translation': { 'tr': 'C79.5 - Kemik ve kemik iliği sekonder malign neoplazmı' }, 'value': 'C79.5' },
    { 'label': 'C79.6 - Over sekonder malign neoplazmı', 'translation': { 'tr': 'C79.6 - Over sekonder malign neoplazmı' }, 'value': 'C79.6' },
    { 'label': 'C79.7 - Adrenal bez sekonder malign neoplazmı', 'translation': { 'tr': 'C79.7 - Adrenal bez sekonder malign neoplazmı' }, 'value': 'C79.7' },
    { 'label': 'C79.8 - Sekonder malign neoplazm, diğer tanımlanmış yerlerin', 'translation': { 'tr': 'C79.8 - Sekonder malign neoplazm, diğer tanımlanmış yerlerin' }, 'value': 'C79.8' },
    { 'label': 'C80 - Malign neoplazm, bölge belirtilmemiş', 'translation': { 'tr': 'C80 - Malign neoplazm, bölge belirtilmemiş' }, 'value': 'C80' },
    { 'label': 'C81 - Hodgkin hastalığı', 'translation': { 'tr': 'C81 - Hodgkin hastalığı' }, 'value': 'C81' },
    { 'label': 'C81.0 - Hodgkin hastalığı, lenfosit ağırlıklı', 'translation': { 'tr': 'C81.0 - Hodgkin hastalığı, lenfosit ağırlıklı' }, 'value': 'C81.0' },
    { 'label': 'C81.1 - Hodgkin hastalığı, nodüler skleroz', 'translation': { 'tr': 'C81.1 - Hodgkin hastalığı, nodüler skleroz' }, 'value': 'C81.1' },
    { 'label': 'C81.2 - Hodgkin hastalığı, karma hücreli', 'translation': { 'tr': 'C81.2 - Hodgkin hastalığı, karma hücreli' }, 'value': 'C81.2' },
    { 'label': 'C81.3 - Hodgkin hastalığı, lenfositten fakir', 'translation': { 'tr': 'C81.3 - Hodgkin hastalığı, lenfositten fakir' }, 'value': 'C81.3' },
    { 'label': 'C81.7 - Hodgkin hastalığı, diğer', 'translation': { 'tr': 'C81.7 - Hodgkin hastalığı, diğer' }, 'value': 'C81.7' },
    { 'label': 'C81.9 - Hodgkin hastalığı, tanımlanmamış', 'translation': { 'tr': 'C81.9 - Hodgkin hastalığı, tanımlanmamış' }, 'value': 'C81.9' },
    { 'label': 'C82 - Folliküler [nodüler] non-Hodgkin lenfoma', 'translation': { 'tr': 'C82 - Folliküler [nodüler] non-Hodgkin lenfoma' }, 'value': 'C82' },
    { 'label': 'C82.0 - Folliküler non-Hodgkin lenfoma, küçük yarık hücreli, folliküler', 'translation': { 'tr': 'C82.0 - Folliküler non-Hodgkin lenfoma, küçük yarık hücreli, folliküler' }, 'value': 'C82.0' },
    { 'label': 'C82.1 - Folliküler non-Hodgkin lenfoma, karma küçük yarık ve geniş hücreli, folliküler', 'translation': { 'tr': 'C82.1 - Folliküler non-Hodgkin lenfoma, karma küçük yarık ve geniş hücreli, folliküler' }, 'value': 'C82.1' },
    { 'label': 'C82.2 - Folliküler non-Hodgkin lenfoma, büyük hücreli, folliküler', 'translation': { 'tr': 'C82.2 - Folliküler non-Hodgkin lenfoma, büyük hücreli, folliküler' }, 'value': 'C82.2' },
    { 'label': 'C82.7 - Folliküler non-Hodgkin lenfoma diğer tipleri', 'translation': { 'tr': 'C82.7 - Folliküler non-Hodgkin lenfoma diğer tipleri' }, 'value': 'C82.7' },
    { 'label': 'C82.9 - Folliküler non-Hodgkin lenfoma, tanımlanmamış', 'translation': { 'tr': 'C82.9 - Folliküler non-Hodgkin lenfoma, tanımlanmamış' }, 'value': 'C82.9' },
    { 'label': 'C83 - Yaygın non-Hodgkin lenfoma', 'translation': { 'tr': 'C83 - Yaygın non-Hodgkin lenfoma' }, 'value': 'C83' },
    { 'label': 'C83.0 - Yaygın non-Hodgkin lenfoma, küçük hücreli (yaygın)', 'translation': { 'tr': 'C83.0 - Yaygın non-Hodgkin lenfoma, küçük hücreli (yaygın)' }, 'value': 'C83.0' },
    { 'label': 'C83.1 - Yaygın non-Hodgkin lenfoma, küçük yarık hücreli (yaygın)', 'translation': { 'tr': 'C83.1 - Yaygın non-Hodgkin lenfoma, küçük yarık hücreli (yaygın)' }, 'value': 'C83.1' },
    { 'label': 'C83.2 - Yaygın non-Hodgkin lenfoma, karma küçük ve büyük hücreli (yaygın)', 'translation': { 'tr': 'C83.2 - Yaygın non-Hodgkin lenfoma, karma küçük ve büyük hücreli (yaygın)' }, 'value': 'C83.2' },
    { 'label': 'C83.3 - Yaygın non-Hodgkin lenfoma, büyük hücreli (yaygın)', 'translation': { 'tr': 'C83.3 - Yaygın non-Hodgkin lenfoma, büyük hücreli (yaygın)' }, 'value': 'C83.3' },
    { 'label': 'C83.4 - Yaygın non-Hodgkin lenfoma, immünoblastik (yaygın)', 'translation': { 'tr': 'C83.4 - Yaygın non-Hodgkin lenfoma, immünoblastik (yaygın)' }, 'value': 'C83.4' },
    { 'label': 'C83.5 - Yaygın non-Hodgkin lenfoma, lenfoblastik (yaygın)', 'translation': { 'tr': 'C83.5 - Yaygın non-Hodgkin lenfoma, lenfoblastik (yaygın)' }, 'value': 'C83.5' },
    { 'label': 'C83.6 - Yaygın non-Hodgkin lenfoma, indiferensiye (yaygın)', 'translation': { 'tr': 'C83.6 - Yaygın non-Hodgkin lenfoma, indiferensiye (yaygın)' }, 'value': 'C83.6' },
    { 'label': 'C83.7 - Burkitt tümörü', 'translation': { 'tr': 'C83.7 - Burkitt tümörü' }, 'value': 'C83.7' },
    { 'label': 'C83.8 - Yaygın non-Hodgkin lenfoma, diğer', 'translation': { 'tr': 'C83.8 - Yaygın non-Hodgkin lenfoma, diğer' }, 'value': 'C83.8' },
    { 'label': 'C83.9 - Yaygın non-Hodgkin lenfoma, tanımlanmamış', 'translation': { 'tr': 'C83.9 - Yaygın non-Hodgkin lenfoma, tanımlanmamış' }, 'value': 'C83.9' },
    { 'label': 'C84 - Periferal ve kutanöz T- hücreli lenfomalar', 'translation': { 'tr': 'C84 - Periferal ve kutanöz T- hücreli lenfomalar' }, 'value': 'C84' },
    { 'label': 'C84.0 - Mikozis fungoides', 'translation': { 'tr': 'C84.0 - Mikozis fungoides' }, 'value': 'C84.0' },
    { 'label': 'C84.1 - Sézary hastalığı', 'translation': { 'tr': 'C84.1 - Sézary hastalığı' }, 'value': 'C84.1' },
    { 'label': 'C84.2 - T-bölge lenfoması', 'translation': { 'tr': 'C84.2 - T-bölge lenfoması' }, 'value': 'C84.2' },
    { 'label': 'C84.3 - Lenfoepiteloid lenfoma', 'translation': { 'tr': 'C84.3 - Lenfoepiteloid lenfoma' }, 'value': 'C84.3' },
    { 'label': 'C84.4 - Periferal T-hücreli lenfoma', 'translation': { 'tr': 'C84.4 - Periferal T-hücreli lenfoma' }, 'value': 'C84.4' },
    { 'label': 'C84.5 - T-hücreli lenfomalar, diğer ve tanımlanmamış', 'translation': { 'tr': 'C84.5 - T-hücreli lenfomalar, diğer ve tanımlanmamış' }, 'value': 'C84.5' },
    { 'label': 'C85 - Non-Hodgkin lenfoma, diğer ve tanımlanmamış tipler', 'translation': { 'tr': 'C85 - Non-Hodgkin lenfoma, diğer ve tanımlanmamış tipler' }, 'value': 'C85' },
    { 'label': 'C85.0 - Lenfosarkom', 'translation': { 'tr': 'C85.0 - Lenfosarkom' }, 'value': 'C85.0' },
    { 'label': 'C85.1 - B-hücreli lenfoma, tanımlanmamış', 'translation': { 'tr': 'C85.1 - B-hücreli lenfoma, tanımlanmamış' }, 'value': 'C85.1' },
    { 'label': 'C85.7 - Non-Hodgkin lenfomanın, diğer tanımlanmış tipler', 'translation': { 'tr': 'C85.7 - Non-Hodgkin lenfomanın, diğer tanımlanmış tipler' }, 'value': 'C85.7' },
    { 'label': 'C85.9 - Non-Hodgkin lenfoma, tanımlanmamış tip', 'translation': { 'tr': 'C85.9 - Non-Hodgkin lenfoma, tanımlanmamış tip' }, 'value': 'C85.9' },
    { 'label': 'C88 - Malign immünoproliferatif hastalıklar', 'translation': { 'tr': 'C88 - Malign immünoproliferatif hastalıklar' }, 'value': 'C88' },
    { 'label': 'C88.0 - Waldenström makroglobulinemi', 'translation': { 'tr': 'C88.0 - Waldenström makroglobulinemi' }, 'value': 'C88.0' },
    { 'label': 'C88.1 - Alfa ağır zincir hastalığı', 'translation': { 'tr': 'C88.1 - Alfa ağır zincir hastalığı' }, 'value': 'C88.1' },
    { 'label': 'C88.2 - Gama ağır zincir hastalığı', 'translation': { 'tr': 'C88.2 - Gama ağır zincir hastalığı' }, 'value': 'C88.2' },
    { 'label': 'C88.3 - İmmünoproliferatif ince bağırsak hastalığı', 'translation': { 'tr': 'C88.3 - İmmünoproliferatif ince bağırsak hastalığı' }, 'value': 'C88.3' },
    { 'label': 'C88.7 - Malign immünoproliferatif hastalıklar, diğer', 'translation': { 'tr': 'C88.7 - Malign immünoproliferatif hastalıklar, diğer' }, 'value': 'C88.7' },
    { 'label': 'C88.9 - Malign immünoproliferatif hastalık, tanımlanmamış', 'translation': { 'tr': 'C88.9 - Malign immünoproliferatif hastalık, tanımlanmamış' }, 'value': 'C88.9' },
    { 'label': 'C90 - Multipl miyelom ve malign plazma hücreli neoplazmlar', 'translation': { 'tr': 'C90 - Multipl miyelom ve malign plazma hücreli neoplazmlar' }, 'value': 'C90' },
    { 'label': 'C90.0 - Multipl miyelom', 'translation': { 'tr': 'C90.0 - Multipl miyelom' }, 'value': 'C90.0' },
    { 'label': 'C90.1 - Plazma hücreli lösemi', 'translation': { 'tr': 'C90.1 - Plazma hücreli lösemi' }, 'value': 'C90.1' },
    { 'label': 'C90.2 - Plazmositom, ekstramedüller', 'translation': { 'tr': 'C90.2 - Plazmositom, ekstramedüller' }, 'value': 'C90.2' },
    { 'label': 'C91 - Lenfoid lösemi', 'translation': { 'tr': 'C91 - Lenfoid lösemi' }, 'value': 'C91' },
    { 'label': 'C91.0 - Akut lenfoblastik lösemi', 'translation': { 'tr': 'C91.0 - Akut lenfoblastik lösemi' }, 'value': 'C91.0' },
    { 'label': 'C91.1 - Kronik lenfositik lösemi', 'translation': { 'tr': 'C91.1 - Kronik lenfositik lösemi' }, 'value': 'C91.1' },
    { 'label': 'C91.2 - Subakut lenfositik lösemi', 'translation': { 'tr': 'C91.2 - Subakut lenfositik lösemi' }, 'value': 'C91.2' },
    { 'label': 'C91.3 - Prolenfositik lösemi', 'translation': { 'tr': 'C91.3 - Prolenfositik lösemi' }, 'value': 'C91.3' },
    { 'label': 'C91.4 - Saçlı hücreli lösemi', 'translation': { 'tr': 'C91.4 - Saçlı hücreli lösemi' }, 'value': 'C91.4' },
    { 'label': 'C91.5 - Yetişkin T-hücreli lösemi', 'translation': { 'tr': 'C91.5 - Yetişkin T-hücreli lösemi' }, 'value': 'C91.5' },
    { 'label': 'C91.7 - Lenfoid lösemiler, diğer', 'translation': { 'tr': 'C91.7 - Lenfoid lösemiler, diğer' }, 'value': 'C91.7' },
    { 'label': 'C91.9 - Lenfoid lösemi, tanımlanmamış', 'translation': { 'tr': 'C91.9 - Lenfoid lösemi, tanımlanmamış' }, 'value': 'C91.9' },
    { 'label': 'C92 - Myeloid lösemi', 'translation': { 'tr': 'C92 - Myeloid lösemi' }, 'value': 'C92' },
    { 'label': 'C92.0 - Akut myeloid lösemi', 'translation': { 'tr': 'C92.0 - Akut myeloid lösemi' }, 'value': 'C92.0' },
    { 'label': 'C92.1 - Kronik myeloid lösemi', 'translation': { 'tr': 'C92.1 - Kronik myeloid lösemi' }, 'value': 'C92.1' },
    { 'label': 'C92.2 - Subakut myeloid lösemi', 'translation': { 'tr': 'C92.2 - Subakut myeloid lösemi' }, 'value': 'C92.2' },
    { 'label': 'C92.3 - Myeloid sarkoma', 'translation': { 'tr': 'C92.3 - Myeloid sarkoma' }, 'value': 'C92.3' },
    { 'label': 'C92.4 - Akut promyelositik lösemi', 'translation': { 'tr': 'C92.4 - Akut promyelositik lösemi' }, 'value': 'C92.4' },
    { 'label': 'C92.5 - Akut myelomonositik lösemi', 'translation': { 'tr': 'C92.5 - Akut myelomonositik lösemi' }, 'value': 'C92.5' },
    { 'label': 'C92.7 - Myeloid lösemiler, diğer', 'translation': { 'tr': 'C92.7 - Myeloid lösemiler, diğer' }, 'value': 'C92.7' },
    { 'label': 'C92.9 - Myeloid lösemi, tanımlanmamış', 'translation': { 'tr': 'C92.9 - Myeloid lösemi, tanımlanmamış' }, 'value': 'C92.9' },
    { 'label': 'C93 - Monositik lösemi', 'translation': { 'tr': 'C93 - Monositik lösemi' }, 'value': 'C93' },
    { 'label': 'C93.0 - Akut monositik lösemi', 'translation': { 'tr': 'C93.0 - Akut monositik lösemi' }, 'value': 'C93.0' },
    { 'label': 'C93.1 - Kronik monositik lösemi', 'translation': { 'tr': 'C93.1 - Kronik monositik lösemi' }, 'value': 'C93.1' },
    { 'label': 'C93.2 - Subakut monositik lösemi', 'translation': { 'tr': 'C93.2 - Subakut monositik lösemi' }, 'value': 'C93.2' },
    { 'label': 'C93.7 - Monositik lösemiler, diğer', 'translation': { 'tr': 'C93.7 - Monositik lösemiler, diğer' }, 'value': 'C93.7' },
    { 'label': 'C93.9 - Monositik lösemi, tanımlanmamış', 'translation': { 'tr': 'C93.9 - Monositik lösemi, tanımlanmamış' }, 'value': 'C93.9' },
    { 'label': 'C94 - Lösemiler diğer, özel hücre tipli', 'translation': { 'tr': 'C94 - Lösemiler diğer, özel hücre tipli' }, 'value': 'C94' },
    { 'label': 'C94.0 - Akut eritremi ve eritrolösemi', 'translation': { 'tr': 'C94.0 - Akut eritremi ve eritrolösemi' }, 'value': 'C94.0' },
    { 'label': 'C94.1 - Kronik eritremi', 'translation': { 'tr': 'C94.1 - Kronik eritremi' }, 'value': 'C94.1' },
    { 'label': 'C94.2 - Akut megakaryoblastik lösemi', 'translation': { 'tr': 'C94.2 - Akut megakaryoblastik lösemi' }, 'value': 'C94.2' },
    { 'label': 'C94.3 - Mast hücreli lösemi', 'translation': { 'tr': 'C94.3 - Mast hücreli lösemi' }, 'value': 'C94.3' },
    { 'label': 'C94.4 - Akut panmyelozis', 'translation': { 'tr': 'C94.4 - Akut panmyelozis' }, 'value': 'C94.4' },
    { 'label': 'C94.5 - Akut myelofibrozis', 'translation': { 'tr': 'C94.5 - Akut myelofibrozis' }, 'value': 'C94.5' },
    { 'label': 'C94.7 - Lösemiler, diğer tanımlanmış', 'translation': { 'tr': 'C94.7 - Lösemiler, diğer tanımlanmış' }, 'value': 'C94.7' },
    { 'label': 'C95 - Lösemi, tanımlanmamış hücre tipli', 'translation': { 'tr': 'C95 - Lösemi, tanımlanmamış hücre tipli' }, 'value': 'C95' },
    { 'label': 'C95.0 - Akut lösemi, tanımlanmamış hücre tipli', 'translation': { 'tr': 'C95.0 - Akut lösemi, tanımlanmamış hücre tipli' }, 'value': 'C95.0' },
    { 'label': 'C95.1 - Kronik lösemi, tanımlanmamış hücre tipli', 'translation': { 'tr': 'C95.1 - Kronik lösemi, tanımlanmamış hücre tipli' }, 'value': 'C95.1' },
    { 'label': 'C95.2 - Subakut lösemi, tanımlanmamış hücre tipli', 'translation': { 'tr': 'C95.2 - Subakut lösemi, tanımlanmamış hücre tipli' }, 'value': 'C95.2' },
    { 'label': 'C95.7 - Lösemiler, tanımlanmamış hücre tipli, diğer', 'translation': { 'tr': 'C95.7 - Lösemiler, tanımlanmamış hücre tipli, diğer' }, 'value': 'C95.7' },
    { 'label': 'C95.9 - Lösemi, tanımlanmamış', 'translation': { 'tr': 'C95.9 - Lösemi, tanımlanmamış' }, 'value': 'C95.9' },
    { 'label': 'C96 - Lenfoid, hematopoetik ve ilgili dokuların malign neoplazmları ', 'translation': { 'tr': 'C96 - Lenfoid, hematopoetik ve ilgili dokuların malign neoplazmları ' }, 'value': 'C96' },
    { 'label': 'C96.0 - Letterer-Siwe hastalığı', 'translation': { 'tr': 'C96.0 - Letterer-Siwe hastalığı' }, 'value': 'C96.0' },
    { 'label': 'C96.1 - Malign histiositoz', 'translation': { 'tr': 'C96.1 - Malign histiositoz' }, 'value': 'C96.1' },
    { 'label': 'C96.2 - Malign mast hücreli tümör', 'translation': { 'tr': 'C96.2 - Malign mast hücreli tümör' }, 'value': 'C96.2' },
    { 'label': 'C96.3 - Gerçek histiyositik lenfoma', 'translation': { 'tr': 'C96.3 - Gerçek histiyositik lenfoma' }, 'value': 'C96.3' },
    { 'label': 'C96.7 - Lenfoid, hematopoetik ve ilgili dokuların malign neoplazmları, diğer tanımlanmış', 'translation': { 'tr': 'C96.7 - Lenfoid, hematopoetik ve ilgili dokuların malign neoplazmları, diğer tanımlanmış' }, 'value': 'C96.7' },
    { 'label': 'C96.9 - Lenfoid hematopoetik ve ilgili dokuların malign neoplazmı, tanımlanmamış', 'translation': { 'tr': 'C96.9 - Lenfoid hematopoetik ve ilgili dokuların malign neoplazmı, tanımlanmamış' }, 'value': 'C96.9' },
    { 'label': 'U07.3 - COVID-19 ', 'translation': { 'tr': 'U07.3 - COVID-19 ' }, 'value': 'U07.3' }
  ];

export { favorite_diagnosis as options };
