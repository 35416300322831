<template>
  <div>
    <b-row style="margin: 0px; border-bottom: solid 1px black;">
      <!-- <b-col cols="4"></b-col> -->
      <b-col cols="12">
        <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
          <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
            <b-row>
              <b-col sm="12" md="3">
                <i class="fa fa-database"></i>
              </b-col>
              <b-col sm="12" md="3" style="padding: 2px;">
                <b-button variant="secondary" style="width: 100%; height: 100%" @click="f_addToValList(record_loc[param_data.name.value]['val'], param_data)">{{ $t('wdm16.4194') }}</b-button>
              </b-col>
              <b-col sm="12" md="4" style="padding: 2px;">
                <b-form-select v-model="param_rule_data.op" :disabled="param_rule_data.val_list.length > 0">
                  <template v-for="op in operator">
                    <option v-if="op.param_types.indexOf(param_data.type) !== -1" :value="op.value">
                      {{ $t('wdm16.' + op.label_wdm16_id) }}
                    </option>
                  </template>
                </b-form-select>
              </b-col>
              <b-col sm="12" md="2" style="padding: 2px;">
                <b-dropdown variant="warning" text="" size="md" dropleft>
                  <template v-if="query_list_type && query_list_type === 'one_query'">
                    <template v-if="polestar">
                      <template v-if="query_list.length === 0">
                        <b-dropdown-item @click="f_addNewQuery('polestar_column')"> {{ $t('wdm16.11230') }} </b-dropdown-item>
                        <b-dropdown-item @click="f_addNewQuery()">  {{ $t('wdm16.11232') }}  </b-dropdown-item>
                      </template>
                      <template v-else>
                        <b-dropdown-item @click="f_addToThisQuery(0, 'polestar_column')">
                          <strong style="color: blue;"> {{ query_list[0].type.label }} => </strong> {{ $t('wdm16.11471') }}
                        </b-dropdown-item>
                        <b-dropdown-item @click="f_addToThisQuery(0)">
                          <strong style="color: blue;"> {{ query_list[0].type.label }} => </strong> {{ $t('wdm16.11231') }}
                        </b-dropdown-item>
                      </template>
                    </template>
                    <template v-else>
                      <b-dropdown-item v-if="query_list.length === 0" @click="f_addNewQuery()">  {{ $t('wdm16.11232') }}  </b-dropdown-item>
                      <b-dropdown-item v-else @click="f_addToThisQuery(0, '')">
                        <strong style="color: blue;"> {{ query_list[0].type.label }} => </strong> {{ $t('wdm16.11233') }}
                      </b-dropdown-item>
                    </template>
                  </template>
                  <template v-else>
                    <b-dropdown-item @click="f_addNewQuery()">  {{ $t('wdm16.11232') }}  </b-dropdown-item>
                    <b-dropdown-item @click="f_addNewQuery('missing')">  {{ $t('wdm16.11232') }}  ({{ $t('wdm16.11234') }}) </b-dropdown-item>
                    <template v-for="(query, q_ind) in query_list">
                      <b-dropdown-item v-if="query.type.value === data_type" @click="f_addToThisQuery(q_ind, '')">
                        <strong style="color: blue;"> {{ q_ind + 1 }} ) {{ query.type.label }} => </strong> {{ $t('wdm16.11233') }}
                      </b-dropdown-item>
                      <b-dropdown-item v-if="query.type.value === data_type" @click="f_addToThisQuery(q_ind, 'missing')">
                        <strong style="color: blue;"> {{ q_ind + 1 }} ) {{ query.type.label }} => </strong> {{ $t('wdm16.11233') }} ({{ $t('wdm16.11234') }})
                      </b-dropdown-item>
                    </template>
                  </template>
                </b-dropdown>
              </b-col>
            </b-row>
          </b-card-header>
          <b-row>
            <b-col cols="12">
              <div id="s_operationsScrollbar" style="min-height: 50px; max-height: 150px; overflow-y: scroll; overflow-x: hidden; border-radius: 10px 0 0 10px; padding: 3px">
                <template v-for="(val, val_ind) in param_rule_data.val_list">
                  <b-row class="s_rowNotSelected" @dblclick="param_rule_data.val_list.splice(val_ind, 1); $delete(param_rule_data.labels, val)">
                    <b-col cols="12">
                      <i class="fa fa-caret-right"></i>
                      <span v-if="typeof val === 'object'"> {{ val.label }} </span>
                      <span v-else> {{ val }} </span>
                    </b-col>
                  </b-row>
                </template>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import vSelect from 'vue-select';
import { ClsRule } from '@/services/public/rule';
import { mapGetters } from 'vuex';

export default {
  name: 'WisdomDataRule',
  props: {
    param_change: {
      type: Object,
      required: true
    },
    option_data: {
      type: Object,
      required: true
    },
    param_data: {
      type: Object,
      required: false
    },
    option_loc: {
      type: Array,
      required: false
    },
    record_loc: {
      type: Object,
      required: false
    },
    wdm_name: {
      type: Object,
      required: false
    },
    data_type: {
      type: String,
      required: true
    },
    query_list: {
      type: Array,
      required: false
    },
    query_list_type: {
      type: String,
      required: false
    },
    polestar: {
      type: Boolean,
      required: false
    }
  },
  mounted () {},
  computed: mapGetters({
    lang: 'lang'
  }),
  data () {
    return {
      operator: require('@/options/operator').options,
      param_rule_data: {
        "time_of_value": "any_value",
        "data_type": "old_recorded_data",
        "val_list": [],
        "record_type": "many",
        "significant": "true",
        "requesting_operation": "wisdom_data_save",
        "date": "1",
        "op": "="
      }
    };
  },
  methods: {
    f_paramChangeRule: function () {
      if (this.param_change.rule) {
        this.param_change.rule = 0;
      } else {
        this.param_change.rule = 1;
      }
    },
    f_addToValList: function (val, param_data) {
      // console.log(val);
      if (['select', 'object_list', 'radiogroup'].indexOf(param_data.type) !== -1) {
        if (val) {
          if (['>', '<', '>=', '<='].indexOf(this.param_rule_data.op) !== -1) {
            // This operations analyzing over val.label
            try {
              // FIRST WE CONTROL THE ELIGIBILITY FOR THE OPERATIONS BY MAKING THE LABEL AS FLOAT.
              let test_float_ability = parseFloat(val.label);
              let det = false;
              for (let i in this.param_rule_data.val_list) {
                if (this.param_rule_data.val_list[i].label === val.label) {
                  det = true;
                  alert('bu değer zaten eklenmiştir.');
                  return;
                }
              }
              if (!det) { this.param_rule_data.val_list.push(val); }
            } catch (err) {}
          } else { // =, <>, in, notin => This operations analyzing over val.value
            try {
              let det = false;
              for (let i in this.param_rule_data.val_list) {
                if (this.param_rule_data.val_list[i].value === val.value) {
                  det = true;
                  alert('bu değer zaten eklenmiştir.');
                  return;
                }
              }
              if (!det) { this.param_rule_data.val_list.push(val); }
            } catch (err) {}
          }
        }
      } else if (['integer', 'float', 'text', 'textarea', 'date', 'datetime', 'plain_text', 'time'].indexOf(param_data.type) !== -1) {
        if (this.param_rule_data.val_list.indexOf(val) === -1) {
          this.param_rule_data.val_list.push(val);
        } else {
          alert('Bu değer zaten eklenmiştir.');
        }
      } else if (['checkbox'].indexOf(param_data.type) !== -1) {
        // val in checkbox = [{'value': '', 'label': ''}, .....]
        if (val && val.length > 0) {
          for (let v in val) {
            let det = false;
            for (let i in this.param_rule_data.val_list) {
              if (this.param_rule_data.val_list[i].value === val[v].value) {
                det = true;
                break;
              }
            }
            if (!det) { this.param_rule_data.val_list.push(val[v]); }
          }
        }
      }
      this.$forceUpdate();
    },
    f_addNewQuery: function (type = '') {
      let rule_data = { 'op': this.param_rule_data.op, 'val_list': this.param_rule_data.val_list };
      ClsRule.f_addNewQuery(type, this.option_data[this.data_type], this.data_type, rule_data, this.param_data, this.option_loc, this.query_list);
      this.f_paramChangeRule();
    },
    f_addToThisQuery: function (query_ind, type = '') {
      let rule_data = { 'op': this.param_rule_data.op, 'val_list': this.param_rule_data.val_list };
      ClsRule.f_addToThisQuery(query_ind, type, this.option_data[this.data_type], this.data_type, rule_data, this.param_data, this.option_loc, this.query_list);
      this.f_paramChangeRule();
    }
  },
  watch: {},
  components: {}
};

</script>

<style>
.info-wisdom {
  color: red;
  background-color: #abf1d1;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

</style>

