var dosage_form = [
{ 'label': 'Ağız içi', 'value': '1' },
{ 'label': 'Ağızdan (oral)', 'value': '2' },
{ 'label': 'Burun içi (intranazal)', 'value': '3' },
{ 'label': 'Cilt üzerine (epidermal)', 'value': '4' },
{ 'label': 'Dış kulak yolu', 'value': '5' },
{ 'label': 'Dil altı (sublingual)', 'value': '6' },
{ 'label': 'Göz üzerine', 'value': '7' },
{ 'label': 'İnhalasyon', 'value': '8' },
{ 'label': 'İntradermal', 'value': '9' },
{ 'label': 'İntramusküler', 'value': '10' },
{ 'label': 'İntravajinal', 'value': '11' },
{ 'label': 'İntravenöz', 'value': '12' },
{ 'label': 'intravenöz (Pompa ile)', 'value': '13' },
{ 'label': 'İntraartiküler', 'value': '14' },
{ 'label': 'İntrakaviter', 'value': '15' },
{ 'label': 'İntraligamenter', 'value': '16' },
{ 'label': 'İntraperitoneal', 'value': '17' },
{ 'label': 'İntratekal', 'value': '18' },
{ 'label': 'İntravezikal', 'value': '19' },
{ 'label': 'İntravitreal', 'value': '20' },
{ 'label': 'İntrakardiak', 'value': '21' },
{ 'label': 'Kolon', 'value': '22' },
{ 'label': 'Perinöral', 'value': '23' },
{ 'label': 'Rektal', 'value': '24' },
{ 'label': 'Solunum yolu', 'value': '25' },
{ 'label': 'Subkutan', 'value': '26' },
{ 'label': 'Transdermal', 'value': '27' }];

export { dosage_form as options };
