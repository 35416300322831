import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  get_schema_groups (data) {
    return Vue.http.post(API_BASE_URL + 'get_schema_groups', data);
  },
  new_wdm_schema (data) {
    return Vue.http.post(API_BASE_URL + 'new_wdm_schema', data);
  },
  new_wdm_anatomy_schema (data) {
    return Vue.http.post(API_BASE_URL + 'new_wdm_anatomy_schema', data);
  },
  new_wdm_schema_group (data) {
    return Vue.http.post(API_BASE_URL + 'new_wdm_schema_group', data);
  },
  new_wdm_anatomy_schema_group (data) {
    return Vue.http.post(API_BASE_URL + 'new_wdm_anatomy_schema_group', data);
  },
  save_multi_wdmr_schema_group (data) {
    return Vue.http.post(API_BASE_URL + 'schema/save_multi_wdmr_schema_group', data);
  },
  get_multi_wdmr_schema_data (data) {
    return Vue.http.post(API_BASE_URL + 'schema/get_multi_wdmr_schema_data', data);
  },
  save_multi_wdmr_schema (data) {
    return Vue.http.post(API_BASE_URL + 'schema/save_multi_wdmr_schema', data);
  },
  get_schema_data_list (data) {
    return Vue.http.post(API_BASE_URL + 'schema/get_schema_data_list', data);
  },
  get_multi_wdmr_schema_list (data) {
    return Vue.http.post(API_BASE_URL + 'schema/get_multi_wdmr_schema_list', data);
  },
  copy_schema (data) {
    return Vue.http.post(API_BASE_URL + 'copy_schema', data);
  },
  edit_schema (data) {
    return Vue.http.post(API_BASE_URL + 'edit_schema', data);
  },
  manage_schema_groups (data) {
    return Vue.http.post(API_BASE_URL + 'manage_schema_groups', data);
  },
  get_all_available_schema_for_user (data) {
    return Vue.http.post(API_BASE_URL + 'get_all_available_schema_for_user', data);
  },
  search (data) {
    return Vue.http.post(API_BASE_URL + 'patient/' + data + '/staging/list');
  },
  get (puid, uuid) {
    return Vue.http.get(API_BASE_URL + 'patient/' + puid + '/staging/' + uuid);
  },
  delete (puid, uuid) {
    return Vue.http.delete(API_BASE_URL + 'patient/' + puid + '/staging/' + uuid);
  }
};
