<template>
  <div>
    <pole-star-projects-details></pole-star-projects-details>
  </div>
</template>

<script>
import store from '@/store';
import PoleStarProjectsDetails from '@/components/widgets/PoleStarProjectsDetails';
import { mapGetters } from 'vuex';

export default {
  name: 'PoleStarProjectsDetailsParent',
  data () {
    return {};
  },
  components: {
    PoleStarProjectsDetails
  },
  computed: mapGetters({
    help: 'help'
  }),
  created: function () {},
  mounted () {},
  methods: {},
  watch: {}
};

</script>

<style type="text/css"></style>

