<template>
  <div>
    <div :style="f_calculateDivStyle()">
      <table key="wdm_designer" v-if="p_wdmrSettings.wdm_designer">
        <template v-for="(row, row_ind) in p_wdmrSettings.wdm_designer.table">
          <b-dropdown v-if="p_wdmrSettings.wdm_designer.settings" variant="secondary" size="md" style="padding: 2px;">
            <template v-slot:button-content>
              <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" />
            </template>
            <b-dropdown-item @click="f_deleteRow(row_ind)">
              <img src="@/icon/2960590.png" style="width: 2.5em;" /> sil
            </b-dropdown-item>
            <b-dropdown-item @click="f_addNewRowToUp(row_ind)">
              <i class="icon-plus"></i> üzerine yeni satır ekle
            </b-dropdown-item>
          </b-dropdown>
          <tr>
            <template v-for="(col, col_ind) in row">
              <td :key="'wdm_designer_td_' + col_ind.toString() + row_ind.toString()" @mouseover="d_hoverTd = col_ind.toString() + row_ind.toString()" @mouseleave="d_hoverTd = ''" :style="f_calculateTdStyle(col)">
                <template v-if="p_wdmrSettings.wdm_designer.settings">
                  <b-button style="margin: 5px; padding: 2px;" size="md" variant="secondary" @click="f_deleteThisCell(row_ind, col_ind)">
                    <img src="@/icon/2960590.png" style="width: 1.5em;" />
                  </b-button>
                  <b-button size="md" style="margin: 5px; padding: 2px;" variant="secondary" @click="f_openCellEdit(row_ind, col_ind)">
                    <img src="@/icon/1584256.png" style="width: 1.5em;" />
                  </b-button>
                  <b-row>
                    <b-col sm="12" lg="12">
                      minimum genişlik
                    </b-col>
                    <b-col sm="12" lg="12">
                      <input type="number" name="style_number" v-model="col.style.minWidth">
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" lg="12">
                      yükseklik
                    </b-col>
                    <b-col sm="12" lg="12">
                      <input type="number" name="style_number" v-model="col.style.height">
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" lg="12">
                      sınır çizgi tipi
                    </b-col>
                    <b-col sm="12" lg="12">
                      <select v-model="col.style.border.type">
                        <option value="dotted">{{ $t('wdm16.13156') }}</option>
                        <option value="solid">{{ $t('wdm16.1701') }}</option>
                      </select>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" lg="12">
                      sınır çizgi kalınlığı
                    </b-col>
                    <b-col sm="12" lg="12">
                      <input type="number" name="style_number" v-model="col.style.border.size">
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" lg="12">
                      sınır çizgi rengi
                    </b-col>
                    <b-col sm="12" lg="12">
                      <input type="color" class="form-control" v-model="col.style.border.color">
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" lg="12">
                      arka renk
                    </b-col>
                    <b-col sm="12" lg="12">
                      <input type="color" class="form-control" v-model="col.style.backgroundColor">
                    </b-col>
                  </b-row>
                </template>
                <div>
                  <template v-for="(list_item, list_item_ind) in col.list">
                    <template v-if="list_item.type === 'text'">
                      <div>
                        {{ list_item.caption }}
                      </div>
                    </template>
                    <template v-else-if="list_item.type === 'wdm_group'">
                      <div>
                        {{ f_getParamCaption(list_item) }}
                      </div>
                    </template>
                    <template v-else-if="list_item.type === 'anatomy_component'">
                      <anatomy :external_patient_data="external_patient_data" :param_change="param_change" :selected_anatomy_region="selected_anatomy_region" :selected_anatomy_parent="selected_anatomy_parent" :patient_follow="patient.selected ? true : false"></anatomy>
                      <b-button variant="success" title="Anatomik bölge ekle" style="margin-right: 5px;" v-if="selected_anatomy_region" @click="f_addAnatomy()">
                        <i class="icon-plus"></i>
                      </b-button>
                    </template>
                    <template v-else-if="list_item.type === 'wdm_parameter'">
                      <div>
                        <!-- {{ f_getParamCaption(list_item) }} -->
                        <!-- {{ list_item.wdm_parameter.index_list }} -->
                        <template v-if="list_item.wdm_parameter.index_list && list_item.wdm_parameter.index_list.length > 0">
                          <template v-if="list_item.wdm_parameter.index_list[0] === 'anatomy'">
                            <template v-for="(ana, ana_ind) in data.data.anatomy">
                              <b-row>
                                <b-col cols="12">
                                  {{ana.name.label}}
                                </b-col>
                              </b-row>
                              <wisdom-param :p_aiRobotMatchParameterResult="p_aiRobotMatchParameterResult" :d_showModal="d_showModal" :d_patientSearchModalData="d_patientSearchModalData" :test_mode="test_mode" :option_data="option_data" :wdm_name="option_data[data_type]['name']" :p_wdmrSettings="p_wdmrSettings" :external_patient_data="external_patient_data" :anatomy="ana.name" :data="f_calculateRecordingData(list_item, ana_ind)" :option_param_mother="f_getOptionParamMother(list_item.wdm_parameter.index_list)" :param="list_item.wdm_parameter.index_list[list_item.wdm_parameter.index_list.length - 1]" :level="'first'" :data_type="data_type" :param_change="param_change" :option_loc="[]" :stage_data="stage_data" :key="'param_order_wisdom-param-component-'" :wdm_version_wisdomera="wdm_version_wisdomera" :p_wdmrData="p_wdmrData"></wisdom-param>
                            </template>
                          </template>
                          <template v-else>
                            <wisdom-param :p_aiRobotMatchParameterResult="p_aiRobotMatchParameterResult" :d_showModal="d_showModal" :d_patientSearchModalData="d_patientSearchModalData" :test_mode="test_mode" :option_data="option_data" :wdm_name="option_data[data_type]['name']" :p_wdmrSettings="p_wdmrSettings" :external_patient_data="external_patient_data" :anatomy="{}" :data="f_calculateRecordingData(list_item)" :option_param_mother="f_getOptionParamMother(list_item.wdm_parameter.index_list)" :param="list_item.wdm_parameter.index_list[list_item.wdm_parameter.index_list.length - 1]" :level="'first'" :data_type="data_type" :param_change="param_change" :option_loc="[]" :stage_data="stage_data" :key="'param_order_wisdom-param-component-'" :wdm_version_wisdomera="wdm_version_wisdomera" :p_wdmrData="p_wdmrData"></wisdom-param>
                          </template>
                        </template>
                      </div>
                    </template>
                  </template>
                </div>
              </td>
            </template>
            <td v-if="p_wdmrSettings.wdm_designer.settings">
              <b-button size="md" variant="secondary" style="height: 100%; margin-left: 10px; padding: 2px;" @click="f_addNewColumn(row_ind)">
                <img src="@/icon/2940638.png" style="width: 1.5em;" /> {{ row_ind + 1 }}
              </b-button>
            </td>
          </tr>
        </template>
        <b-button v-if="p_wdmrSettings.wdm_designer.settings" size="md" variant="secondary" style="margin: 10px; padding: 2px;" @click="f_addNewRow()">
          <img src="@/icon/3134186.png" style="width: 1.5em;" /> yeni satır
        </b-button>
      </table>
    </div>
    <modal v-if="d_cellDetails.show" @close="d_cellDetails.show = false" :width="'1000'">
      <h3 slot="header">Bölüm Ayarları</h3>
      <div slot="body">
        <div>
          <b-row style="margin: 10px;">
            <b-col sm="12" lg="6">
              <template v-for="(x, x_ind) in d_cellDetails.data.list">
                <b-row>
                  <b-col cols="2" style="padding: 3px;">
                    <b-dropdown class="pull-right" variant="secondary" size="md" right style="margin-right: 5px;">
                      <template v-slot:button-content>
                        <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                      </template>
                      <b-dropdown-item @click="f_deleteListItem(x_ind)">
                        <img src="@/icon/2087726.png" style="width: 2.5em;" /> sil
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                  <b-col cols="4" style="padding: 3px;">
                    <b-form-select v-model="x.type">
                      <option value="wdm_parameter">wdm parametresi</option>
                      <option value="anatomy_component">anatomi komponenti</option>
                      <option value="wdm_group">wdm grubu</option>
                      <option value="text">yazı</option>
                    </b-form-select>
                  </b-col>
                  <b-col cols="6" :style="d_cellDetails.selected_list_item === x_ind ? 'padding: 3px; background-color: #c3eaeb;' : 'padding: 3px;'" @click="f_selectListItem(x_ind)">
                    <b-row>
                      <b-col lg="12">
                        <template v-if="x.type === 'wdm_parameter' || x.type === 'wdm_group'">
                          <strong>{{ f_getParamCaption(x) }}</strong>
                        </template>
                        <template v-else-if="x.type === 'text'">
                          <b-form-input v-model="x.caption" placeholder="başlığı yazınız"></b-form-input>
                        </template>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </template>
              <b-row>
                <b-col cols="12">
                  <b-button class="pull-right" size="md" variant="secondary" style="padding: 2px;" @click="f_addListItem()">
                    <img src="@/icon/2766751.png" style="width: 1.5em;" /> yeni
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col sm="12" lg="6">
              <div :style="f_calculateWdmParametersDivStyle()">
                <wdm-parameters :data_type="data_type" :search_in_first_parameter_list="d_wdmParameters.search_in_first_parameter_list" :option_data="option_data" :selected_index_data="d_wdmParameters.selected_index_data" :show_parameter_features="d_wdmParameters.show_parameter_features" :d_isThisModelEditable="false" :param_change="d_wdmParameters.param_change" :selection="d_wdmParameters.selection"></wdm-parameters>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div slot="footer">
        <b-button @click="f_saveCellDetails()" variant="success">{{ $t('wdm16.11212') }}</b-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { ClsWdm } from "@/services/public/wdm";
import {
  default as WdmParameters
} from "@/components/widgets/WdmParameters";
import {
  default as Anatomy
} from "@/components/widgets/Anatomy";
import {
  default as WisdomParam
} from "@/components/widgets/WisdomParam";
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';
// import moment from 'moment';
import {
  default as Modal
} from '@/components/widgets/Modal';

export default {
  name: 'WdmDesigner',
  components: {
    Modal,
    vSelect,
    WdmParameters,
    WisdomParam,
    Anatomy
  },
  props: {
    wdm_version_wisdomera: {
      type: Object,
      required: false
    },
    data: {
      type: Object,
      required: true
    },
    option_data: {
      type: Object,
      required: false
    },
    stage_data: {
      type: Object,
      required: false
    },
    data_type: {
      type: String,
      required: true
    },
    anatomy: {
      type: Object,
      required: false
    },
    param_change: {
      type: Object,
      required: true
    },
    external_patient_data: {
      type: Object,
      required: false
    },
    p_wdmrSettings: {
      type: Object,
      required: true
    },
    test_mode: {
      type: Boolean,
      required: false,
      default: false
    },
    d_showModal: {
      type: Object,
      required: false
    },
    d_patientSearchModalData: {
      type: Object,
      required: false
    },
    p_aiRobotMatchParameterResult: {
      type: Object,
      required: false
    },
    p_wdmrData: {
      type: Object,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      patient_tmp: "patient_selected",
      device: 'device'
    })
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.external_patient_data) {
      this.patient = JSON.parse(JSON.stringify(this.external_patient_data))
    } else {
      this.patient = JSON.parse(JSON.stringify(this.patient_tmp))
    }
  },
  mounted: function () {},
  data () {
    return {
      patient: {},
      selected_anatomy_region: {},
      selected_anatomy_parent: {},
      d_hoverTd: '',
      d_cellDetails: {
        'selected_list_item': '',
        'show': false,
        'data': {},
        'row_ind': '',
        'col_ind': ''
      },
      d_wdmParameters: {
        'selection': {
          'times': 'one', // one, multi
          'type': 'all', // all, this_index, this_types
          'this_index': []
        },
        'brief_list': {},
        'selected_wdm': '',
        'user_selected_brief_wdm_list': [],
        'option_data': {},
        'data_type': '',
        'search_in_first_parameter_list': '',
        'selected_index_data': { 'index': '', 'index_list': [] },
        'show_parameter_features': '',
        'param_change': { 'update': 0, 'new_parameter_added': 0 }
      }
    }
  },
  methods: {
    f_addNewRowToUp: function (row_ind) {
      this.p_wdmrSettings.wdm_designer.table.splice(row_ind, 0, []);
      this.$forceUpdate();
    },
    f_getOptionParamMother: function (index_list) {
      let option_param_mother = ClsWdm.getParamMother(this.option_data[this.data_type], index_list);
      return option_param_mother;
    },
    f_addAnatomy: function () {
      if (this.selected_anatomy_region && this.selected_anatomy_region.value && !this.data['data'].anatomy[this.selected_anatomy_region.value]) {
        this.data['data'].anatomy[this.selected_anatomy_region.value] = {
          name: JSON.parse(JSON.stringify(this.selected_anatomy_region)),
          signs: {}
        };
      }
      this.$forceUpdate();
    },
    f_calculateRecordingData: function (item, ana) {
      if (item.wdm_parameter.index_list[0] === 'anatomy') {
        return this.data.data['anatomy'][ana];
      } else {
        let index_list = item.wdm_parameter.index_list;
        return this.data.data[index_list[0]];
      }
    },
    f_getParamCaption: function (x) {
      // console.log(x);
      if (x.type === 'text') {
        return x.caption;
      } else if (x.type === 'wdm_group') {
        if (x.wdm_parameter && x.wdm_parameter.index_list) {
          let index_list = x.wdm_parameter.index_list;
          let param_data = ClsWdm.getParamDataWithIndexList(this.option_data[this.data_type], index_list);
          return param_data.label;
        }
      } else if (x.type === 'wdm_parameter') {
        if (x.wdm_parameter && x.wdm_parameter.index_list) {
          let index_list = x.wdm_parameter.index_list;
          let param_data = ClsWdm.getParamDataWithIndexList(this.option_data[this.data_type], index_list);
          return param_data.name.label;
        }
      }
    },
    f_selectListItem: function (list_item_ind) {
      this.d_cellDetails.selected_list_item = list_item_ind;
      this.$forceUpdate();
    },
    f_calculateWdmParametersDivStyle: function () {
      let height = '';
      let style = '';
      /*
        if (this.d_fullScreen) {
          height = 'height: ' + (window.innerHeight - 75).toString() + 'px;';
        } else if (this.p_mode && this.p_mode === 'talk') {
          height = 'height: ' + (window.innerHeight - 200).toString() + 'px;';
        } else {
          height = 'height: ' + (window.innerHeight - 300).toString() + 'px;';
        }
      */
      height = 'height: ' + (window.innerHeight - 300).toString() + 'px;';
      style = 'border: solid 1px rgba(158, 193, 179, 0.3); overflow-y: scroll; overflow-x: scroll;' + height;
      // console.log(style);
      return style;
    },
    f_deleteListItem: function (list_item_ind) {
      this.d_cellDetails.data.list.splice(list_item_ind, 1);
      this.$forceUpdate();
    },
    f_addListItem: function () {
      let data = {
        'type': 'wdm_parameter',
        'caption': '',
        'wdm_parameter': ''
      };
      this.d_cellDetails.data.list.push(data);
      this.d_cellDetails.selected_list_item = this.d_cellDetails.data.list.length - 1;
      this.$forceUpdate();
    },
    f_openCellEdit: function (row_ind, col_ind) {
      this.d_cellDetails.row_ind = row_ind;
      this.d_cellDetails.col_ind = col_ind;
      this.d_cellDetails.data = this.p_wdmrSettings.wdm_designer.table[row_ind][col_ind];
      this.d_cellDetails.show = true;
    },
    f_saveCellDetails: function () {
      this.p_wdmrSettings.wdm_designer.table[this.d_cellDetails.row_ind][this.d_cellDetails.col_ind] = this.d_cellDetails.data;
      this.d_cellDetails.show = false;
    },
    f_deleteRow: function (row_ind) {
      this.p_wdmrSettings.wdm_designer.table.splice(row_ind, 1);
      this.$forceUpdate();
    },
    f_calculateDivStyle: function () {
      let height = '';
      let style = '';
      /*
        if (this.d_fullScreen) {
          height = 'height: ' + (window.innerHeight - 75).toString() + 'px;';
        } else if (this.p_mode && this.p_mode === 'talk') {
          height = 'height: ' + (window.innerHeight - 200).toString() + 'px;';
        } else {
          height = 'height: ' + (window.innerHeight - 300).toString() + 'px;';
        }
      */
      height = 'height: ' + (window.innerHeight - 300).toString() + 'px;';
      style = 'overflow-y: scroll; font-size: 10px; overflow-x: scroll;' + height;
      // console.log(style);
      return style;
    },
    f_deleteThisCell: function (row_ind, col_ind) {
      this.p_wdmrSettings.wdm_designer.table[row_ind].splice(col_ind, 1);
      this.$forceUpdate();
    },
    f_calculateTdStyle: function (col) {
      let style = 'vertical-align: top;';
      if (col.style.width) {
        style += 'width: ' + col.style.width.toString() + 'px;';
      }
      if (col.style.minWidth) {
        style += 'min-width: ' + col.style.minWidth.toString() + 'px;';
      }
      if (col.style.height) {
        style += 'height: ' + col.style.height.toString() + 'px;';
      }
      if (col.style.backgroundColor) {
        style += 'background-color: ' + col.style.backgroundColor + ';';
      }
      if (col.style.border && col.style.border.type) {
        style += 'border: ' + col.style.border.type + ' ' + col.style.border.size.toString() + 'px' + ' ' + col.style.border.color + ';';
      }
      // console.log(style);
      return style;
    },
    f_addNewRow: function () {
      this.p_wdmrSettings.wdm_designer.table.push([]);
      this.$forceUpdate();
    },
    f_addNewColumn: function (row_ind) {
      let col_data = {
        'style': {
          'width': 200,
          'minWidth': 200,
          'height': 50,
          'backgroundColor': 'white',
          'border': { 'type': 'dotted', 'size': 1, 'color': 'black' }
        },
        'list': []
      };
      this.p_wdmrSettings.wdm_designer.table[row_ind].push(col_data);
      this.$forceUpdate();
    }
  },
  watch: {
    'd_wdmParameters.selected_index_data.index_list': function () {
      let index_list = JSON.parse(JSON.stringify(this.d_wdmParameters.selected_index_data.index_list));
      console.log(index_list);
      if (this.d_cellDetails.selected_list_item !== '' && this.d_cellDetails.data.list[this.d_cellDetails.selected_list_item]) {
        if (this.d_cellDetails.data.list[this.d_cellDetails.selected_list_item].type === 'wdm_parameter') {
          if (index_list.length > 1) {
            this.d_cellDetails.data.list[this.d_cellDetails.selected_list_item].wdm_parameter = { 'index_list': index_list };
            let param_data = ClsWdm.getParamDataWithIndexList(this.option_data[this.data_type], index_list);
            console.log('param_data', param_data);
            this.d_cellDetails.data.list[this.d_cellDetails.selected_list_item].caption = param_data.name.label;
          } else {
            alert('Lütfen parametre seçiniz');
          }
        } else if (this.d_cellDetails.data.list[this.d_cellDetails.selected_list_item].type === 'wdm_group') {
          if (index_list.length === 1) {
            this.d_cellDetails.data.list[this.d_cellDetails.selected_list_item].wdm_parameter = { 'index_list': index_list };
            let param_data = ClsWdm.getParamDataWithIndexList(this.option_data[this.data_type], index_list);
            this.d_cellDetails.data.list[this.d_cellDetails.selected_list_item].caption = param_data.label;
          } else {
            alert('Lütfen grup seçimi yapınız');
          }
        }
      }
    }
  }
};

</script>

<style type="text/css">
.td-class {
  background-color: transparent;
  min-height: 50px;
  width: 200px;
}

.normal-mode {}

.full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

.cls_th_variable_selected_column {
  position: sticky;
  z-index: 1000;
  top: 0;
  white-space: nowrap;
  /*border-right: solid 1px #c3d8d8;*/
  /*border-bottom: solid 1px #c3d8d8;*/
  /*background-color: #ececec;*/
  min-width: 700px;
}

.cls_th_variable_cross_columns {
  position: sticky;
  z-index: 1000;
  top: 0;
  white-space: nowrap;
  /*border-right: solid 1px #c3d8d8;*/
  /*border-bottom: solid 1px #c3d8d8;*/
  /*background-color: #ececec;*/
  min-width: 250px;
}

</style>

