import Vue from 'vue';
import { API_BASE_URL } from '@/config';

export default {
  save (data, uuid) {
    if (uuid) {
      return Vue.http.put(API_BASE_URL + 'patient/' + uuid, data);
    } else {
      return Vue.http.put(API_BASE_URL + 'patient/new', data);
    }
  },
  get_fast_follow (data) {
    return Vue.http.post(API_BASE_URL + 'patient/get_fast_follow', data);
  },
  force_get_hims_data (data) {
    return Vue.http.post(API_BASE_URL + 'patient/force_get_hims_data', data);
  },
  search (data) {
    return Vue.http.post(API_BASE_URL + 'patient/search', data);
  },
  get_open_protocols_for_wdm_by_patient_id (data) {
    return Vue.http.post(API_BASE_URL + 'patient/get_open_protocols_for_wdm_by_patient_id', data);
  },
  bulk_record_patients_with_hims_no_or_identity_no (data) {
    return Vue.http.post(API_BASE_URL + 'patient/bulk_record_patients_with_hims_no_or_identity_no', data);
  },
  search_from_patientlist (data) {
    return Vue.http.post(API_BASE_URL + 'patient/search_from_patientlist', data);
  },
  get_organization_task_patient (data) {
    return Vue.http.post(API_BASE_URL + 'patient/get_organization_task_patient', data);
  },
  get_patient_organization_task_data (data) {
    return Vue.http.post(API_BASE_URL + 'patient/get_patient_organization_task_data', data);
  },
  detailed_search (data) {
    return Vue.http.post(API_BASE_URL + 'patient/detailed_search', data);
  },
  get (uuid) {
    return Vue.http.get(API_BASE_URL + 'patient/' + uuid);
  },
  get_patient_diagnosis_value_list (uuid) {
    return Vue.http.get(API_BASE_URL + 'patient/get_patient_diagnosis_value_list/' + uuid);
  },
  get_project_patient (uuid) {
    return Vue.http.get(API_BASE_URL + 'patient/get_project_patient/' + uuid);
  },
  find_hospital_patient (data) {
    return Vue.http.post(API_BASE_URL + 'patient/find_hospital_patient', data);
  },
  get_project_patient_for_wdmr (uuid) {
    return Vue.http.get(API_BASE_URL + 'patient/get_project_patient_for_wdmr/' + uuid);
  },
  get_patient_wdmr_for_wdm_version (data) {
    return Vue.http.post(API_BASE_URL + 'patient/get_patient_wdmr_for_wdm_version', data);
  },
  get_patient_wdmr_by_type (data) {
    return Vue.http.post(API_BASE_URL + 'patient/get_patient_wdmr_by_type', data);
  },
  merge_patients (data) {
    return Vue.http.post(API_BASE_URL + 'patient/merge_patients', data);
  },
  getPatientData (uuid, query) {
    return Vue.http.get(API_BASE_URL + 'patient/getPatientData/' + uuid + '?' + query);
  },
  get_eligible_record_id () {
    return Vue.http.get(API_BASE_URL + 'patient/get_eligible_record_id/new-record-id');
  },
  find_all_used_diagnosis () {
    return Vue.http.get(API_BASE_URL + 'patient/find_all_used_diagnosis');
  },
  get_patient_drugs (uuid) {
    return Vue.http.get(API_BASE_URL + 'patient/get_patient_drugs/' + uuid);
  }
};
