import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  update_column_feature (data) {
    return Vue.http.post(API_BASE_URL + 'column/update_column_feature', data);
  },
  control_deletion_ability (data) {
    return Vue.http.post(API_BASE_URL + 'column/control_deletion_ability', data);
  }
};
