<template>
  <div class="animated fadeIn">
    <b-card v-if="d_showCkEditor">
      <ckeditor :editor="editor" v-model="p_editorData.text" :config="editorConfig"></ckeditor>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use(CKEditor);
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  default as Modal
} from '@/components/widgets/Modal';
import CertificateService from '@/services/certificate';
import UserService from '@/services/users';
import { mapGetters } from 'vuex';
export default {
  name: 'HtmlEditor',
  computed: mapGetters({
    lang: 'lang',
    device: 'device'
  }),
  props: {
    p_editorData: {
      type: Object,
      required: true
    },
    p_editorConfigToolbar: {
      type: Array,
      required: false
    },
    p_editorConfigPlaceholder: {
      type: String,
      required: false
    }
  },
  components: {
    Modal
  },
  data () {
    return {
      d_showCkEditor: false,
      editor: ClassicEditor,
      editorConfig: {
        // placeholder: 'placeholder datası',
        toolbar: ["heading", "|", "bold", "italic", "link", "|", "bulletedList", "numberedList", "|", "indent", "outdent", "|", "blockQuote", "|", "mediaEmbed", "|", "insertTable", "tableColumn", "tableRow", "mergeTableCells", "|", "undo", "redo"]
          // toolbar: ["undo", "redo", "bold", "italic", "blockQuote", "ckfinder", "imageTextAlternative", "imageUpload", "heading", "imageStyle:full", "imageStyle:side", "link", "numberedList", "bulletedList", "mediaEmbed", "insertTable", "tableColumn", "tableRow", "mergeTableCells"]
          // config parameters: ['alignment', 'autosave', 'balloonToolbar', 'blockToolbar', 'ckfinder', 'cloudServices', 'codeBlock', 'exportPdf', 'exportWord', 'extraPlugins', 'fontBackgroundColor', 'fontColor', 'fontFamily', 'fontSize', 'heading', 'highlight', 'image', 'indentBlock', 'initialData', 'language', 'link', 'mediaEmbed', 'mention', 'placeholder', 'plugins', 'removePlugins', 'restrictedEditing', 'simpleUpload', 'table', 'title', 'toolbar', 'typing', 'wordCount']
          // https://ckeditor.com/docs/ckeditor5/latest/api/module_core_editor_editorconfig-EditorConfig.html
      }
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.p_editorConfigToolbar) {
      this.editorConfig.toolbar = this.p_editorConfigToolbar;
    }
    if (this.p_editorConfigPlaceholder) {
      this.editorConfig.placeholder = this.p_editorConfigPlaceholder;
    }
  },
  mounted: function () {
    setTimeout(function () {
      this.d_showCkEditor = true
    }.bind(this), 500);
  },
  destroyed () {},
  methods: {},
  watch: {}
};

</script>

