<template>
  <div>
    <b-row :style="['basic'].indexOf(p_wdmrSettings.wdmr_schema[p_schemaType].view_mode) !== -1 ? 'background-color: #c8ced3; margin: 0px;' : 'margin: 0px;'">
      <b-col sm="12" lg="11" style="padding-left: 3px;">
        <template v-if="p_schemaType === 'wdm_anatomy' && p_wdmrSettings.wdmr_schema[p_schemaType].view_mode === 'anatomy_schema_list'">
          <template v-for="(favorite_anatomy_schema, favorite_anatomy_schema_ind) in d_schemaList">
            <b-row :style="p_selectedAnatomySchemaList.indexOf(favorite_anatomy_schema.value) !== -1 ? 'margin: 5px; cursor: pointer;' : 'margin: 5px; cursor: pointer;'" @click="f_addToAnatomySchemaList(favorite_anatomy_schema)">
              <b-col cols="1">
                <img v-if="f_includesSelectedAnatomySchemaList(favorite_anatomy_schema)" src="@/icon/2147852.png" style="width: 2em;" />
              </b-col>
              <b-col cols="11">
                <span :style="f_includesSelectedAnatomySchemaList(favorite_anatomy_schema) ? 'color: #177e7d' : ''">
                  {{ favorite_anatomy_schema.label }}
                </span>
              </b-col>
            </b-row>
          </template>
        </template>
        <template v-if="p_wdmrSettings.wdmr_schema[p_schemaType].view_mode === 'basic'">
          <b-row style="margin: 0px;" key="d_selectedSchemaType">
            <b-col sm="12" md="2" style="padding: 3px;">
              <b-dropdown variant="secondary" size="md" style="width: 100%;" title="Wisdom: sistem şablonlarını, Hastane: hastane şablonlarını, Benim: size ait şablonları ifade eder">
                <template v-slot:button-content>
                  {{ d_schemaOptions[d_selectedSchemaType].translation.tr }}
                </template>
                <b-dropdown-item v-for="(schema_option, schema_option_ind) in d_schemaOptions" :key="'schema_type_option_2_'+ schema_option_ind" @click="f_selectSchemaOption(schema_option_ind)">{{ schema_option.translation.tr }}</b-dropdown-item>
              </b-dropdown>
            </b-col>
            <template v-if="d_selectedSchemaType !== 'favorite'">
              <b-col sm="12" md="2" style="padding: 3px;">
                <b-dropdown variant="secondary" size="md" style="width: 100%;" title="Seçilen Grup Tipine Göre Filtreleme Yapmanızı Sağlar">
                  <template v-slot:button-content>
                    {{ d_schemaGroupTypeOptions[d_selectedSchemaGroupType].translation.tr }}
                  </template>
                  <b-dropdown-item v-for="(schema_group_type_option, schema_group_type_option_ind) in d_schemaGroupTypeOptions" :key="'schema_group_type_option_2_'+ schema_group_type_option_ind" @click="f_selectSchemaGroupTypeOption(schema_group_type_option_ind)">{{ schema_group_type_option.translation.tr }}</b-dropdown-item>
                </b-dropdown>
              </b-col>
              <b-col sm="12" md="8" v-if="d_selectedSchemaGroupType === 'selected'" style="padding-left: 3px;">
                <v-select v-model="d_selectedSchemaGroup" name="d_selectedSchemaGroup" :options="d_schemaGroupList" v-on:change="f_changeSelectedSchemaGroup" style="width: 100%;">
                </v-select>
              </b-col>
            </template>
            <template v-else>
              <b-col sm="12" md="10" style="padding: 3px;">
                <b-input-group>
                  <v-select name="selected_schema" ref="selected_schema" :options="d_schemaList" v-model="d_selectedSchema" style="width:80%;">
                  </v-select>
                  <b-input-group-append>
                    <b-button :disabled="d_selectedSchema.value ? false : true" size="sm" block variant="primary" @click="f_useSchema(p_schemaType)"><i class="fa fa-plus"></i> şablonu kullan</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </template>
          </b-row>
          <b-row v-if="d_selectedSchemaType !== 'favorite'" style="margin: 0px;">
            <b-col sm="12" md="12" style="padding: 3px;">
              <b-input-group>
                <v-select v-model="d_selectedSchema" name="selected_schema" :options="d_schemaList" style="width:80%;">
                </v-select>
                <b-input-group-append>
                  <b-button :disabled="d_selectedSchema.value ? false : true" size="sm" block variant="primary" @click="f_useSchema(p_schemaType)"><i class="fa fa-plus"></i> şablonu kullan</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </template>
        <template v-if="p_wdmrSettings.wdmr_schema[p_schemaType].view_mode === 'up_down'">
          <b-card no-body header-tag="header" footer-tag="footer" class="mb-1" style="padding: 0px;">
            <b-card-header header-bg-variant="secondary" class="p-1" style="cursor: pointer;">
              <b-row>
                <b-col cols="6" v-b-toggle="'wdm_schema_multi'">
                  <img src="@/icon/2958560.png" style="width: 2em;" />
                  <strong>Şablon Kullanımı</strong>
                </b-col>
                <b-col cols="6">
                  <b-dropdown class="pull-right" variant="secondary" size="md" right style="margin-right: 5px;">
                    <template v-slot:button-content>
                      <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 2.2em;" />
                    </template>
                    <b-dropdown-item v-if="f_checkNewSchemaAdd()" @click="f_addNewSchema()">
                      <img src="@/icon/2766751.png" style="width: 2.5em;" /> Yeni şablon
                    </b-dropdown-item>
                    <b-dropdown-item v-if="d_selectedSchema && d_selectedSchema.value" @click="f_editSchema(d_selectedSchema.id)">
                      <img src="@/icon/2087726.png" style="width: 2.5em;" /> Şablonu güncelle
                    </b-dropdown-item>
                    <b-dropdown-item v-if="f_checkNewSchemaGroupAdd()" @click="f_addNewSchemaGroup()">
                      <img src="@/icon/2448909.png" style="width: 2.5em;" /> Yeni grup ekle
                    </b-dropdown-item>
                    <b-dropdown-item v-if="d_selectedSchema" @click="f_updateMultiWdmrFavoriteSchema('add')">
                      <img src="@/icon/1869589.png" style="width: 2.5em;" /> Favorilerime Ekle
                    </b-dropdown-item>
                    <b-dropdown-item v-if="d_selectedSchema" @click="f_updateMultiWdmrFavoriteSchema('delete')">
                      <img src="@/icon/2960590.png" style="width: 2.5em;" /> Favorilerimden sil
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-card-header>
            <b-collapse :visible="true" id="wdm_schema_multi">
              <b-row>
                <b-col md="12" lg="6">
                  <b-row style="margin: 2px;">
                    <b-col sm="12">
                      <small><strong>Şablon Tipi</strong></small>
                    </b-col>
                  </b-row>
                  <b-row style="margin: 2px;">
                    <b-col sm="12" md="12" lg="12">
                      <b-form-group label="">
                        <b-form-radio-group key="d_selectedSchemaType" v-model="d_selectedSchemaType">
                          <b-form-radio v-for="(op, op_ind) in d_schemaOptions" :key="'schema_type_option_'+ op_ind" :value="op_ind"> {{ op.translation.tr }} </b-form-radio>
                        </b-form-radio-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="12" lg="6">
                  <b-row style="margin: 2px;">
                    <b-col sm="12">
                      <small><strong>Şablon Grubu</strong></small>
                    </b-col>
                  </b-row>
                  <b-row style="margin: 2px;">
                    <b-col sm="12" md="12" lg="12">
                      <b-form-group label="">
                        <b-form-radio-group key="d_selectedSchemaGroupType" v-model="d_selectedSchemaGroupType">
                          <b-form-radio v-for="(op, op_ind) in d_schemaGroupTypeOptions" :key="'schema_group_type_option_'+ op_ind" :value="op_ind"> {{ op.translation.tr }} </b-form-radio>
                        </b-form-radio-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row v-if="d_selectedSchemaGroupType === 'selected'">
                <b-col md="12" lg="12">
                  <b-row style="margin: 2px;">
                    <b-col sm="12">
                      <small><strong>Şablon Grubu</strong></small>
                    </b-col>
                  </b-row>
                  <b-row style="margin: 2px;">
                    <b-col sm="12" md="12" lg="12">
                      <v-select v-model="d_selectedSchemaGroup" name="selected_schema" :options="d_schemaGroupList" v-on:change="f_changeSelectedSchemaGroup" style="width:100%;">
                      </v-select>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" lg="12">
                  <b-row style="margin: 2px;">
                    <b-col sm="12">
                      <small><strong>Şablon Seçimi</strong></small>
                    </b-col>
                  </b-row>
                  <b-row style="margin: 2px;">
                    <b-col sm="12" md="12" lg="12">
                      <b-input-group>
                        <v-select key="selected_schema" v-model="d_selectedSchema" name="selected_schema" :options="d_schemaList" style="width:80%;">
                        </v-select>
                        <b-input-group-append>
                          <b-button :disabled="d_selectedSchema.value ? false : true" size="sm" block variant="primary" @click="f_useSchema(p_schemaType)"><i class="fa fa-plus"></i> şablonu kullan</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-collapse>
          </b-card>
        </template>
      </b-col>
      <b-col sm="12" lg="1" style="padding: 0px; margin-right: 0px;">
        <b-button size="md" style="padding: 0px;" variant="secondary" @click="f_changeMultiSchemaMode()" title="Şablon modu değiştir">
          <img src="@/icon/2958560.png" style="width: 2.5em;" />
        </b-button>
        <b-button v-if="p_schemaType === 'wdm_anatomy'" size="md" style="padding: 0px;" variant="secondary" @click="f_testAnatomyWdmrCombining()" title="Anatomi şablon birleştirme testi">
          <img src="@/icon/1430869.png" style="width: 2.5em;" />
        </b-button>
      </b-col>
    </b-row>
    :<b-modal title="$t('wdm16.13229')" centered class="modal-success" v-model="d_showNewSchemaGroup" @ok="d_showNewSchemaGroup = false" ok-variant="success" hide-footer>
      <b-row v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('administrator') !== -1)">
        <b-col cols="3">
          <strong>{{ $t('wdm16.13230') }}</strong>
        </b-col>
        <b-col cols="9">
          <select v-model="d_newSchemaGroupOwnerType" class="form-control">
            <option value="user">{{ $t('wdm16.13231') }}</option>
            <option value="hospital">{{ $t('wdm16.13232') }}</option>
            <option value="wisdom">{{ $t('wdm16.13233') }}</option>
          </select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <strong>{{ $t('wdm16.7119') }}</strong>
        </b-col>
        <b-col cols="9">
          <b-form-input type="text" class="form-control-warning" id="d_newSchemaGroupTitle" v-model="d_newSchemaGroupTitle"></b-form-input>
        </b-col>
      </b-row>
      <br>
      <b-row class="pull-right">
        <b-col cols="12">
          <b-button v-if="d_newSchemaGroupTitle !== ''" variant="success" @click="f_saveNewSchemaGroup()">{{ $t('wdm16.4061') }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal title="Şablon kaydetme ekranı" centered class="modal-success" v-model="d_showNewSchema" @ok="d_showNewSchema = false" ok-variant="success" hide-footer>
      <b-row>
        <b-col cols="3">
          <strong>{{ $t('wdm16.6772') }}</strong>
        </b-col>
        <b-col cols="9">
          <b-form-input type="text" class="form-control-warning" id="d_newSchemaTitle" v-model="d_newSchemaTitle"></b-form-input>
        </b-col>
      </b-row>
      <br>
      <b-row class="pull-right">
        <b-col cols="12">
          <b-button v-if="d_newSchemaTitle !== ''" variant="success" @click="f_saveNewSchema()">{{ $t('wdm16.4061') }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <modal v-if="d_anatomyCombination.show" @close="d_anatomyCombination.show = false" :width="'1000'">
      <h3 slot="header">
        Anatomi birleştirme
      </h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="d_anatomyCombination.wisdom_data" :data_type="data_type" :option_data="d_anatomyCombination.option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="p_selectedAnatomySchemaList"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <!-- <b-button variant="success" @click="f_saveDrugDateAdjust()">{{ $t('wdm16.4061') }}</b-button> -->
        <b-button variant="danger" @click="d_anatomyCombination.show = false">{{ $t('wdm16.4053') }}</b-button>
        <b-button variant="success" @click="f_addThisAnatomyCombination()">Anatomiyi ekle</b-button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  default as Modal
} from '@/components/widgets/Modal';
import { ClsWdm } from "@/services/public/wdm";
import UserService from "@/services/users";
import { ClsUserSettings } from '@/services/public/user';
import { mapGetters } from 'vuex';
import vSelect from "vue-select";
import SchemaService from "@/services/schema";
import GlobalService from '@/services/global';
import { ClsWdmrForever } from "@/services/public/wdm";

export default {
  name: 'WdmrSchema',
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      lang: 'lang',
      help: 'help'
    })
  },
  props: {
    p_selectedAnatomySchemaList: {
      type: Array,
      required: false
    },
    p_wdmrSettings: {
      type: Object,
      required: true
    },
    p_schemaType: {
      type: String,
      required: true
    },
    option_data: {
      type: Object,
      required: true,
      default: () => {}
    },
    data_type: {
      type: String,
      required: true
    },
    p_schemaEdit: {
      type: Boolean,
      required: false
    },
    data: {
      type: Object,
      required: false,
      default: () => {}
    },
    mother_children: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      d_anatomyCombination: {
        'wisdom_data': '',
        'show': false
      },
      d_showAnatomyCombine: false,
      d_schemaOptions: {
        'favorite': { 'translation': { 'tr': 'Favori', 'en': 'Favorite' } },
        'user': { 'translation': { 'tr': 'Benim', 'en': 'My' } },
        'hospital': { 'translation': { 'tr': 'Hastane', 'en': '' } },
        'wisdom': { 'translation': { 'tr': 'Wisdom', 'en': 'Wisdom' } }
      },
      d_schemaGroupTypeOptions: {
        'all': { 'translation': { 'tr': 'Tümü', 'en': 'All' } },
        'selected': { 'translation': { 'tr': 'Seçilen', 'en': 'Selected' } }
      },
      user: {},
      d_selectedSchemaType: 'wisdom',
      d_selectedSchemaGroup: '',
      d_selectedSchemaGroupType: 'all',
      d_newSchemaTitle: '',
      d_showNewSchema: false,
      d_showNewSchemaGroup: false,
      d_schemaGroupList: [],
      d_wisdomSchemaGroupList: [],
      d_userSchemaGroupList: [],
      d_hospitalSchemaGroupList: [],
      d_schemaList: [],
      d_newSchemaGroupTitle: '',
      d_newSchemaGroupOwnerType: 'user',
      d_selectedSchema: '',
      d_referenceWdmKey: ''
    };
  },
  created () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.d_referenceWdmKey = 'wdm_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_v' + this.option_data[this.data_type].version;
    if (this.option_data[this.data_type].owner_type === 'hospital') {
      this.d_referenceWdmKey = 'wdm_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.hospital_group + '_' + this.user.hospital_id + '_v' + this.option_data[this.data_type].version;
    }
    if (this.p_wdmrSettings) {
      if (this.p_wdmrSettings.wdmr_schema[this.p_schemaType].d_selectedSchema) {
        this.d_selectedSchema = this.p_wdmrSettings.wdmr_schema[this.p_schemaType].d_selectedSchema;
      }
      if (this.p_wdmrSettings.wdmr_schema[this.p_schemaType].d_selectedSchemaType) {
        this.d_selectedSchemaType = this.p_wdmrSettings.wdmr_schema[this.p_schemaType].d_selectedSchemaType;
      }
      if (this.p_wdmrSettings.wdmr_schema[this.p_schemaType].d_selectedSchemaGroupType) {
        this.d_selectedSchemaGroupType = this.p_wdmrSettings.wdmr_schema[this.p_schemaType].d_selectedSchemaGroupType;
      }
      if (this.p_wdmrSettings.wdmr_schema[this.p_schemaType].d_selectedSchemaGroup) {
        this.d_selectedSchemaGroup = this.p_wdmrSettings.wdmr_schema[this.p_schemaType].d_selectedSchemaGroup;
      }
      if (this.p_wdmrSettings.wdmr_schema[this.p_schemaType].view_mode === 'anatomy_schema_list') {
        this.d_selectedSchemaType = 'favorite';
      }
    }
  },
  mounted () {
    this.f_getSchemaGroups();
  },
  methods: {
    f_includesSelectedAnatomySchemaList: function (schema_data) {
      for (let i in this.p_selectedAnatomySchemaList) {
        if (this.p_selectedAnatomySchemaList[i].value === schema_data.value) {
          return true;
        }
      }
      return false;
    },
    f_allToOneWdmr: function (anatomy_schema_list) {
      let wdmr_list = [];
      for (let i in anatomy_schema_list) {
        let wdmr = {
          'data': {
            'anatomy': anatomy_schema_list[i].schema_data
          }
        };
        wdmr_list.push(wdmr);
      }
      // console.log(wdmr_list);
      let return_data = ClsWdm.combineWdmrList(wdmr_list, this.option_data[this.data_type], ['require', 'name', 'delete']);
      let tmp_option_data = JSON.parse(JSON.stringify(this.option_data));
      let other_than_anatomy_groups = [];
      for (let i in this.option_data[this.data_type].param_group.param_order) {
        if (this.option_data[this.data_type].param_group.param_order[i] !== 'anatomy') {
          other_than_anatomy_groups.push(this.option_data[this.data_type].param_group.param_order[i]);
        }
      }
      for (let i in other_than_anatomy_groups) {
        this.$delete(tmp_option_data[this.data_type], other_than_anatomy_groups[i]);
        this.$delete(tmp_option_data[this.data_type].param_group, other_than_anatomy_groups[i]);
      }
      tmp_option_data[this.data_type].param_group.param_order = ['anatomy'];
      this.d_anatomyCombination.option_data = tmp_option_data;
      return_data.reference_wdm = {
        'key': this.d_referenceWdmKey,
        'owner_type': this.option_data[this.data_type].owner_type,
        'version': this.option_data[this.data_type].version
      };
      this.d_anatomyCombination.wisdom_data = return_data;
      this.d_anatomyCombination.show = true;
      // console.log(return_data);
    },
    f_addThisAnatomyCombination: function () {
      let wdmr_list = [];
      wdmr_list.push(this.data);
      wdmr_list.push(this.d_anatomyCombination.wisdom_data);
      let return_data = ClsWdm.combineWdmrList(wdmr_list, this.option_data[this.data_type], ['require', 'name', 'delete']);
      this.data.data = JSON.parse(JSON.stringify(return_data.data));
      this.d_anatomyCombination.show = false;
    },
    f_testAnatomyWdmrCombining: function () {
      let data = {
        'list': this.p_selectedAnatomySchemaList
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Anatomi birleştirme işlemi başlatıldı. Lütfen bekleyiniz.' } });
      SchemaService.get_schema_data_list(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (resp.data.status === 'success') {
            // console.log(resp.data.result);
            this.f_allToOneWdmr(resp.data.result);
          } else {
            //
          }
        });
    },
    f_addToAnatomySchemaList: function (schema_data) {
      let det = false;
      let det_ind = '';
      for (let i in this.p_selectedAnatomySchemaList) {
        if (this.p_selectedAnatomySchemaList[i].value === schema_data.value) {
          det = true;
          det_ind = i;
        }
      }
      if (!det) {
        this.p_selectedAnatomySchemaList.push(schema_data);
      } else {
        this.p_selectedAnatomySchemaList.splice(det_ind, 1);
      }
      this.$forceUpdate();
    },
    f_selectSchemaOption: function (schema_option_ind) {
      this.d_selectedSchemaType = schema_option_ind;
      this.f_changeSelectedSchemaType();
      this.$forceUpdate();
    },
    f_changeMultiSchemaMode: function () {
      if (this.p_schemaType === 'wdm_anatomy') {
        if (this.p_wdmrSettings.wdmr_schema[this.p_schemaType].view_mode === 'basic') {
          this.p_wdmrSettings.wdmr_schema[this.p_schemaType].view_mode = 'up_down';
        } else if (this.p_wdmrSettings.wdmr_schema[this.p_schemaType].view_mode === 'up_down') {
          if (this.p_selectedAnatomySchemaList) {
            this.f_selectSchemaOption('favorite');
            this.p_selectedAnatomySchemaList.splice(0, this.p_selectedAnatomySchemaList.length);
            this.p_wdmrSettings.wdmr_schema[this.p_schemaType].view_mode = 'anatomy_schema_list';
          } else {
            this.p_wdmrSettings.wdmr_schema[this.p_schemaType].view_mode = 'basic';
          }
        } else if (this.p_wdmrSettings.wdmr_schema[this.p_schemaType].view_mode === 'anatomy_schema_list') {
          this.p_wdmrSettings.wdmr_schema[this.p_schemaType].view_mode = 'basic';
        }
      } else {
        if (this.p_wdmrSettings.wdmr_schema[this.p_schemaType].view_mode === 'basic') {
          this.p_wdmrSettings.wdmr_schema[this.p_schemaType].view_mode = 'up_down';
        } else if (this.p_wdmrSettings.wdmr_schema[this.p_schemaType].view_mode === 'up_down') {
          this.p_wdmrSettings.wdmr_schema[this.p_schemaType].view_mode = 'basic';
        }
      }
      this.$forceUpdate();
    },
    f_updateMultiWdmrFavoriteSchema: function (type) {
      let user_data = JSON.parse(localStorage.getItem('user'));
      let schema_data = JSON.parse(JSON.stringify(this.d_selectedSchema));
      schema_data.d_selectedSchemaType = this.d_selectedSchemaType;
      if (type === 'delete') {
        try {
          let det_ind = '';
          for (let i in user_data.settings.wdmr_record.settings.wdm_schema_anatomy.settings[this.d_referenceWdmKey].list) {
            if (user_data.settings.wdmr_record.settings.wdm_schema_anatomy.settings[this.d_referenceWdmKey].list[i].value === schema_data.value) {
              det_ind = i;
              break;
            }
          }
          if (det_ind !== '') {
            user_data.settings.wdmr_record.settings.wdm_schema_anatomy.settings[this.d_referenceWdmKey].list.splice(det_ind, 1);
            this.d_schemaList.splice(det_ind, 1);
          }
        } catch (err) {}
      } else if (type === 'add') {
        ClsUserSettings.free_structure(user_data);
        if (!user_data.settings.wdmr_record.settings.wdm_schema_anatomy.settings[this.d_referenceWdmKey]) {
          user_data.settings.wdmr_record.settings.wdm_schema_anatomy.settings[this.d_referenceWdmKey] = { 'list': [] };
        }
        let det = false;
        for (let i in user_data.settings.wdmr_record.settings.wdm_schema_anatomy.settings[this.d_referenceWdmKey].list) {
          if (user_data.settings.wdmr_record.settings.wdm_schema_anatomy.settings[this.d_referenceWdmKey].list[i] === schema_data.value) {
            det = true;
            break;
          }
        }
        if (!det) {
          user_data.settings.wdmr_record.settings.wdm_schema_anatomy.settings[this.d_referenceWdmKey].list.push(schema_data);
        }
      }
      let data = {
        'schema': schema_data,
        'type': type,
        'reference_wdm_key': this.d_referenceWdmKey
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Favori şablon listeniz güncelleniyor. Lütfen bekleyiniz.' } });
      UserService.update_wdm_schema_anatomy(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            alert(this.$t('wdm16.12713'));
            localStorage.setItem('user', JSON.stringify(user_data));
            this.user = user_data;
            this.$forceUpdate();
          } else {
            alert(this.$t('wdm16.12714'), resp.data.message);
          }
        });
      // localStorage.user = JSON.stringify(user_data);
    },
    f_selectSchemaGroupTypeOption: function (schema_group_type_option_ind) {
      this.d_selectedSchemaGroupType = schema_group_type_option_ind;
      this.$forceUpdate();
    },
    f_checkNewSchemaAdd: function () {
      // this func return for disabled status
      if (this.d_selectedSchemaType === 'user' && this.d_selectedSchemaGroupType === 'selected' && this.d_selectedSchemaGroup.value) {
        return false;
      } else if (this.user.permissions_result && (this.user.permissions_result.indexOf('wisdom') !== -1 || this.user.permissions_result.indexOf('administrator') !== -1 || this.user.permissions_result.indexOf('general_open-test-model') !== -1) && this.d_selectedSchemaType === 'wisdom' && this.d_selectedSchemaGroupType === 'selected' && this.d_selectedSchemaGroup.value) {
        return false;
      } else if (this.user.permissions_result && (this.user.permissions_result.indexOf('wisdom') !== -1 || this.user.permissions_result.indexOf('administrator') !== -1) && this.d_selectedSchemaType === 'hospital' && this.d_selectedSchemaGroupType === 'selected' && this.d_selectedSchemaGroup.value) {
        return false;
      }
      return true;
    },
    f_checkNewSchemaGroupAdd: function () {
      // this func return for disabled status
      if (this.d_selectedSchemaType === 'user') {
        return false;
      } else if (this.user.permissions_result && (this.user.permissions_result.indexOf('wisdom') !== -1 || this.user.permissions_result.indexOf('administrator') !== -1 || this.user.permissions_result.indexOf('general_open-test-model') !== -1) && this.d_selectedSchemaType === 'wisdom') {
        return false;
      } else if (this.user.permissions_result && (this.user.permissions_result.indexOf('wisdom') !== -1 || this.user.permissions_result.indexOf('administrator') !== -1) && this.d_selectedSchemaType === 'hospital') {
        return false;
      }
      return true;
    },
    f_addNewSchema: function () {
      this.d_newSchemaTitle = '';
      this.d_showNewSchema = true;
    },
    f_changeSelectedSchemaType: function () {
      this.d_selectedSchema = '';
      this.d_schemaGroupList = [];
      if (this.d_selectedSchemaType === 'wisdom') {
        this.d_schemaGroupList = JSON.parse(JSON.stringify(this.d_wisdomSchemaGroupList));
        this.d_selectedSchemaGroupType = 'all';
        this.f_changeSelectedSchemaGroupType();
      } else if (this.d_selectedSchemaType === 'user') {
        this.d_schemaGroupList = JSON.parse(JSON.stringify(this.d_userSchemaGroupList));
        this.d_selectedSchemaGroupType = 'all';
        this.f_changeSelectedSchemaGroupType();
      } else if (this.d_selectedSchemaType === 'hospital') {
        this.d_schemaGroupList = JSON.parse(JSON.stringify(this.d_hospitalSchemaGroupList));
        this.d_selectedSchemaGroupType = 'all';
        this.f_changeSelectedSchemaGroupType();
      } else if (this.d_selectedSchemaType === 'favorite') {
        this.d_schemaList = [];
        let user_data = JSON.parse(localStorage.getItem('user'));
        try {
          if (this.p_schemaType === 'wdm_anatomy') {
            this.d_schemaList = user_data.settings.wdmr_record.settings.wdm_schema_anatomy.settings[this.d_referenceWdmKey].list;
          }
        } catch (err) {}
      }
      this.$forceUpdate();
    },
    f_changeSelectedSchemaGroupType: function () {
      this.d_schemaList = [];
      this.d_selectedSchemaGroup = {};
      if (this.d_selectedSchemaGroupType === 'all') {
        for (let i in this.d_schemaGroupList) {
          if (this.d_schemaGroupList[i].list[this.option_data[this.data_type].version]) {
            let group = this.d_schemaGroupList[i].list[this.option_data[this.data_type].version];
            for (let k in group) {
              this.d_schemaList.push(group[k]);
            }
          }
        }
      }
      this.$forceUpdate();
    },
    f_changeSelectedSchemaGroup: function () {
      if (this.d_selectedSchemaGroupType === 'selected' && this.d_selectedSchemaGroup.value) {
        if (this.d_selectedSchemaGroup.list[this.option_data[this.data_type].version]) {
          this.d_schemaList = JSON.parse(JSON.stringify(this.d_selectedSchemaGroup.list[this.option_data[this.data_type].version]));
        } else {
          this.d_schemaList = [];
        }
      }
    },
    f_addNewSchemaGroup: function () {
      this.d_newSchemaGroupTitle = '';
      this.d_newSchemaGroupOwnerType = this.d_selectedSchemaType;
      this.d_showNewSchemaGroup = true;
    },
    f_useSchema: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Şablon yükleniyor' } });
      let bucket = '';
      if (this.d_selectedSchemaType === 'favorite') {
        if (this.d_selectedSchema.d_selectedSchemaType === 'wisdom') {
          bucket = 'option';
        } else {
          bucket = 'hospital';
        }
      } else {
        if (this.d_selectedSchemaType === 'wisdom') {
          bucket = 'option';
        } else {
          bucket = 'hospital';
        }
      }
      GlobalService.get_one_cb_document({ 'key': this.d_selectedSchema.value, 'bucket': bucket })
        .then(resp => {
          // console.log(resp.data.result);
          if (resp.data.status === 'success') {
            if (this.p_schemaType === 'wdm_anatomy') {
              this.mother_children.d_showAnatomyData = false;
              if (!this.data.data.anatomy) {
                this.data.data.anatomy = JSON.parse(JSON.stringify(resp.data.result.schema_data));
              } else {
                if (JSON.stringify(this.data.data.anatomy).indexOf(JSON.stringify(resp.data.result.schema_data)) === -1) {
                  this.data.data.anatomy = {...JSON.parse(JSON.stringify(this.data.data.anatomy)), ...JSON.parse(JSON.stringify(resp.data.result.schema_data)) };
                  // TODO -- adding anatomy schema will warn user
                  // for (let ana_group in resp.data.result.schema_data) {
                  //   console.log(ana_group);
                  //   console.log(this.data.data.anatomy);
                  //   if (!this.data.data.anatomy[ana_group]) {
                  //     this.data.data.anatomy[ana_group] = resp.data.result.schema_data[ana_group];
                  //   }
                  // }
                }
              }
            } else {
              this.mother_children.d_showNonAnatomyData = false;
              this.data.data = JSON.parse(JSON.stringify(resp.data.result.schema_data));
            }
            setTimeout(function () {
              this.mother_children.d_showAnatomyData = true;
              this.mother_children.d_showNonAnatomyData = true;
              this.$store.commit('Loading', { 'status': false, 'data': {} });
            }.bind(this), 50)
          } else {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
          }
        });
    },
    f_calculateSchemaData: function () {
      let return_data = ClsWdmrForever.turn(this.data, this.option_data[this.data_type], ['schema']);
      return return_data.schema;
    },
    f_saveNewSchema: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Şablon kayıt işlemi başladı' } });
      let data = {};
      data.owner_type = this.d_selectedSchemaType;
      data.username = this.user.username;
      if (this.d_selectedSchemaType === 'hospital') {
        data.key_info = this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.hospital_group + '_' + this.user.hospital_id;
      } else {
        data.key_info = this.option_data[this.data_type].owner_type + '_' + this.data_type;
      }
      data.schema_name = this.d_newSchemaTitle;
      data.reference_wdm = {
        'data_type': this.data_type,
        'owner_type': this.option_data[this.data_type].owner_type,
        'version': this.option_data[this.data_type].version,
        'key': 'wdm_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_v' + this.option_data[this.data_type].version
      };
      if (this.option_data[this.data_type].owner_type === 'hospital') {
        data.reference_wdm.key = 'wdm_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.hospital_group + '_' + this.user.hospital_id + '_v' + this.option_data[this.data_type].version;
      }
      if (this.p_schemaType === 'wdm_anatomy') {
        data.schema_data = this.f_calculateSchemaData().anatomy;
      } else {
        data.schema_data = this.f_calculateSchemaData();
      }
      data.schema_group = this.d_selectedSchemaGroup.value;
      data.wdm_version = this.option_data[this.data_type].version;
      if (this.p_schemaType === 'wdm_anatomy') {
        SchemaService.new_wdm_anatomy_schema(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              this.d_schemaList.push(resp.data.result);
            }
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            this.d_showNewSchema = false;
          });
      } else {
        SchemaService.new_wdm_schema(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              this.d_schemaList.push(resp.data.result);
            }
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            this.d_showNewSchema = false;
          });
      }
    },
    f_saveNewSchemaGroup: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12728') } });
      let data = {};
      data.owner_type = this.d_newSchemaGroupOwnerType;
      data.username = this.user.username;
      data.wisdom_data_model = {
        'wdm': this.data_type,
        'owner_type': this.option_data[this.data_type].owner_type,
        'hospital_group': '',
        'hospital_id': ''
      }
      if (this.d_newSchemaGroupOwnerType === 'user') {
        data.key = 'schema_group_user_' + this.p_schemaType + '_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.username;
      } else if (this.d_newSchemaGroupOwnerType === 'wisdom') {
        data.key = 'schema_group_wisdom_' + this.p_schemaType + '_' + this.option_data[this.data_type].owner_type + '_' + this.data_type;
      } else if (this.d_newSchemaGroupOwnerType === 'hospital') {
        data.key = 'schema_group_hospital_' + this.p_schemaType + '_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.hospital_group + '_' + this.user.hospital_id;
      }
      if (this.option_data[this.data_type].owner_type === 'hospital') {
        data.wisdom_data_model.hospital_group = this.user.hospital_group;
        data.wisdom_data_model.hospital_id = this.user.hospital_id;
      }
      data.group_name = this.d_newSchemaGroupTitle;
      if (this.p_schemaType === 'wdm_anatomy') {
        SchemaService.new_wdm_anatomy_schema_group(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              this.f_getSchemaGroups();
            } else {
              this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            }
            this.d_showNewSchemaGroup = false;
          });
      } else {
        SchemaService.new_wdm_schema_group(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              this.f_getSchemaGroups();
            } else {
              this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            }
            this.d_showNewSchemaGroup = false;
          });
      }
    },
    f_getSchemaGroups: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Şablonları getiriliyor' } });
      let wisdom_key = 'schema_group_wisdom_' + this.p_schemaType + '_' + this.option_data[this.data_type].owner_type + '_' + this.data_type;
      let option_bucket_key_list = [];
      option_bucket_key_list.push(wisdom_key);
      let user_key = 'schema_group_user_' + this.p_schemaType + '_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.username;
      let hospital_key = 'schema_group_hospital_' + this.p_schemaType + '_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.hospital_group + '_' + this.user.hospital_id;
      let hospital_bucket_key_list = [];
      hospital_bucket_key_list.push(user_key);
      hospital_bucket_key_list.push(hospital_key);
      let data = {}
      data.schema_type = this.p_schemaType;
      data.data_type = this.data_type;
      data.wdm_owner_type = this.option_data[this.data_type].owner_type;
      data.username = this.user.username;
      data.hospital_group = this.user.hospital_group;
      data.hospital_id = this.user.hospital_id;
      SchemaService.get_schema_groups(data)
        .then(resp => {
          this.d_wisdomSchemaGroupList = []
          this.d_userSchemaGroupList = []
          this.d_hospitalSchemaGroupList = []
          if (resp.data.status === 'success') {
            if (resp.data.result.wisdom && resp.data.result.wisdom.list) {
              this.d_wisdomSchemaGroupList = JSON.parse(JSON.stringify(resp.data.result.wisdom.list));
            }
            if (resp.data.result.user && resp.data.result.user.list) {
              this.d_userSchemaGroupList = JSON.parse(JSON.stringify(resp.data.result.user.list));
            }
            if (resp.data.result.hospital && resp.data.result.hospital.list) {
              this.d_hospitalSchemaGroupList = JSON.parse(JSON.stringify(resp.data.result.hospital.list));
            }

            this.f_changeSelectedSchemaType();
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            this.$forceUpdate();
            if (this.p_schemaEdit) {
              if (this.p_schemaEdit.wdm_or_anatomy === 'wdm') {
                if (this.p_schemaEdit.schema_owner_type === 'wisdom') {
                  this.d_editSchemaGroupList = JSON.parse(JSON.stringify(this.d_wisdomWdmSchemaGroupList));
                } else if (this.p_schemaEdit.schema_owner_type === 'user') {
                  this.d_editSchemaGroupList = JSON.parse(JSON.stringify(this.d_userWdmSchemaGroupList));
                } else if (this.p_schemaEdit.schema_owner_type === 'hospital') {
                  this.d_editSchemaGroupList = JSON.parse(JSON.stringify(this.d_hospitalWdmSchemaGroupList));
                }
              } else {
                if (this.p_schemaEdit.schema_owner_type === 'wisdom') {
                  this.d_editSchemaGroupList = JSON.parse(JSON.stringify(this.d_wisdomSchemaGroupList));
                } else if (this.p_schemaEdit.schema_owner_type === 'user') {
                  this.d_editSchemaGroupList = JSON.parse(JSON.stringify(this.d_userSchemaGroupList));
                } else if (this.p_schemaEdit.schema_owner_type === 'hospital') {
                  this.d_editSchemaGroupList = JSON.parse(JSON.stringify(this.d_hospitalSchemaGroupList));
                }
              }
            }

          }
        });
      this.$forceUpdate();
    },
    f_updateWdmrSchemaSettings: function () {
      this.p_wdmrSettings.wdmr_schema[this.p_schemaType].d_selectedSchema = this.d_selectedSchema;
      this.p_wdmrSettings.wdmr_schema[this.p_schemaType].d_selectedSchemaType = this.d_selectedSchemaType;
      this.p_wdmrSettings.wdmr_schema[this.p_schemaType].d_selectedSchemaGroupType = this.d_selectedSchemaGroupType;
      this.p_wdmrSettings.wdmr_schema[this.p_schemaType].d_selectedSchemaGroup = this.d_selectedSchemaGroup;
    }
  },
  watch: {
    'd_selectedSchema': function () {
      this.f_updateWdmrSchemaSettings();
    },
    'd_selectedSchemaType': function () {
      this.f_updateWdmrSchemaSettings();
    },
    'd_selectedSchemaGroupType': function () {
      this.f_updateWdmrSchemaSettings();
    },
    'd_selectedSchemaGroup': function () {
      this.f_updateWdmrSchemaSettings();
    }
  },
  components: {
    vSelect,
    Modal,
    WisdomDataModal: () =>
      import ('@/components/widgets/WisdomDataModal')
  }
};

</script>

<style type="text/css">


</style>

