import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  save (data) {
    var d_uuid = 'new';
    if (data.id) {
      d_uuid = data.id;
    }
    let url = API_BASE_URL + 'package/' + d_uuid;
    return Vue.http.put(url, data);
  },
  search (data) {
    return Vue.http.get(API_BASE_URL + 'patient/' + data.patient_id + '/laboratory/' + data.lab_type + '/list?' + data.query);
  },
  get_package_rules (data) {
    return Vue.http.put(API_BASE_URL + 'package/get_package_rules/', data);
  },
  delete (pc_uid) {
    return Vue.http.delete(API_BASE_URL + 'package/delete/' + pc_uid);
  },
  get_packages () {
    return Vue.http.get(API_BASE_URL + 'packages');
  },
  get_eligible_packages () {
    return Vue.http.get(API_BASE_URL + 'get_eligible_packages');
  }
};
