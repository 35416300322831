import UserService from "../users";
import store from '../../store';

class ClsUserSettings {
  // user settings son hali authdaki user dökümanına güncelleniyor.
  static saveSettings = function(user_data) {
    UserService.saveSettings(user_data)
      .then(resp => {
        let msg = '';
        if (resp.data.status === 'success') {
          msg = 'Kullanıcı Ayarlarınız Başarıyla Güncellendi.';
        } else {
          msg = 'Error: ' + resp.data.message;
        }
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Kullanıcı Ayarları Düzenleme Hakkında' };
        store.commit('modal', modal_data);
        store.commit('modal_show', true);
      });
  }

  // aşağıdaki fonksiyonda user yapısı dizayn ediliyor.
  static free_structure = function(user_data) {
    // oncologyModule free design
    if (!user_data.settings) {
      user_data.settings = {};
    }
    if (!user_data.settings.oncologyModule) {
      user_data.settings.oncologyModule = {
        'name': {
          'label': 'Onkoloji Modülü Ayarları',
          'translation': { 'en': 'Oncology Module Settings', 'tr': 'Onkoloji Modülü Ayarları' }
        },
        'settings': {}
      };
    }
    if (!user_data.settings.oncologyModule.settings) {
      user_data.settings.oncologyModule.settings = {};
    }
    if (!user_data.settings.oncologyModule.settings.patients_chemo_list) {
      user_data.settings.oncologyModule.settings.patients_chemo_list = {
        'name': {
          'label': 'Hastalar kemogerapi listesi ayarları',
          'translation': { 'en': 'Patient chemotherapy list settings', 'tr': 'Hastalar kemogerapi listesi ayarları' }
        },
        'settings': {}
      };
    }
    if (!user_data.settings.oncologyModule.settings.patients_chemo_list.settings) {
      user_data.settings.oncologyModule.settings.patients_chemo_list.settings = {};
    }
    if (!user_data.settings.oncologyModule.settings.patients_chemo_list.settings.operation_list) {
      user_data.settings.oncologyModule.settings.patients_chemo_list.settings.operation_list = {
        'name': {
          'label': 'Hastalar kemogerapi listesi ayarları',
          'translation': { 'en': 'Patient chemotherapy list settings', 'tr': 'Hastalar kemogerapi listesi ayarları' }
        },
        'check_list': []
      }
    }
    // wdmr_record free design
    if (!user_data.settings.wdmr_record) {
      user_data.settings.wdmr_record = {
        'settings': {},
        'name': {
          'label': 'WDMR dökümanı kayıt ayarları',
          'translation': { 'en': 'WDMR document record settings', 'tr': 'WDMR dökümanı kayıt ayarları' }
        }
      };
    }
    if (!user_data.settings.wdmr_record.settings.favorite_show_mode) {
      user_data.settings.wdmr_record.settings.favorite_show_mode = {
        'settings': {},
        'name': {
          'label': 'WDMR dökümanı favori parametreler',
          'translation': { 'en': 'WDMR document favorite parameters settings', 'tr': 'WDMR dökümanı favori parametreler' }
        }
      };
    }
    if (!user_data.settings.wdmr_record.settings.dont_show_caption_mode) {
      user_data.settings.wdmr_record.settings.dont_show_caption_mode = {
        'settings': {},
        'name': {
          'label': 'WDMR dökümanı gösterilmeyecek başlıklar',
          'translation': { 'en': 'WDMR document do not show captions', 'tr': 'WDMR dökümanı gösterilmeyecek başlıklar' }
        }
      };
    }
    if (!user_data.settings.wdmr_record.settings.multi_wdmr_schema) {
      user_data.settings.wdmr_record.settings.multi_wdmr_schema = {
        'settings': {},
        'name': {
          'label': 'Multi WDMR şemaları görünüm ayarları',
          'translation': { 'en': 'Multi WDMR form view settings', 'tr': 'Multi WDMR şemaları görünüm ayarları' }
        }
      };
    }
    if (!user_data.settings.wdmr_record.settings.multi_wdmr_favorite_schema_list) {
      user_data.settings.wdmr_record.settings.multi_wdmr_favorite_schema_list = {
        'settings': {
          'list': []
        },
        'name': {
          'label': 'Multi WDMR favori şemaları listesi',
          'translation': { 'en': 'Multi WDMR favorite scheme list', 'tr': 'Multi WDMR favori şemaları listesi' }
        }
      };
    }
    if (!user_data.settings.wdmr_record.settings.wdm_schema_anatomy) {
      user_data.settings.wdmr_record.settings.wdm_schema_anatomy = {
        'settings': {},
        'name': {
          'label': 'Wdmler ile ilişkili favori anatomi şablonları',
          'translation': { 'en': 'Favorite anatomy schemas of wdm', 'tr': 'Wdmler ile ilişkili favori anatomi şablonları' }
        }
      };
    }
    if (!user_data.settings.wdmr_record.settings.wdm_general) {
      user_data.settings.wdmr_record.settings.wdm_general = {
        'settings': {},
        'name': {
          'label': 'Wdm genel ayarları',
          'translation': { 'en': 'Wdm general settings', 'tr': 'Wdm genel ayarları' }
        }
      };
    }
  }
};

export { ClsUserSettings };
