var favorite_drugs = [{
  'label': 'setuksimab',
  'translation': {
    'en': 'cetuximab',
    'tr': 'setuksimab'
  },
  'value': 'cetuximab'
}, {
  'label': 'granisetron',
  'translation': {
    'en': 'granisetron',
    'tr': 'granisetron'
  },
  'value': 'granisetron'
}, {
  'label': 'ranitidine',
  'translation': {
    'en': 'ranitidine',
    'tr': 'ranitidine'
  },
  'value': 'ranitidine'
}, {
  'label': 'palonosetron',
  'translation': {
    'en': 'palonosetron',
    'tr': 'palonosetron'
  },
  'value': 'palonosetron'
}, {
  'label': 'löprolid',
  'translation': {
    'en': 'leuprolide',
    'tr': 'löprolid'
  },
  'value': 'leuprolide'
}, {
  'label': 'Pertuzumab',
  'translation': {
    'en': 'Pertuzumab',
    'tr': 'Pertuzumab'
  },
  'value': 'pertuzumab'
}, {
  'label': 'interferon alfa-2a',
  'translation': {
    'en': 'peginterferon alfa-2a',
    'tr': 'interferon alfa-2a'
  },
  'value': 'peginterferon-alfa-2a'
}, {
  'label': 'goserelin',
  'translation': {
    'en': 'goserelin',
    'tr': 'goserelin'
  },
  'value': 'goserelin'
}, {
  'label': 'interferon alfa-2b',
  'translation': {
    'en': 'peginterferon alfa-2b',
    'tr': 'interferon alfa-2b'
  },
  'value': 'peginterferon-alfa-2b'
}, {
  'label': 'asparajinaz',
  'translation': {
    'en': 'asparaginase',
    'tr': 'asparajinaz'
  },
  'value': 'asparaginase'
}, {
  'label': 'atropine',
  'translation': {
    'en': 'atropine',
    'tr': 'atropine'
  },
  'value': 'atropine'
}, {
  'label': 'trastuzumab',
  'translation': {
    'en': 'trastuzumab',
    'tr': 'trastuzumab'
  },
  'value': 'trastuzumab'
}, {
  'label': 'trastuzumab emtansine',
  'translation': {
    'en': 'trastuzumab emtansine',
    'tr': 'trastuzumab emtansine'
  },
  'value': 'trastuzumab-emtansine'
}, {
  'label': 'rituksimab',
  'translation': {
    'en': 'rituximab',
    'tr': 'rituksimab'
  },
  'value': 'rituximab'
}, {
  'label': 'filgrastim',
  'translation': {
    'en': 'filgrastim',
    'tr': 'filgrastim'
  },
  'value': 'filgrastim'
}, {
  'label': 'oktreotid',
  'translation': {
    'en': 'octreotide',
    'tr': 'octreotide'
  },
  'value': 'octreotide'
}, {
  'label': 'bevasizumab',
  'translation': {
    'en': 'bevacizumab',
    'tr': 'bevasizumab'
  },
  'value': 'bevacizumab'
}, {
  'label': 'bortezomib',
  'translation': {
    'en': 'bortezomib',
    'tr': 'bortezomib'
  },
  'value': 'bortezomib'
}, {
  'label': 'kladribin',
  'translation': {
    'en': 'cladribine',
    'tr': 'kladribin'
  },
  'value': 'cladribine'
},
{
  'label': 'dexrazoxane',
  'translation': {
    'en': 'dexrazoxane',
    'tr': 'dexrazoxane'
  },
  'value': 'dexrazoxane'
},
{
  'label': 'karmustin',
  'translation': {
    'en': 'carmustine',
    'tr': 'karmustin'
  },
  'value': 'carmustine'
},
{
  'label': 'pamidronate',
  'translation': {
    'en': 'pamidronate',
    'tr': 'pamidronate'
  },
  'value': 'pamidronate'
}, {
  'label': 'bleomisin',
  'translation': {
    'en': 'bleomycin',
    'tr': 'bleomisin'
  },
  'value': 'bleomycin'
}, {
  'label': 'klorambusil',
  'translation': {
    'en': 'chlorambucil',
    'tr': 'klorambusil'
  },
  'value': 'chlorambucil'
}, {
  'label': 'mitomisin',
  'translation': {
    'en': 'mitomycin',
    'tr': 'mitomisin'
  },
  'value': 'mitomycin'
}, {
  'label': 'asetaminofen',
  'translation': {
    'en': 'acetaminophen',
    'tr': 'asetaminofen'
  },
  'value': 'acetaminophen'
}, {
  'label': 'aprepitant',
  'translation': {
    'en': 'aprapitant',
    'tr': 'aprepitant'
  },
  'value': 'aprepitant'
}, {
  'label': 'gefitinib',
  'translation': {
    'en': 'gefitinib',
    'tr': 'gefitinib'
  },
  'value': 'gefitinib'
}, {
  'label': 'megestrol acetate',
  'translation': {
    'en': 'megestrol acetate',
    'tr': 'megestrol acetate'
  },
  'value': 'megestrol-acetate'
}, {
  'label': 'tioguanin',
  'translation': {
    'en': 'tioguanine',
    'tr': 'tioguanin'
  },
  'value': 'tioguanine'
}, {
  'label': 'vinorelbin',
  'translation': {
    'en': 'vinorelbine',
    'tr': 'vinorelbin'
  },
  'value': 'vinorelbine'
}, {
  'label': 'sorafenib',
  'translation': {
    'en': 'sorafenib',
    'tr': 'sorafenib'
  },
  'value': 'sorafenib'
}, {
  'label': 'zoledronik asid',
  'translation': {
    'en': 'zoledronic acid',
    'tr': 'zoledronik asid'
  },
  'value': 'zoledronic-acid'
}, {
  'label': 'allopürinol',
  'translation': {
    'en': 'allopurinol',
    'tr': 'allopürinol'
  },
  'value': 'allopurinol'
}, {
  'label': 'gemsitabin',
  'translation': {
    'en': 'gemcitabine',
    'tr': 'gemsitabin'
  },
  'value': 'gemcitabine'
}, {
  'label': 'epirubisin',
  'translation': {
    'en': 'epirubicin',
    'tr': 'epirubisin'
  },
  'value': 'epirubicin'
}, {
  'label': 'lenalidomid',
  'translation': {
    'en': 'lenalidomide',
    'tr': 'lenalidomid'
  },
  'value': 'lenalidomide'
}, {
  'label': 'flutamid',
  'translation': {
    'en': 'flutamide',
    'tr': 'flutamid'
  },
  'value': 'flutamide'
}, {
  'label': 'sisplatin',
  'translation': {
    'en': 'cisplatin',
    'tr': 'sisplatin'
  },
  'value': 'cisplatin'
}, {
  'label': 'oksaliplatin',
  'translation': {
    'en': 'oxaliplatin',
    'tr': 'oksaliplatin'
  },
  'value': 'oxaliplatin'
}, {
  'label': 'erlotinib',
  'translation': {
    'en': 'erlotinib',
    'tr': 'erlotinib'
  },
  'value': 'erlotinib'
}, {
  'label': 'siklofosfamid',
  'translation': {
    'en': 'cyclophosphamide',
    'tr': 'siklofosfamid'
  },
  'value': 'cyclophosphamide'
}, {
  'label': 'denosumab',
  'translation': {
    'en': 'denosumab',
    'tr': 'denosumab'
  },
  'value': 'denosumab'
}, {
  'label': 'vinkristin',
  'translation': {
    'en': 'vincristine',
    'tr': 'vinkristin'
  },
  'value': 'vincristine'
}, {
  'label': 'fluorourasil',
  'translation': {
    'en': 'fluorouracil',
    'tr': 'fluorourasil'
  },
  'value': 'fluorouracil'
}, {
  'label': 'pentostatin',
  'translation': {
    'en': 'pentostatin',
    'tr': 'pentostatin'
  },
  'value': 'pentostatin'
}, {
  'label': 'methotreksat',
  'translation': {
    'en': 'methotrexate',
    'tr': 'methotreksat'
  },
  'value': 'methotrexate'
}, {
  'label': 'vinblastin',
  'translation': {
    'en': 'vinblastine',
    'tr': 'vinblastin'
  },
  'value': 'vinblastine'
}, {
  'label': 'imatinib',
  'translation': {
    'en': 'imatinib',
    'tr': 'imatinib'
  },
  'value': 'imatinib'
}, {
  'label': 'prednison',
  'translation': {
    'en': 'prednisone',
    'tr': 'prednison'
  },
  'value': 'prednisone'
}, {
  'label': 'pheniramine',
  'translation': {
    'en': 'pheniramine',
    'tr': 'pheniramine'
  },
  'value': 'pheniramine'
}, {
  'label': 'pemetreksed',
  'translation': {
    'en': 'pemetrexed',
    'tr': 'pemetreksed'
  },
  'value': 'pemetrexed'
}, {
  'label': 'lökovorin',
  'translation': {
    'en': 'leucovorin',
    'tr': 'lökovorin'
  },
  'value': 'leucovorin'
}, {
  'label': 'magnezyum sülfat',
  'translation': {
    'en': 'magnesium sulfate',
    'tr': 'magnezyum sülfat'
  },
  'value': 'magnesium-sulfate'
}, {
  'label': 'tamoksifen',
  'translation': {
    'en': 'tamoxifen',
    'tr': 'tamoksifen'
  },
  'value': 'tamoxifen'
}, {
  'label': 'daunorubicin',
  'translation': {
    'en': 'daunorubicin',
    'tr': 'daunorubicin'
  },
  'value': 'daunorubicin'
}, {
  'label': 'ibandronat',
  'translation': {
    'en': 'ibandronate',
    'tr': 'ibandronat'
  },
  'value': 'ibandronate'
}, {
  'label': 'klodronik asit',
  'translation': {
    'en': 'clodronic acid',
    'tr': 'klodronik asit'
  },
  'value': 'clodronic-acid'
}, {
  'label': 'hidrokortizon',
  'translation': {
    'en': 'hydrocortisone',
    'tr': 'hidrokortizon'
  },
  'value': 'hydrocortisone'
}, {
  'label': 'mannitol',
  'translation': {
    'en': 'mannitol',
    'tr': 'mannitol'
  },
  'value': 'mannitol'
}, {
  'label': 'tretinoin',
  'translation': {
    'en': 'tretinoin',
    'tr': 'tretinoin'
  },
  'value': 'tretinoin'
}, {
  'label': 'potasyum klorür',
  'translation': {
    'en': 'potassium chloride',
    'tr': 'potasyum klorür'
  },
  'value': 'potassium-chloride'
}, {
  'label': 'irinotekan',
  'translation': {
    'en': 'irinotecan',
    'tr': 'irinotekan'
  },
  'value': 'irinotecan'
}, {
  'label': 'etoposid',
  'translation': {
    'en': 'etoposide',
    'tr': 'etoposid'
  },
  'value': 'etoposide'
}, {
  'label': 'dakarbazin',
  'translation': {
    'en': 'dacarbazine',
    'tr': 'dakarbazin'
  },
  'value': 'dacarbazine'
}, {
  'label': 'temozolomid',
  'translation': {
    'en': 'temozolomide',
    'tr': 'temozolomid'
  },
  'value': 'temozolomide'
}, {
  'label': 'prednisolon',
  'translation': {
    'en': 'prednisolone',
    'tr': 'prednisolon'
  },
  'value': 'prednisolone'
}, {
  'label': 'mekloretamin',
  'translation': {
    'en': 'mechlorethamine',
    'tr': 'mekloretamin'
  },
  'value': 'mechlorethamine'
}, {
  'label': 'fulvestrant',
  'translation': {
    'en': 'fulvestrant',
    'tr': 'fulvestrant'
  },
  'value': 'fulvestrant'
}, {
  'label': 'karboplatin',
  'translation': {
    'en': 'carboplatin',
    'tr': 'karboplatin'
  },
  'value': 'carboplatin'
}, {
  'label': 'metilprednisolon',
  'translation': {
    'en': 'methylprednisolone',
    'tr': 'metilprednisolon'
  },
  'value': 'methylprednisolone'
}, {
  'label': 'daktinomisin',
  'translation': {
    'en': 'dactinomycin',
    'tr': 'daktinomisin'
  },
  'value': 'dactinomycin'
}, {
  'label': 'sitarabin',
  'translation': {
    'en': 'cytarabine',
    'tr': 'sitarabin'
  },
  'value': 'cytarabine'
}, {
  'label': 'eksemestan',
  'translation': {
    'en': 'exemestane',
    'tr': 'eksemestan'
  },
  'value': 'exemestane'
}, {
  'label': 'doksorubisin',
  'translation': {
    'en': 'doxorubicin',
    'tr': 'doksorubisin'
  },
  'value': 'doxorubicin'
}, {
  'label': 'hidroksiüre',
  'translation': {
    'en': 'hydroxyurea',
    'tr': 'hidroksiüre'
  },
  'value': 'hydroxyurea'
}, {
  'label': 'letrozole',
  'translation': {
    'en': 'letrozole',
    'tr': 'letrozole'
  },
  'value': 'letrozole'
}, {
  'label': 'ketokonazol',
  'translation': {
    'en': 'ketoconazole',
    'tr': 'ketokonazol'
  },
  'value': 'ketoconazole'
}, {
  'label': 'topotekan',
  'translation': {
    'en': 'topotecan',
    'tr': 'topotekan'
  },
  'value': 'topotecan'
}, {
  'label': 'merkaptopürin',
  'translation': {
    'en': 'mercaptopurine',
    'tr': 'merkaptopürin'
  },
  'value': 'mercaptopurine'
}, {
  'label': 'melfalan',
  'translation': {
    'en': 'melphalan',
    'tr': 'melfalan'
  },
  'value': 'melphalan'
}, {
  'label': 'fludarabin',
  'translation': {
    'en': 'fludarabine',
    'tr': 'fludarabin'
  },
  'value': 'fludarabine'
}, {
  'label': 'difenhidramin',
  'translation': {
    'en': 'diphenhydramine',
    'tr': 'difenhidramin'
  },
  'value': 'diphenhydramine'
}, {
  'label': 'kapesitabin',
  'translation': {
    'en': 'capecitabine',
    'tr': 'kapesitabin'
  },
  'value': 'capecitabine'
}, {
  'label': 'bikalutamid',
  'translation': {
    'en': 'bicalutamide',
    'tr': 'bikalutamid'
  },
  'value': 'bicalutamide'
}, {
  'label': 'prokarbazin',
  'translation': {
    'en': 'procarbazine',
    'tr': 'prokarbazin'
  },
  'value': 'procarbazine'
}, {
  'label': 'arsenik trioksid',
  'translation': {
    'en': 'arsenic trioxide',
    'tr': 'arsenik trioksid'
  },
  'value': 'arsenic-trioxide'
}, {
  'label': 'idarubisin',
  'translation': {
    'en': 'idarubicin',
    'tr': 'idarubisin'
  },
  'value': 'idarubicin'
}, {
  'label': 'ifosfamid',
  'translation': {
    'en': 'ifosfamide',
    'tr': 'ifosfamid'
  },
  'value': 'ifosfamide'
}, {
  'label': 'estramustine',
  'translation': {
    'en': 'estramustine',
    'tr': 'estramustine'
  },
  'value': 'estramustine'
}, {
  'label': 'mitoksantron',
  'translation': {
    'en': 'mitoxantrone',
    'tr': 'mitoksantron'
  },
  'value': 'mitoxantrone'
}, {
  'label': 'lomustin',
  'translation': {
    'en': 'lomustine',
    'tr': 'lomustin'
  },
  'value': 'lomustine'
}, {
  'label': 'ramusirumab',
  'translation': {
    'en': 'ramucirumab',
    'tr': 'ramusirumab'
  },
  'value': 'ramucirumab'
}, {
  'label': 'paklitaksel',
  'translation': {
    'en': 'paclitaxel',
    'tr': 'paklitaksel'
  },
  'value': 'paclitaxel'
}, {
  'label': 'deksametazon',
  'translation': {
    'en': 'dexamethasone',
    'tr': 'deksametazon'
  },
  'value': 'dexamethasone'
}, {
  'label': 'dosetaksel',
  'translation': {
    'en': 'docetaxel',
    'tr': 'dosetaksel'
  },
  'value': 'docetaxel'
}, {
  'label': 'dasatinib',
  'translation': {
    'en': 'dasatinib',
    'tr': 'dasatinib'
  },
  'value': 'dasatinib'
}, {
  'label': 'lapatinib',
  'translation': {
    'en': 'lapatinib',
    'tr': 'lapatinib'
  },
  'value': 'lapatinib'
}, {
  'label': 'sunitinib',
  'translation': {
    'en': 'sunitinib',
    'tr': 'sunitinib'
  },
  'value': 'sunitinib'
}, {
  'label': 'panitumumab',
  'translation': {
    'en': 'panitumumab',
    'tr': 'panitumumab'
  },
  'value': 'panitumumab'
}, {
  'label': 'nelarabin',
  'translation': {
    'en': 'nelarabine',
    'tr': 'nelarabin'
  },
  'value': 'nelarabine'
}, {
  'label': 'everolimus',
  'translation': {
    'en': 'everolimus',
    'tr': 'everolimus'
  },
  'value': 'everolimus'
}, {
  'label': 'iksabepilon',
  'translation': {
    'en': 'ixabepilone',
    'tr': 'iksabepilon'
  },
  'value': 'ixabepilone'
}, {
  'label': 'omasetaksin mepesüksinat',
  'translation': {
    'en': 'omacetaxine mepesuccinate',
    'tr': 'omasetaksin mepesüksinat'
  },
  'value': 'omacetaxine-mepesuccinate'
}, {
  'label': 'nilotinib',
  'translation': {
    'en': 'nilotinib',
    'tr': 'nilotinib'
  },
  'value': 'nilotinib'
}, {
  'label': 'kobimetinib',
  'translation': {
    'en': 'cobimetinib',
    'tr': 'kobimetinib'
  },
  'value': 'cobimetinib'
}, {
  'label': 'abirateron',
  'translation': {
    'en': 'abiraterone',
    'tr': 'abirateron'
  },
  'value': 'abiraterone'
}, {
  'label': 'romidepsin',
  'translation': {
    'en': 'romidepsin',
    'tr': 'romidepsin'
  },
  'value': 'romidepsin'
}, {
  'label': 'ipilimumab',
  'translation': {
    'en': 'ipilimumab',
    'tr': 'ipilimumab'
  },
  'value': 'ipilimumab'
}, {
  'label': 'temsirolimus',
  'translation': {
    'en': 'temsirolimus',
    'tr': 'temsirolimus'
  },
  'value': 'temsirolimus'
}, {
  'label': 'pazopanib',
  'translation': {
    'en': 'pazopanib',
    'tr': 'pazopanib'
  },
  'value': 'pazopanib'
}, {
  'label': 'bosutinib',
  'translation': {
    'en': 'bosutinib',
    'tr': 'bosutinib'
  },
  'value': 'bosutinib'
}, {
  'label': 'ofatumumab',
  'translation': {
    'en': 'ofatumumab',
    'tr': 'ofatumumab'
  },
  'value': 'ofatumumab'
}, {
  'label': 'bendamustin',
  'translation': {
    'en': 'bendamustine',
    'tr': 'bendamustin'
  },
  'value': 'bendamustine'
}, {
  'label': 'kabazitaksel',
  'translation': {
    'en': 'cabazitaxel',
    'tr': 'kabazitaksel'
  },
  'value': 'cabazitaxel'
}, {
  'label': 'siyanokobalamin',
  'translation': {
    'en': 'cyanocobalamin',
    'tr': 'siyanokobalamin'
  },
  'value': 'cyanocobalamin'
}, {
  'label': 'pralatreksat',
  'translation': {
    'en': 'pralatrexate',
    'tr': 'pralatreksat'
  },
  'value': 'pralatrexate'
}, {
  'label': 'krizotinib',
  'translation': {
    'en': 'crizotinib',
    'tr': 'krizotinib'
  },
  'value': 'crizotinib'
}, {
  'label': 'brentuksimab',
  'translation': {
    'en': 'brentuximab vedotin',
    'tr': 'brentuksimab'
  },
  'value': 'brentuximab-vedotin'
}, {
  'label': 'eribulin',
  'translation': {
    'en': 'eribulin',
    'tr': 'eribulin'
  },
  'value': 'eribulin'
}, {
  'label': 'vemurafenib',
  'translation': {
    'en': 'vemurafenib',
    'tr': 'vemurafenib'
  },
  'value': 'vemurafenib'
}, {
  'label': 'aflibercept',
  'translation': {
    'en': 'aflibercept',
    'tr': 'aflibercept'
  },
  'value': 'aflibercept'
}, {
  'label': 'regorafenib',
  'translation': {
    'en': 'regorafenib',
    'tr': 'regorafenib'
  },
  'value': 'regorafenib'
}, {
  'label': 'enzalutamide',
  'translation': {
    'en': 'enzalutamide',
    'tr': 'enzalutamide'
  },
  'value': 'enzalutamide'
}, {
  'label': 'ponatinib',
  'translation': {
    'en': 'ponatinib',
    'tr': 'ponatinib'
  },
  'value': 'ponatinib'
}, {
  'label': 'trametinib',
  'translation': {
    'en': 'trametinib',
    'tr': 'trametinib'
  },
  'value': 'trametinib'
}, {
  'label': 'dabrafenib',
  'translation': {
    'en': 'dabrafenib',
    'tr': 'dabrafenib'
  },
  'value': 'dabrafenib'
}, {
  'label': 'obinutuzumab',
  'translation': {
    'en': 'obinutuzumab',
    'tr': 'obinutuzumab'
  },
  'value': 'obinutuzumab'
}, {
  'label': 'nivolumab',
  'translation': {
    'en': 'nivolumab',
    'tr': 'nivolumab'
  },
  'value': 'nivolumab'
}, {
  'label': 'pembrolizumab',
  'translation': {
    'en': 'pembrolizumab',
    'tr': 'pembrolizumab'
  },
  'value': 'pembrolizumab'
}, {
  'label': 'ibrutinib',
  'translation': {
    'en': 'ibrutinib',
    'tr': 'ibrutinib'
  },
  'value': 'ibrutinib'
}, {
  'label': 'mesna',
  'translation': {
    'en': 'mesna',
    'tr': 'mesna'
  },
  'value': 'mesna'
}, {
  'label': 'radyum 223',
  'translation': {
    'en': 'radium ra-223',
    'tr': 'radyum 223'
  },
  'value': 'radium-ra-223'
}, {
  'label': 'nab-paklitaksel',
  'translation': {
    'en': 'nab-paclitaxel',
    'tr': 'nab-paklitaksel'
  },
  'value': 'nab-paclitaxel'
}, {
  'label': 'radyoterapi',
  'translation': {
    'en': 'radiotherapy',
    'tr': 'radyoterapi'
  },
  'value': 'radiotherapy'
}, {
  'label': 'kranial radyoterapi',
  'translation': {
    'en': 'cranial irradiation',
    'tr': 'kranial radyoterapi'
  },
  'value': 'cranial-irradiation'
}, {
  'label': 'liposomal doksorubisin',
  'translation': {
    'en': 'liposomal doxorubicin',
    'tr': 'liposomal doksorubisin'
  },
  'value': 'liposomal-doxorubicin'
}, {
  'label': 'liposomal vinkristin sülfat',
  'translation': {
    'en': 'vincristine sulfate liposome',
    'tr': 'liposomal vinkristin sülfat'
  },
  'value': 'vincristine-sulfate-liposome'
}, {
  'label': '1000 cc % 0.9 sodium chloride',
  'translation': {
    'en': '1000 cc % 0.9 sodium chloride',
    'tr': '1000 cc % 0.9 sodium chloride'
  },
  'value': '1000cc-09-sodium-chloride'
}, {
  'label': '1000 cc % 5 dextrose',
  'translation': {
    'en': '1000 cc % 5 dextrose',
    'tr': '1000 cc % 5 dextrose'
  },
  'value': '1000cc-5-dextrose'
}, {
  'label': '100 cc % 0.9 sodium chloride',
  'translation': {
    'en': '100 cc % 0.9 sodium chloride',
    'tr': '100 cc % 0.9 sodium chloride'
  },
  'value': '100cc-09-sodium-chloride'
}, {
  'label': '100 cc % 5 dextrose',
  'translation': {
    'en': '100 cc % 5 dextrose',
    'tr': '100 cc % 5 dextrose'
  },
  'value': '100cc-5-dextrose'
}, {
  'label': '150 cc % 0.9 sodium chloride',
  'translation': {
    'en': '150 cc % 0.9 sodium chloride',
    'tr': '150 cc % 0.9 sodium chloride'
  },
  'value': '150cc-09-sodium-chloride'
}, {
  'label': '150 cc % 5 dextrose',
  'translation': {
    'en': '150 cc % 5 dextrose',
    'tr': '150 cc % 5 dextrose'
  },
  'value': '150cc-5-dextrose'
}, {
  'label': '250 cc % 0.9 sodium chloride',
  'translation': {
    'en': '250 cc % 0.9 sodium chloride',
    'tr': '250 cc % 0.9 sodium chloride'
  },
  'value': '250cc-09-sodium-chloride'
}, {
  'label': '250 cc % 5 dextrose',
  'translation': {
    'en': '250 cc % 5 dextrose',
    'tr': '250 cc % 5 dextrose'
  },
  'value': '250cc-5-dextrose'
}, {
  'label': '500 cc % 0.9 sodium chloride',
  'translation': {
    'en': '500 cc % 0.9 sodium chloride',
    'tr': '500 cc % 0.9 sodium chloride'
  },
  'value': '500cc-09-sodium-chloride'
}, {
  'label': '500 cc % 5 dextrose',
  'translation': {
    'en': '500 cc % 5 dextrose',
    'tr': '500 cc % 5 dextrose'
  },
  'value': '500cc-5-dextrose'
}, {
  'label': '50 cc % 0.9 sodium chloride',
  'translation': {
    'en': '50 cc % 0.9 sodium chloride',
    'tr': '50 cc % 0.9 sodium chloride'
  },
  'value': '50cc-09-sodium-chloride'
}, {
  'label': '1000 cc % 0.9 sodium chloride or 1000 cc % 5 dextrose',
  'translation': {
    'en': '1000 cc % 0.9 sodium chloride or 1000 cc % 5 dextrose',
    'tr': '1000 cc % 0.9 sodium chloride or 1000 cc % 5 dextrose'
  },
  'value': '1000cc-09-sodium-chloride-or-1000cc-5-dextrose'
}, {
  'label': '100 cc % 0.9 sodium chloride or 100 cc % 5 dextrose',
  'translation': {
    'en': '100 cc % 0.9 sodium chloride or 100 cc % 5 dextrose',
    'tr': '100 cc % 0.9 sodium chloride or 100 cc % 5 dextrose'
  },
  'value': '100cc-09-sodium-chloride-or-100cc-5-dextrose'
}, {
  'label': '250 cc % 0.9 sodium chloride or 250 cc % 5 dextrose',
  'translation': {
    'en': '250 cc % 0.9 sodium chloride or 250 cc % 5 dextrose',
    'tr': '250 cc % 0.9 sodium chloride or 250 cc % 5 dextrose'
  },
  'value': '250cc-09-sodium-chloride-or-250cc-5-dextrose'
}, {
  'label': '500 cc % 0.9 sodium chloride or 500 cc % 5 dextrose',
  'translation': {
    'en': '500 cc % 0.9 sodium chloride or 500 cc % 5 dextrose',
    'tr': '500 cc % 0.9 sodium chloride or 500 cc % 5 dextrose'
  },
  'value': '500cc-09-sodium-chloride-or-500cc-5-dextrose'
}, {
  'label': '50 cc % 0.9 sodium chloride or 50 cc % 5 dextrose',
  'translation': {
    'en': '50 cc % 0.9 sodium chloride or 50 cc % 5 dextrose',
    'tr': '50 cc % 0.9 sodium chloride or 50 cc % 5 dextrose'
  },
  'value': '50cc-09-sodium-chloride-or-50cc-5-dextrose'
}];

export { favorite_drugs as options };
