<template>
  <div>
    <b-row style="padding: 5px; box-shadow: -1px -1px 1px #feff95; margin: 10px; border: solid 1px #d4d4d4;">
      <b-col sm="12" md="11"></b-col>
      <b-col sm="12" md="1">
        <b-dropdown variant="secondary" text="" size="md">
          <b-dropdown-item @click="f_addParamAsColumn()"> yeni kolon oluştur </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import vSelect from 'vue-select';
import { mapGetters } from 'vuex';
import { RuleEngine } from '@/services/public/functions';

export default {
  name: 'WisdomDataPoleStar',
  props: {
    option_data: {
      type: Object,
      required: false
    },
    param_change: {
      type: Object,
      required: true
    },
    param_data: {
      type: Object,
      required: false
    },
    option_loc: {
      type: Object,
      required: false
    },
    record_loc: {
      type: Object,
      required: false
    },
    wdm_name: {
      type: Object,
      required: false
    },
    data_type: {
      type: String,
      required: true
    },
    query_list: {
      type: Array,
      required: false
    },
    column_list: {
      type: Array,
      required: false
    }
  },
  mounted () {},
  computed: mapGetters({
    lang: 'lang'
  }),
  data () {
    return {
      param_rule_data: {
        "time_of_value": "any_value",
        "data_type": "old_recorded_data",
        "val_list": [],
        "record_type": "many",
        "significant": "true",
        "requesting_operation": "wisdom_data_save",
        "date": "1",
        "op": "="
      }
    };
  },
  methods: {
    f_addParamAsColumn: function () {
      let col_data = {
        'loc': this.option_loc,
        'type': 'wdm',
        'wdm_type': this.data_type,
        'wdm_name': this.wdm_name,
        'param_type': this.param_data.type,
        'date_ability': this.option_data[this.wdm_name.value].date_ability,
        'date_ability_limit': this.option_data[this.wdm_name.value].date_ability_limit,
        'date_ability_type': this.option_data[this.wdm_name.value].date_ability_type,
        'record_count': this.option_data[this.wdm_name.value].record_count
      };
      /*
      for (let i in this.column_list) {
        if (JSON.stringify(this.column_list[i]) === JSON.stringify(col_data)) {
          let msg = 'Bu kolon daha önceden eklenmiştir';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }
      }
      */
      this.column_list.push(col_data);
    },
    f_paramChangeRule: function () {
      if (this.param_change.rule) {
        this.param_change.rule = 0;
      } else {
        this.param_change.rule = 1;
      }
    },
    AddToValList: function (val, param_data) {
      // console.log(val);
      if (val) {
        if (['select', 'object_list'].indexOf(param_data.type) !== -1) {
          let det = false;
          for (let i in this.param_rule_data.val_list) {
            if (this.param_rule_data.val_list[i].value === val.value) {
              det = true;
              alert('bu değer zaten eklenmiştir.');
              return;
            }
          }
          if (!det) { this.param_rule_data.val_list.push(val); }
        } else { // integer, float
          this.param_rule_data.val_list = [];
          this.param_rule_data.val_list.push(val);
        }
      }
      this.$forceUpdate();
    },
    add_new_query: function () {
      let rule_data = JSON.parse(JSON.stringify(this.param_rule_data));
      let query = {};
      query = {
        'fields': [],
        'significant': 'true',
        'date': rule_data.date,
        'type': this.wdm_name,
        'data_type': rule_data.data_type,
        'time_of_value': rule_data.time_of_value,
        'compile_str_array': [],
        'compile_str': ''
      };
      let field_data_list = this.field_data_create();
      for (let f in field_data_list) {
        query.fields.push(field_data_list[f]);
        this.compile_str_func(query, 'new');
      }
      // console.log(query);
      this.query_list.push(query);
      this.field_loc_func();
      this.f_paramChangeRule();
    },
    add_to_this_query: function (query_ind) {
      let field_data_list = this.field_data_create();
      for (let f in field_data_list) {
        let det = false;
        for (let i in this.query_list[query_ind].fields) {
          if (JSON.stringify(field_data_list[f]) === JSON.stringify(this.query_list[query_ind].fields[i])) {
            this.query_list[query_ind].fields[i].values = JSON.parse(JSON.stringify(field_data_list[f].values));
            det = true;
            break;
          }
        }
        if (!det) {
          this.query_list[query_ind].fields.push(field_data_list[f]);
          this.compile_str_func(this.query_list[query_ind], 'field');
        }
      }
      this.field_loc_func();
      this.f_paramChangeRule();
    },
    compile_str_func: function (query, type) {
      if (type === 'new') {
        query.compile_str_array = ['(', 0, ')'];
      } else if (type === 'field') {
        let last_field_index = query.fields.length - 1;
        query.compile_str_array.splice(query.compile_str_array.length - 1, 1);
        query.compile_str_array.push('and');
        query.compile_str_array.push(last_field_index);
        query.compile_str_array.push(')');
      }
    },
    field_data_create: function () {
      let rule_data = JSON.parse(JSON.stringify(this.param_rule_data));
      let option_loc_data = JSON.parse(JSON.stringify(this.option_loc));
      let value_type = this.param_data.type;
      let field_data_list = [];
      // backendde sorgularken tipinin object_list_options olmasına ve level değerine bakıyoruz.
      if (this.param_data.type === 'object_list') {
        // her bir value için yani her bir object list options elemanı için yeni bir field oluşturuluyor
        for (let i in rule_data.val_list) {
          option_loc_data = JSON.parse(JSON.stringify(this.option_loc));
          value_type = 'object_list_options';
          if (this.option_loc.level === '1') {
            option_loc_data.pr1 = this.param_data.object_param;
            option_loc_data.param = this.param_data.object_param;
          } else if (this.option_loc.level === '2') {
            option_loc_data.pr2 = this.param_data.object_param;
            option_loc_data.param = this.param_data.object_param;
          } else if (this.option_loc.level === '3') {
            option_loc_data.pr3 = this.param_data.object_param;
            option_loc_data.param = this.param_data.object_param;
          }
          let val_list = [];
          let values = [];
          val_list.push(rule_data.val_list[i]);
          values.push(rule_data.val_list[i].value);
          let field_data = { value_type: value_type, significant: rule_data.significant, using_data_if_not_reaching_record_data: 'true', val_list: val_list, values: values, operation: rule_data.op, loc: option_loc_data };
          field_data_list.push(field_data);
        }
      } else {
        let values = [];
        if (['select'].indexOf(this.param_data.type) !== -1) {
          for (let i in rule_data.val_list) {
            values.push(rule_data.val_list[i].value);
          }
        } else if (['integer', 'float'].indexOf(this.param_data.type) !== -1) {
          values = rule_data.val_list;
        }
        let field_data = { value_type: value_type, significant: rule_data.significant, using_data_if_not_reaching_record_data: 'true', val_list: rule_data.val_list, values: values, operation: rule_data.op, loc: option_loc_data };
        field_data_list.push(field_data);
      }
      return field_data_list;
    },
    field_loc_func: function () {
      RuleEngine.field_tree_calculate(this.query_list);
      this.$forceUpdate();
    }
  },
  watch: {},
  components: {}
};

</script>

<style>
.info-wisdom {
  color: red;
  background-color: #abf1d1;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

</style>

