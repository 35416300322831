<template>
  <div>
    <template v-if="d_selectedDmpKey">
      <b-row style="margin: 0px;">
        <b-col sm="12" md="1">
          <img src="@/icon/2324511.png" style="width: 5em;" />
        </b-col>
        <b-col sm="12" md="8">
          <b-row style="margin: 0px;" v-if="rule_results.list[d_selectedDmpKey].true_rules[rule_index].results && rule_results.list[d_selectedDmpKey].true_rules[rule_index].results.combine_text_result">
            <b-col sm="12" md="12">
              <strong>{{ rule_results.list[d_selectedDmpKey].true_rules[rule_index].data.general.message.val }} </strong>
              <small>
            (
            <span style="margin-right: 5px; padding: 0px; cursor: pointer;" @click="f_getOptionData(rule_results.list[d_selectedDmpKey].true_rules[rule_index].reference_wdm)">
              <img src="@/icon/680848.png" :title="$t('wdm16.11237')" style="width: 2em;" />
              <i>{{ rule_results.list[d_selectedDmpKey].label }}....</i>
            </span>
            )
          </small>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;" v-if="rule_results.list[d_selectedDmpKey].true_rules[rule_index].results && rule_results.list[d_selectedDmpKey].true_rules[rule_index].results.combine_text_result">
            <b-col sm="12" md="12">
              {{ f_getTextResult(rule_results.list[d_selectedDmpKey].true_rules[rule_index].results.combine_text_result) }}
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" md="3" style="text-align: right;">
          <template v-if="rule_results.list[d_selectedDmpKey].true_rules.length > 1">
            <div class="pagination pull-right">
              <ul>
                <!-- <li class="prev"><button @click="ignore_this_rule(rule_results.list[rule_index].id)">  ignore  </button></li> -->
                <li class="prev">
                  <button @click="back_next_more('back_one')">
                    < </button>
                </li>
                <li class="prev">
                  <button @click="back_next_more('next_one')"> > </button>
                </li>
                <li class="prev"> {{ rule_index + 1 }} / {{ rule_results.list[d_selectedDmpKey].true_rules.length }} </li>
              </ul>
            </div>
          </template>
        </b-col>
      </b-row>
      <b-row style="margin: 0px;" v-if="rule_results.list[d_selectedDmpKey].true_rules[rule_index].data.general.message_image && rule_results.list[d_selectedDmpKey].true_rules[rule_index].data.general.message_image.val">
        <b-col sm="12" md="2">
        </b-col>
        <b-col sm="12" md="8">
          <br>
          <img :src="rule_results.list[d_selectedDmpKey].true_rules[rule_index].data.general.message_image.val" style="width: 100%;" />
        </b-col>
        <b-col sm="12" md="2">
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="6">
        </b-col>
        <b-col sm="12" md="6" style="text-align: right;">
        </b-col>
      </b-row>
    </template>
    <!-- <rule-detail :rule_data="rule_results.list[rule_index]" :rule_result="true" :patient_id="rule_results.patient_id"></rule-detail> -->
    <modal v-if="d_showWisdomShow" @close="d_showWisdomShow = false" :width="'750'">
      <h3 slot="header">{{ $t('wdm16.11238') }}</h3>
      <div slot="body">
        <wisdom-data-show :change="change" :view_mode="'table'" :data="rule_results.list[d_selectedDmpKey].true_rules[rule_index]" :data_type="'rule'" :option_data="option_data"></wisdom-data-show>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-secondary" @click="d_showWisdomShow = false">{{ $t("wdm16.4053") }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import GlobalService from "@/services/global";
import {
  default as RuleDetail
} from '@/components/widgets/RuleDetail';

import OptionService from '@/services/option';
import {
  default as Modal
} from './Modal';

import {
  default as WisdomDataModal
} from './WisdomDataModal';

import {
  default as WisdomDataShow
} from './WisdomDataShow';
import {
  default as router
} from '@/router';

import WisdomDataService from '@/services/wisdom_data';
import { mapGetters } from 'vuex';
export default {
  name: 'RuleEngine',
  mounted () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.d_selectedDmpKey = Object.keys(this.rule_results.list)[0];
  },
  computed: {
    ...mapGetters({
      patient: 'patient_data.selected',
      lang: 'lang'
    })
  },
  props: {
    rule_results: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      change: '0',
      data_type: '',
      wisdom_data: {},
      option_data: {},
      show_wisdom_data_modal: false,
      evaluated_rules: [],
      user: {},
      rule_index: 0,
      d_showWisdomShow: false,
      d_selectedDmpKey: ''
    };
  },
  created () {
    // this.f_getOptionData(this.rule_results.list[this.d_selectedDmpKey].true_rules[0].reference_wdm);
  },
  methods: {
    f_getTextResult: function (text_list) {
      let str = '';
      for (let i in text_list) {
        for (let t in text_list[i]) {
          str += text_list[i][t];
          if (parseInt(t) !== text_list[i][t].length - 1) {
            str += ' ';
          } else {
            str += '.';
          }
        }
      }
      return str;
    },
    f_getOptionData: function (reference_wdm) {
      this.d_showWisdomShow = false;
      let buck = '';
      if (reference_wdm.owner_type === 'wisdom') {
        buck = 'option';
      } else {
        buck = 'hospital';
      }
      let data = { 'key': reference_wdm.key, 'bucket': buck };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
      GlobalService.get_one_cb_document(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.option_data = resp.data.result;
            this.d_showWisdomShow = true;
          } else {
            console.log('error ', resp.data.message);
          }
        });
    },
    wisdom_show_update: function () {
      if (this.change === "0") {
        this.change = "1";
      } else {
        this.change = "0";
      }
      this.$forceUpdate();
    },
    ignore_this_rule: function (rule_id) {
      if (this.rule_results.rule_ignore.indexOf(rule_id) === -1) {
        this.rule_results.rule_ignore.push(rule_id);
      } else {
        alert('Already added to ignored rule list');
      }
    },
    back_next_more: function (val) {
      if (val === 'next_one' && this.rule_index !== this.rule_results.list.length - 1) {
        this.rule_index += 1;
      } else if (val === 'back_one' && this.rule_index !== 0) {
        this.rule_index -= 1;
      }
      // this.f_getOptionData(this.rule_results.list[this.d_selectedDmpKey].true_rules[this.rule_index].reference_wdm);
    }
  },
  watch: {},
  components: {
    WisdomDataShow,
    WisdomDataModal,
    Modal,
    RuleDetail
  }
};

</script>

<style type="text/css">
.footer {
  position: fixed;
  bottom: 100px;
  left: 0px;
  width: 100%;
  height: 50px;
  padding: 15px;
}

.pagination {
  border-radius: 3px;
  border-top-color: #c5c5c5;
  border-bottom-color: #fff;
  padding: 2px 2px 3px 1px;
  display: inline-block;
}

.pagination ul {
  margin: 0;
  padding: 0;
  list-style: none;
  float: left;
}

.pagination li {
  float: left;
  margin: 0 4px 0 0;
  font-size: 12px;
}

.pagination li button {
  cursor: pointer;
  float: left;
  background: #20a8d8;
  background: linear-gradient(to bottom, #fff, #f5f5f5);
  border-radius: 3px;
  padding: 4px 10px;
  border: 1px solid #d9d9d9;
  border-bottom-color: #b6b7b9;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
  color: #7b8095;
  text-decoration: none;
}

</style>

