import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  get_patient_info_with_identity_no (data) {
    return Vue.http.put(API_BASE_URL + 'webservice/patient_info_with_identity_no', data);
  },
  getHimsDoctorProtocolList (query) {
    return Vue.http.get(API_BASE_URL + 'webservice/getHimsDoctorProtocolList?' + query);
  },
  getPersonelIdWithHimsUserName (query) {
    return Vue.http.get(API_BASE_URL + 'webservice/getPersonelIdWithHimsUserName?' + query);
  },
  getAllHimsUsersByHospitalCode (query) {
    return Vue.http.get(API_BASE_URL + 'webservice/getAllHimsUsersByHospitalCode?' + query);
  },
  get_lab_result (data) {
    return Vue.http.put(API_BASE_URL + 'webservice/get_lab_result', data);
  },
  make_request_to_get_data_from_whs (data) {
    return Vue.http.post(API_BASE_URL + 'webservice/make_request_to_get_data_from_whs', data);
  },
  filter_inspection_info (data) {
    return Vue.http.post(API_BASE_URL + 'webservice/filter_inspection_info', data);
  },
  prepare_inspection_wdmr_and_wdm (data) {
    return Vue.http.post(API_BASE_URL + 'webservice/prepare_inspection_wdmr_and_wdm', data);
  },
  SendWisdomDataToProtocolInspection (data) {
    return Vue.http.put(API_BASE_URL + 'webservice/SendWisdomDataToProtocolInspection', data);
  },
  SendProtocolInspectionOriginalData (data) {
    return Vue.http.put(API_BASE_URL + 'webservice/SendProtocolInspectionOriginalData', data);
  },
  get_open_protocol_list (query) {
    return Vue.http.get(API_BASE_URL + 'webservice/get_open_protocol_list?' + query);
  },
  getPatientLaboratoryByProtocolInfo (data) {
    return Vue.http.post(API_BASE_URL + 'webservice/getPatientLaboratoryByProtocolInfo', data);
  },
  getPatientLaboratoryByDate (query) {
    return Vue.http.get(API_BASE_URL + 'webservice/getPatientLaboratoryByDate?' + query);
  },
  getAllProtocolList (query) {
    return Vue.http.get(API_BASE_URL + 'webservice/getAllProtocolList?' + query);
  },
  getHospitalLaboratoryTestListByHospicalCode (query) {
    return Vue.http.get(API_BASE_URL + 'webservice/getHospitalLaboratoryTestListByHospicalCode?' + query);
  },
  GetInspectionOfThisProtocol (query) {
    return Vue.http.get(API_BASE_URL + 'webservice/GetInspectionOfThisProtocol?' + query);
  },
  get_clinical_branches_of_hospital (query) {
    return Vue.http.get(API_BASE_URL + 'webservice/get_clinical_branches_of_hospital?' + query);
  },
  get_unit_definitions_of_hospital (query) {
    return Vue.http.get(API_BASE_URL + 'webservice/get_unit_definitions_of_hospital?' + query);
  },
  get_hims_protocol_pharmacy (query) {
    return Vue.http.get(API_BASE_URL + 'webservice/get_hims_protocol_pharmacy?' + query);
  },
  get_hims_laboratory_test_groups (query) {
    return Vue.http.get(API_BASE_URL + 'webservice/get_hims_laboratory_test_groups?' + query);
  },
  get_hims_laboratory_test_infos (query) {
    return Vue.http.get(API_BASE_URL + 'webservice/get_hims_laboratory_test_infos?' + query);
  },
  get_hims_radiology_test_groups (query) {
    return Vue.http.get(API_BASE_URL + 'webservice/get_hims_radiology_test_groups?' + query);
  },
  get_hims_radiology_test_infos (query) {
    return Vue.http.get(API_BASE_URL + 'webservice/get_hims_radiology_test_infos?' + query);
  },
  get_hims_patient_report_information (query) {
    return Vue.http.get(API_BASE_URL + 'webservice/get_hims_patient_report_information?' + query);
  },
  get_hims_patient_report_details (query) {
    return Vue.http.get(API_BASE_URL + 'webservice/get_hims_patient_report_details?' + query);
  },
  get_hims_patient_report_drug_active_ingredients (query) {
    return Vue.http.get(API_BASE_URL + 'webservice/get_hims_patient_report_drug_active_ingredients?' + query);
  }
};
