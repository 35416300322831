<template>
  <div>
    <template>
      <b-card no-body header-tag="header" footer-tag="footer" border-variant="primary" class="mb-1">
        <b-card-header v-b-toggle="'wdm_old_data'" header-bg-variant="primary" class="p-1" style="cursor: pointer;">
          <strong>Eski Veri Kullanımı</strong>
        </b-card-header>
        <b-collapse :visible="true" :id="'wdm_old_data'">
          <b-row style="margin: 2px;">
            <b-col sm="12" md="4" lg="4">
              Eski Veri Seçimi
            </b-col>
            <b-col sm="12" md="8" lg="8">
              <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '150')">?</b-badge>
              <b-input-group>
                <v-select v-model="d_selectedOldWdmrData" name="d_selectedOldWdmrData" :options="d_wdmrOldDataList" style="width:50%;">
                </v-select>
                <b-input-group-append>
                  <b-button :disabled="d_selectedOldWdmrData && d_selectedOldWdmrData.value ? false : true" size="sm" block variant="primary" @click="f_useWdmrOldData()"><i class="fa fa-plus"></i> eski veriyi kullan</b-button>
                </b-input-group-append>
                <b-input-group-append>&nbsp;
                  <b-button :disabled="f_checkEditOldData()" size="sm" block variant="primary" @click="f_editWdmrOldData()"><i class="fa fa-edit"></i> eski veriyi düzenle</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </b-collapse>
      </b-card>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import vSelect from "vue-select";
import GlobalService from '@/services/global';
import PatientService from "@/services/patient";
import { ClsWdmrForever } from "@/services/public/wdm";

export default {
  name: 'WdmrOldData',
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      lang: 'lang',
      help: 'help'
    })
  },
  props: {
    option_data: {
      type: Object,
      required: true,
      default: () => {}
    },
    data_type: {
      type: String,
      required: true
    },
    patient_id: {
      type: String,
      required: false
    },
    data: {
      type: Object,
      required: false,
      default: () => {}
    },
    mother_children: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      user: {},
      d_wdmrOldDataList: [],
      d_selectedOldWdmrData: ''
    };
  },
  created () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted () {
    this.f_getPatientOldTests();
  },
  methods: {
    f_checkEditOldData: function () {
      if (!this.d_selectedOldWdmrData || !this.d_selectedOldWdmrData.value) {
       return true;
      }
      for (let i in this.user.position_department_title) {
        if (this.user.position_department_title[i].active && this.user.position_department_title[i].department.value === this.d_selectedOldWdmrData.department) {
          return false;
        }
      }
      return true;
    },
    f_getPatientOldTests: function () {
      this.selected_old_test = { 'label': this.$t('wdm16.12734'), 'value': 'updating' };
      PatientService.get_patient_wdmr_for_wdm_version({ 'patient_id': this.patient_id, 'type': this.data_type, 'owner_type': this.option_data[this.data_type].owner_type, 'version': this.option_data[this.data_type].version })
        .then(resp => {
          // console.log(resp);
          this.d_wdmrOldDataList = resp.data.result;
          this.$forceUpdate();
        });
    },
    f_useWdmrOldData: function () {
      GlobalService.get_one_cb_document({ 'key': this.d_selectedOldWdmrData.value, 'bucket': 'wisdom' })
        .then(resp => {
          // console.log(resp.data.result);
          if (resp.data.status === 'success') {
            this.mother_children.d_showWdmrData = false;
            this.data.data = JSON.parse(JSON.stringify(resp.data.result.data));
            setTimeout(function () {
              this.mother_children.d_showWdmrData = true;
            }.bind(this), 500)
          }
        });
    },
    f_editWdmrOldData: function () {
      GlobalService.get_one_cb_document({ 'key': this.d_selectedOldWdmrData.value, 'bucket': 'wisdom' })
        .then(resp => {
          if (resp.data.status === 'success') {
            this.mother_children.d_showWdmrData = false;
            this.f_updatePropObj(resp.data.result);
            setTimeout(function () {
              this.mother_children.d_showWdmrData = true;
            }.bind(this), 500)
          }
        });
    },
    f_updatePropObj: function (new_data) {
      for (let i in new_data) {
        if (i === 'date') {
          this.data[i] = this.f_calculateDateFromWdmr(new_data['date'], new_data['date_type']);
        } else if (i === 'ending_date') {
          this.data[i] = this.f_calculateDateFromWdmr(new_data['ending_date'], new_data['ending_date_type']);
        } else {
          this.data[i] = new_data[i];
        }
      }
    },
    f_calculateDateFromWdmr: function (date, date_type) {
      if (date_type === 'datetime') {
        return date.split('T')[0] + ' ' + date.split('T')[1].split(':')[0] + ':' + date.split('T')[1].split(':')[1];
      } else if (date_type === 'date') {
        return date.split('T')[0];
      } else if (date_type === 'month') {
        return date.split('T')[0].split('-')[0] + '-' + date.split('T')[0].split('-')[1];
      } else if (date_type === 'year') {
        return date.split('T')[0].split('-')[0];
      }
    }
  },
  watch: {},
  components: {
    vSelect
  }
};

</script>

<style type="text/css"></style>

