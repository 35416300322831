var columns = {
  'user': {
    'type': 'user',
    'value': 'user',
    'name': {
      'label': 'Kullanıcı'
    },
    'list': []
  },
  'hospital': {
    'type': 'hospital',
    'value': 'hospital',
    'name': {
      'label': 'Hastane'
    },
    'list': []
  },
  'my_hospital_group': {
    'type': 'my_hospital_group',
    'value': 'my_hospital_group',
    'name': {
      'label': 'Hastane Grubu'
    },
    'list': []
  },
  'wisdom': {
    'type': 'wisdom',
    'value': 'wisdom',
    'name': {
      'label': 'WisdomEra'
    },
    'list': [{
      'type': 'wisdom',
      'param_type': 'select',
      'label': 'Hastane Kodu',
      'value': 'hospital_id'
    }, {
      'type': 'wisdom',
      'param_type': 'select',
      'label': 'Hastane Grubu',
      'value': 'hospital_group'
    }, {
      'type': 'wisdom',
      'param_type': 'text',
      'label': 'Hasta ID',
      'value': 'patient_id'
    }, {
      'type': 'wisdom',
      'param_type': 'integer',
      'label': 'Tedavi Sayısı',
      'value': 'treatment_count'
    }, {
      'type': 'wisdom',
      'param_type': 'select',
      'label': 'Tedavi Listesini Getir',
      'value': 'treatment_list',
      'filter': {
        // ilgili değerler boş ise filte yapmayacak
        'selected_drugs': [],
        'selected_drug_type': 'include', // include, only_selected_list, one_of_those
        'treatment_reason': [],
        'created_by': [],
        'treatment_status': []
      }
    }, {
      'type': 'wisdom',
      'param_type': 'integer',
      'label': 'Kayıtlı Patoloji Sayısı',
      'value': 'pathology_wdmr_count'
    }, {
      'type': 'wisdom',
      'param_type': 'integer',
      'label': 'Kayıtlı Görüntüleme Sayısı',
      'value': 'imaging_wdmr_count'
    }, {
      'type': 'wisdom',
      'param_type': 'integer',
      'label': 'Evreleme',
      'value': 'patient_staging_wdmr_count'
    }]
  }
};

var operations = [{
    'label': 'Eşittir',
    'value': '=',
    'translation': {
      'tr': 'Eşittir',
      'en': 'Equal to'
    }
  },
  {
    'label': 'Eşit Değildir',
    'value': '<>',
    'translation': {
      'tr': 'Eşit Değildir',
      'en': 'Not Equal to'
    }
  },
  {
    'label': 'Büyüktür',
    'value': '>',
    'translation': {
      'tr': 'Büyüktür',
      'en': 'Bigger than'
    }
  },
  {
    'label': 'Büyük eşittir',
    'value': '>=',
    'translation': {
      'tr': 'Büyük eşittir',
      'en': 'Bigger than and equal to'
    }
  },
  {
    'label': 'Küçüktür',
    'value': '<',
    'translation': {
      'tr': 'Küçüktür',
      'en': 'Smaller than'
    }
  },
  {
    'label': 'Küçük eşittir',
    'value': '<=',
    'translation': {
      'tr': 'Küçük eşittir',
      'en': 'Smaller than and equal to'
    }
  },
  {
    'label': 'İçerisinde mevcut',
    'value': 'in',
    'translation': {
      'tr': 'İçerisinde mevcut',
      'en': 'There is in'
    }
  }
];

export { columns as options };
