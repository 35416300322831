<template>
  <div>
    <table>
      <thead>
        <tr>
          <th :style="'position: sticky; z-index: 1000; top: 0; text-align: center; background-color: white'">
            <img src="@/icon/2653039.png" class="img-rounded img-responsive" style="width: 3em;" />
          </th>
          <template v-for="(col, col_ind) in p_columnList">
            <th :key="'th_' + col_ind.toString()" :style="'position: sticky; z-index: 1000; top: 0; white-space: nowrap; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: white; min-width: 200px;'">
              <div @click="f_selectCell('', col_ind,'col')" style="font-size: 10px;">
                {{ col_ind + 1 }} - {{ col.label }}
              </div>
              <!-- <b-row>
                <b-col cols="12">
                  <b-button disabled variant="success" style="width: 100%; height: 10px; padding: 0px; margin: 0px 3px 0px 0px;"></b-button>
                </b-col>
              </b-row> -->
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-for="(row, row_ind) in p_dataset">
          <tr style="border-bottom: solid 1px #c1c7cc;">
            <td :id="'td_row_no_' + row_ind.toString()" :style="f_calculateRowNoStyle(row_ind)" @click="f_selectCell(row_ind,'','row')">
              <!-- <b-button variant="success" @click="f_showRowModal(row_ind)" style="width: 15px; height: 15px; padding: 0px; margin: 0px 3px 0px 0px;"></b-button> -->
              {{ f_calculateRowNumber(row_ind) }}
            </td>
            <template v-for="(col, col_ind) in p_columnList">
              <td :id="'td_cell_' + row_ind.toString() + '_' + col_ind.toString()" :style="f_calculateStyleDatasetTable(row_ind, col_ind)" @click="f_selectCell(row_ind, col_ind, 'cell')" @dblclick="f_dblClickToCell(row, col.value)" class="cell-class" :title="w_presentation ? 'Sunum modu açık' : row[col.value]">
                <div :id="'td_cell_div_' + row_ind.toString() + '_' + col_ind.toString()" style="width: 100%; min-height: 10px;">
                  <template v-if="row[col.value] !== ''">
                    {{ f_showThisChars(row[col.value], 20) }}
                  </template>
                </div>
              </td>
            </template>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'WTable',
  computed: mapGetters({
    lang: 'lang',
    device: 'device'
  }),
  props: {
    p_tablePagination: {
      type: Object,
      required: true
    },
    p_dataset: {
      type: Array,
      required: true
    },
    f_dblClickToCell: {
      type: Function,
      required: true
    },
    p_columnList: {
      type: Array,
      required: true
    },
    p_selectedCells: {
      type: Array,
      required: true
    }
  },
  components: {},
  data () {
    return {
      w_presentation: false,
      d_searchColumnText: ''
    };
  },
  created: function () {
    this.w_presentation = JSON.parse(localStorage.getItem('presentation'));
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {},
  methods: {

    f_calculateStyleDatasetTable: function (row_ind, col_ind) {
      let style = '';
      style = 'white-space: nowrap; border-right: solid 1px #c1c7cc; width: 150px;';
      if (this.p_selectedCells.indexOf(row_ind.toString() + '-' + col_ind.toString()) !== -1) {
        style += 'background-color: #c6fcd8;';
      } else {
        style += 'background-color: white;';
      }
      return style;
    },
    f_selectCell: function (row_ind, col_ind, type) {
      if (type === 'cell') {
        let select = row_ind.toString() + '-' + col_ind.toString();
        if (this.p_selectedCells.indexOf(select) === -1) {
          this.p_selectedCells.push(select);
        } else {
          this.p_selectedCells.splice(this.p_selectedCells.indexOf(select), 1);
        }
      } else if (type === 'row') {
        for (let c in this.p_columnList) {
          let y = row_ind.toString() + '-' + c.toString();
          this.p_selectedCells.push(y);
        }
      } else if (type === 'col') {
        for (let r in this.p_dataset) {
          let y = r.toString() + '-' + col_ind.toString();
          this.p_selectedCells.push(y);
        }
      }
      this.$forceUpdate();
    },
    f_calculateRowNoStyle: function (row_ind) {
      let style = '';
      let w = '60px';
      style = 'position: sticky; left: 0; width: ' + w + '!important; border-right: solid 1px #c3d8d8; cursor: pointer; background-color: white; font-size: 10px; color: black';
      return style;
    },
    f_calculateRowNumber: function (row_ind) {
      return ((this.p_tablePagination.current - 1) * this.p_tablePagination.perpage) + row_ind + 1;
    },
    DateFormat: function (date) {
      return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
    },
    f_showThisChars: function (text, count) {
      let str = '';
      try {
        text = text.toString();
        let x = text.length > count ? count : text.length;
        for (let i = 0; i < x; i++) {
          if (text[i]) {
            str += text[i];
          } else {
            str += '.';
          }
        }
        if (text.length > count) {
          str += '...';
        }
      } catch (err) {}
      return str;
    }
  },
  watch: {}
}

</script>

<style type="text/css">


</style>

