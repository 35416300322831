import Vue from 'vue';
import { API_BASE_URL } from '@/config';

export default {
  save (data) {
    return Vue.http.put(API_BASE_URL + 'option/save', data);
  },
  saveWisdomDataModel (data) {
    return Vue.http.put(API_BASE_URL + 'option/saveWisdomDataModel', data);
  },
  new_wisdom_model (data) {
    return Vue.http.put(API_BASE_URL + 'option/new_wisdom_model', data);
  },
  create_new_wdm_version (data) {
    return Vue.http.put(API_BASE_URL + 'option/create_new_wdm_version', data);
  },
  deleteThisWisdomDataModel (query) {
    return Vue.http.delete(API_BASE_URL + 'option/deleteThisWisdomDataModel?' + query);
  },
  save_wisdom_data_schema (data) {
    return Vue.http.put(API_BASE_URL + 'option/save_wisdom_data_schema', data);
  },
  save_wisdom_data_anatomy_schema (data) {
    return Vue.http.put(API_BASE_URL + 'option/save_wisdom_data_anatomy_schema', data);
  },
  search (name, data) {
    data.o_parent = name;
    return Vue.http.post(API_BASE_URL + 'option/search', data);
  },
  search_brand (data) {
    return Vue.http.post(API_BASE_URL + 'option/search_brand', data);
  },
  search_wdm_option (data) {
    return Vue.http.post(API_BASE_URL + 'option/search_wdm_option', data);
  },
  save_wdm_new_phrases (data) {
    return Vue.http.post(API_BASE_URL + 'option/save_wdm_new_phrases', data);
  },
  get_wdm_records (data) {
    return Vue.http.post(API_BASE_URL + 'option/get_wdm_records', data);
  },
  save_wdm_brand_details (data) {
    return Vue.http.put(API_BASE_URL + 'option/save_wdm_brand_details', data);
  },
  save_wdm_option (data) {
    return Vue.http.put(API_BASE_URL + 'option/save_wdm_option', data);
  },
  get_user_sub_settings (data) {
    return Vue.http.post(API_BASE_URL + 'option/get_user_sub_settings', data);
  },
  get_user_settings_list () {
    return Vue.http.post(API_BASE_URL + 'option/get_user_settings_list');
  },
  publish_wdm (data) {
    return Vue.http.post(API_BASE_URL + 'option/publish_wdm', data);
  },
  getAllSchemas () {
    return Vue.http.get(API_BASE_URL + 'option/getAllSchemas');
  },
  getOptionParentList () {
    return Vue.http.get(API_BASE_URL + 'option/getOptionParentList');
  },
  get_titck_list () {
    return Vue.http.get(API_BASE_URL + 'option/get_titck_list');
  },
  get_model_with_key (query) {
    return Vue.http.get(API_BASE_URL + 'option/get_model_with_key?' + query);
  },
  get_quick_brand_page_needs () {
    return Vue.http.get(API_BASE_URL + 'option/get_quick_brand_page_needs');
  },
  getWisdomDataSchemaList (data) {
    return Vue.http.post(API_BASE_URL + 'option/getWisdomDataSchemaList', data);
  },
  getWisdomDataAnatomySchemaList (data) {
    return Vue.http.post(API_BASE_URL + 'option/getWisdomDataAnatomySchemaList', data);
  },
  get_wisdom_data_schema (data) {
    return Vue.http.post(API_BASE_URL + 'option/get_wisdom_data_schema', data);
  },
  get_wisdom_data_anatomy_schema (data) {
    return Vue.http.post(API_BASE_URL + 'option/get_wisdom_data_anatomy_schema', data);
  },
  update_titck_brands (data) {
    return Vue.http.post(API_BASE_URL + 'option/update_titck_brands', data);
  },
  quick_add_update_brands (data) {
    return Vue.http.post(API_BASE_URL + 'option/quick_add_update_brands', data);
  },
  get_list (data) {
    return Vue.http.post(API_BASE_URL + 'option/get_list', data);
  },
  type_list () {
    return Vue.http.post(API_BASE_URL + 'option/type_list');
  },
  getHospitalWisdomDataModelList (query) {
    return Vue.http.get(API_BASE_URL + 'option/getHospitalWisdomDataModelList?' + query);
  },
  getHospitalWtl (query) {
    return Vue.http.get(API_BASE_URL + 'option/getHospitalWtl?' + query);
  },
  get_wdm_version_hospital (query) {
    return Vue.http.get(API_BASE_URL + 'option/get_wdm_version_hospital?' + query);
  },
  activateDataModel (data) {
    return Vue.http.post(API_BASE_URL + 'option/activateDataModel', data);
  },
  activateWtlModel (data) {
    return Vue.http.post(API_BASE_URL + 'option/activateWtlModel', data);
  },
  get_brand_list_of_not_recorded_to_wisdomera (data) {
    return Vue.http.post(API_BASE_URL + 'option/get_brand_list_of_not_recorded_to_wisdomera', data);
  },
  get_model_wisdom_data (query) {
    return Vue.http.get(API_BASE_URL + 'option/get_model_wisdom_data?' + query);
  },
  get_last_wdm_of_this_type (query) {
    return Vue.http.get(API_BASE_URL + 'option/get_last_wdm_of_this_type?' + query);
  },
  getUserWisdomDataModelData (data) {
    return Vue.http.post(API_BASE_URL + 'option/getUserWisdomDataModelData', data);
  },
  get_multi_wdmr_wdm_list (data) {
    return Vue.http.post(API_BASE_URL + 'option/get_multi_wdmr_wdm_list', data);
  },
  get_all_wisdom_data_models () {
    return Vue.http.post(API_BASE_URL + 'option/get_all_wisdom_data_models')
                        .then(resp => {
                          localStorage.wisdom_data_models = JSON.stringify(resp.data.results);
                        });
  },
  get_wisdom_data (data_type) {
    var wisdom_data_models = JSON.parse(localStorage.wisdom_data_models);
    return wisdom_data_models[data_type];
  },
  get_toxicity_data () {
    return Vue.http.post(API_BASE_URL + 'option/get_toxicity_data')
                        .then(resp => {
                          localStorage.toxicity = JSON.stringify(resp.data.results['toxicity']);
                        });
  },
  get_toxicity_local_storage (type, toxicity_group_label) {
    var toxicity_data = JSON.parse(localStorage.toxicity);
    console.log('toxicity_data', toxicity_data);
    if (type === 'toxicity_group_list') {
      return toxicity_data.parameters.toxicity_group.options;
    } else if (type === 'toxicity_list') {
      let tox_options = [];
      for (let i in toxicity_data.parameters.toxicity_list.options) {
        if (toxicity_data.parameters.toxicity_list.options[i].toxicity_group === toxicity_group_label) {
          tox_options.push(toxicity_data.parameters.toxicity_list.options[i]);
        }
      }
      return tox_options;
    }
  },
  get_wisdom_data_for_specific_follow_up (data) {
    return Vue.http.post(API_BASE_URL + 'option/get_wisdom_data_for_specific_follow_up', data);
  },
  is_wisdomparameter_be_used (data) {
    return Vue.http.post(API_BASE_URL + 'option/is_wisdomparameter_be_used', data);
  }
};
