var unit = [{'value': 'ampul', 'label': 'ampul'},
    {'value': 'auc', 'label': 'auc'},
    {'value': 'cc', 'label': 'cc'},
    {'value': 'drop', 'label': 'damla'},
    {'value': 'gy', 'label': 'gy'},
    {'value': 'gr', 'label': 'gr'},
    {'value': 'mcg', 'label': 'mcg'},
    {'value': 'mcg_m2', 'label': 'mcg/m²'},
    {'value': 'mcg_kg', 'label': 'mcg/kg'},
    {'value': 'mg', 'label': 'mg'},
    {'value': 'mg_kg', 'label': 'mg/kg'},
    {'value': 'mg_m2', 'label': 'mg/m²'},
    {'value': 'unit_m2', 'label': 'unit/m²'},
    {'value': 'ml', 'label': 'ml'},
    {'value': 'mu', 'label': 'mu'},
    {'value': 'mu_m2', 'label': 'mu/m²'},
    {'value': 'mu_kg', 'label': 'mu/kg'},
    {'value': 'scale', 'label': 'ölçek'},
    {'value': 'tablet', 'label': 'tablet'},
    {'value': 'unite', 'label': 'unite'}];

export { unit as options };
