var wdm10_delay_reasons = [
  {'value': 'patient_preference', 'label': 'Hasta isteği'},
  {'value': 'physician_preference', 'label': 'Doktor tercihi'},
  {'value': 'intolerability', 'label': 'İntolerabilite'},
  {'value': 'patient_wants_to_rest', 'label': 'Hasta dinlenmek istiyor'},
  {'value': 'patient_arrived_late', 'label': 'Hasta tedaviye geç geldi'},
  {'value': 'toxicity', 'label': 'Toksisite'}
];

export { wdm10_delay_reasons as options };
