import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  get_organization_list (data) {
    return Vue.http.post(API_BASE_URL + 'organization/get_organization_list', data);
  },
  invite_to_organization (data) {
    return Vue.http.post(API_BASE_URL + 'organization/invite_to_organization', data);
  },
  organization_invite_response (data) {
    return Vue.http.post(API_BASE_URL + 'organization/organization_invite_response', data);
  },
  get_organization_task_stats (data) {
    return Vue.http.post(API_BASE_URL + 'organization/get_organization_task_stats', data);
  },
  save_organization (data) {
    return Vue.http.post(API_BASE_URL + 'organization/save_organization', data);
  },
  save_task (data) {
    return Vue.http.post(API_BASE_URL + 'organization/save_task', data);
  },
  get_task_list (data) {
    return Vue.http.post(API_BASE_URL + 'organization/get_task_list', data);
  },
  get_specific_tasks (data) {
    return Vue.http.post(API_BASE_URL + 'organization/get_specific_tasks', data);
  },
  share_unassigned_brands_to_selected_users (data) {
    return Vue.http.post(API_BASE_URL + 'organization/share_unassigned_brands_to_selected_users', data);
  },
  delete_tasks_in_organization (data) {
    return Vue.http.post(API_BASE_URL + 'organization/delete_tasks_in_organization', data);
  },
  create_organization_messenger_group (data) {
    return Vue.http.post(API_BASE_URL + 'organization/create_organization_messenger_group', data);
  },
  save_patients_from_data_project (data) {
    return Vue.http.post(API_BASE_URL + 'organization/save_patients_from_data_project', data);
  },
  create_patient_data_project_document (data) {
    return Vue.http.post(API_BASE_URL + 'organization/create_patient_data_project_document', data);
  },
  get_patient_file_system_list (data) {
    return Vue.http.post(API_BASE_URL + 'organization/get_patient_file_system_list', data);
  },
  get_not_assigned_brand_count (organization_id) {
    return Vue.http.get(API_BASE_URL + 'organization/get_not_assigned_brand_count?organization_id=' + organization_id);
  },
  who_can_see_this_task (query) {
    return Vue.http.get(API_BASE_URL + 'organization/who_can_see_this_task?' + query);
  },
  get_patient_data_project_list (query) {
    return Vue.http.get(API_BASE_URL + 'organization/get_patient_data_project_list?' + query);
  },
  assign_not_assigned_data_project_patients (data) {
    return Vue.http.post(API_BASE_URL + 'organization/assign_not_assigned_data_project_patients', data);
  },
  send_email_to_organization_users (data) {
    return Vue.http.post(API_BASE_URL + 'organization/send_email_to_organization_users', data);
  },
  create_wdm21_for_task (data) {
    return Vue.http.post(API_BASE_URL + 'organization/create_wdm21_for_task', data);
  }
};
