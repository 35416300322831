import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  get_user_layer_list (data) {
    return Vue.http.post(API_BASE_URL + 'layer/get_user_layer_list', data);
  },
  get_sub_layer_wdm_list (data) {
    return Vue.http.post(API_BASE_URL + 'layer/get_sub_layer_wdm_list', data);
  },
  get_sql_of_main_rule (data) {
    return Vue.http.post(API_BASE_URL + 'layer/get_sql_of_main_rule', data);
  }
};
