var fluids = [
  {'label': '50 cc %0.9 sodium chloride', 'value': '50cc-09-sodium-chloride', 'fluid_value': '50'},
  {'label': '100 cc %0.9 sodium chloride', 'value': '100cc-09-sodium-chloride', 'fluid_value': '100'},
  {'label': '150 cc %0.9 sodium chloride', 'value': '150cc-09-sodium-chloride', 'fluid_value': '150'},
  {'label': '250 cc %0.9 sodium chloride', 'value': '250cc-09-sodium-chloride', 'fluid_value': '250'},
  {'label': '500 cc %0.9 sodium chloride', 'value': '500cc-09-sodium-chloride', 'fluid_value': '500'},
  {'label': '1000 cc %0.9 sodium chloride', 'value': '1000cc-09-sodium-chloride', 'fluid_value': '1000'},
  {'label': '50 cc %5 dextrose', 'value': '50cc-5-dextrose', 'fluid_value': '50'},
  {'label': '100 cc %5 dextrose', 'value': '100cc-5-dextrose', 'fluid_value': '100'},
  {'label': '150 cc %5 dextrose', 'value': '150cc-5-dextrose', 'fluid_value': '150'},
  {'label': '250 cc %5 dextrose', 'value': '250cc-5-dextrose', 'fluid_value': '250'},
  {'label': '500 cc %5 dextrose', 'value': '500cc-5-dextrose', 'fluid_value': '500'},
  {'label': '1000 cc %5 dextrose', 'value': '1000cc-5-dextrose', 'fluid_value': '1000'},
  {'label': '50 cc %0.9 sodium chloride or 50 cc %5 dextrose', 'value': '50cc-09-sodium-chloride-or-50cc-5-dextrose', 'fluid_value': '50'},
  {'label': '100 cc %0.9 sodium chloride or 100 cc %5 dextrose', 'value': '100cc-09-sodium-chloride-or-100cc-5-dextrose', 'fluid_value': '100'},
  {'label': '150 cc %0.9 sodium chloride or 150 cc %5 dextrose', 'value': '150cc-09-sodium-chloride-or-150cc-5-dextrose', 'fluid_value': '150'},
  {'label': '250 cc %0.9 sodium chloride or 250 cc %5 dextrose', 'value': '250cc-09-sodium-chloride-or-250cc-5-dextrose', 'fluid_value': '250'},
  {'label': '500 cc %0.9 sodium chloride or 500 cc %5 dextrose', 'value': '500cc-09-sodium-chloride-or-500cc-5-dextrose', 'fluid_value': '500'},
  {'label': '1000 cc %0.9 sodium chloride or 1000 cc %5 dextrose', 'value': '1000cc-09-sodium-chloride-or-1000cc-5-dextrose', 'fluid_value': '1000'},
  {'label': 'isolyte m pvc 500 ml setli', 'value': 'isolyte-m-pvc-500-ml-setli', 'fluid_value': '500'},
  {'label': 'isolyte m pvc 500 ml setsiz', 'value': 'isolyte-m-pvc-500-ml-setsiz', 'fluid_value': '500'},
  {'label': 'isolyte m sise 500 ml setli', 'value': 'isolyte-m-sise-500-ml-setli', 'fluid_value': '500'},
  {'label': 'isolyte m sise 500 ml setsiz', 'value': 'isolyte-m-sise-500-ml-setsiz', 'fluid_value': '500'},
  {'label': 'isolyte p pvc 250 ml setli', 'value': 'isolyte-p-pvc-250-ml-setli', 'fluid_value': '250'},
  {'label': 'isolyte p pvc 250 ml setsiz', 'value': 'isolyte-p-pvc-250-ml-setsiz', 'fluid_value': '250'},
  {'label': 'isolyte p pvc 500 ml setli', 'value': 'isolyte-p-pvc-500-ml-setli', 'fluid_value': '500'},
  {'label': 'isolyte p pvc 500 ml setsiz', 'value': 'isolyte-p-pvc-500-ml-setsiz', 'fluid_value': '500'},
  {'label': 'isolyte p sise 500 ml setsiz', 'value': 'isolyte-p-sise-500-ml-setsiz', 'fluid_value': '500'},
  {'label': 'isolyte psise 500 ml setli', 'value': 'isolyte-psise-500-ml-setli', 'fluid_value': '500'},
  {'label': 'isolyte pvc 1000 ml setli', 'value': 'isolyte-pvc-1000-ml-setli', 'fluid_value': '1000'},
  {'label': 'isolyte pvc 1000 ml setsiz', 'value': 'isolyte-pvc-1000-ml-setsiz', 'fluid_value': '1000'},
  {'label': 'isolyte pvc 500 ml setli', 'value': 'isolyte-pvc-500-ml-setli', 'fluid_value': '500'},
  {'label': 'isolyte pvc 500 ml setsiz', 'value': 'isolyte-pvc-500-ml-setsiz', 'fluid_value': '500'},
  {'label': 'isolyte s ph 7 4 pvc 1000 ml setli', 'value': 'isolyte-s-ph-7-4-pvc-1000-ml-setli', 'fluid_value': '1000'},
  {'label': 'isolyte s ph 7 4 pvc 1000 ml setsiz', 'value': 'isolyte-s-ph-7-4-pvc-1000-ml-setsiz', 'fluid_value': '1000'},
  {'label': 'isolyte s ph 7 4 pvc 500 ml setli', 'value': 'isolyte-s-ph-7-4-pvc-500-ml-setli', 'fluid_value': '500'},
  {'label': 'isolyte s ph 7 4 pvc 500 ml setsiz', 'value': 'isolyte-s-ph-7-4-pvc-500-ml-setsiz', 'fluid_value': '500'},
  {'label': 'isolyte sise 1000 ml setli', 'value': 'isolyte-sise-1000-ml-setli', 'fluid_value': '1000'},
  {'label': 'isolyte sise 1000 ml setsiz', 'value': 'isolyte-sise-1000-ml-setsiz', 'fluid_value': '1000'},
  {'label': 'isolyte sise 500 ml setli', 'value': 'isolyte-sise-500-ml-setli', 'fluid_value': '500'},
  {'label': 'isolyte sise 500 ml setsiz', 'value': 'isolyte-sise-500-ml-setsiz', 'fluid_value': '500'},
  {'label': '100 cc %10 dextrose', 'value': '100cc-10-dextrose', 'fluid_value': '100'},
  {'label': '100 cc %20 dextrose', 'value': '100cc-20-dextrose', 'fluid_value': '100'},
  {'label': '100 cc %30 dextrose', 'value': '100cc-30-dextrose', 'fluid_value': '100'},
  {'label': '100 cc %5 dextrose lactate ringer', 'value': '100cc-5-dextrose-lactate-ringer', 'fluid_value': '100'},
  {'label': '100 cc %50 dextrose', 'value': '100cc-50-dextrose', 'fluid_value': '100'},
  {'label': '100cc laktat ringer', 'value': '100cc-lactate-ringer', 'fluid_value': '100'},
  {'label': '150 cc %10 dextrose', 'value': '150cc-10-dextrose', 'fluid_value': '150'},
  {'label': '150 cc %20 dextrose', 'value': '150cc-20-dextrose', 'fluid_value': '150'},
  {'label': '150 cc %3 sodium chloride', 'value': '150cc-3-sodium-chloride', 'fluid_value': '150'},
  {'label': '150 cc %30 dextrose', 'value': '150cc-30-dextrose', 'fluid_value': '150'},
  {'label': '150 cc %5 dextrose lactate ringer', 'value': '150cc-5-dextrose-lactate-ringer', 'fluid_value': '150'},
  {'label': '150 cc %50 dextrose', 'value': '150cc-50-dextrose', 'fluid_value': '150'},
  {'label': '150cc laktat ringer', 'value': '150cc-lactate-ringer', 'fluid_value': '150'},
  {'label': '250 cc %10 dextrose', 'value': '250cc-10-dextrose', 'fluid_value': '250'},
  {'label': '250 cc %20 dextrose', 'value': '250cc-20-dextrose', 'fluid_value': '250'},
  {'label': '250 cc %3 sodium chloride', 'value': '250cc-3-sodium-chloride', 'fluid_value': '250'},
  {'label': '250 cc %30 dextrose', 'value': '250cc-30-dextrose', 'fluid_value': '250'},
  {'label': '250 cc %5 dextrose %0.2 sodium chloride', 'value': '250cc-5-dextrose-02-sodium-chloride', 'fluid_value': '250'},
  {'label': '250 cc %5 dextrose lactate ringer', 'value': '250cc-5-dextrose-lactate-ringer', 'fluid_value': '250'},
  {'label': '250 cc %50 dextrose', 'value': '250cc-50-dextrose', 'fluid_value': '250'},
  {'label': '250cc laktat ringer', 'value': '250cc-lactate-ringer', 'fluid_value': '250'},
  {'label': '500 cc %10 dextrose hypertonic solution', 'value': '500cc-10-dextrose-hypertonic-solution', 'fluid_value': '1000'},
  {'label': '500 cc %10 dextrose', 'value': '500cc-10-dextrose', 'fluid_value': '500'},
  {'label': '500 cc %20 dextrose', 'value': '500cc-20-dextrose', 'fluid_value': '500'},
  {'label': '500 cc %3 sodium chloride', 'value': '500cc-3-sodium-chloride', 'fluid_value': '500'},
  {'label': '500 cc %30 dextrose', 'value': '500cc-30-dextrose', 'fluid_value': '500'},
  {'label': '500 cc %5 dextrose %0.2 sodium chloride', 'value': '500cc-5-dextrose-02-sodium-chloride', 'fluid_value': '500'},
  {'label': '500 cc %5 dextrose %0.45 sodium chloride', 'value': '500cc-5-dextrose-045-sodium-chloride', 'fluid_value': '500'},
  {'label': '500 cc %5 dextrose %0.9 sodium chloride', 'value': '500cc-5-dextrose-09-sodium-chloride', 'fluid_value': '500'},
  {'label': '500 cc %5 dextrose lactate ringer', 'value': '500cc-5-dextrose-lactate-ringer', 'fluid_value': '500'},
  {'label': '500 cc %50 dextrose', 'value': '500cc-50-dextrose', 'fluid_value': '500'},
  {'label': '500cc laktat ringer', 'value': '500cc-lactate-ringer', 'fluid_value': '500'},
  {'label': '1000 cc %10 dextrose', 'value': '1000cc-10-dextrose', 'fluid_value': '1000'},
  {'label': '1000 cc %20 dextrose', 'value': '1000cc-20-dextrose', 'fluid_value': '1000'},
  {'label': '1000 cc %20 sodium chloride', 'value': '1000cc-20-sodium-chloride', 'fluid_value': '1000'},
  {'label': '1000 cc %3 sodium chloride', 'value': '1000cc-3-sodium-chloride', 'fluid_value': '1000'},
  {'label': '1000 cc %30 dextrose', 'value': '1000cc-30-dextrose', 'fluid_value': '1000'},
  {'label': '1000 cc %5 dextrose %0.45 sodium chloride', 'value': '1000cc-5-dextrose-045-sodium-chloride', 'fluid_value': '1000'},
  {'label': '1000 cc %5 dextrose %0.9 sodium chloride', 'value': '1000cc-5-dextrose-09-sodium-chloride', 'fluid_value': '1000'},
  {'label': '1000 cc %5 dextrose lactate ringer', 'value': '1000cc-5-dextrose-lactate-ringer', 'fluid_value': '1000'},
  {'label': '1000 cc %50 dextrose', 'value': '1000cc-50-dextrose', 'fluid_value': '1000'},
  {'label': '1000cc laktat ringer', 'value': '1000cc-lactate-ringer', 'fluid_value': '1000'},
  {'label': '2000cc laktat ringer', 'value': '2000cc-lactate-ringer', 'fluid_value': '2000'},
  {'label': '3000 cc %0.9 sodium chloride', 'value': '3000cc-09-sodium-chloride', 'fluid_value': '3000'}
];

export { fluids as options };
