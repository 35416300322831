<template>
  <div v-if="rule_data.name">
    <div class="row form-group" v-if="user.permissions_result.indexOf('wisdom') !== -1">
      <div class="col-sm-3"><b> {{ $t('wdm16.11351') }} </b></div>
      <div class="col-sm-9"> {{ rule_data.id }}</div>
    </div>
    <div class="row form-group">
      <div class="col-sm-3"><b> {{ $t('wdm16.11352') }} </b></div>
      <div class="col-sm-9"> {{ rule_data.name }} </div>
    </div>
    <div class="row form-group" v-if="user.permissions_result.indexOf('wisdom') !== -1 || !rule_result">
      <div class="col-sm-3"><b> {{ $t('wdm16.11353') }} </b></div>
      <div class="col-sm-9">{{ rule_data.rule_type.label }}</div>
    </div>
    <div class="row form-group" v-if="user.permissions_result.indexOf('wisdom') !== -1 || !rule_result">
      <div class="col-sm-3"><b> {{ $t('wdm16.11354') }} </b></div>
      <div class="col-sm-9">{{ rule_data.response_type.label }}</div>
    </div>
    <div class="row form-group" v-if="rule_data.rule_type.value === 'operation' && (user.permissions_result.indexOf('wisdom') !== -1 || !rule_result)">
      <div class="col-sm-3"><b> {{ $t('wdm16.3866') }} </b> </div>
      <div class="col-sm-9">
        <template v-for="(op, op_ind) in rule_data.operation">
          {{ op.name.translation[lang] }}
        </template>
      </div>
    </div>
    <div class="row form-group" v-if="!rule_result">
      <div class="col-sm-3"><b> {{ $t('wdm16.3945') }} </b></div>
      <div class="col-sm-9">{{ rule_data.message }} </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-12"><b>{{ $t('wdm16.11355') }}</b></div>
    </div>
    <!-- <div class="row form-group">
      <div class="col-sm-12">
        <div class="form-group row" v-for="(op_func, op_func_ind) in rule_data.data_record_operations">
          <div :class="[rule_result ? 'col-sm-10' : 'col-sm-12']">
            <strong> {{ op_func_ind + 1 }} ) {{ op_func.op_name }}</strong>
            <br>
            <wisdom-data-show :change="'0'" :view_mode="'table'" :data="op_func" :data_type="op_func.type" :option_data="get_option_data(op_func.type)"></wisdom-data-show>
          </div>
          <div class="col-sm-2" v-if="rule_result">
            <button type="button" class="btn btn-primary" @click="apply_operation(op_func_ind)">işlemi uygula</button>
          </div>
        </div>
      </div>
    </div>
     --><div class="row form-group">
      <div class="col-sm-3"><b> {{ $t('wdm16.11356') }} </b></div>
      <div class="col-sm-9">
        {{ $t('wdm16.11357') }}: {{ rule_data.reference_name }}
        <br> {{ $t('wdm16.11358') }}: {{ rule_data.reference_type.label }}
        <br> {{ $t('wdm16.11359') }} {{ rule_data.reference_source.label }}
        <br> {{ $t('wdm16.11360') }}: <a :href="'http://' + rule_data.reference_link" :title="'http://' + rule_data.reference_link" Target="_self">{{ rule_data.reference_link }}</a>
        <br>
        <!-- prepared by: {{ rule_data.reference_prepared_by }} <br> -->
        {{ $t('wdm16.6210') }}: {{ rule_data.reference_note }}
        <br>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-3"><b> {{ $t('wdm16.3857') }} </b></div>
      <div class="col-sm-9">{{ DateFormat(rule_data.created_at) }} </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-3"><b> {{ $t('wdm16.11361') }} </b></div>
      <div class="col-sm-9">{{ rule_data.created_by }} </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-3"><b> {{ $t('wdm16.6210') }} </b> </div>
      <div class="col-sm-9">{{ rule_data.note }}</div>
    </div>
    <div class="row form-group">
      <div class="col-sm-12"><b>{{ $t('wdm16.11362') }}</b></div>
    </div>
    <div class="row form-group">
      <div class="col-sm-12">
        <rule-query :query_list="rule_data.queries" :compile_str="rule_data.compile_str_array" :compile_str_date="rule_data.compile_str_date" :mode="'view'"></rule-query>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-12">
        <rule-compile-str :compile_str_array="rule_data.compile_str_array" :mode="'view'" :show_caption="'sorgu'" :acordion_index="'rule-1'"></rule-compile-str>
      </div>
    </div>
    <modal v-if="show_wisdom_data_modal" @close="show_wisdom_data_modal = false" :width="'1500'">
      <h3 slot="header"> {{ $t('wdm16.11363') }} </h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="wisdom_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
        <!--               <wisdom-data-modal-template-study :data="wisdom_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal-template-study>
-->
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="wisdom_data_save()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="show_wisdom_data_modal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  default as WisdomDataShow
} from '@/components/widgets/WisdomDataShow';
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import { mapGetters } from 'vuex';
import moment from 'moment';
import {
  default as Modal
} from '@/components/widgets/Modal';
import {
  default as RuleQuery
} from '@/components/widgets/RuleQuery';
import {
  default as RuleCompileStr
} from '@/components/widgets/RuleCompileStr';
import OptionService from '@/services/option';
import WisdomDataService from '@/services/wisdom_data';

export default {
  name: 'RuleDetail',
  computed: {
    ...mapGetters({
      lang: 'lang'
    })
  },
  props: {
    patient_id: {
      type: String,
      required: false
    },
    rule_data: {
      type: Object,
      required: true
    },
    rule_result: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      d_selectedAnatomySchemaList: [],
      data_type: '',
      wisdom_data: {},
      option_data: {},
      show_wisdom_data_modal: false,
      user: {}
    };
  },
  mounted: function () {},
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  methods: {
    apply_operation: function (op_func_ind) {
      let data = this.rule_data.data_record_operations[op_func_ind];
      this.data_type = data.type;
      if (this.data_type === 'oncology_treatment') {
        this.show_wisdom_data_modal = false;
        let route_data = { name: 'patient_treatment_new', path: '/patient/chemotherapy/new', query: { patient_id: this.patient_id, rule_engine: 1 }, params: { chemo_data: data } };
        this.$router.push(route_data);
      } else {
        this.wisdom_data = data;
        this.$delete(this.wisdom_data, 'op_name');
        let option_data = OptionService.get_wisdom_data(this.data_type);
        this.option_data = {};
        this.option_data[this.data_type] = option_data;
        this.show_wisdom_data_modal = true;
      }
    },
    wisdom_data_save: function () {
      if (!this.wisdom_data.date) {
        alert("lütfen tarih seçimini yapınız");
        return
      }
      this.show_wisdom_data_modal = false;
      for (let i in this.option_data[this.wisdom_data.type].param_group.param_order) {
        let group = this.option_data[this.wisdom_data.type].param_group.param_order[i];
        for (let k in this.option_data[this.wisdom_data.type][group].parameters) {
          let param_data = this.option_data[this.wisdom_data.type][this.option_data[this.data_type].param_group.param_order[i]].parameters[k];
          if (param_data.required && param_data.required === 'yes') {
            if (!this.wisdom_data['data'][group][k]) {
              alert('Lütfen zorunlu alanları tamamlayınız');
              return;
            }
          }
        }
      }
      this.$refs.wisdom_data_modal.calculate_name();
      WisdomDataService.save(this.wisdom_data)
        .then(resp => {
          if (resp.data.status === 'success') {
            alert('işlem tamamlandı.');
          }
        });
    },
    get_option_data: function (data_type) {
      return OptionService.get_wisdom_data(data_type);
    },
    DateFormat: function (date) {
      return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
    }
  },
  watch: {},
  components: {
    WisdomDataShow,
    RuleCompileStr,
    RuleQuery,
    Modal,
    WisdomDataModal
  }
};

</script>

<style type="text/css">


</style>

