<template>
  <div>
    <b-row style="margin: 0px;">
      <b-col sm="12" md="6">
        <b-card no-body>
          <b-card-header header-bg-variant="secondary" class="p-1">
            Parametre başlığı ayarları
          </b-card-header>
          <b-row>
            <b-col sm="12" lg="12">
              sınır çizgi tipi
            </b-col>
            <b-col sm="12" lg="12">
              <select v-model="p_styleData.caption.border.type">
                <option value="dotted">{{ $t('wdm16.13156') }}</option>
                <option value="solid">{{ $t('wdm16.1701') }}</option>
              </select>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" lg="12">
              sınır çizgi kalınlığı
            </b-col>
            <b-col sm="12" lg="12">
              <input type="number" name="style_number" v-model="p_styleData.caption.border.size">
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" lg="12">
              sınır çizgi rengi
            </b-col>
            <b-col sm="12" lg="12">
              <input type="color" class="form-control" v-model="p_styleData.caption.border.color">
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" lg="12">
              arka renk
            </b-col>
            <b-col sm="12" lg="12">
              <input type="color" class="form-control" v-model="p_styleData.caption.backgroundColor">
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" lg="12">
              sınır köşe eğriliği
            </b-col>
            <b-col sm="12" lg="12">
              <input type="number" name="style_number" v-model="p_styleData.caption.borderRadius">
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" lg="12">
              Font
            </b-col>
            <b-col sm="12" lg="12">
              <input type="number" name="style_number" v-model="p_styleData.caption.fontSize">
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" lg="12">
              Yazı rengi
            </b-col>
            <b-col sm="12" lg="12">
              <input type="color" class="form-control" v-model="p_styleData.caption.color">
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" lg="12">
              parametreden sonra
            </b-col>
            <b-col sm="12" lg="12">
              <input type="text" class="form-control" v-model="p_styleData.caption.after">
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col sm="12" md="6">
        <b-card no-body>
          <b-card-header header-bg-variant="secondary" class="p-1">
            Parametre değeri ayarları
          </b-card-header>
          <b-row>
            <b-col sm="12" lg="12">
              sınır çizgi tipi
            </b-col>
            <b-col sm="12" lg="12">
              <select v-model="p_styleData.value.border.type">
                <option value="dotted">{{ $t('wdm16.13156') }}</option>
                <option value="solid">{{ $t('wdm16.1701') }}</option>
              </select>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" lg="12">
              sınır çizgi kalınlığı
            </b-col>
            <b-col sm="12" lg="12">
              <input type="number" name="style_number" v-model="p_styleData.value.border.size">
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" lg="12">
              sınır çizgi rengi
            </b-col>
            <b-col sm="12" lg="12">
              <input type="color" class="form-control" v-model="p_styleData.value.border.color">
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" lg="12">
              arka renk
            </b-col>
            <b-col sm="12" lg="12">
              <input type="color" class="form-control" v-model="p_styleData.value.backgroundColor">
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" lg="12">
              sınır köşe eğriliği
            </b-col>
            <b-col sm="12" lg="12">
              <input type="number" name="style_number" v-model="p_styleData.value.borderRadius">
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" lg="12">
              Font
            </b-col>
            <b-col sm="12" lg="12">
              <input type="number" name="style_number" v-model="p_styleData.value.fontSize">
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" lg="12">
              Yazı rengi
            </b-col>
            <b-col sm="12" lg="12">
              <input type="color" class="form-control" v-model="p_styleData.value.color">
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" lg="12">
              parametreden sonra
            </b-col>
            <b-col sm="12" lg="12">
              <input type="text" class="form-control" v-model="p_styleData.value.after">
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: "WdmAndParameterStyle",
  props: {
    p_styleData: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      d_emptyStyleParameters: {
        'caption': {
          'fontSize': 12,
          'color': 'black',
          'after': '',
          'backgroundColor': 'white',
          'border': {
            'type': 'solid',
            'size': 0,
            'color': 'black'
          },
          'borderRadius': 0
        },
        'value': {
          'fontSize': 10,
          'color': 'black',
          'after': '',
          'backgroundColor': 'white',
          'border': {
            'type': 'solid',
            'size': 0,
            'color': 'black'
          },
          'borderRadius': 0
        }
      }
    };
  },
  created: function () {
    this.f_fixMissingParameter(this.d_emptyStyleParameters, this.p_styleData);
  },
  mounted () {},
  methods: {
    f_fixMissingParameter: function (object_list, p_styleData) {
      for (let i in object_list) {
        if (Object.keys(p_styleData).indexOf(i) === -1) {
          p_styleData[i] = JSON.parse(JSON.stringify(object_list[i]));
        }
        if (typeof object_list[i] === 'object') {
          this.f_fixMissingParameter(object_list[i], p_styleData[i]);
        }
      }
    }
  },
  watch: {},
  components: {}
};

</script>

<style></style>

