<template>
  <div>
    <b-card v-if="d_selectedProjectData" header-tag="header">
      <b-row>
        <b-col cols="12">
          <b-card no-body class="mb-1" header-tag="header" key="patient-diagnosis-list">
            <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
              <b-row>
                <b-col sm="12" lg="6">
                  <img src="@/icon/2930471.png" style="width: 1.5em;" /> {{ d_selectedProjectData.data.general.name.val }},
                  <img src="@/icon/9370.png" style="width: 1.5em;" /> {{ d_selectedProjectData.created_by }}
                  <img src="@/icon/2080590.png" style="width: 1.5em;" /> {{ DateFormat(d_selectedProjectData.created_at) }}
                </b-col>
                <b-col sm="12" lg="6">
                  <b-button style="margin-right: 5px;" class="pull-right" variant="secondary" @click="f_savePoleStarProject()">
                    <img src="@/icon/2087726.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> kaydet
                  </b-button>
                  <b-button style="margin-right: 5px;" class="pull-right" variant="secondary" @click="f_goToProjectList()">
                    <img src="@/icon/969174.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> proje listesi
                  </b-button>
                  <b-button style="margin-right: 5px;" class="pull-right" variant="secondary" @click="f_goToWisdomStats()">
                    <img src="@/icon/890016.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Wisdom Stats
                  </b-button>
                  <!-- <b-button style="margin-right: 5px;" class="pull-right" variant="secondary" @click="f_goToPoleStarArticle()">
                    <img src="@/icon/890016.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Makale
                  </b-button> -->
                </b-col>
              </b-row>
              <!--
              <b-button style="margin-right: 5px;" class="pull-right" variant="secondary" @click="f_createGlobalProject()">
                <img src="@/icon/890016.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Globale Taşı
              </b-button>
              -->
            </b-card-header>
            <b-navbar style="padding: 0px;">
              <b-nav pills>
                <b-nav-item @click="selected_wdm_group_type = 'details'" :active="selected_wdm_group_type === 'details'"> Detaylar </b-nav-item>
                <b-nav-item v-if="d_projectWdmList.length > 0" @click="selected_wdm_group_type = 'general_case_rule'" :active="selected_wdm_group_type === 'general_case_rule'"> Genel Olgu kuralı </b-nav-item>
                <b-nav-item @click="selected_wdm_group_type = 'case_column_details'" :active="selected_wdm_group_type === 'case_column_details'"> Kolon Detayları </b-nav-item>
                <!-- <b-nav-item @click="selected_wdm_group_type = 'case_column_date_relation'" :active="selected_wdm_group_type === 'case_column_date_relation'"> Kolon Tarih İlişkileri </b-nav-item> -->
              </b-nav>
            </b-navbar>
            <b-card v-if="selected_wdm_group_type === 'details'" no-body class="mb-1" header-tag="header" style="border: solid 1px #20a8d8;">
              <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
                Proje detayları
                <b-button style="margin-right: 5px;" size="sm" class="pull-right" variant="secondary" @click="f_editProject()">
                  <img src="@/icon/2087726.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> düzenle
                </b-button>
              </b-card-header>
              <b-row v-if="d_showProjectDetails">
                <b-col cols="12">
                  <wisdom-data-show :change="'0'" :view_mode="'table'" :data="d_selectedProjectData" :data_type="'wdm3'" :option_data="option_data['wdm3']"></wisdom-data-show>
                </b-col>
              </b-row>
            </b-card>
            <b-card v-if="selected_wdm_group_type === 'general_case_rule'" no-body header-tag="header">
              <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
                Proje vakalarını genel olarak filtreleyip hazırlayan kural algoritması
                <b-dropdown class="pull-right">
                  <b-dropdown-item @click="f_deleteAllColumns()"> <img src="@/icon/2960590.png" style="width: 2em;"> Tüm Kolonları Sil </b-dropdown-item>
                </b-dropdown>
              </b-card-header>
              <b-row>
                <b-col cols="12">
                  <template v-if="d_userPolestarPermissions.indexOf('owner') !== -1 || d_userPolestarPermissions.indexOf('admin') !== -1 || d_userPolestarPermissions.indexOf('project_general_main_rule_edit') !== -1">
                    <wdm-rule-a :data_type="d_mainRuleData.data_type" :option_data="selected_option" :prepare_data="prepare_data" :query_list="query_list" :compile_str_array="compile_str_array" :compile_str_date="compile_str_date" :wdmr_change="wdmr_change" :p_wdmList="d_projectWdmList" :mother_children="mother_children" :p_wdmParameterSelectData="d_mainRuleData.wps_data" :p_wdmRuleAsettings="d_mainRuleData.wra_settings" :compile_str_text="compile_str_text"></wdm-rule-a>
                  </template>
                </b-col>
              </b-row>
            </b-card>
            <template v-if="selected_wdm_group_type === 'case_column_details'">
              <pole-star-column-feature :column_list="column_list" :mother="'project_details'" :p_selectedProjectData="d_selectedProjectData" :p_polestarSettings="d_polestarSettings" :p_userPolestarPermissions="d_userPolestarPermissions"></pole-star-column-feature>
            </template>
            <b-card v-if="selected_wdm_group_type === 'case_column_date_relation'" no-body header-tag="header">
              <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
                Kolon Tarih İlişkileri
                <b-dropdown class="pull-right">
                  <b-dropdown-item @click="f_addNewDateRelation()"> <img src="@/icon/109638.png" style="width: 2em;"> Yeni Kolon Tarih İlişkisi Ekle </b-dropdown-item>
                </b-dropdown>
              </b-card-header>
              <div style="overflow-x: auto; overflow-y: auto; height: 450px;">
                <template v-for="(date_relation, dr_ind) in d_columnDateRelation">
                  <b-row>
                    <b-col cols="12">
                      <b-button variant="success" @click="f_addNewRow(dr_ind)" style="float: left;"><i class="fa fa-plus"></i> satır</b-button>
                      <b-button variant="secondary" @click="f_addNewColumn(dr_ind)" style="float: left;"><i class="fa fa-plus"></i> sütun</b-button>
                    </b-col>
                  </b-row>
                  <b-row style="margin-bottom: 10px;">
                    <b-col cols="12">
                      <table id="column-date-relation">
                        <thead>
                          <tr>
                            <th style="text-align: center; background-color: #ececec;">
                              <img src="@/icon/2653039.png" class="img-rounded img-responsive" style="width: 3em;" />
                            </th>
                            <template v-for="(col, col_ind) in date_relation[0]">
                              <th style="white-space: nowrap; text-align: center; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: #ececec; min-width: 150px;">
                                <template v-if="col_ind === 0">
                                  <b-form-select v-model="date_relation[0][col_ind]">
                                    <option :value="{'label': 'Başlama Tarihi (belirtildi)', 'value': 'one_day'}">Başlama Tarihi Belirtildi</option>
                                    <option :value="{'label': 'Başlama Tarihi (belirtilmedi)', 'value': 'none'}">Başlama Tarihi Belirtilmedi</option>
                                  </b-form-select>
                                </template>
                                <template v-else-if="col_ind === date_relation[0].length - 1">
                                  <b-form-select v-model="date_relation[0][col_ind]">
                                    <option :value="{'label': 'Bitiş Tarihi (belirtildi)', 'value': 'one_day'}">Bitiş Tarihi Belirtildi</option>
                                    <option :value="{'label': 'Bitiş Tarihi (belirtilmedi)', 'value': 'none'}">Bitiş Tarihi Belirtilmedi</option>
                                  </b-form-select>
                                </template>
                                <template v-else-if="col_ind%2 === 0">
                                  <b-form-select v-model="date_relation[0][col_ind]">
                                    <option :value="{ 'label': 'Tarih (belirtilmedi)', 'value': 'none' }">Tarih Belirtilmedi</option>
                                    <option :value="{ 'label': 'Tarih bir gün', 'value': 'one_day' }">Tarih Bir Gün</option>
                                    <option :value="{ 'label': 'Tarih aralığı', 'value': 'interval' }">Tarih Aralığı </option>
                                  </b-form-select>
                                </template>
                                <template v-else>
                                  {{ ' ' }}
                                </template>
                              </th>
                            </template>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(row, row_ind) in date_relation">
                            <tr style="border-bottom: solid 1px #c1c7cc;" v-if="row_ind === 1">
                              <td style="text-align: center; border-right: solid 1px #c3d8d8; background-color: #ececec;"> 1 </td>
                              <template v-for="(col, col_ind) in row">
                                <td style="white-space: nowrap; border-right: solid 1px #c1c7cc;">
                                  <template v-if="date_relation[0][col_ind] && date_relation[0][col_ind].value === 'one_day'">
                                    <b-form-input type="date" v-model="date_relation[row_ind][col_ind].date_start"></b-form-input>
                                  </template>
                                  <template v-else-if="date_relation[0][col_ind] && date_relation[0][col_ind].value === 'interval'">
                                    başlama:
                                    <br>
                                    <b-form-input type="date" v-model="date_relation[row_ind][col_ind].date_start"></b-form-input>
                                    bitiş:
                                    <br>
                                    <b-form-input type="date" v-model="date_relation[row_ind][col_ind].date_end"></b-form-input>
                                  </template>
                                </td>
                              </template>
                            </tr>
                            <tr style="border-bottom: solid 1px #c1c7cc;" v-if="[0,1].indexOf(row_ind) === -1">
                              <td style="text-align: center; border-right: solid 1px #c3d8d8; background-color: #ececec;">
                                <template v-if="row_ind > 2">
                                  <b-button variant="danger" size="sm" @click="f_deleteRow(dr_ind, row_ind)"><i class="fa fa-trash"></i></b-button>
                                </template>
                              </td>
                              <template v-for="(col, col_ind) in row">
                                <td style="white-space: nowrap; border-right: solid 1px #c1c7cc;">
                                  <template v-if="col_ind === 0">{{ col.label }}</template>
                                  <template v-else-if="col_ind === date_relation[0].length - 1">{{ col.label }}</template>
                                  <template v-else-if="col_ind%2 === 1">
                                    <b-form-select v-model="date_relation[row_ind][col_ind].date_operator">
                                      <option v-for="(x, x_ind) in d_dateOperators" :value="x"> {{ x }} </option>
                                    </b-form-select>
                                  </template>
                                  <template v-else>
                                    <b-form-select v-model="date_relation[row_ind][col_ind]" @change="$forceUpdate()">
                                      <option v-for="(column, column_ind) in f_getEligibleColumnList(dr_ind, row_ind, col_ind)" :value="column.value"> {{ column.label }} </option>
                                    </b-form-select>
                                  </template>
                                </td>
                              </template>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </b-col>
                  </b-row>
                </template>
              </div>
            </b-card>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <modal v-if="show_wisdom_data_modal" @close="show_wisdom_data_modal = false" :width="'1500'">
      <h3 slot="header">Proje Düzenleme Ekranı</h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="wisdom_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveWisdomData()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="show_wisdom_data_modal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showPolestarUserModal" @close="d_showPolestarUserModal = false" :width="'750'">
      <h3 slot="header">Kutup Yıldızı Kullanıcı Ekleme Ekranı</h3>
      <div slot="body">
        <b-card>
          <b-form-group validated label-for="username">
            <b-form-input id="username" type="text" placeholder="Kutup Yıldızı Kullanıcı adınızı girin" required autocomplete="email" style="border: 1px solid rgb(54, 138, 155);" v-model="d_polestar.username"></b-form-input>
          </b-form-group>
          <b-form-group validated label-for="password">
            <b-form-input id="password" type="password" placeholder="Şifrenizi girin" required autocomplete="current-password" style="border: 1px solid rgb(54, 138, 155);" v-model="d_polestar.password"></b-form-input>
          </b-form-group>
        </b-card>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showPolestarUserModal = false">{{ $t('wdm16.4053') }}</button>
        <button type="button" class="btn btn-success" v-on:click="f_createPolestarUser()">{{ $t('wdm16.4061') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import { ClsRule } from '@/services/public/rule';
import {
  default as WdmRuleA
} from '@/components/widgets/WdmRuleA';
import {
  default as PoleStarColumnFeature
} from '@/components/widgets/PoleStarColumnFeature';
import {
  default as WdmParameterLocation
} from '@/components/widgets/WdmParameterLocation';
import {
  default as RuleCompileStr
} from '@/components/widgets/RuleCompileStr';
import {
  default as RuleQuery
} from '@/components/widgets/RuleQuery';
import {
  default as WisdomDataShow
} from '@/components/widgets/WisdomDataShow';
import {
  default as Modal
} from "@/components/widgets/Modal";
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import { mapGetters } from 'vuex';
import OptionService from '@/services/option';
import GlobalService from '@/services/global';
import { ClsWdmrForever, ClsWdmrSave } from "@/services/public/wdm";
import { ClsPolestar } from "@/services/public/polestar";
import vSelect from 'vue-select';
import WebService from '@/services/webservice';
import PoleStarService from '@/services/polestar';
import PatientService from '@/services/patient';
import HospitalService from '@/services/hospital';
import { DateFunctions } from '@/services/public/date_functions';
import moment from 'moment';
import FileSaver from 'file-saver';
export default {
  name: 'PoleStarProjectsDetails',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    vSelect,
    Modal,
    WisdomDataModal,
    WisdomDataShow,
    RuleQuery,
    RuleCompileStr,
    WdmParameterLocation,
    PoleStarColumnFeature,
    WdmRuleA
  },
  props: {},
  data () {
    return {
      compile_str_text: [],
      d_selectedAnatomySchemaList: [],
      d_mainRuleData: {
        'data_type': '',
        'wra_settings': {
          'mode': 'param',
          'options': [
            { 'label': 'WDM & QUERY', 'value': 'wdm_query' },
            { 'label': 'PARAM', 'value': 'param' },
            { 'label': 'PARAM & QUERY', 'value': 'param_query' }
          ]
        },
        'wps_data': [{
          'operation': '',
          'selected_value': '',
          'loc': [],
          'val_list': [],
          'selected_wdm': '',
          'd_level': 0,
          'd_level_count': 0,
          'significant': 'true'
        }]
      },
      mother_children: { 'forceUpdate': 0, 'send_table_to_article': 0 },
      algoritm_height: 320,
      d_showProjectDetails: true,
      d_dateOperators: ['<', '<='],
      d_columnDateRelationExample: [
        [
          { 'label': 'Başlama Tarihi (belirtilmedi)', 'value': 'none' },
          { 'label': '', 'value': '' },
          { 'label': 'Tarih (belirtilmedi)', 'value': 'none' },
          { 'label': '', 'value': '' },
          { 'label': 'Bitiş Tarihi (belirtilmedi)', 'value': 'none' }
        ],
        [
          { 'date_start': '', 'date_end': '' },
          { 'date_start': '', 'date_end': '' },
          { 'date_start': '', 'date_end': '' },
          { 'date_start': '', 'date_end': '' },
          { 'date_start': '', 'date_end': '' }
        ],
        [
          { 'label': '', 'value': '' },
          { 'date_operator': '<' },
          '',
          { 'date_operator': '<=' },
          { 'label': '', 'value': '' }
        ]
      ],
      d_columnDateRelation: [],
      d_newOption: '',
      param_type: ['select', 'integer', 'float', 'text', 'date', 'time', 'datetime'],
      d_showLabelsWdmLocationFormat: false,
      d_showPolestarUserModal: false,
      d_polestar: { 'username': '', 'password': '' },
      d_selectedDateRelation: { 'col_ind': '', 'op': '' },
      d_selectedTreatmentCreatedBy: '',
      d_selectedTreatmentStatus: '',
      d_selectedTreatmentReason: '',
      d_selectedDrug: '',
      d_selectedColumnInd: '',
      column_list: [],
      wdmr_change: { 'rule': 0 },
      show_wisdom_data_model: false,
      parameter_design_full_screen: false,
      d_projectWdmList: [],
      prepare_data: { 'data': {} },
      compile_str_array: [],
      compile_str_date: [],
      query_list: [],
      column_compile_str_array: [],
      column_query_list: [],
      selected_option: {},
      d_selectedProjectInd: '',
      d_selectedWdmType: '',
      d_selectedWdmVersion: '',
      selected_wdm_group_type: 'details',
      wisdom_data: {},
      // seçeneklerde olabilecekler veritabanından analiz edilerek çekiliyor.
      d_statisticalParameterOptions: {
        'diagnosis_main_group_code': [],
        'diagnosis_sub_group_code': [],
        'treatment_status': [{ 'label': 'Aktif Tedavi', 'value': 'active' }, { 'label': 'Sonlandırılmış Tedavi', 'value': 'terminated' }],
        'hospital_list': [],
        'drugs_in_treatment': require('@/options/favorite_drugs').options,
        'treatment_reason': require('@/options/treatment_reason').options,
        'gender': [{ 'label': 'Erkek', 'value': '0' }, { 'label': 'Kadın', 'value': 1 }]
      },
      d_selectedStatisticalModel: '',
      d_selectedStatisticalModelGroup: 'my_hospital',
      d_prepared_columns: require('@/options/prepared_columns').options,
      d_addedModels: [],
      d_expandAllCollapsed: true,
      d_showTable: true,
      d_hospitalGroupList: [],
      user: {},
      option_data: {},
      wisdom_op: 'new',
      data_type: '',
      show_wisdom_data_modal: false,
      d_selectedProjectData: '',
      wdm_version: '',
      d_selectedWdmTypeData: '',
      d_showRuleQuery: true,
      d_selectedProjectAnalyze: { 'excel_file': '', 'case_rows': [], 'case_columns': [] },
      column_rule_operation_type: 'new',
      d_selectedColumnRuleInd: '',
      d_selectedColumnList: [],
      d_columnSelectionType: 'wdm_columns',
      d_allUsers: [],
      d_polestarSettings: {},
      d_userPolestarPermissions: []
    };
  },
  created: function () {
    this.wdm_version = JSON.parse(localStorage.getItem('wdm_version'));
    this.user = JSON.parse(localStorage.getItem('user'));
    let x = JSON.parse(localStorage.getItem('all_users'));
    for (let i in x) {
      this.d_allUsers.push({ 'label': x[i].first_name + ' ' + x[i].last_name, 'value': x[i].username });
    }
    let query = 'data_type=wdm3&location=wisdom';
    OptionService.get_last_wdm_of_this_type(query)
      .then(resp => {
        if (resp.data.status === 'success') {
          this.option_data['wdm3'] = resp.data.result;
          if (this.$route.query.project_id) {
            let wdm3_id = this.$route.query.project_id;
            PoleStarService.get_this_project({ 'wdm3_id': wdm3_id })
              .then(resp => {
                if (resp.data.status === 'success') {
                  this.d_selectedProjectData = resp.data.result;
                  if (this.d_selectedProjectData.rule) {
                    if (this.d_selectedProjectData.rule['main_rule']) {
                      this.compile_str_array = this.d_selectedProjectData.rule['main_rule']['compile_str_array'];
                      if (this.d_selectedProjectData.rule['main_rule']['compile_str_date']) {
                        this.compile_str_date = this.d_selectedProjectData.rule['main_rule']['compile_str_date'];
                      }
                      if (this.d_selectedProjectData.rule['main_rule']['compile_str_text']) {
                        this.compile_str_text = this.d_selectedProjectData.rule['main_rule']['compile_str_text'];
                      }
                      this.query_list = this.d_selectedProjectData.rule['main_rule']['query_list'];
                    }
                  }
                  if (this.d_selectedProjectData.design) {
                    this.column_list = this.d_selectedProjectData.design.column_list;
                    // this.d_columnDateRelation = this.d_selectedProjectData.design.column_date_relations;
                  }
                  this.d_userPolestarPermissions = ClsPolestar.f_createUserPermissions(this.d_selectedProjectData, this.user.username);
                  if (this.user.permissions_result.indexOf('admin') !== -1 || this.user.permissions_result.indexOf('wisdom') !== -1) {
                    this.d_userPolestarPermissions.push('admin');
                  }
                  this.d_polestarSettings = ClsPolestar.f_getProjectSettings(this.d_selectedProjectData, this.user.username);
                  this.f_createProjectWdmList();
                } else {
                  alert('error ', resp.data.message);
                }
              });
          }
        } else {
          console.log('errror ', resp.data.message);
        }
      });
  },
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_createProjectWdmList: function () {
      this.d_projectWdmList = ClsPolestar.f_createProjectWdmList(this.d_selectedProjectData);
    },
    f_addNewColumn: function (dr_ind) {
      for (let i in this.d_columnDateRelation[dr_ind]) {
        if (parseInt(i) === 0) {
          this.d_columnDateRelation[dr_ind][i].splice(this.d_columnDateRelation[dr_ind][i].length - 2, 0, { "label": "Tarihi (belirtilmedi)", "value": "none" });
          this.d_columnDateRelation[dr_ind][i].splice(this.d_columnDateRelation[dr_ind][i].length - 2, 0, { "label": "", "value": "" });
        } else if (parseInt(i) === 1) {
          this.d_columnDateRelation[dr_ind][i].splice(this.d_columnDateRelation[dr_ind][i].length - 2, 0, { "date_start": "", "date_end": "" });
          this.d_columnDateRelation[dr_ind][i].splice(this.d_columnDateRelation[dr_ind][i].length - 2, 0, { "date_start": "", "date_end": "" });
        } else {
          this.d_columnDateRelation[dr_ind][i].splice(this.d_columnDateRelation[dr_ind][i].length - 2, 0, { "label": "", "value": "" });
          this.d_columnDateRelation[dr_ind][i].splice(this.d_columnDateRelation[dr_ind][i].length - 2, 0, { "label": "", "value": "" });
        }
      }
      this.$forceUpdate();
    },
    f_deleteRow: function (dr_ind, row_ind) {
      this.d_columnDateRelation[dr_ind].splice(row_ind, 1);
      this.$forceUpdate();
    },
    f_addNewRow: function (dr_ind) {
      let last_row = JSON.parse(JSON.stringify(this.d_columnDateRelation[dr_ind][this.d_columnDateRelation[dr_ind].length - 1]));
      for (let i in last_row) {
        if (typeof last_row[i] === 'string') {
          last_row[i] = '';
        }
      }
      this.d_columnDateRelation[dr_ind].push(last_row);
      this.$forceUpdate();
    },
    f_getEligibleColumnList: function (date_relation_index, row_ind, col_ind) {
      let arr = [];
      for (let i in this.column_list) {
        if (this.column_list[i].date_ability && this.column_list[i].date_ability === 'yes') {
          let det = false;
          for (let r in this.d_columnDateRelation[date_relation_index]) {
            for (let c in this.d_columnDateRelation[date_relation_index][r]) {
              if (r + c !== row_ind.toString() + col_ind.toString()) {
                if (this.d_columnDateRelation[date_relation_index][r][c] === i) {
                  det = true;
                  break;
                }
              }
            }
            if (det) {
              break;
            }
          }
          if (!det) {
            arr.push({ 'label': this.column_list[i].label, 'value': i });
          }
        }
      }
      return arr;
    },
    f_getEligibleColumnList_old: function (date_relation_index, row_ind, col_ind) {
      let arr = [];
      for (let i in this.column_list) {
        if (this.column_list[i].date_ability && this.column_list[i].date_ability === 'yes') {
          let det = false;
          for (let k in this.d_columnDateRelation) {
            for (let r in this.d_columnDateRelation[k]) {
              for (let c in this.d_columnDateRelation[k][r]) {
                if (k + r + c !== date_relation_index.toString() + row_ind.toString() + col_ind.toString()) {
                  if (this.d_columnDateRelation[k][r][c] === i) {
                    det = true;
                    break;
                  }
                }
              }
              if (det) {
                break;
              }
            }
            if (det) {
              break;
            }
          }
          if (!det) {
            arr.push({ 'label': this.column_list[i].label, 'value': i });
          }
        }
      }
      return arr;
    },
    f_addNewDateRelation: function () {
      this.d_columnDateRelation.push(JSON.parse(JSON.stringify(this.d_columnDateRelationExample)));
      this.$forceUpdate();
    },
    f_deleteOption: function (col_ind, op_ind) {
      this.column_list[col_ind].options.splice(op_ind, 1);
      this.$forceUpdate();
    },
    f_addNewOption: function () {
      if (this.d_newOption) {
        let det = false;
        if (!this.column_list[this.d_selectedColumnInd].options) {
          this.column_list[this.d_selectedColumnInd].options = [];
        } else {
          for (let i in this.column_list[this.d_selectedColumnInd].options) {
            if (this.column_list[this.d_selectedColumnInd].options[i] === this.d_newOption) {
              det = true;
              break;
            }
          }
        }
        if (!det) {
          this.column_list[this.d_selectedColumnInd].options.push(this.d_newOption);
        } else {
          alert('Zaten eklidir');
        }
      }
      this.$forceUpdate();
    },
    f_addNewManuelColumn: function () {
      let new_column = {
        "label": "",
        "param_type": "select",
        "type": "manuel" // ['by_rule_from_other_columns', 'manuel'] If we create a new column from the other columns' by rules, this will be, 'by_rule_from_other_columns'
      };
      this.column_list.push(new_column);
      this.$forceUpdate();
    },
    f_createPolestarUser: function () {
      if (this.d_polestar.username && this.d_polestar.password) {
        PoleStarService.create_polestar_user(this.d_polestar)
          .then(resp => {
            if (resp.data.status === 'success') {
              if (resp.data.result) {
                this.user.polestar = { 'username': this.d_polestar.username };
                localStorage.setItem('user', JSON.stringify(this.user));
                this.d_showPolestarUserModal = false;
                alert('Kutup yıldızı kullanıcısı eklendi');
              }
            } else {
              alert('error: ', resp.data.message);
            }
          });
      } else {
        alert('Lütfen ilgili alanları doldurunuz.');
      }
    },
    f_controlDateEligibility: function (selected_col_ind, target_col_ind) {
      if (!this.column_list[target_col_ind].data_selection || (this.column_list[target_col_ind].data_selection && ['2', '3'].indexOf(this.column_list[target_col_ind].data_selection) === -1)) {
        return false;
      }
      if (selected_col_ind !== target_col_ind) {
        if (!this.column_list[target_col_ind].date_ability || (this.column_list[target_col_ind].date_ability && this.column_list[target_col_ind].date_ability !== 'yes')) {
          return false;
        }
        // seçili kolon başka bir kolonun içerisinde kullanıldıysa da o kolon tarih ilişkisi seçilemez
        for (let i in this.column_list) {
          if (this.column_list[i].date_relation) {
            for (let d in this.column_list[i].date_relation) {
              if (this.column_list[i].date_relation[d].col_ind === selected_col_ind && parseInt(i) === target_col_ind) {
                return false;
              }
            }
          }
        }
      } else {
        return false;
      }
      return true;
    },
    f_addDateRelation: function () {
      if (!this.column_list[this.d_selectedColumnInd].date_relation) {
        this.column_list[this.d_selectedColumnInd].date_relation = [];
      }
      let x = { 'op': this.d_selectedDateRelation.op, 'col_ind': this.d_selectedDateRelation.col_ind };
      let det = false;
      for (let i in this.column_list[this.d_selectedColumnInd].date_relation) {
        if (this.column_list[this.d_selectedColumnInd].date_relation[i].col_ind === x.col_ind) {
          det = true;
          this.column_list[this.d_selectedColumnInd].date_relation[i] = x;
        }
      }
      if (!det) {
        this.column_list[this.d_selectedColumnInd].date_relation.push(x);
      }
      this.$forceUpdate();
    },
    f_deleteAllColumns: function () {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {
        if (confirm('Kolonları silmek istediğinizden emin misiniz.')) {
          if (this.d_selectedProjectData.design && this.d_selectedProjectData.design.column_list) {
            this.d_selectedColumnInd = '';
            this.d_selectedProjectData.design.column_list = [];
            this.column_list = [];
            let data = {};
            data = { 'data': this.d_selectedProjectData };
            this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İşlem gerçekleştiriliyor. Lütfen bekleyiniz.' } });
            PoleStarService.save_polestar_project(data).then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              if (resp.data.status === "success") {
                this.d_selectedProjectData = resp.data.result.project;
                this.$forceUpdate();
              } else {
                let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
                this.$store.commit('modal', modal_data);
                this.$store.commit('modal_show', true);
              }
            });
          }
        }
      } else {
        let msg = 'Kolon düzenleme yetkiniz yok. Lütfen proje yetkilisi ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Proje kolonları düzenleme işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_showDetailsOnLeft: function (col_ind) {
      for (let i in this.d_prepared_columns[this.column_list[col_ind].type].list) {
        if (this.d_prepared_columns[this.column_list[col_ind].type].list[i].value === this.column_list[col_ind].value) {
          this.d_prepared_columns[this.column_list[col_ind].type].list[i] = JSON.parse(JSON.stringify(this.column_list[col_ind]));
          break;
        }
      }
      this.$forceUpdate();
    },
    f_addSelectedTreatmentCreatedBy: function (section_ind, list_ind) {
      for (let i in this.d_prepared_columns[section_ind]['list'][list_ind].filter.created_by) {
        if (this.d_prepared_columns[section_ind]['list'][list_ind].filter.created_by[i].value === this.d_selectedTreatmentCreatedBy.value) {
          return;
        }
      }
      this.d_prepared_columns[section_ind]['list'][list_ind].filter.created_by.push(this.d_selectedTreatmentCreatedBy);
    },
    f_addSelectedTreatmentStatus: function (section_ind, list_ind) {
      for (let i in this.d_prepared_columns[section_ind]['list'][list_ind].filter.treatment_status) {
        if (this.d_prepared_columns[section_ind]['list'][list_ind].filter.treatment_status[i].value === this.d_selectedTreatmentStatus.value) {
          return;
        }
      }
      this.d_prepared_columns[section_ind]['list'][list_ind].filter.treatment_status.push(this.d_selectedTreatmentStatus);
    },
    f_addSelectedTreatmentReason: function (section_ind, list_ind) {
      for (let i in this.d_prepared_columns[section_ind]['list'][list_ind].filter.treatment_reason) {
        if (this.d_prepared_columns[section_ind]['list'][list_ind].filter.treatment_reason[i].value === this.d_selectedTreatmentReason.value) {
          return;
        }
      }
      this.d_prepared_columns[section_ind]['list'][list_ind].filter.treatment_reason.push(this.d_selectedTreatmentReason);
    },
    f_addSelectedDrugToTreatmentList: function (section_ind, list_ind) {
      for (let i in this.d_prepared_columns[section_ind]['list'][list_ind].filter.selected_drugs) {
        if (this.d_prepared_columns[section_ind]['list'][list_ind].filter.selected_drugs[i].value === this.d_selectedDrug.value) {
          return;
        }
      }
      this.d_prepared_columns[section_ind]['list'][list_ind].filter.selected_drugs.push(this.d_selectedDrug);
    },
    f_addPreparedColumn: function (column) {
      this.column_list.push(column);
      this.$forceUpdate();
    },
    f_addRuleToSelectedColumns: function (colrule_ind) {
      // ilgili rule içerisindeki veri tipinin ilgili kolon ile aynı olup olmadığını kontrol edip ekliyoruz.
      let data_type = this.d_selectedProjectData.rule.column_rule.list[colrule_ind].query_list[0].type.value;
      if (this.d_selectedColumnList.length > 0) {
        for (let i in this.d_selectedColumnList) {
          if (data_type === this.column_list[this.d_selectedColumnList[i]].wdm_type) {
            this.column_list[this.d_selectedColumnList[i]].rule_ind = colrule_ind;
          }
        }
      } else {
        alert('Seçili kolon yok. Lütfen kolon seçimi yapınız.');
      }
      this.$forceUpdate();
    },
    f_addColumnIndToList: function (colrule_ind) {
      if (this.d_selectedColumnList.indexOf(colrule_ind) === -1) {
        this.d_selectedColumnList.push(colrule_ind);
      } else {
        this.d_selectedColumnList.splice(this.d_selectedColumnList.indexOf(colrule_ind), 1);
      }
      this.$forceUpdate();
    },
    DateFormat: function (date) {
      return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
    },
    f_goToProjectList: function () {
      this.$router.push({ path: '/polestar/projects' });
    },
    f_goToWisdomStats: function () {
      this.$router.push({ path: '/polestar/wisdomstats', query: { project_id: this.d_selectedProjectData.id } });
    },
    f_createGlobalProject: function () {
      if (!this.user.polestar || (this.user.polestar && !this.user.polestar.username)) {
        this.d_showPolestarUserModal = true;
      } else {
        let data = {
          'polestar_username': this.user.polestar.username,
          'project_id': this.d_selectedProjectData.id,
          'type': 'project' // project, project_and_dataset
        };
        PoleStarService.transfer_global_polestar_project(data)
          .then(resp => {
            console.log(resp.data);
            if (resp.data.status === 'success') {} else {}
          });
      }
    },
    f_goToPoleStarArticle: function () {
      this.$router.push({ path: '/polestar/article', query: { project_id: this.d_selectedProjectData.id } });
    },
    f_scrollCaseTable: function () {
      var content = document.getElementById("case-table");
      // var scrolled = document.scrollingElement.scrollTop;
      // console.log('scrolled', scrolled);
      var position = content.offsetTop;
      var scrollHeight = content.scrollHeight;
      console.log('position', position);
      console.log('scrollHeight', scrollHeight);
    },
    f_downloadExcelFile: function (file) {
      FileSaver.saveAs(file, "cases.xlsx");
    },
    f_getCases: function () {
      if (this.d_selectedProjectData.design && this.d_selectedProjectData.design.column_list && this.d_selectedProjectData.design.column_list.length > 0) {
        let data = {
          'project': this.d_selectedProjectData,
          'hospital_list': [],
          'hospital_type': 'all',
          'user_type': 'all'
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Vaka listeniz hazırlanıyor. Lütfen bekleyiniz.' } });
        PoleStarService.get_project_cases(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              this.d_selectedProjectAnalyze.case_rows = resp.data.result.case_rows;
              this.d_selectedProjectAnalyze.case_columns = resp.data.result.case_columns;
              this.d_selectedProjectAnalyze.excel_file = resp.data.result.excel_file;
              // FileSaver.saveAs(this.d_selectedProjectAnalyze.excel_file, "xxx.xlsx");
            } else {
              let msg = resp.data.message;
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İstatistik için verilerin hazırlanması işlemi' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          });
      } else {
        let msg = 'Lütfen kolon listenizi hazırlayınız';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İstatistik için verilerin hazırlanması işlemi' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_selectColumn: function (col_ind) {
      this.d_selectedColumnInd = col_ind;
      this.$forceUpdate();
    },
    f_deleteColumn: function (col_ind) {
      this.column_list.splice(col_ind, 1);
      this.$forceUpdate();
    },
    f_saveWisdomData: function () {
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['name', 'delete']);
      let wdmr_name = return_data.name;
      if (wdmr_name) {
        this.wisdom_data.label = wdmr_name;
      }
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = {
          'type': 'alert',
          'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.',
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' *' + wdmr_require_alerts[r].label;
        }
        let modal_data = {
          'type': 'alert',
          'text': require_txt,
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      let data = {};
      data = { 'data': this.wisdom_data };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İşlem gerçekleştiriliyor. Lütfen bekleyiniz.' } });
      PoleStarService.save_polestar_project(data).then(resp => {
        this.$store.commit('Loading', { 'status': false, 'data': {} });
        if (resp.data.status === "success") {
          this.d_selectedProjectData = resp.data.result.project;
          this.f_showProjectDetailsResume();
          this.show_wisdom_data_modal = false;
          this.f_createProjectWdmList();
        } else {
          let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
        this.$forceUpdate();
      });
    },
    f_savePoleStarProject: function () {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_general_info_edit') !== -1) {
        if (!this.d_selectedProjectData.rule) {
          this.d_selectedProjectData.rule = {};
        }
        if (!this.d_selectedProjectData.rule.main_rule) {
          this.d_selectedProjectData.rule.main_rule = {};
        }
        if (this.d_mainRuleData.wra_settings.mode === 'param') {
          ClsRule.f_paramToQuery(this.query_list, this.compile_str_array, this.d_mainRuleData.wps_data);
        }
        this.d_selectedProjectData.rule['main_rule'] = {
          'query_list': this.query_list,
          'compile_str_array': this.compile_str_array,
          'compile_str_date': this.compile_str_date,
          'compile_str_text': this.compile_str_text
        };
        this.d_selectedProjectData.design = {
          'column_list': this.column_list
            // 'column_date_relations': this.d_columnDateRelation
        };
        ClsPolestar.f_controlColumnFeatures(this.column_list, '', 'all')
        let data = { 'data': this.d_selectedProjectData };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İşlem gerçekleştiriliyor. Lütfen bekleyiniz.' } });
        PoleStarService.save_polestar_project(data).then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          let msg = '';
          if (resp.data.status === "success") {
            this.d_selectedProjectData = resp.data.result.project;
            msg = 'Proje başarıyla kaydedildi.';
          } else {
            msg = resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          this.$forceUpdate();
        });
      } else {
        let msg = 'Proje düzenleme yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Proje düzenleme işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_showProjectDetailsResume: function () {
      this.d_showProjectDetails = false;
      setTimeout(function () {
        this.d_showProjectDetails = true;
        this.$forceUpdate();
      }.bind(this), 500)
    },
    f_ruleQueryTrueFalse: function () {
      this.d_showRuleQuery = false;
      setTimeout(function () {
        this.d_showRuleQuery = true;
        this.$forceUpdate();
      }.bind(this), 500)
    },
    f_editProject: function () {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_general_info_edit') !== -1) {
        this.wisdom_op = "edit";
        this.data_type = 'wdm3';
        this.wisdom_data = JSON.parse(JSON.stringify(this.d_selectedProjectData));
        // yeni grup tanımlandığında problem oluşturuyor. Dolayısıyla yeni bir grup tanımlandıysa wdmde onu önce edit modda da olsa oluşturuyoruz.
        for (let i in this.option_data['wdm3'].param_group.param_order) {
          if (!this.wisdom_data['data'][this.option_data['wdm3'].param_group.param_order[i]]) {
            this.wisdom_data['data'][this.option_data['wdm3'].param_group.param_order[i]] = {};
          }
        }
        this.show_wisdom_data_modal = true;
      } else {
        let msg = 'Proje düzenleme yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Proje düzenleme işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_selectThisWdm: function (wdm_type, version, wdm_type_data) {
      this.d_selectedWdmTypeData = wdm_type_data;
      this.d_selectedWdmType = wdm_type;
      this.d_selectedWdmVersion = version;
      this.$forceUpdate();
    },
    f_deleteFromCombine: function (model_ind, param_ind, type, opt_ind, comb_ind) {
      if (type === 'first_value') {
        if (this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['combine_list'] && this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['combine_list'].length > 0) {
          let x = this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['combine_list'][0];
          this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['value'] = x.value;
          this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['label'] = x.label;
          if (this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['translation']) {
            this.$delete(this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind], 'translation');
          }
          if (x.translation) {
            this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['translation'] = x.translation;
          }
          this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['combine_list'].splice(0, 1);
          if (this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['combine_list'].length === 0) {
            this.$delete(this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind], 'combine_list');
          }
        }
      } else {
        this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['combine_list'].splice(comb_ind, 1);
        if (this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['combine_list'].length === 0) {
          this.$delete(this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind], 'combine_list');
        }
      }
      this.$forceUpdate();
    },
    f_addAsCombineOption: function (model_ind, param_ind) {
      for (let i in this.d_addedModels[model_ind]['parameters'][param_ind]['options']) {
        if (this.d_addedModels[model_ind]['parameters'][param_ind]['options'][i].selected) {
          if (!this.d_addedModels[model_ind]['parameters'][param_ind]['options'][i].combine_list) {
            this.d_addedModels[model_ind]['parameters'][param_ind]['options'][i].combine_list = [];
            this.d_addedModels[model_ind]['parameters'][param_ind]['options'][i].combine_list.push(JSON.parse(JSON.stringify(this.d_addedModels[model_ind]['parameters'][param_ind].selected)));
          } else {
            for (let k in this.d_addedModels[model_ind]['parameters'][param_ind]['options'][i].combine_list) {
              if (this.d_addedModels[model_ind]['parameters'][param_ind]['options'][i].combine_list[k].value === this.d_addedModels[model_ind]['parameters'][param_ind].selected.value) {
                return;
              }
            }
            this.d_addedModels[model_ind]['parameters'][param_ind]['options'][i].combine_list.push(JSON.parse(JSON.stringify(this.d_addedModels[model_ind]['parameters'][param_ind].selected)));
          }
        }
      }
      this.$forceUpdate();
    },
    f_anySelectedOptionForCombine: function (param) {
      if (param.selected) {
        for (let i in param.options) {
          if (param.options[i].selected) {
            if (param.options[i].value !== param.selected.value) {
              return true;
            }
          }
        }
      }
      return false;
    },
    f_selectThisOption: function (model_ind, pr, opt_ind, type) {
      // console.log('model_ind', model_ind);
      // console.log('pr', pr);
      // console.log('opt_ind', opt_ind);
      for (let i in this.d_addedModels[model_ind]['parameters'][pr]['options']) {
        this.$delete(this.d_addedModels[model_ind]['parameters'][pr]['options'][i], 'selected');
      }
      this.d_addedModels[model_ind]['parameters'][pr]['options'][opt_ind].selected = 1;
      this.$forceUpdate();
    },
    f_analyzeWithModels: function () {
      for (let i in this.d_addedModels) {
        this.d_addedModels[i].rows = [];
        this.d_addedModels[i].columns = [];
        this.d_addedModels[i].file = '';
        if (this.d_addedModels[i].row.length === 0 || this.d_addedModels[i].column.length === 0) {
          alert('Lütfen istantistiksel analize başlamadan önce satır ve sütun değerlerini belirtiniz.');
          return;
        }
      }
      // this.d_showTable = false;
      let data = { 'models': this.d_addedModels };
      // console.log('before send', data);
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kutup Yıldızı istatistiksel modellerinizi analiz ediyor. Lütfen bekleyiniz.' } });
      PoleStarService.analyze_with_statistic_models(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            // console.log('resp', resp.data.result);
            for (let i in resp.data.result) {
              this.d_addedModels[i] = resp.data.result[i];
              for (let param_ind in this.d_addedModels[i]['parameters']) {
                // date_type sebepsiz yere undefined oluyor. Bu nedenle kontrol edip düzeltiyoruz.
                if (this.d_addedModels[i]['parameters']['type'] === 'date' && !this.d_addedModels[i]['parameters']['date_type']) {
                  this.d_addedModels[i]['parameters']['date_type'] = 'none';
                  this.d_addedModels[i]['parameters']['starting_at'] = '';
                  this.d_addedModels[i]['parameters']['ending_at'] = '';
                }
              }
            }
          } else {
            alert('Error', resp.data.message);
          }
          // this.d_showTable = true;
          this.$forceUpdate();
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    f_deleteParam: function (model_ind, param_ind) {
      if (this.d_addedModels[model_ind]['row'].indexOf(param_ind) !== -1) {
        this.d_addedModels[model_ind]['row'].splice(param_ind);
      }
      if (this.d_addedModels[model_ind]['column'].indexOf(param_ind) !== -1) {
        this.d_addedModels[model_ind]['column'].splice(param_ind);
      }
      this.d_addedModels[model_ind]['parameters'][param_ind]['options'] = [];
      this.$forceUpdate();
    },
    f_filterWithSelectedValue: function (model_ind, param_ind, type) {
      if (type === 'filter') {
        let det = false;
        for (let f in this.d_addedModels[model_ind]['parameters'][param_ind]['filter']) {
          if (this.d_addedModels[model_ind]['parameters'][param_ind]['filter'][f].value === this.d_addedModels[model_ind]['parameters'][param_ind].selected.value) {
            det = true;
            alert('Bu parametre ekli görünmektedir.');
            return;
          }
        }
        if (!det) {
          this.d_addedModels[model_ind]['parameters'][param_ind]['filter'].push(JSON.parse(JSON.stringify(this.d_addedModels[model_ind]['parameters'][param_ind].selected)));
          // row ya da columnda ekli ise temizliyoruz.
          // aynı anda bağımlı ya da bağımsız değişken olamaz.
          if (this.d_addedModels[model_ind].column.indexOf(param_ind) !== -1) {
            this.d_addedModels[model_ind].column.splice(param_ind, 1);
          }
          if (this.d_addedModels[model_ind].row.indexOf(param_ind) !== -1) {
            this.d_addedModels[model_ind].row.splice(param_ind, 1);
          }
          this.d_addedModels[model_ind]['parameters'][param_ind]['option_type'] = 'filter';
          this.d_addedModels[model_ind]['parameters'][param_ind]['options'] = [];
        }
      } else if (type === 'row') {
        this.d_addedModels[model_ind]['parameters'][param_ind]['filter'] = [];
        if (this.d_addedModels[model_ind].row.indexOf(param_ind) === -1) {
          this.d_addedModels[model_ind].row.push(param_ind);
        }
        if (this.d_addedModels[model_ind].column.indexOf(param_ind) !== -1) {
          this.d_addedModels[model_ind].column.splice(param_ind, 1);
        }
        this.d_addedModels[model_ind]['parameters'][param_ind]['option_type'] = 'all';
        this.d_addedModels[model_ind]['parameters'][param_ind]['options'] = JSON.parse(JSON.stringify(this.d_statisticalParameterOptions[param_ind]));
      } else if (type === 'row_selected') {
        this.d_addedModels[model_ind]['parameters'][param_ind]['filter'] = [];
        if (this.d_addedModels[model_ind].row.indexOf(param_ind) === -1) {
          this.d_addedModels[model_ind].row.push(param_ind);
        }
        if (this.d_addedModels[model_ind].column.indexOf(param_ind) !== -1) {
          this.d_addedModels[model_ind].column.splice(param_ind, 1);
        }
        if (this.d_addedModels[model_ind]['parameters'][param_ind]['option_type'] === 'selected') {
          let det = false;
          for (let i in this.d_addedModels[model_ind]['parameters'][param_ind]['options']) {
            if (this.d_addedModels[model_ind]['parameters'][param_ind]['options'][i].value === this.d_addedModels[model_ind]['parameters'][param_ind].selected.value) {
              det = true;
            }
          }
          if (!det) {
            this.d_addedModels[model_ind]['parameters'][param_ind]['options'].push(JSON.parse(JSON.stringify(this.d_addedModels[model_ind]['parameters'][param_ind].selected)));
          }
        } else {
          // alldan selecteda geçişte önce optionsı temizliyoruz.
          this.d_addedModels[model_ind]['parameters'][param_ind]['option_type'] = 'selected';
          this.d_addedModels[model_ind]['parameters'][param_ind]['options'] = [];
          this.d_addedModels[model_ind]['parameters'][param_ind]['options'].push(JSON.parse(JSON.stringify(this.d_addedModels[model_ind]['parameters'][param_ind].selected)));
        }
      } else if (type === 'column') {
        this.d_addedModels[model_ind]['parameters'][param_ind]['filter'] = [];
        if (this.d_addedModels[model_ind].column.indexOf(param_ind) === -1) {
          this.d_addedModels[model_ind].column.push(param_ind);
        }
        if (this.d_addedModels[model_ind].row.indexOf(param_ind) !== -1) {
          this.d_addedModels[model_ind].row.splice(param_ind, 1);
        }
        this.d_addedModels[model_ind]['parameters'][param_ind]['option_type'] = 'all';
        this.d_addedModels[model_ind]['parameters'][param_ind]['options'] = JSON.parse(JSON.stringify(this.d_statisticalParameterOptions[param_ind]));
      } else if (type === 'column_selected') {
        this.d_addedModels[model_ind]['parameters'][param_ind]['filter'] = [];
        if (this.d_addedModels[model_ind].column.indexOf(param_ind) === -1) {
          this.d_addedModels[model_ind].column.push(param_ind);
        }
        if (this.d_addedModels[model_ind].row.indexOf(param_ind) !== -1) {
          this.d_addedModels[model_ind].row.splice(param_ind, 1);
        }
        if (this.d_addedModels[model_ind]['parameters'][param_ind]['option_type'] === 'selected') {
          let det = false;
          for (let i in this.d_addedModels[model_ind]['parameters'][param_ind]['options']) {
            if (this.d_addedModels[model_ind]['parameters'][param_ind]['options'][i].value === this.d_addedModels[model_ind]['parameters'][param_ind].selected.value) {
              det = true;
            }
          }
          if (!det) {
            this.d_addedModels[model_ind]['parameters'][param_ind]['options'].push(JSON.parse(JSON.stringify(this.d_addedModels[model_ind]['parameters'][param_ind].selected)));
          }
        } else {
          // alldan selecteda geçişte önce optionsı temizliyoruz.
          this.d_addedModels[model_ind]['parameters'][param_ind]['option_type'] = 'selected';
          this.d_addedModels[model_ind]['parameters'][param_ind]['options'] = [];
          this.d_addedModels[model_ind]['parameters'][param_ind]['options'].push(JSON.parse(JSON.stringify(this.d_addedModels[model_ind]['parameters'][param_ind].selected)));
        }
      }
      this.$forceUpdate();
    },
    f_addStatisticalModel: function () {
      if (this.d_selectedStatisticalModel) {
        // eklerken aynı modeli birden fazla parametre kombinasyonuyla kullanabileceğinden sorgulama yapmıyoruz.
        /*
        for (let i in this.d_addedModels) {
          if (this.d_addedModels[i].type === this.d_selectedStatisticalModel.type && this.d_addedModels[i].value === this.d_selectedStatisticalModel.value) {
            alert('Bu model ekli görünmektedir.');
            return;
          }
        }
        */
        this.d_addedModels.push(JSON.parse(JSON.stringify(this.d_selectedStatisticalModel)));
        if (this.d_selectedStatisticalModel['parameters']['diagnosis_main_group_code']) {
          if (this.d_statisticalParameterOptions['diagnosis_main_group_code'].length === 0) {
            this.f_getAllPatientRecordedDiagnosisList();
          }
          if (this.d_statisticalParameterOptions['hospital_list'].length === 0) {
            this.f_getHospitalGroup(this.d_selectedStatisticalModel.type);
          }
        }
        // this.$forceUpdate();
        // this.f_showTrueFalse();
      }
    },
    'f_getAllPatientRecordedDiagnosisList': function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Sistemde şu ana kadar kullanılmış olan tüm tanılar listeleniyor' } });
      PatientService.find_all_used_diagnosis()
        .then(resp => {
          if (resp.data.status === 'success') {
            for (let icd_code in resp.data.result) {
              // subgrupta direkt push yapıyrouz. main grup için ise noktadan ayrıştırarak kontrol edip push yapıyoruz.
              this.d_statisticalParameterOptions['diagnosis_sub_group_code'].push(resp.data.result[icd_code]);
              let det = false;
              let main_group = '';
              if (icd_code.indexOf('.') === -1) { // c18 ise
                main_group = icd_code;
              } else { // örneğin c18.5
                main_group = icd_code.split('.')[0];
              }
              for (let i in this.d_statisticalParameterOptions['diagnosis_main_group_code']) {
                if (this.d_statisticalParameterOptions['diagnosis_main_group_code'][i].value === main_group) {
                  det = true;
                  break;
                }
              }
              if (!det) {
                let x = { 'label': main_group, 'value': main_group };
                this.d_statisticalParameterOptions['diagnosis_main_group_code'].push(x);
              }
            }
          }
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        });
    },
    f_getHospitalGroup: function (stat_model_type) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Hastane listesi yükleniyor' } });
      HospitalService.get_all_hospitals_list()
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (resp.data.status === 'success') {
            if (stat_model_type === 'my_hospital_group') {
              this.d_hospitalGroupList = resp.data.result;
            }
            for (let hg in this.d_hospitalGroupList) {
              if (this.d_hospitalGroupList[hg]['value'] === this.user.hospital_group) {
                this.d_statisticalParameterOptions['hospital_list'] = this.d_hospitalGroupList[hg]['hospital_list'];
                break;
              }
            }
          } else {
            alert('Grup kaydı henüz yok.');
          }
        });
    },
    f_showTrueFalse: function () {
      this.d_showTable = false;
      setTimeout(function () {
        this.d_showTable = true;
        this.$forceUpdate();
      }.bind(this), 100)
    }
  },
  watch: {
    'wdmr_change.rule': function () {
      // this.f_ruleQueryTrueFalse();
    },
    'd_selectedWdmTypeData': function () {
      if (this.d_selectedWdmTypeData) {
        // console.log(this.d_selectedWdmTypeData);
        let bucket = '';
        if (this.d_selectedWdmTypeData.group === 'wisdom') {
          bucket = 'option';
        } else {
          bucket = 'hospital';
        }
        this.show_wisdom_data_model = false;
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Veri modeli yükleniyor. Lütfen bekleyiniz.' } });
        GlobalService.get_one_cb_document({ 'key': this.d_selectedWdmTypeData.key, 'bucket': bucket })
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let data_type = this.d_selectedWdmTypeData.value;
            if (resp.data.status === 'success') {
              let option_data = resp.data.result;
              this.selected_option = {};
              this.selected_option[data_type] = option_data;
              for (let i in option_data.param_group.param_order) {
                this.prepare_data['data'][option_data.param_group.param_order[i]] = {};
              }
              this.show_wisdom_data_model = true;
            } else {
              alert('error ', resp.data.message);
            }
          });
      }
    },
    'd_selectedStatisticalModelGroup': function () {
      this.d_selectedStatisticalModel = '';
    }
  }
};

</script>

<style>
.parameter-design-full-screen {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid 1px #20a8d8;
  /*background-color: rgba(0, 0, 0, .5);*/
  display: table;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  margin-bottom: 0px;
}

.not-parameter-design-full-screen {
  width: 100%;
  height: 100%;
  border: solid 1px #20a8d8;
  /*background-color: rgba(0, 0, 0, .5);*/
  display: table;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>

